import React from 'react';

import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import { isEmpty } from '@/utils/helpers';

const StyledSearch = styled.div`
  position: relative;
  width: 100%;
  max-width: 560px;

  input {
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    width: 100%;
    padding: 1em;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => props.theme.offWhite};
    border: none;
    font-family: inherit;
    transition: background-color 0.2s ease;
    -webkit-appearance: none;
    color: ${props => props.theme.black};

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    &:focus:enabled,
    &:active:enabled,
    &:hover:enabled {
      background-color: ${props => props.theme.offWhiteHover};
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border: none;
    background: none;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px 0 0;
    color: ${props => props.theme.black};

    &.search-button {
      pointer-events: none;
    }
  }
`;

interface SearchProps {
  name: string;
  placeholder: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClose: () => void;
  autoFocus?: boolean;
}

function Search({
  name,
  placeholder,
  value,
  onChange,
  onClose,
  autoFocus,
}: SearchProps): JSX.Element {
  return (
    <StyledSearch className="search">
      <input
        name={name}
        id={name}
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        aria-label={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
      {isEmpty(value) ? (
        <button className="search-button" type="button" aria-label={name}>
          <SearchIcon />
        </button>
      ) : (
        <button className="close" type="button" onClick={onClose} aria-label="Close">
          <CloseIcon />
        </button>
      )}
    </StyledSearch>
  );
}

export default Search;
