import React, { Suspense } from 'react';

const CookiePolicyContent = React.lazy(() => import('./CookiePolicyContent'));

const CookiePolicy = () => (
  <Suspense fallback={<div />}>
    <CookiePolicyContent />
  </Suspense>
);

export default CookiePolicy;
