import React from 'react';

import Accordion from '@/components/accordion/Accordion';

const LigaExplainerText = (): JSX.Element => {
  return (
    <div>
      <p className="font-bold">
        Joacă, învârte cele 19 Roți și câștigă în fiecare Ligă! Rotiri Gratuite garantat și Bonusuri
        săptămânale!
      </p>
      <div className="grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-2 text-sm [&_p]:mb-1">
        <div>
          <p>
            <span className="font-bold">Pasul #1:</span> Optează, efectuează prima depunere și
            verifică-ți contul pentru a debloca Roata cu premii din Liga 7.
          </p>
          <p>
            <span className="font-bold">Pasul #2:</span> Câștigă 1 punct pentru fiecare 1 Leu cash
            pariat la sloturi.
          </p>
          <p>
            <span className="font-bold">Pasul #3:</span> Avansează din Liga 7 până în Liga 1 pentru
            a debloca toate Roțile cu premii din fiecare săptămână!
          </p>
        </div>
        <div>
          <div className="font-bold">Premii din ce în ce mai mari!</div>
          <p>- Cu cât avansezi într-o Ligă superioară, cu atât premiile vor fi mai mari!</p>
          <p>- Ai un premiu garantat pentru fiecare Roata din Ligile 6 - 1!</p>
          <p>- Poți juca săptămânal! LigaMagică se resetează în fiecare Duminică, la 23:59 EEST.</p>
        </div>
      </div>
    </div>
  );
};

const LigaOverviewExplainer = ({ ligaOptIn }: { ligaOptIn: boolean }): JSX.Element => {
  if (ligaOptIn) {
    return (
      <Accordion question={'Cum funcționează LigaMagică?'}>
        <LigaExplainerText />
      </Accordion>
    );
  }

  return (
    <div className="text-left text-lg mb-8 mt-8">
      <p className="text-2xl">Cum funcționează LigaMagică?</p>
      <LigaExplainerText />
    </div>
  );
};

export default LigaOverviewExplainer;
