import { createSelector } from 'reselect';

import { CashierBonus } from '@/models/cashier-bonus.model';
import { ExtendedBonusModel } from '@/models/extended-bonus.model';
import { PublicBonusesModel } from '@/models/public-bonuses-object.model';
import { RootState } from '@/models/root-state.model';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';
import { isXmasBonus } from '@/utils/helpers';

export const selectAvailableBonuses = (state: RootState): PublicBonusesModel[] =>
  state.availableBonuses.availableBonuses;
export const selectAvailableBonusesLoading = (state: RootState): boolean =>
  state.availableBonuses.loading;
export const selectAvailableBonusesError = (state: RootState): boolean =>
  state.availableBonuses.error;

export const selectAvailableBonusesContent = (
  state: RootState,
): Record<number, WpBonusContentModel | null> => state.availableBonuses.content;

export const selectAvailableBonusesWithContent = createSelector(
  selectAvailableBonuses,
  selectAvailableBonusesContent,
  (bonuses, content): ExtendedBonusModel[] =>
    bonuses
      .filter(bonus => !!content[bonus.BonusId])
      .map(bonus => ({ ...bonus, Content: content[bonus.BonusId]! })),
);

export const selectAvailableBonusesForHome = createSelector(
  selectAvailableBonusesWithContent,
  bonuses => {
    return bonuses.filter(bonus => !isXmasBonus(bonus));
  },
);

export const selectFirstAvailableBonus = createSelector(
  selectAvailableBonusesForHome,
  (bonuses): ExtendedBonusModel | null => (bonuses.length > 0 ? bonuses[0] : null),
);

export const selectAvailableBonusesFetched = createSelector(
  selectAvailableBonuses,
  selectAvailableBonusesContent,
  selectAvailableBonusesError,
  selectAvailableBonusesLoading,
  (bonuses, content, error, loading): boolean => {
    if (loading) {
      return false;
    }

    if (error) {
      return true;
    }

    return bonuses.reduce((allFetched: boolean, bonus) => {
      if (content[bonus.BonusId] === undefined) {
        return false;
      }
      return allFetched;
    }, true);
  },
);

export const selectCashierBonuses = createSelector(selectAvailableBonusesWithContent, bonuses => {
  return bonuses.reduce((reducedBonuses: CashierBonus[], bonus): CashierBonus[] => {
    // Don't push opt in or free spins on game launch bonuses
    if (bonus.RedeemTypeId === 5 || bonus.RedeemTypeId === 6) {
      reducedBonuses.push({
        bonusAmount: {},
        code: bonus.PromoCodes[0],
        title: { en: bonus.Content.header || bonus.Name },
        desc: { en: bonus.Content.description || bonus.Description },
        excludedPaymentMethods: [],
        fixed: false,
        id: bonus.BonusId.toString(),
        isCodeOnly: true,
        minAmount: {},
        multiplier: 0,
        typeId: bonus.TypeId,
      });
    }
    return reducedBonuses;
  }, []);
});

export const selectHasWelcomeBonuses = createSelector(selectAvailableBonuses, bonuses => {
  if (bonuses.length === 0) return false;
  if (!bonuses.find(bonus => bonus.Name.toLowerCase().includes('welcome'))) return false;
  return true;
});
