import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import storeSynchronize from 'redux-localstore';
import createSagaMiddleware from 'redux-saga';

import { apiServiceInterceptor } from '@/shared/api-service';
import { bffApiServiceInterceptor } from '@/shared/bff-api-service';
import { activeBonusesReducer } from '@/store/reducers/activeBonusesReducer';
import { authReducer } from '@/store/reducers/authReducer';
import { availableBonusesReducer } from '@/store/reducers/availableBonusesReducer';
import { commonReducer } from '@/store/reducers/commonReducer';
import { consentsReducer } from '@/store/reducers/consentsReducer';
import { cookiesReducer } from '@/store/reducers/cookiesReducer';
import { depositReducer } from '@/store/reducers/depositReducer';
import { drawerReducer } from '@/store/reducers/drawerReducer';
import { gamesReducer } from '@/store/reducers/gamesReducer';
import { kycReducer } from '@/store/reducers/kycReducer';
import { manualBonusesReducer } from '@/store/reducers/manualBonusesReducer';
import { modalReducer } from '@/store/reducers/modalReducer';
import { promotionsReducer } from '@/store/reducers/promotionsReducer';
import { userReducer } from '@/store/reducers/userReducer';
import { walletReducer } from '@/store/reducers/walletReducer';
import { wordpressReducer } from '@/store/reducers/wordpressReducer';
import { watchActiveBonuses } from '@/store/sagas/activeBonusesSagas';
import { watchAuth } from '@/store/sagas/authSagas';
import { watchAvailableBonuses } from '@/store/sagas/availableBonusesSagas';
import { watchConsents } from '@/store/sagas/consentsSagas';
import { watchDeposit } from '@/store/sagas/depositSagas';
import { watchGames } from '@/store/sagas/gamesSagas';
import { watchKyc } from '@/store/sagas/kycSagas';
import { watchManualBonuses } from '@/store/sagas/manualBonusesSagas';
import { watchPromotions } from '@/store/sagas/promotionsSagas';
import { watchUser } from '@/store/sagas/userSagas';
import { watchWallet } from '@/store/sagas/walletSagas';
import { watchWordpress } from '@/store/sagas/wordpressSagas';

const rootReducer = combineReducers({
  modal: modalReducer,
  drawer: drawerReducer,
  user: userReducer,
  gamesBff: gamesReducer,
  availableBonuses: availableBonusesReducer,
  activeBonuses: activeBonusesReducer,
  manualBonuses: manualBonusesReducer,
  consents: consentsReducer,
  auth: authReducer,
  kyc: kycReducer,
  wallet: walletReducer,
  deposit: depositReducer,
  common: commonReducer,
  cookies: cookiesReducer,
  promotions: promotionsReducer,
  wordpress: wordpressReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchManualBonuses);
sagaMiddleware.run(watchAvailableBonuses);
sagaMiddleware.run(watchActiveBonuses);
sagaMiddleware.run(watchConsents);
sagaMiddleware.run(watchGames);
sagaMiddleware.run(watchKyc);
sagaMiddleware.run(watchWallet);
sagaMiddleware.run(watchDeposit);
sagaMiddleware.run(watchPromotions);
sagaMiddleware.run(watchWordpress);

apiServiceInterceptor(store);
bffApiServiceInterceptor(store);

export default store;

storeSynchronize(store, {
  whitelist: ['gamesBff'],
});
