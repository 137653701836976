import * as React from 'react';
import { useEffect, useState } from 'react';

import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LigaPrizeWheelBtn from './LigaPrizeWheelBtn';
import Button from '@/components/button/Button';
import useApiV2Request from '@/hooks/useApiV2Request';
import { useCashierShow } from '@/hooks/useCashier';
import {
  Liga,
  LigaLevelsEnum,
  LigaLevelStatus,
  LigaPrizeWheel,
  LigaPrizeWheels,
  LigaWheelLevelsEnum,
} from '@/models/liga.model';
import ApiService from '@/shared/api-service';
import { openLigaTOSModal } from '@/store/actions/modalActions';
import { getUserTags } from '@/store/actions/userActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { selectUserLigaOptIn } from '@/store/selectors/userSelectors';
import { isLigaWinterPeriod, ligaBadgeChipsUrl } from '@/utils/liga-utils';

const Liga7Prerequisites = ({
  isDepositor,
  isVerified,
}: {
  isDepositor: boolean;
  isVerified: boolean;
}): JSX.Element => {
  const showCashier = useCashierShow();
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-1 gap-2 mt-4">
      <p>Finalizează verificarea pentru a accesa roata cu premii!</p>
      <button
        disabled={isVerified}
        type="button"
        onClick={() => navigate('/setarile-mele/verificare')}
        className="flex gap-4 items-center bg-gray-600 p-2 rounded-md"
      >
        {isVerified && <CheckCircleIcon className="w-8 fill-green-400" />}
        {!isVerified && <ChevronDoubleRightIcon className="w-8" />}
        <div className="flex flex-col items-start">
          <span>Verifică-ți identitatea</span>
          <span className="italic text-xs">Poză Buletin & Dovada Adresei</span>
        </div>
      </button>
      <button
        disabled={isDepositor}
        className="flex gap-4 items-center bg-gray-600 p-2 rounded-md"
        type="button"
        onClick={() => showCashier()}
      >
        {isDepositor && <CheckCircleIcon className="w-8 fill-green-400" />}
        {!isDepositor && <ChevronDoubleRightIcon className="w-8" />}
        <span>Efectuează primul depozit!</span>
      </button>
    </div>
  );
};

const PrizeWheels = ({
  wheels,
  openWheel,
  nextTarget,
}: {
  wheels: { status: LigaLevelStatus; wheel: LigaWheelLevelsEnum }[];
  openWheel: (prizeWheel: LigaPrizeWheel) => void;
  nextTarget: LigaWheelLevelsEnum | 'None' | undefined;
}): JSX.Element => {
  return (
    <div className="flex gap-4 mt-4 justify-center">
      {wheels.map(wheel => (
        <LigaPrizeWheelBtn
          key={`recap-${wheel.wheel}-btn`}
          nextTarget={wheel.wheel === nextTarget}
          status={wheel.status}
          onClick={() => openWheel(wheel)}
          isAuthenticated={true}
        />
      ))}
    </div>
  );
};

const LigaUnclaimedPrizeWheels = ({
  currentLiga,
  prizeWheels,
  availablePrizesClicked,
}: {
  currentLiga: LigaLevelsEnum;
  prizeWheels: LigaPrizeWheels;
  availablePrizesClicked: () => void;
}): JSX.Element | null => {
  const [availableWheels, setAvailableWheels] = useState(0);

  useEffect(() => {
    const availableWheels = (Object.keys(prizeWheels) as LigaLevelsEnum[]).reduce(
      (acc, ligaKey) => {
        // we want to exclude the current liga from the unclaimed prizes notice
        if (currentLiga === ligaKey) {
          return acc;
        }
        const ligaWheels = prizeWheels[ligaKey];
        return (
          ligaWheels.reduce((ligaLevelAcc, wheel) => {
            if (wheel.status === LigaLevelStatus.Eligible) {
              return ligaLevelAcc + 1;
            }
            return ligaLevelAcc;
          }, 0) + acc
        );
      },
      0,
    );
    setAvailableWheels(availableWheels);
  }, [prizeWheels]);

  if (availableWheels === 0) {
    return null;
  }

  return (
    <div>
      <div className="h-[1px] w-full bg-white opacity-20 my-4"></div>
      <div className="text-center">
        {availableWheels === 1 && (
          <span>
            <button type="button" onClick={availablePrizesClicked} className="underline">
              {availableWheels} roată cu premii deblocată
            </button>{' '}
            în ligile anterioare
          </span>
        )}
        {availableWheels !== 1 && (
          <span>
            <button type="button" onClick={availablePrizesClicked} className="underline">
              {availableWheels} roți cu premii deblocate
            </button>{' '}
            în ligile anterioare
          </span>
        )}
      </div>
    </div>
  );
};

const isLigaWinter = isLigaWinterPeriod();

const LigaRecap = ({
  data,
  isVerified,
  isDepositor,
  openWheel,
  availablePrizesClicked,
}: {
  data: Liga;
  isVerified: boolean;
  isDepositor: boolean;
  openWheel: (prizeWheel: LigaPrizeWheel) => void;
  availablePrizesClicked: () => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasLigaOptIn = useSelector(selectUserLigaOptIn);
  const token = useSelector(selectAuthToken);
  const { request: optinRequest } = useApiV2Request();

  const optInHandler = () => {
    optinRequest(ApiService.ligaOptIn, null, () => {
      if (token) {
        dispatch(getUserTags(token));
      }
    });
  };

  const collectPointsHandler = () => {
    navigate('/jocuri/recomandate');
  };

  const tosModalHandler = () => {
    dispatch(openLigaTOSModal());
  };

  return (
    <div className="z-2 max-w-md md:max-w-xl relative bg-brand-gray rounded-lg p-4 shadow-lg justify-around text-center text-white w-full flex flex-col md:flex-row md:items-stretch md:px-8">
      <div>
        <div className="w-full text-center">
          {
            <img
              alt=""
              className={`${!isLigaWinter ? 'w-48' : 'w-36'} inline-block`}
              src={ligaBadgeChipsUrl(data.currentLiga)}
            />
          }
        </div>
        <div className="z-1 text-white flex flex-col relative">
          {(!isVerified || !isDepositor) && (
            <Liga7Prerequisites isDepositor={isDepositor} isVerified={isVerified} />
          )}
          {isVerified && isDepositor && (
            <PrizeWheels
              wheels={data.prizeWheels[data.currentLiga]}
              openWheel={openWheel}
              nextTarget={data.nextPrize.target}
            />
          )}
          {data.nextPrize.target !== LigaWheelLevelsEnum.Liga7Wheel &&
            data.nextPrize.target !== 'None' && (
              <div className="mt-8">
                <div className="bg-gray-500 relative w-full rounded-full py-2 overflow-hidden">
                  <div
                    className={`absolute left-0 top-0 bottom-0 rounded-full bg-brand-gold`}
                    style={{
                      width: `${data.nextPrize.progress}%`,
                    }}
                  ></div>
                  {data.nextPrize.points && (
                    <div className="relative px-2 text-sm">
                      {data.nextPrize.points % 1 === 0
                        ? data.nextPrize.points
                        : data.nextPrize.points.toFixed(2)}{' '}
                      puncte până la următoarea Roată
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
        {data.currentLiga !== LigaLevelsEnum.Liga7 && (
          <LigaUnclaimedPrizeWheels
            currentLiga={data.currentLiga}
            prizeWheels={data.prizeWheels}
            availablePrizesClicked={availablePrizesClicked}
          />
        )}
      </div>
      <div className="h-[1px] w-full bg-white my-4 md:m-0 opacity-20 md:h-auto md:mx-8 md:w-[1px]"></div>
      <div className="md:flex md:justify-center md:flex-col">
        <div>
          <span className="font-bold">Pariază cash la Sloturi</span>
        </div>
        <div className="text-brand-gold font-bold">1 Leu = 1 Punct</div>
        {!hasLigaOptIn && (
          <Button className="mt-4" size="small" color="white" onClick={optInHandler}>
            Optează
          </Button>
        )}
        {hasLigaOptIn && (
          <Button className="mt-4" size="small" color="white" onClick={collectPointsHandler}>
            Adună puncte
          </Button>
        )}
        <div className="text-center mt-4">
          <button type="button" className="underline" onClick={tosModalHandler}>
            Termeni & Condiții
          </button>
        </div>
      </div>
    </div>
  );
};

export default LigaRecap;
