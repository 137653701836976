import { CommonDrawerTypes } from '@/models/enums/drawer.enum';
import { DrawerActions, DrawerActionTypes, OpenDrawerAction } from '@/store/actions/drawerActions';

export interface DrawerStateModel {
  name: CommonDrawerTypes | null;
}

const initialState: DrawerStateModel = {
  name: null,
};

const openDrawer = (state: DrawerStateModel, action: OpenDrawerAction): DrawerStateModel => ({
  ...state,
  name: action.name,
});

const closeDrawer = (state: DrawerStateModel): DrawerStateModel => ({
  ...state,
  name: null,
});

export const drawerReducer = (state = initialState, action: DrawerActions): DrawerStateModel => {
  switch (action.type) {
    case DrawerActionTypes.Open:
      return openDrawer(state, action);
    case DrawerActionTypes.Close:
      return closeDrawer(state);
    default:
      return state;
  }
};
