import React from 'react';

import { useSelector } from 'react-redux';

import GameList from './GameList';
import ProviderCategoryNav from './ProviderCategoryNav';
import { BFF_GAMES_CATEGORY } from '@/constants/constants';
import NoGames from '@/pages/games/NoGames';
import LigaBanner from '@/pages/liga/LigaBanner';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import {
  selectAllGames,
  selectGamesByCategory,
  selectGamesSuccess,
  selectLastPlayedGames,
} from '@/store/selectors/gamesSelectors';
import { isDecember, isEmpty } from '@/utils/helpers';

function GamesLobby(): JSX.Element {
  const gamesSuccess = useSelector(selectGamesSuccess);
  const lastPlayedgames = useSelector(selectLastPlayedGames);
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const allGames = useSelector(selectAllGames);
  const recommendedGames = useSelector(selectGamesByCategory(BFF_GAMES_CATEGORY.RECOMMENDED, 18));
  const newGames = useSelector(selectGamesByCategory(BFF_GAMES_CATEGORY.NEW_GAMES, 18));
  const liveCasino = useSelector(selectGamesByCategory(BFF_GAMES_CATEGORY.LIVE_CASINO));
  const slots = useSelector(selectGamesByCategory(BFF_GAMES_CATEGORY.SLOTS));
  const jackpots = useSelector(selectGamesByCategory(BFF_GAMES_CATEGORY.JACKPOTS));
  const tableGames = useSelector(selectGamesByCategory(BFF_GAMES_CATEGORY.TABLE_GAMES));
  const winterGames = useSelector(selectGamesByCategory(BFF_GAMES_CATEGORY.WINTER_GAMES));

  if (gamesSuccess && isEmpty(allGames)) {
    return <NoGames />;
  }

  return (
    <>
      {isAuthenticated && (
        <GameList
          header="Ultimul jucat"
          games={lastPlayedgames}
          maxLength={6}
          slug="/jocuri/ultimul-jucat"
        />
      )}
      <GameList header="Recomandate" games={recommendedGames} />
      {isDecember() && (
        <GameList
          header="Jocuri de iarnă"
          games={winterGames}
          maxLength={12}
          slug="/jocuri/jocuri-de-iarna"
        />
      )}
      <ProviderCategoryNav />
      <LigaBanner />
      <GameList header="Jocuri noi" games={newGames} />
      <GameList
        header="Cazinou live"
        games={liveCasino}
        maxLength={6}
        slug="/jocuri/cazinou-live"
      />
      <GameList header="Sloturi" games={slots} maxLength={12} slug="/jocuri/sloturi" />
      <GameList header="Jackpoturi" games={jackpots} maxLength={6} slug="/jocuri/jackpoturi" />
      <GameList
        header="Jocuri de masă"
        games={tableGames}
        maxLength={6}
        slug="/jocuri/jocuri-de-masa"
      />
      <GameList
        header="Toate jocurile"
        maxLength={18}
        games={allGames}
        slug="/jocuri/toate-jocurile"
      />
    </>
  );
}

export default GamesLobby;
