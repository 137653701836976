import React from 'react';

import LazyLoad from 'react-lazyload';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/button/Button';
import { ModalType } from '@/models/enums/modal-type.enum';
import { TBonusIntro } from '@/pages/home/BonusIntro';
import { openModal } from '@/store/actions/modalActions';

interface IBonusIntroCardProps extends TBonusIntro {
  index: number;
}

const BonusIntroCard = ({
  imageUrl,
  imageAlt,
  title,
  bodyText,
  index,
  ctaColor,
  ctaSize,
  cardSign: CardSign,
}: IBonusIntroCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="relative mx-auto flex min-w-[325px] max-w-[450px] flex-1 sm:m-0">
      <div className="relative z-[2] flex w-full flex-col justify-between rounded-[30px] border-[1px] border-solid border-[rgba(0,0,0,0.02)] bg-white px-8 pt-12 pb-8 text-center sm:pt-16 sm:pr-10 sm:pb-8 sm:pl-10">
        <LazyLoad height={44} offset={400}>
          <img
            src={imageUrl}
            alt={imageAlt}
            className="mx-auto -mt-8 mb-4 block h-auto w-full max-w-[200px]"
          />
        </LazyLoad>
        <div className="absolute top-2 left-2 flex w-8 flex-col p-2 text-center text-sm font-bold text-black">
          <span>{index}</span>
          <CardSign className="mt-[6px] h-auto w-4" />
        </div>
        <div className="absolute bottom-2 right-2 flex w-8 rotate-180 flex-col p-2 text-center text-sm font-bold text-black">
          <span>{index}</span>
          <CardSign className="mt-[6px] h-auto w-4" />
        </div>
        <div>
          <h2 className="mb-6 text-2xl">{title}</h2>
          <p className="whitespace-pre-wrap text-center">{bodyText}</p>
        </div>
        <div className="button-wrapper">
          <Button
            size={ctaSize}
            color={ctaColor}
            className="mt-8 w-full"
            onClick={() => navigate('/inregistrare')}
          >
            Revendică acum
          </Button>
          <button
            type="button"
            className="mt-6 block w-full underline"
            onClick={() => dispatch(openModal(ModalType.GeneralBonusTerms))}
          >
            Mai multe detalii
          </button>
        </div>
      </div>
      <div className="absolute left-[120px] bottom-0 hidden h-1/3 max-h-[50px] w-1/2 max-w-[200px] origin-[0%_100%] skew-x-[50deg] shadow-[-80px_-30px_40px_rgba(0,0,0,0.4)] sm:block" />
      <div className="absolute right-[120px] bottom-0 hidden h-1/3 max-h-[50px] w-1/2 max-w-[200px] origin-[0%_100%] skew-x-[-50deg] shadow-[80px_-30px_40px_rgba(0,0,0,0.4)] sm:block" />
    </div>
  );
};

export default BonusIntroCard;
