import { ExtendedUserModel } from '@/models/extended-user.model';
import { ParsedIdentityCardModel } from '@/models/parsed-identity-card.model';

export const getExtensionFromFileType = (fileType: string): string | null => {
  switch (fileType) {
    case 'image/png':
      return '.png';
    case 'image/jpeg':
      return '.jpeg';
    case 'application/pdf':
      return '.pdf';
    default:
      return null;
  }
};

export const isFileImage = (file: File): boolean => {
  const extension = getExtensionFromFileType(file.type);

  if (!extension) {
    return false;
  }

  const imgExtensions = ['.png', '.jpeg'];
  return imgExtensions.indexOf(extension) !== -1;
};

export const looselyCompare = (a: string, b: string, allowFault = 0): boolean => {
  let faults = 0;

  const longerString = a.length > b.length ? a : b;

  for (let i = 0; i <= longerString.length; i += 1) {
    if (a[i] !== b[i]) {
      faults += 1;
    }
  }

  return faults <= allowFault;
};

export const replaceSpecialCharacters = (string: string): string => {
  return string
    .replace('Ă', 'A')
    .replace('Â', 'A')
    .replace('Î', 'I')
    .replace('Ș', 'S')
    .replace('Ț', 'T')
    .replace('ă', 'a')
    .replace('â', 'a')
    .replace('î', 'i')
    .replace('ș', 's')
    .replace('ț', 't');
};

export const comparePhotoIdAndUserDetails = (
  user: ExtendedUserModel,
  photoId: ParsedIdentityCardModel,
  today: Date,
): boolean => {
  const userCnp = user.Phone;

  // Use case where cnp's don't match
  if (userCnp !== photoId.cnp) {
    return false;
  }

  // comes in the format '2029-06-21'
  const { expirationDate } = photoId;
  // Use case where the card is expired
  if (isPhotoIdExpired(expirationDate, today)) {
    return false;
  }

  const compare = (a: string, b: string): boolean => {
    // This is done as a locally scoped function so we could always easily switch out
    // our custom compareStrings with just
    // return a === b;

    return looselyCompare(a, b, 2);
  };

  const normalize = (value: string): string => {
    return replaceSpecialCharacters(value).replace('-', ' ');
  };

  const makeParts = (namePart: string[]): string[] => {
    return namePart.reduce((a: string[], i) => {
      return [...a, ...i.split(' ')];
    }, []);
  };

  // first we want to normalize both our user data and photoId data (Romanian special characters and lowercase everything)
  const userFirstName = normalize(user.FirstName.toLowerCase());
  const userLastName = normalize(user.LastName.toLowerCase());

  const photoIdFirstName = normalize(photoId.firstName.toLowerCase());
  const photoIdLastName = normalize(photoId.lastName.toLowerCase());

  // we create user name parts that we will use for finding matches in the photoId scan
  const userNameParts = makeParts([userFirstName, userLastName]);
  // we create user name parts from the photo id scan
  const photoIdNameParts = makeParts([photoIdFirstName, photoIdLastName]);

  /* Andre and Marko meeting spawned this:
   * We'll do the matches in a more loose way. We want to check for each part of the users full name as in gig
   * separately if there is a corresponding word in the photoId scan. That means the order is not relevant.
   * If we find at least 1 match for each part of the users full name, we consider that an OK!
   */
  let matches = 0;
  userNameParts.forEach(part => {
    photoIdNameParts.forEach(photoIdPart => {
      if (compare(part, photoIdPart)) {
        matches += 1;
      }
    });
  });

  // we consider photoId a match if there is at least 1 match for each part of the users name in gig
  return matches >= userNameParts.length;
};

export const isPhotoIdExpired = (expirationDate: string | null, today: Date): boolean => {
  return !expirationDate || today > new Date(expirationDate);
};

export const docNoFromScannedCard = (data: ParsedIdentityCardModel): string => {
  return `${data.serial}-${data.serialNumber}`;
};
