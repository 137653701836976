import { ModalType } from '@/models/enums/modal-type.enum';
import { ConfirmModalProps, LoginModalProps, TermsModalProps } from '@/models/modal-props.model';
import {
  ModalActions,
  ModalActionType,
  OpenConfirmModalAction,
  OpenDepositBalanceUpdateModal,
  OpenLoginModalAction,
  OpenTermsModalAction,
  UpdateConfirmModalError,
  UpdateConfirmModalLoading,
} from '@/store/actions/modalActions';

export interface ModalStateModel {
  name: ModalType | null;
  termsModalProps: TermsModalProps | undefined;
  loginModalProps: LoginModalProps | undefined;
  confirmModalProps: ConfirmModalProps | undefined;
  depositBalanceUpdateModal: boolean;
  cookiesModal: boolean;
  showFtdExitIntent: boolean;
}

const initialState: ModalStateModel = {
  name: null,
  termsModalProps: undefined,
  loginModalProps: undefined,
  confirmModalProps: undefined,
  depositBalanceUpdateModal: false,
  cookiesModal: false,
  showFtdExitIntent: false,
};

const openDepositBalanceUpdateModal = (
  state: ModalStateModel,
  action: OpenDepositBalanceUpdateModal,
): ModalStateModel => ({
  ...state,
  name: ModalType.DepositBalanceUpdate,
  depositBalanceUpdateModal: action.depositBalanceUpdateModal,
});

const openConfirmModal = (
  state: ModalStateModel,
  action: OpenConfirmModalAction,
): ModalStateModel => ({
  ...state,
  name: ModalType.Confirm,
  confirmModalProps: action.props,
});

const openLoginModal = (state: ModalStateModel, action: OpenLoginModalAction): ModalStateModel => ({
  ...state,
  name: ModalType.Login,
  loginModalProps: action.props,
});

const openTermsModal = (state: ModalStateModel, action: OpenTermsModalAction): ModalStateModel => ({
  ...state,
  name: ModalType.Terms,
  termsModalProps: action.props,
});

const updateConfirmModalLoading = (
  state: ModalStateModel,
  action: UpdateConfirmModalLoading,
): ModalStateModel => ({
  ...state,
  confirmModalProps: state.confirmModalProps
    ? { ...state.confirmModalProps, loading: action.loading }
    : state.confirmModalProps,
});

const updateConfirmModalError = (
  state: ModalStateModel,
  action: UpdateConfirmModalError,
): ModalStateModel => ({
  ...state,
  confirmModalProps: state.confirmModalProps
    ? { ...state.confirmModalProps, loading: false, error: action.error }
    : state.confirmModalProps,
});

const openConsentModal = (state: ModalStateModel): ModalStateModel => ({
  ...state,
  name: ModalType.Consent,
});

const openCookiesModal = (state: ModalStateModel): ModalStateModel => ({
  ...state,
  name: ModalType.Cookies,
});

const closeModal = (state: ModalStateModel): ModalStateModel => ({
  ...initialState,
  showFtdExitIntent: state.showFtdExitIntent,
});

export const modalReducer = (state = initialState, action: ModalActions): ModalStateModel => {
  switch (action.type) {
    case ModalActionType.OpenLogin:
      return openLoginModal(state, action);
    case ModalActionType.OpenConfirm:
      return openConfirmModal(state, action);
    case ModalActionType.OpenTerms:
      return openTermsModal(state, action);
    case ModalActionType.UpdateConfirmModalLoading:
      return updateConfirmModalLoading(state, action);
    case ModalActionType.UpdateConfirmModalError:
      return updateConfirmModalError(state, action);
    case ModalActionType.OpenDepositBalanceUpdate:
      return openDepositBalanceUpdateModal(state, action);
    case ModalActionType.OpenConsentModal:
      return openConsentModal(state);
    case ModalActionType.OpenCookiesModal:
      return openCookiesModal(state);
    case ModalActionType.OpenModal:
      return {
        ...state,
        name: action.modal,
      };
    case ModalActionType.OpenLigaTOSModal:
      return {
        ...state,
        name: ModalType.LigaTOS,
      };
    case ModalActionType.OpenLigaOptInModal:
      return {
        ...state,
        name: ModalType.LigaOptIn,
      };
    case ModalActionType.SetFtdExitIntentShown:
      return {
        ...state,
        showFtdExitIntent: true,
      };
    case ModalActionType.Close:
      return closeModal(state);
    default:
      return state;
  }
};
