export enum LigaLevelStatus {
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
  Claimed = 'CLAIMED',
}

export enum LigaLevelsEnum {
  Liga7 = 'Liga7',
  Liga6 = 'Liga6',
  Liga5 = 'Liga5',
  Liga4 = 'Liga4',
  Liga3 = 'Liga3',
  Liga2 = 'Liga2',
  Liga1 = 'Liga1',
}

export const LigaLevels = Object.values(LigaLevelsEnum);

export enum LigaWheelLevelsEnum {
  Liga7Wheel = 'Liga7Wheel',
  Liga6Wheel1 = 'Liga6Wheel1',
  Liga6Wheel2 = 'Liga6Wheel2',
  Liga6Wheel3 = 'Liga6Wheel3',
  Liga5Wheel1 = 'Liga5Wheel1',
  Liga5Wheel2 = 'Liga5Wheel2',
  Liga5Wheel3 = 'Liga5Wheel3',
  Liga4Wheel1 = 'Liga4Wheel1',
  Liga4Wheel2 = 'Liga4Wheel2',
  Liga4Wheel3 = 'Liga4Wheel3',
  Liga3Wheel1 = 'Liga3Wheel1',
  Liga3Wheel2 = 'Liga3Wheel2',
  Liga3Wheel3 = 'Liga3Wheel3',
  Liga2Wheel1 = 'Liga2Wheel1',
  Liga2Wheel2 = 'Liga2Wheel2',
  Liga2Wheel3 = 'Liga2Wheel3',
  Liga1Wheel1 = 'Liga1Wheel1',
  Liga1Wheel2 = 'Liga1Wheel2',
  Liga1Wheel3 = 'Liga1Wheel3',
}

export interface LigaPrizeWheel {
  status: LigaLevelStatus;
  wheel: LigaWheelLevelsEnum;
}

export type LigaPrizeWheels = Record<LigaLevelsEnum, LigaPrizeWheel[]>;

export interface Liga {
  currentLiga: LigaLevelsEnum;
  nextPrize: {
    target: LigaWheelLevelsEnum | 'None';
    points?: number;
    progress?: number;
  };
  prizeWheels: LigaPrizeWheels;
}
