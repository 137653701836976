import React, { useState } from 'react';

import { BffAuthRequest } from '@lucky7ventures/bff-types';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { usePrevious } from 'react-use';
import styled from 'styled-components/macro';

import BackIcon from '@/components/icons/BackIcon';
import FailedLogins from '@/features/modals/login/FailedLogins';
import ForgotPasswordForm from '@/features/modals/login/ForgotPasswordForm';
import LoginForm from '@/features/modals/login/LoginForm';
import OtpAuthenticationForm from '@/features/modals/login/OtpAuthenticationForm';
import { LoginSectionEnum } from '@/models/enums/login-section.enum';
import { selectModalLoginProps } from '@/store/selectors/modalSelectors';

const StyledLoginModal = styled.div`
  background: ${props => props.theme.white};
  width: 320px;
  min-height: 600px;
  display: block;
  padding: 32px;
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;

  @media (min-width: 400px) {
    width: 380px;
  }

  .login,
  .forgot-password,
  .failed-login {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 32px;
  }

  .logo {
    margin: 32px auto 0;
    width: 200px;
    height: auto;
    color: ${props => props.theme.black};
  }

  .info {
    font-size: 14px;
    margin: 16px 0;
    text-align: center;
  }

  .logged-out {
    font-size: 0.9rem;
    margin-top: 1rem;
  }

  form {
    margin-top: 32px;
  }

  label {
    margin-top: 24px;
  }

  .forgot-button {
    font-weight: 600;
    font-size: 14px;
  }

  .back-button {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    z-index: 1;
    align-items: center;

    svg {
      font-size: 24px;
      margin-right: 8px;
    }
  }

  .submit {
    margin-bottom: 32px;
    width: 100%;
  }

  .button-wrapper {
    position: absolute;
    bottom: 32px;
    right: 32px;
    left: 32px;

    .error {
      padding: 16px;
      color: ${props => props.theme.red};
      font-weight: 600;
    }

    .success {
      padding: 16px;
      color: ${props => props.theme.mint};
      font-weight: 600;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;

      button {
        font-weight: 600;
      }
    }
  }

  .buttons {
    margin-top: 32px;
  }
`;

function LoginModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const [loginSection, setLoginSection] = useState<LoginSectionEnum>(LoginSectionEnum.Login);
  const [credentials, setCredentials] = useState<BffAuthRequest>({ username: '', password: '' });
  const prevSection = usePrevious(loginSection);
  const loginModalProps = useSelector(selectModalLoginProps);

  const animation = prevSection && prevSection > loginSection ? 'slide-right' : 'slide-left';

  return (
    <StyledLoginModal id="e2e_loginModal">
      <CSSTransition
        in={loginSection !== LoginSectionEnum.Login}
        timeout={500}
        appear
        unmountOnExit
        classNames="fade"
      >
        <button
          type="button"
          onClick={() => setLoginSection(LoginSectionEnum.Login)}
          className="back-button"
        >
          <BackIcon /> Înapoi
        </button>
      </CSSTransition>
      <CSSTransition
        in={loginSection === LoginSectionEnum.Login}
        timeout={500}
        unmountOnExit
        classNames={animation}
      >
        <LoginForm
          closeModal={closeModal}
          setLoginSection={setLoginSection}
          loggedOut={!!loginModalProps?.loggedOut}
          setCredentials={setCredentials}
        />
      </CSSTransition>
      <CSSTransition
        in={loginSection === LoginSectionEnum.Otp}
        timeout={500}
        unmountOnExit
        classNames={animation}
      >
        <OtpAuthenticationForm credentials={credentials} closeModal={closeModal} />
      </CSSTransition>
      <CSSTransition
        in={loginSection === LoginSectionEnum.ForgotPassword}
        timeout={500}
        unmountOnExit
        classNames={animation}
      >
        <ForgotPasswordForm setLoginSection={setLoginSection} closeModal={closeModal} />
      </CSSTransition>
      <CSSTransition
        in={loginSection === LoginSectionEnum.FailedLogins}
        timeout={500}
        unmountOnExit
        classNames={animation}
      >
        <FailedLogins closeModal={closeModal} setLoginSection={setLoginSection} />
      </CSSTransition>
    </StyledLoginModal>
  );
}

export default LoginModal;
