import * as React from 'react';
import { useState } from 'react';

import LazyLoad, { forceCheck } from 'react-lazyload';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import CloseButton from '@/components/button/CloseButton';
import Search from '@/components/search/Search';
import DrawerHeader from '@/features/drawer/DrawerHeader';
import { DrawerPropsModel } from '@/models/drawer-props.model';
import { GameCardVM } from '@/models/game-card-vm.model';
import SearchGameCard from '@/pages/games/SearchGameCard';
import { selectAllGames } from '@/store/selectors/gamesSelectors';
import { searchGames } from '@/utils/game-helpers';
import { isEmpty } from '@/utils/helpers';

const StyledSearchDrawer = styled.div`
  width: 85vw !important;
  max-width: 480px !important;
  overflow: hidden !important;
  color: white;

  .games {
    height: calc(100vh - 180px);
    display: flex;
    flex-flow: column;
    padding: 1rem;
    justify-content: flex-start;
    position: relative;
    overflow-y: scroll;

    .wrapper {
      padding-bottom: 1rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 2rem;
    }

    .header {
      grid-column: 1/-1;
      display: inline-flex;
      align-items: flex-end;
      margin-bottom: 32px;

      h1 {
        font-size: 32px;
        font-weight: 700;
      }

      span {
        color: ${props => props.theme.green};
        font-weight: 600;
        margin-left: 16px;
        line-height: 1.4;
      }
    }

    .small-text {
      width: 100%;
      text-align: center;
      color: ${props => props.theme.fontBlack};
    }
  }
`;

export function SearchDrawer({ closeDrawer }: DrawerPropsModel): JSX.Element {
  const [searchValue, setSearchValue] = useState('');
  const [searchedGames, setSearchedGames] = useState<GameCardVM[]>([]);
  const games = useSelector(selectAllGames);

  function updateSearch(event: React.ChangeEvent<HTMLInputElement>) {
    if (games && games.length > 0) {
      const newSearchValue = event.target.value.substr(0, 20);
      setSearchValue(newSearchValue);
      setSearchedGames(searchGames(newSearchValue, games));

      setTimeout(() => {
        forceCheck();
      }, 100);
    }
  }

  return (
    <StyledSearchDrawer className="drawer">
      <CloseButton position="left" onClick={closeDrawer} />
      <DrawerHeader>
        <Search
          name="gameSearch"
          placeholder="Caută un joc sau studio"
          value={searchValue}
          onChange={event => updateSearch(event)}
          onClose={() => setSearchValue('')}
          autoFocus
        />
        {!isEmpty(searchValue) && !isEmpty(searchedGames) && (
          <span className="number-of-games">{searchedGames.length} jocuri</span>
        )}
        {isEmpty(searchedGames) && !isEmpty(searchValue) && (
          <span className="number-of-games">0 jocuri</span>
        )}
      </DrawerHeader>
      <section className="games">
        <div className="wrapper">
          {!isEmpty(searchValue) ? (
            <>
              {!isEmpty(searchedGames) ? (
                searchedGames.map(game => (
                  <LazyLoad key={game.gameId} offset={400}>
                    <SearchGameCard game={game} closeDrawer={closeDrawer} />
                  </LazyLoad>
                ))
              ) : (
                <div className="small-text absolute-center">Niciun joc găsit</div>
              )}
            </>
          ) : (
            <div className="small-text absolute-center">Caută un joc sau studio</div>
          )}
        </div>
      </section>
    </StyledSearchDrawer>
  );
}
