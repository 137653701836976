import React, { useRef, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import useToggle from '@/hooks/useToggle';

const StyledAccordion = styled.div<{ active: boolean; panelHeight: string }>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .accordion {
    color: ${props => props.theme.black};
    cursor: pointer;
    padding: 2rem 0;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.2s;
    display: flex;
    justify-content: space-between;
    font-weight: 700;

    &:after {
      content: '+';
    }
  }

  .panel {
    max-height: ${props => props.panelHeight};
    overflow: hidden;
    transition: 0.2s;
    margin: 0 auto;
    color: ${props => props.theme.fontBlack};

    p {
      margin-bottom: 0;
    }

    .panel-content {
      padding: 0 0 2rem;
    }
  }

  ${props =>
    props.active &&
    css`
      .accordion {
        &:after {
          content: '-';
        }
      }

      .panel {
        display: block;
      }
    `};
`;

function Accordion({
  question,
  children,
}: {
  question: string;
  children: React.ReactNode;
}): JSX.Element {
  const [active, toggleActive] = useToggle();
  const [panelHeight, setPanelHeight] = useState('0px');
  const panelRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    if (!panelRef.current) {
      return;
    }
    toggleActive();
    setPanelHeight(active ? '0px' : `${panelRef.current.scrollHeight}px`);
  };

  return (
    <StyledAccordion active={active} panelHeight={panelHeight}>
      <button type="button" className="accordion" onClick={toggleAccordion}>
        {question}
      </button>
      <div className="panel" ref={panelRef}>
        <div className="panel-content">{children}</div>
      </div>
    </StyledAccordion>
  );
}

export default Accordion;
