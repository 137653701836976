import { UserWalletPublic } from '@lucky7ventures/lucky-components';
import { Action } from 'redux';

export enum WalletActionsTypes {
  Get = 'GET_WALLET',
  GetSuccess = 'GET_WALLET_SUCCESS',
  GetError = 'GET_WALLET_ERROR',
  ClearWalletState = 'CLEAR_WALLET_STATE',
}

export type GetWalletAction = Action<WalletActionsTypes.Get> & {
  token?: string;
};
export type GetWalletSuccessAction = Action<WalletActionsTypes.GetSuccess> & {
  wallet: UserWalletPublic;
};
export type GetWalletErrorAction = Action<WalletActionsTypes.GetError>;
export type ClearWalletStateAction = Action<WalletActionsTypes.ClearWalletState>;

export type WalletActions =
  | GetWalletAction
  | GetWalletSuccessAction
  | GetWalletErrorAction
  | ClearWalletStateAction;

export const getWallet = (token?: string): GetWalletAction => ({
  type: WalletActionsTypes.Get,
  token,
});

export const getWalletSuccess = (wallet: UserWalletPublic): GetWalletSuccessAction => ({
  type: WalletActionsTypes.GetSuccess,
  wallet,
});

export const getWalletError = (): GetWalletErrorAction => ({
  type: WalletActionsTypes.GetError,
});

export const clearWalletState = (): ClearWalletStateAction => ({
  type: WalletActionsTypes.ClearWalletState,
});
