import React, { ReactNode } from 'react';

import styled from 'styled-components/macro';

export const Divider = () => <div className="col-span-4 h-0.5 mb-2 bg-brand-gold rounded-xl" />;

export const GridContent = ({ children }: { children?: ReactNode }) => (
  <div className="">{children}</div>
);

export const Grid = ({ children }: { children?: ReactNode }) => (
  <div className="no-scrollbar overflow-scroll my-8">
    <div className="grid grid-cols-[22%,43%,17%,117%] w-[700px] h-[600px] sm:h-full gap-x-2">
      {children}
    </div>
  </div>
);

export const GridHeading = ({ children }: { children?: ReactNode }) => (
  <div className="font-bold text-gray-600">{children}</div>
);

export const StyledLegal = styled.div`
  position: relative;
  .content {
    max-width: 800px;
    margin: 86px auto;
    width: 100%;
    padding: 4rem 2rem;
    min-height: 100vh;
    position: relative;
    h1 {
      margin-bottom: 0;
    }
    h2,
    h3,
    h4 {
      display: block;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
    ul {
      list-style: disc;
      padding-left: 1em;
      margin-left: 1em;
    }
    span {
      line-height: 1.6;
      margin-bottom: 1em;
    }
    li {
      line-height: 1.6;
      margin: 1em 0;
    }
    a {
      text-decoration: underline;
    }
    ol {
      padding-left: 1em;
      margin-left: 1em;
      list-style: upper-latin;
      margin-top: 1rem;
      ol {
        list-style: decimal;
        ol {
          list-style: lower-latin;
        }
      }
    }
  }
`;
