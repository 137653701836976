export enum ModalType {
  Confirm = 'CONFIRM',
  Consent = 'CONSENT',
  Cookies = 'COOKIES',
  DepositBalanceUpdate = 'DEPOSIT_BALANCE_UPDATE',
  ExitIntent = 'EXIT_INTENT',
  GeneralBonusTerms = 'GENERAL_BONUS_TERMS',
  LigaOptIn = 'LIGA_OPT_IN',
  LigaTOS = 'LIGA_TOS',
  Login = 'LOGIN',
  Terms = 'TERMS',
  XmasDay = 'XMAS_DAY',
}
