import { WpPromotion } from '@/models/wp-promotion.model';
import {
  GetPromotionsSuccessAction,
  PromotionsActions,
  PromotionsActionsTypes,
} from '@/store/actions/promotionsActions';

export interface PromotionsStateModel {
  promotions: WpPromotion[];
  loading: boolean;
  error: boolean;
}

const defaultState: PromotionsStateModel = {
  promotions: [],
  loading: false,
  error: false,
};

const getPromotions = (state: PromotionsStateModel): PromotionsStateModel => ({
  ...state,
  loading: true,
  error: false,
});

const getPromotionsSuccess = (
  state: PromotionsStateModel,
  action: GetPromotionsSuccessAction,
): PromotionsStateModel => ({
  ...state,
  loading: false,
  promotions: action.promotions,
});

const getPromotionsError = (state: PromotionsStateModel): PromotionsStateModel => ({
  ...state,
  loading: false,
  error: true,
});

export const promotionsReducer = (
  state = defaultState,
  action: PromotionsActions,
): PromotionsStateModel => {
  switch (action.type) {
    case PromotionsActionsTypes.GetPromotions:
      return getPromotions(state);
    case PromotionsActionsTypes.GetPromotionsSuccess:
      return getPromotionsSuccess(state, action);
    case PromotionsActionsTypes.GetPromotionsError:
      return getPromotionsError(state);
    default:
      return state;
  }
};
