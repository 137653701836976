import * as React from 'react';

function ArrowDropUpIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M12 11.828l-2.828 2.829-1.415-1.414L12 9l4.243 4.243-1.415 1.414L12 11.828z"
      />
    </svg>
  );
}

export default ArrowDropUpIcon;
