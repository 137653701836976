import Cookies from 'universal-cookie';

const cookies = new Cookies();

export enum CookiesNames {
  Btag = 'mjp_btag',
  ClickId = 'mjp_clickid',
  Token = 'mjp_token',
  KycNotificationClosed = 'mjp_kyc_notification_closed',
}

const MAX_COOKIE_SIZE = 50; // 50 chars

const CookiesManagerUtils = {
  getBtagCookie: (): string => {
    return cookies.get(CookiesNames.Btag);
  },
  setBtagCookie: (value: string) => {
    if (value.length <= MAX_COOKIE_SIZE) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 30);
      cookies.set(CookiesNames.Btag, value, { path: '/', expires });
    }
  },
  removeBtagCookie: () => {
    cookies.remove(CookiesNames.Btag);
  },
  getClickIdCookie: (): string => {
    return cookies.get(CookiesNames.ClickId);
  },
  setClickIdCookie: (value: string) => {
    if (value.length <= MAX_COOKIE_SIZE) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 30);
      cookies.set(CookiesNames.ClickId, value, { path: '/', expires });
    }
  },
  setTokenCookie: (value: string) => {
    if (value.length <= MAX_COOKIE_SIZE) {
      cookies.set(CookiesNames.Token, value, { path: '/' });
    }
  },
  removeTokenCookie: () => {
    cookies.remove(CookiesNames.Token);
  },
  setKycNotificationClosedCookie: () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    cookies.set(CookiesNames.KycNotificationClosed, true, {
      path: '/',
      expires,
      secure: true,
      sameSite: 'strict',
    });
  },
};

export default CookiesManagerUtils;
