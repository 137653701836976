import * as React from 'react';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import GameList from './GameList';
import { GamesCategorySlug } from '@/features/router/routes-constants';
import NoGames from '@/pages/games/NoGames';
import { selectGamesByCategory } from '@/store/selectors/gamesSelectors';
import { mapCategorySlugToTitle, mapGamesCategorySlugToName } from '@/utils/game-helpers';

const limit = (categorySlug: GamesCategorySlug | undefined): number => {
  switch (categorySlug) {
    case 'recomandate':
    case 'jocuri-noi':
      return 18;
    default:
      return 0;
  }
};

function GamesByCategory(): JSX.Element {
  const { pathname } = useLocation();
  const [categorySlug, setCategorySlug] = useState<GamesCategorySlug>();
  const categoryGames = useSelector(
    selectGamesByCategory(mapGamesCategorySlugToName(categorySlug), limit(categorySlug)),
  );

  useEffect(() => {
    setCategorySlug(pathname.split('/').pop() as GamesCategorySlug);
  }, [pathname]);

  return (
    <>
      {categorySlug && categoryGames && categoryGames.length > 0 ? (
        <GameList games={categoryGames} header={mapCategorySlugToTitle(categorySlug)} />
      ) : (
        <NoGames text="Niciun joc găsit" />
      )}
    </>
  );
}

export default GamesByCategory;
