import React from 'react';

import LazyLoad from 'react-lazyload';
import { Link, useNavigate } from 'react-router-dom';

import maestro from '@/images/maestro.svg';
import mastercard from '@/images/mastercard.svg';
import neteller from '@/images/neteller.svg';
import paysafecard from '@/images/paysafecard.svg';
import skrill from '@/images/skrill.svg';
import visa from '@/images/visa.svg';

const providers = [
  {
    id: 1,
    url: visa,
    alt: 'Visa',
  },
  {
    id: 2,
    url: mastercard,
    alt: 'Mastercard',
  },
  {
    id: 3,
    url: maestro,
    alt: 'Maestro',
  },
  {
    id: 4,
    url: neteller,
    alt: 'Neteller',
  },
  {
    id: 5,
    url: skrill,
    alt: 'Skrill',
  },
  {
    id: 6,
    url: paysafecard,
    alt: 'Paysafecard',
  },
];

function PaymentProviders(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div
      data-cy="PAYMENT_PROVIDERS"
      className="mx-auto w-full max-w-3xl px-8 pt-16 pb-20 text-center"
    >
      <h1>
        Depuneri sigure și <br /> retrageri super-rapide!
      </h1>
      <p>Plătești în siguranță prin intermediul furnizorilor noștri de plăți</p>
      <div className="mt-8 grid grid-cols-3 gap-16 md:grid-cols-6">
        {providers.map(provider => (
          <div className="flex items-center justify-center" key={provider.id}>
            <Link to="/metode-de-plata">
              <LazyLoad offset={400} height={63}>
                <img className="max-w-[80px]" src={provider.url} alt={provider.alt} />
              </LazyLoad>
            </Link>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="mt-16 inline-flex rounded-lg bg-[#173033] px-10 py-6 font-bold text-white transition-colors hover:bg-[#203b3f] md:text-lg"
        onClick={() => navigate('/inregistrare')}
      >
        Înregistrare
      </button>
    </div>
  );
}

export default PaymentProviders;
