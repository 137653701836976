import React, { useEffect, useRef, useState } from 'react';

import WindowMessenger from '@betit/ramson-window-messenger';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';

import { CASHIER_CSS, CASHIER_TRANSLATIONS } from '@/constants/constants';
import useApiV2Request from '@/hooks/useApiV2Request';
import { useCashierIncreaseTransition, useCashierShown } from '@/hooks/useCashier';
import { GTagEvents, triggerGTag, triggerNetReferer } from '@/lib/gTagManager';
import ApiService from '@/shared/api-service';
import { getActiveBonuses } from '@/store/actions/activeBonusesActions';
import { getAvailableBonuses } from '@/store/actions/availableBonusesActions';
import {
  getDepositCount,
  incrementDepositCount,
  setLifetimeDeposits,
  setToggleDepositAdded,
} from '@/store/actions/depositActions';
import { closeDrawer } from '@/store/actions/drawerActions';
import { getWallet } from '@/store/actions/walletActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { selectCashierBonuses } from '@/store/selectors/availableBonusesSelectors';
import { selectDepositCount, selectIsSingleGame } from '@/store/selectors/depositSelectors';
import { selectUser } from '@/store/selectors/userSelectors';

const StyledCashierIframe = styled.div`
  margin: 0 auto;
  width: 100%;
  height: ${props => `${props.iframeHeight}px`};
  min-height: calc(100vh - 178px);
  position: relative;

  #iframe-wrapper {
    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 0 2rem;
    }
  }

  #paymentsIframe {
    width: 100%;
    height: ${props => `${props.iframeHeight}px`};
    min-height: calc(100vh - 178px);
    position: relative;
  }
`;

function CashierIframe({ closeCashier, setLoading }) {
  const token = useSelector(selectAuthToken);
  const availableBonuses = useSelector(selectCashierBonuses);
  const cashierDrawerIsOpen = useCashierShown();
  const depositCount = useSelector(selectDepositCount);
  const [iframeHeight, setIframeHeight] = useState(3100);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const iframeRef = useRef();
  const isSingleGameRef = useRef(false);
  const [messenger, setMessenger] = useState(null);
  const isSingleGame = useSelector(selectIsSingleGame);
  const user = useSelector(selectUser);
  const { request } = useApiV2Request();
  const isFTD = useRef(true);
  const increaseCashierTransition = useCashierIncreaseTransition();

  useEffect(() => {
    isFTD.current = depositCount === 0;
  }, [depositCount]);

  function updateAvailableBonuses() {
    dispatch(getAvailableBonuses());
  }

  function updateWallet() {
    dispatch(getWallet());
    dispatch(getActiveBonuses());
    dispatch(getDepositCount());
  }

  const refetchDeposits = () => {
    const handleOnSuccess = deposits => {
      dispatch(setLifetimeDeposits(deposits));
    };
    request(ApiService.getLifetimeDeposits, null, handleOnSuccess);
  };

  useEffect(() => {
    isSingleGameRef.current = isSingleGame;
  }, [isSingleGame]);

  useEffect(() => {
    const iframe = document.createElement('iframe');
    const iframePlaceholder = iframeRef.current;
    iframe.id = 'paymentsIframe';
    iframePlaceholder.appendChild(iframe);
    const cashierUrl = process.env.REACT_APP_CASHIER_URL;
    const merchantId = process.env.REACT_APP_CASHIER_DEVCODE_MERCHANT_ID;
    const cashierEnv = process.env.REACT_APP_CASHIER_ENVIRONMENT;
    const cashierDebug = process.env.REACT_APP_CASHIER_DEBUG;
    const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const termsAndConditionsLink = 'https://magicjackpot.ro/termeni-si-conditii';
    const brandId = 'magicjackpot';
    const randomUUID = `magicjackpot-${uuidv4()}`;
    const messenger = new WindowMessenger(iframe, cashierUrl, randomUUID, 'MagicJackpot Cashier');

    messenger.register({
      getBonuses: (event, onSuccess) => {
        onSuccess(availableBonuses);
      },
      onError: () => setLoading(false),
      onEvent: event => {
        if (event.params.type && event.params.type.toLocaleLowerCase().includes('error')) {
          setLoading(false);
        }

        if (event.params.type === 'transition') {
          increaseCashierTransition();
          if (event.params.params.stack === 1) {
            setLoading(false);
          }
        }

        if (
          event.params.type === 'onWithdrawalSuccess' ||
          event.params.type === 'onWithdrawalCancelled' ||
          event.params.type === 'onBonusForfeited'
        ) {
          updateWallet();
        }

        if (event.params.type === 'onDepositSuccess') {
          const amount = parseInt(`${event.params.params.receiptDepositAmount.value}`, 10);
          if (isFTD.current) {
            triggerGTag(GTagEvents.first_deposit, { amount });
            triggerNetReferer('First Time Deposit', user?.Reference, user?.UserID);
          } else {
            triggerGTag(GTagEvents.deposit_success, { amount });
          }
          dispatch(incrementDepositCount());
          refetchDeposits();
          updateWallet();
          if (isSingleGameRef.current) {
            dispatch(setToggleDepositAdded(true));
          }
          setTimeout(() => {
            updateAvailableBonuses();
            closeCashier();
          }, 5000);
        }

        if (event.params.type === 'onContentResized') {
          setIframeHeight(event.params.params.height);
        }
      },
      goToGames: () => {
        // Close the payments if on a game, else go to /jocuri
        if (location.pathname.includes('jocuri') || location.pathname.includes('joc')) {
          dispatch(closeDrawer());
        } else {
          navigate('/jocuri');
          dispatch(closeDrawer());
        }
      },
    });

    messenger.init();
    iframe.src = `${cashierUrl}?devcodeMerchantId=${merchantId}&brandId=${brandId}&environment=${cashierEnv}&sessionId=${token}&cssUrl=${CASHIER_CSS}&displayDebugInfo=${cashierDebug}&tncUrl=${termsAndConditionsLink}&translationsUrl=${CASHIER_TRANSLATIONS}&disableBonusCache=true&googleMapsAPIKey=${googleMapsAPIKey}`;

    setMessenger(messenger);

    return () => {
      if (messenger && messenger.destroy) {
        messenger.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!cashierDrawerIsOpen) {
      if (messenger) {
        messenger.send('setView', 'deposit');
      }
    }
  }, [cashierDrawerIsOpen]);

  return <StyledCashierIframe ref={iframeRef} id="iframe-wrapper" iframeHeight={iframeHeight} />;
}

CashierIframe.propTypes = {
  closeCashier: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default CashierIframe;
