import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import Terms from '@/components/formats/Terms';
import { getTermsAndConditions } from '@/store/actions/wordpressActions';
import { selectTermsAndConditions } from '@/store/selectors/wordpressSelectors';

function TermsAndConditions() {
  const dispatch = useDispatch();
  const terms = useSelector(selectTermsAndConditions);

  useEffect(() => {
    dispatch(getTermsAndConditions());
  }, []);

  return (
    <div>
      <Helmet>
        <body className="header-background" />
      </Helmet>
      <Terms terms={terms?.content} />
    </div>
  );
}

export default TermsAndConditions;
