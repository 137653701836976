export const gameCategoriesSlugs = [
  'jocuri-de-iarna',
  'recomandate',
  'jocuri-noi',
  'cazinou-live',
  'sloturi',
  'jackpoturi',
  'jocuri-de-masa',
  'toate-jocurile',
] as const;

export type GamesCategorySlug = typeof gameCategoriesSlugs[number];
