import * as React from 'react';
import { useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

import Anchor from '@/components/anchor/Anchor';
import Button, { StyledButton } from '@/components/button/Button';
import Input from '@/components/form/Input';
import ValidationMessage from '@/components/form/ValidationMessage';
import { openLoginModal } from '@/store/actions/modalActions';

const StyledResetpassword = styled.div`
  background: ${props => props.theme.redGradient};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    object-position: 0 0;
  }

  .container {
    max-width: 464px;
    background: white;
    padding: 0 2rem 2rem;
    border-radius: 0.5rem;
    box-shadow: ${props => props.theme.boxShadow};
    position: relative;
    margin: 5rem 0 2rem;

    > img {
      display: block;
      width: 200px;
      height: auto;
      margin: 0 auto 2rem;
    }

    .text {
      text-align: center;
      display: block;
      margin-bottom: 3rem;
    }

    h1 {
      font-size: 28px;
      margin-bottom: 16px;
    }

    > p {
      opacity: 0.8;
      margin-bottom: 48px;
    }

    .button-wrapper {
      .error {
        padding: 1rem;
        font-weight: 700;
        color: ${props => props.theme.red};
        font-size: 0.9rem;
        margin-bottom: 0;
      }

      ${StyledButton} {
        width: 100%;
        margin-top: 32px;
      }

      .login {
        margin-top: 32px;
        text-align: center;
        margin-bottom: 0;
        font-size: 14px;

        button {
          font-weight: 700;
        }
      }
    }
  }
`;

interface FormValues {
  password: string;
}

function ResetPassword(): JSX.Element {
  const { recoveryCode } = useParams<{ recoveryCode: string }>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues: FormValues = {
    password: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Parola este obligatorie')
      .min(6, 'Parola trebuie să conțină minim 6 caractere'),
  });

  function submitHandler(values: FormValues) {
    setLoading(true);
    setError(null);
    setSuccess(false);

    axios
      .post('/api/auth/forgotpassword/change', { recoveryCode, password: values.password })
      .then(response => {
        if (response.data.success) {
          setLoading(false);
          setSuccess(true);
        } else {
          if (response.data.error[0].ErrorCodeID === 51) {
            setError('Codul de recuperare al parolei furnizat nu este valid.');
          } else {
            setError(response.data.error[0].Message);
          }
          setLoading(false);
        }
      })
      .catch(error => {
        setError(`${error.response.status} - ${error.response.statusText}`);
        setLoading(false);
      });
  }

  return (
    <StyledResetpassword>
      <img
        src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=1440,format=auto,dpr=2/assets/hero-without-asset.png"
        alt="Hero"
        className="background-image"
      />
      <div className="container">
        <img
          src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=200,height=200,format=auto,dpr=2/assets/hat.png"
          alt="Hat"
        />
        {success ? (
          <div className="text">
            <h1>Parola a fost actualizată</h1>
            <p>Contul a fost actualizat și te poți conecta cu noua parolă</p>
            <div className="button-wrapper">
              <Button
                onClick={() => {
                  dispatch(openLoginModal());
                  navigate('/');
                }}
              >
                Intră în cont
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="text">
              <h1>Resetează parola</h1>
              <p>Introdu o nouă parolă pentru a te conecta din nou!</p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={values => submitHandler(values)}
              validateOnMount
            >
              {({ isValid }) => (
                <Form>
                  <Field name="password">
                    {(
                      { field }: FieldProps, // { name, value, onChange, onBlur }
                    ) => (
                      <>
                        <Input
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          type="password"
                          placeholder="Introdu parolă nouă"
                          label="Parolă nouă"
                        />
                        <ErrorMessage name="password">
                          {message => <ValidationMessage type="new-password" message={message} />}
                        </ErrorMessage>
                      </>
                    )}
                  </Field>
                  <div className="button-wrapper">
                    {error && <p className="error">{error}</p>}
                    <Button disabled={!isValid} type="submit">
                      {loading ? 'Se încarcă...' : 'Schimbă parola'}
                    </Button>
                    <p className="login">
                      Îți aminteși parola?{' '}
                      <Anchor onClick={() => dispatch(openLoginModal())}>Autentifică-te!</Anchor>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </StyledResetpassword>
  );
}

export default ResetPassword;
