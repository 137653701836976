import axios, { AxiosResponse } from 'axios';
import { all, AllEffect, ForkEffect, put, takeEvery, call, StrictEffect } from 'redux-saga/effects';

import { WP_URL } from '../../constants/constants';
import { ApiResponseModel } from '../../models/api-response.model';
import { WpPromotion } from '../../models/wp-promotion.model';
import {
  getPromotionsError,
  getPromotionsSuccess,
  PromotionsActionsTypes,
} from '../actions/promotionsActions';
import { isSuccessfulWpResponse } from '@/utils/helpers';

type PromotionsAxiosResponse = AxiosResponse<ApiResponseModel<WpPromotion[]>>;

export function* getPromotionsSaga(): Generator<StrictEffect, void, PromotionsAxiosResponse> {
  try {
    const response = yield call(axios.get, `${WP_URL}/promotions`);

    if (isSuccessfulWpResponse<WpPromotion[]>(response)) {
      yield put(getPromotionsSuccess(response.data));
    } else {
      yield put(getPromotionsError());
    }
  } catch (error) {
    yield put(getPromotionsError());
  }
}

export function* watchPromotions(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(PromotionsActionsTypes.GetPromotions, getPromotionsSaga)]);
}
