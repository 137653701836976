import React from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';

import ResponsibleGamingContent from '@/pages/settings/responsible-gaming/ResponsibleGamingContent';

export const StyledResponsibleGamingPage = styled.div`
  padding: 4rem 0;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 128px 0;
  }

  .container {
    padding: 0 1rem;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 0 2rem;
    }
  }
`;

function ResponsibleGaming(): JSX.Element {
  return (
    <StyledResponsibleGamingPage>
      <Helmet>
        <body className="header-background" />
      </Helmet>
      <div className="container">
        <ResponsibleGamingContent />
      </div>
    </StyledResponsibleGamingPage>
  );
}

export default ResponsibleGaming;
