import React, { useState } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import {
  closeModal,
  openConfirmModal,
  updateConfirmModalError,
  updateConfirmModalLoading,
} from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated, selectAuthToken } from '@/store/selectors/authSelectors';

const StyledResponsibleGaming = styled.div`
  .content {
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
    padding: 3rem 0;

    h1 {
      font-size: 1.8rem;
      line-height: 1.4;
    }

    h2 {
      margin: 2rem 0 1.5rem;
      line-height: 1.4;
    }

    h3 {
      font-size: 1.5rem;
      margin: 2rem 0 1.5rem;
      line-height: 1.4;
    }

    ul {
      list-style: disc;
      padding-left: 1em;
      margin-left: 1em;
    }

    p,
    li {
      line-height: 1.6;
      margin-bottom: 1rem;
    }

    b {
      font-weight: 700;
    }
  }

  .take-a-break {
    padding: 2rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-radius: ${props => props.theme.borderRadius};
    margin-top: 4rem;
    margin-bottom: 8rem;
    box-shadow: ${props => props.theme.boxShadow};

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 3rem 2rem;
    }

    h2 {
      margin-top: 0;
    }

    .label {
      font-weight: 700;
      margin-bottom: 1rem;
      display: block;
      margin-top: 2rem;
    }

    .select {
      border: none;
      outline: none;

      * {
        cursor: pointer;
      }

      > span {
        outline: none;
      }

      &__control {
        cursor: text;
        background: none;
        padding: 0;
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: ${props => props.theme.borderRadius};
        box-shadow: none;

        &:hover {
          border-color: rgba(0, 0, 0, 0.1);
        }
      }

      &__value-container {
        padding: 1rem;
      }

      &__indicator-separator {
        display: none;
      }

      &__dropdown-indicator {
        cursor: pointer;
        padding: 1rem;
      }

      &__placeholder {
        padding: 0;
      }

      &__input,
      &__single-value {
        color: ${props => props.theme.black};
        line-height: 1.5;
      }

      &__menu {
        overflow: hidden;
        background: ${props => props.theme.white};
        margin-top: 1rem;
        border-radius: ${props => props.theme.borderRadius};
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0.5rem;
        box-shadow: ${props => props.theme.boxShadow};
      }

      &__menu-list {
        padding: 0;
        max-height: 180px;
      }

      &__option,
      &__menu-notice {
        padding: 0.8rem 1rem;
        transition: all 0.2s ease;
        color: ${props => props.theme.black};
        font-size: 14px;
        border-radius: 6px;

        &:hover,
        &--is-focused,
        &--is-selected {
          background: rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
    }
  }

  .radio-buttons {
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 2rem;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      grid-template-columns: repeat(4, minmax(10px, 1fr));
    }
  }

  .radio {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 1rem 1rem 1rem 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    white-space: nowrap;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      border-radius: 8px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 0;
      font-size: 16px;
      padding-left: 40px;
    }

    &:last-child {
      border-right: none;
      border-bottom: none;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 15px;
      left: 8px;
      height: 18px;
      width: 18px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 50vh;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: ${props => props.theme.mint};
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 7px;
      top: 4px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;

function ResponsibleGamingContent(): JSX.Element {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const [selectedOption, setSelectedOption] = useState('7 zile');
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function submitHandler() {
    const date = new Date();
    let endDate = null;
    dispatch(updateConfirmModalLoading(true));

    if (selectedOption === 'Nedefinit') {
      axios
        .post(`/api/responsiblegaming/addgenericblock`, {
          token,
          blockReason: 10,
          blockComment: 'Set by player from the site',
        })
        .then(response => {
          if (response.data.success) {
            dispatch(closeModal());
            // log out user if successfull timeout
            toast(<p>Pauză activată cu succes!</p>, {
              toastId: 'responsibleGamingToast',
              position: 'bottom-center',
              autoClose: 15000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            navigate(`/deconectare`);
          } else {
            dispatch(updateConfirmModalError(response.data.error[0].Message));
          }
        })
        .catch(() => {
          dispatch(updateConfirmModalError('En error from catch'));
        });
    } else {
      switch (selectedOption) {
        case '7 zile':
          [endDate] = new Date(date.getTime() + 60 * 60 * 24 * 7 * 1000).toJSON().split('.');
          break;
        case '14 zile':
          [endDate] = new Date(date.getTime() + 60 * 60 * 24 * 14 * 1000).toJSON().split('.');
          break;
        case '30 zile':
          [endDate] = new Date(date.getTime() + 60 * 60 * 24 * 30 * 1000).toJSON().split('.');
          break;
        case '90 zile':
          [endDate] = new Date(date.getTime() + 60 * 60 * 24 * 90 * 1000).toJSON().split('.');
          break;
        default:
          console.log('Error: case did not exist');
      }

      axios
        .post(`/api/responsiblegaming/timeout/addtimeout`, { token, endDate })
        .then(response => {
          if (response.data.success) {
            dispatch(closeModal());
            // log out user if successfull timeout
            toast(<p>Pauză activată cu succes!</p>, {
              toastId: 'responsibleGamingToast',
              position: 'bottom-center',
              autoClose: 15000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            navigate(`/deconectare`);
          } else {
            dispatch(updateConfirmModalError(response.data.error[0].Message));
          }
        })
        .catch(error => {
          dispatch(updateConfirmModalError('En error from catch'));
          console.log(error);
        });
    }
  }

  return (
    <StyledResponsibleGaming>
      <div className="content">
        <div>
          <h1>
            Joc Responsabil, <br />
            limitări și autoexcludere
          </h1>
          <h3>1. Politica referitoare la minori</h3>
          <p>
            Politica MagicJackpot in ceea ce priveşte accesul minorilor la jocurile de noroc este
            foarte strictă. Este complet interzisă utilizarea site-ul MagicJackpot de către copii.
            Un jucător trebuie să aibă vârsta de minim 18 ani, sau mai mult.{' '}
          </p>
          <p>
            În momentul înregistrării sau depunerii de fonduri, MagicJackpot aplică o procedură de
            cunoaştere a clienţilor (KYC), detaliată în celelalte documente depuse, prin care se
            verifică vârsta și datele unui jucător. Nefurnizarea acestor date în termenul stabilit
            poate duce la blocarea contului.
          </p>
          <p>
            De asemenea, dacă serviciul pentru clienți are suspiciuni cu privire la vârsta
            jucătorului, acest lucru este raportat imediat pentru a se întreprinde o serie de
            controale suplimentare.
          </p>
          <h3>2. Procedură</h3>
          <p>2.1 Măsuri pentru protecția minorilor</p>
          <p>
            Următoarele informații sunt afișate pe site-ul MagicJackpot, pentru a sublinia că numai
            adulţii pot folosi aceste servicii:
          </p>
          <ul>
            <li>Logo „18 ani” (Joc responsabil) pe fiecare pagină;</li>
            <li>
              Pe fiecare pagină, se face precizarea, în scris, că doar persoanele cu vârsta legală
              peste 18 de ani pot utiliza serviciile MagicJackpot
            </li>
            <li>
              Pe fiecare pagină, se face precizarea că jucătorii trebuie să acționeze în limitele
              legii și ale regulamentului;
            </li>
            <li>
              Procesul de înregistrare implică introducerea datei de naștere, care corespunde
              întotdeauna vârstei de minim 18 ani sau mai mult;
            </li>
            <li>Confirmarea expresă a utilizatorului că are 18 ani împliniți ;</li>
            <li>
              Prevederi specifice în termenii și condițiile MagicJackpot referitoare la accesul
              minorilor;
            </li>
            <li>
              Indicații despre modul în care părinții pot instala un mecanism de filtrare a
              conținutului pe calculatorul lor, astfel încât minorii să nu poată accesa sub nicio
              formă serviciile MagicJackpot.
            </li>
          </ul>
          <p>În plus:</p>
          <ul>
            <li>
              Design-ul jocurilor și funcţiilor site-ului nu trebuie să conţină: personaje de
              animaţie pentru copii, articole care pot trezi interesul copiilor;
            </li>
            <li>
              Logo-urile şi imaginile trebuie să aibă un stil atractiv, dar niciodată orientat către
              copii;
            </li>
            <li>Conținutul nu trebuie niciodată asociat cu viața de familie;</li>
            <li>
              Conținutul trebuie să fie întotdeauna verificat de către directorul de marketing,
              astfel încât să nu poată trezi interesul copiilor.
            </li>
          </ul>
          <h3>3. Jocul responsabil</h3>
          <p>
            MagicJackpot susține complet și fară echivoc ideea jocului responsabil, deoarece
            dependenţa de jocurile de noroc este o boală reală, care poate duce la pierderea de bani
            și chiar la lucruri mai grave.{' '}
          </p>
          <p>
            Este foarte important ca jucătorii să înţeleagă faptul că jocurile de noroc pentru
            divertisment pot duce la pagube financiare.{' '}
          </p>
          <p>
            Este foarte important ca jucătorii să se bucure de serviciile si jocurile MagicJackpot,
            însă într-un mod responsabil, fară a duce lucrurile la extrem și fară a suferi orice fel
            de pagubă sau neplăcere.
          </p>
          <p>
            <b>3.1 Abordarea MagicJackpot</b>
          </p>
          <ol>
            <li>Asigurarea unui mediu sigur și favorabil pentru clienți;</li>
            <li>
              Asigurarea că nu se va permite niciodată și sub nicio circumstanță accesul persoanelor
              sub 18 ani pe platforma MagicJackpot;
            </li>
            <li>Asigurarea că nu se vor exploata niciodată vulnerabilitățile jucătorilor;</li>
            <li>
              Asigurarea că se vor oferi mereu jocuri responsabile în fiecare parte a activității;
            </li>
            <li>
              Asigurarea că toți clienții vor fi informați despre jocul responsabil și pericolele la
              care se pot expune;
            </li>
            <li>
              Asigurarea că personalul este instruit și pregătit în domeniul jocului responsabil,
              astfel încât să poată identifica și lua acțiune atunci când este nevoie.
            </li>
          </ol>
          <h3>4. Sfaturile MagicJackpot cu privire la Jocul responsabil</h3>
          <p>
            (1) Joacă doar pentru amuzament și nu pentru a câștiga bani(2) Nu folosi niciodată bani
            care îți sunt necesari (chirie, facturi, întreținere, etc)pentru a juca(3) Nu încerca să
            recuperezi niciodată o pierdere și nu încerca sub nicio formă să mizezi sume mai mari de
            bani pentru a recupera banii pierduți(4) Nu participa la jocuri dacă ești deprimat,
            supărat, obosit, sub influența medicamentelor sau a băuturilor alcoolice.(5) Găsește și
            alte forme de distracție, astfel încât participarea la jocuri de noroc să nu devină o
            parte constantă a vieții tale(6) Nu ezita să folosești funcțiile de limitare sau
            autoexcludere(7) Nu ezita să ne ceri ajutorul, nouă, familiei sau specialiștilor dacă
            simți că ai probleme cu jocurile de noroc
          </p>

          <p>
            MagicJackpot are implementate un set de măsuri, programe informatice și proceduri după
            cum urmează:
          </p>
          <p>
            ✓ Pe fiecare pagină se pot găsi logo-urile organizațiilor de tratament și control al
            dependenței;
            <br />✓ MagicJackpot se asigură că deține o politică clară privind jocurile de noroc;
            <br />✓ Programul MagicJackpot de ajutor conţine informaţii complete cu privire la modul
            de abordare a problemei jocurilor de noroc, cu posibilitatea de a cere ajutor
            suplimentar;
            <br />✓ Proceduri de autoexcludere / excludere totală și de autolimitare pentru depuneri
            / pariuri (tip de produs);
            <br />✓ Ora exactă este afişată pe toate paginile site-ului și în cadrul jocurilor;
            <br />✓ MagicJackpot detine o procedură completă de cunoaştere a clienţilor pentru
            asistarea jucătorilor cu privire la înregistrare și depuneri;
            <br />✓ În cazul excluderii şi limitării accesului unui utilizator, MagicJackpot nu va
            trimite materiale de marketing jucătorilor din această categorie
            <br />✓ Tot personalul general şi departamentul de servicii clienți, trebuie să
            beneficieze de instruire repetată și de reînnoirea cunoştinţelor privind jocurile de
            noroc practicate de tineri, precum și, privind jocul compulsiv;
            <br />✓ Este disponibil un chestionar de autoevaluare care să permită clienţilor să
            verifice dacă au probleme legate de dependenţă de jocurile de noroc;
            <br />✓ Pagina „Contul meu”, trebuie să fie mereu disponibilă pentru a permite
            jucătorilor să-şi verifice depunerile / retragerile și istoricul de joc.
            <br />✓ Orice persoană, care nu poate lua decizii în deplinătatea facultăţilor mintale
            pe site-urile şi în cadrul serviciilor MagicJackpot, trebuie să fie exclusă:
          </p>
          <ul>
            <li>Dacă există dovezi privind consumul de alcool;</li>
            <li>Dacă există dovezi privind consumul de droguri;</li>
            <li>Dacă există dovezi că jucătorul suferă de o afecţiune psihică;</li>
            <li>
              Aceste dovezi trebuie să fie furnizate de către terţi personalului MagicJackpot;
            </li>
            <li>
              Directorul Serviciului pentru Clienți și agentul de conformitate trebuie să pună în
              aplicare această politică.
            </li>
          </ul>

          <h3>5. Instruirea personalului cu privire la Jocul Responsabil</h3>
          <p>
            Fiecare angajat este instruit cu privire la asistarea jucătorului şi la practicile
            privind jocul responsabil. Fiecare angajat trece printr-o instruire completă privind
            procedurile și produsele MagicJackpot, inclusiv cele legate de jocul responsabil.
          </p>
          <p>
            Ocazional, MagicJackpot poate contracta o companie terță care să furnizeze serviciile de
            instruire și actualizare.
          </p>
          <p>Instruirea privind jocul responsabil se desfășoară în 3 moduri:</p>
          <ol>
            <li>Pentru toți angajații</li>
          </ol>
          <p>
            - program de învățare electronică în care se oferă informații generale despre jocul
            responsabil- informații de învățare electronică privind psihologia din spatele jocurilor
            de noroc- scurtă evaluare
          </p>
          <ol start={2}>
            <li>
              Pentru angajații care sunt în legătură directă cu clienții și pentru anumite persoane
              selectate
            </li>
          </ol>
          <p>
            - persoanele care sunt în legătură directă cu clienții sunt instruiți într-un mod mai
            cuprinzător- se predau politica și procedurile specifice jocului responsabil
          </p>
          <ol start={3}>
            <li>Pregătire aprofundată</li>
          </ol>
          <p>
            - pentru managerii VIP- informații aprofundate despre modul de abordare a unui jucător
            problemă- empatia și comunicarea cu jucătorii problematici
          </p>

          <h3>6. Monitorizarea indicatorilor privind jocul responsabil</h3>
          <p>
            MagicJackpot are o monitorizare proactivă și de detectare timpurie a persoanelor ce pot
            dezvolta probleme în acest domeniu. Există indicatori specifici care sunt evaluați
            periodic, astfel încât să putem lua măsuri atunci când este cazul.
          </p>
          <p>Indicatorii se bazează pe:</p>
          <p>Depuneri</p>
          <ul>
            <li>Media depunerilor</li>
            <li>Variația față de media depunerilor</li>
            <li>Media este recalculată în fiecare săptămână</li>
            <li>Verificarea depunerilor se efectuează în fiecare zi</li>
          </ul>
          <p>Retrageri</p>
          <ul>
            <li>Media retragerilor</li>
            <li>Variația retragerilor</li>
            <li>Media este recalculată în fiecare săptămână</li>
            <li>Verificarea retragerilor se efectuează în fiecare zi</li>
            <li>Anularea retragerilor (Recreditare)</li>
            <li>Verificarea retragerilor anulate se efectuează în fiecare zi</li>
          </ul>
          <p>Bonusuri</p>
          <ul>
            <li>Media bonusurilor</li>
            <li>Variația bonusurilor</li>
          </ul>
          <p>Pariuri</p>
          <ul>
            <li>Media pariurilor</li>
            <li>Variația pariurilor</li>
          </ul>
          <p>Timp</p>
          <ul>
            <li>Media de timp petrecut într-o sesiune</li>
            <li>Variația timpului petrecut într-o sesiune</li>
          </ul>
          <p>Pierderi</p>
          <ul>
            <li>Pierderea este definită prin (pariu - câştig)</li>
            <li>Media pierderilor</li>
          </ul>
          <p>Înregistrări multiple</p>
          <p>Contactele avute cu serviciul clienți</p>

          <h3>7. Consiliere pe probleme de joc responsabil</h3>
          <p>
            În caz de detectare a unei probleme sau la cerere, MagicJackpot va ghida clientii către
            un centru specializat în identificarea și tratarea problemelor cauzate de jocurile de
            noroc.
          </p>
          <p>
            De la crearea sa, obiectivul grupului MagicJackpot a fost întotdeauna de a oferi
            servicii de înaltă calitate clienților săi, astfel încât aceştia să se bucure de o
            experienţă cât mai plăcută.
          </p>
          <p>
            Se pune la dispozitia clientilor site-ul www.jocresponsabil.ro unde li se ofera
            consiliere gratuita si totodata acestia pot afla daca au probleme sau nu cu dependenta
            de jocuri de noroc.
          </p>
          <p>
            Jocurile de noroc ar trebui să rămână o alegere personală și o alegere care se face pe
            baza satisfacţiei obţinute din această activitate. Acestea nu trebuie să fie o obligație
            în niciun caz sau o constrângere ca rezultat al oricărei alte influențe.
          </p>
          <p>
            Grupul MagicJackpot promovează jocul responsabil și își exprimă speranța că utilizatorii
            săi au un comportament responsabil în ceea ce privește obligaţia lor de zi cu zi.
          </p>
          <p>
            Pentru a ajuta clientii să determine rapid dacă sunt victime ale problemelor cauzate de
            jocurile de noroc, MagicJackpot furnizeaza mai jos o listă de întrebări. Dacă răspunsul
            la oricare dintre aceste întrebări este DA, MagicJackpot recomandă tuturor să contacteze
            de îndată Serviciul Clienți MagicJackpot sau un specialist:
          </p>
          <p>
            <br />✓ V-aţi luat timp liber de la serviciu sau studii din cauza jocurilor de noroc?
            <br />✓ Jocurile de noroc vă afectează relaţiile personale (familie, prieteni)?
            <br />✓ Finanțarea jocurilor de noroc are prioritate faţă de responsabilităţile
            financiare faţă de familie?
            <br />✓ Jocurile de noroc vă afectează sănătatea (probleme cu somnul, autoneglijare,
            izolare, depresie, stres, probleme de comportament)?
            <br />✓ Ați simțit remușcări sau regrete după ce aţi jucat sau aţi simțit nevoia să vă
            întoarceţi la joc cât mai curând posibil, în scopul de a câștiga mai mulți bani / reface
            pierderile?
            <br />✓ Aţi jucat pentru a plăti datorii sau pentru a compensa un deficit financiar?
            <br />✓ Aţi jucat dincolo de propriile limite financiare și de timp?
            <br />✓ Ați împrumutat sau vândut ceva pentru a finanța jocurile de noroc?
            <br />✓ V-aţi gândit să acționaţi în mod ilegal, în scopul de a finanța jocurile de
            noroc?
            <br />✓ Aveți probleme cu consumul de alcool și / sau droguri?
            <br />✓ V-aţi gândit la autovătămare din cauza jocurilor de noroc (de exemplu,
            sinucidere)?
            <br />✓ Aţi observat că deveniţi violent sau agresiv în timpul sau după joc?
          </p>

          <h3>8. Stabiliește-ți limitele</h3>
          <p>
            Pentru a veni în sprijinul clienților săi, platforma MagicJackpot a fost dezvoltată,
            astfel încât să ofere o serie de limitări cuprinzătoare pentru ca jocul să rămână unul
            pentru distracție.
          </p>
          <p>
            Limitările disponibile sunt următoarele, iar parametrii pot fi definiți de fiecare
            client:
          </p>
          <ul>
            <li>Limite de depozitare</li>
            <li>Limite de pariere</li>
          </ul>
          <p>
            Limitările iau efect în maxim 24 de ore după ce o cerere a fost plasată. Solicitarea de
            creștere a unei limite anterior aplicate contului unui client va fi implementată după
            minim 48 ore.
          </p>
          <p>
            Clienții pot contacta oricand MagicJackpot pentru a fi ghidați și ajutați să își
            stabilească limitele dorite.
          </p>
          <h3>9. Perioada de pauza</h3>
          <p>
            Dacă simțiți că aveți nevoie de o scurtă pauză de la jocuri, puteți stabili o perioadă
            de pauză pentru o zi sau mai multe, 2 săptămâni, 3 luni sau o perioadă la alegere. Toate
            opțiunile sunt ireversibile pe durata specificată. La sfârșitul perioadei stabilite
            pentru pauză de joc, restricția va fi anulată automat și veți avea din nou acces la
            contul de jucător și la toate opțiunile aferente.
          </p>
          <h3>10. Autoexcluderea</h3>
          <p>
            MagicJackpot oferă clienților opţiunea de a se autoexclude de la a juca pentru diferite
            perioade de timp, începând cu 7 zile. Toate autoexcluderile sunt ireversibile pe durata
            specificată în cerere. Perioadele de autoexcludere se aplică tuturor jocurilor.
          </p>
          <p>
            Clienții nu vor mai putea face depuneri sau paria pe parcursul perioadei de
            autoexcludere și nu vor mai primi email-uri sau ştiri de la MagicJackpot.
          </p>
          <p>
            În urma primirii unei cereri de autoexcludere, MagicJackpot va informa clienții asupra
            opțiunilor de tratament și consiliere într-un centru specializat, oferind tot suportul
            de care aceștia au nevoie în acest sens.
          </p>
          <p>Autoexcluderea se face din contul personal de jucător, secțiunea Joc Responsabil. </p>
          <p>
            În urma procesării unei cereri de autoexcludere, clientul va fi introdus într-un
            registru unic și confidențial la nivelul organizatorului, care poate fi prezentat la
            cerere organelor de control. Radierea unui client din acest registru se va face pe baza
            unei cereri scrise, la cel puțin 6 luni de la data înregistrării.
          </p>
        </div>
        {isAuthenticated && (
          <div className="take-a-break">
            <h2>Luați o pauză</h2>
            <p>
              Auto-excluderea face parte din politica noastră privind Jocul Responsabil și vă poate
              ajuta să controlați jocurile de noroc. Odată ce vă controlați mai bine jocurile și
              fondurile și trece perioada de autoexcludere, puteți opta pentru a reactiva contul.
            </p>
            <p>
              Este responsabilitatea dvs. să eliminați orice fonduri disponibile din contul de
              utilizator, inclusiv fondurile bonus, ÎNAINTE de a selecta o perioadă de
              autoexcludere, deoarece odată ce opțiunea de autoexcludere a fost activată, nu veți
              avea acces la contul dvs. Fondurile disponibile pot fi solicitate de la Serviciul
              Clienții în timp ce opțiunea de autoexcludere este activă. Dacă doriți să vă
              autoexcludeți permanent, vă rugăm să contactați Serviciul Clienți.
            </p>
            <div className="radio-buttons">
              <label htmlFor="option1" className="radio">
                7 zile
                <input
                  type="radio"
                  id="option1"
                  value="7 zile"
                  checked={selectedOption === '7 zile'}
                  onChange={e => setSelectedOption(e.target.value)}
                />
                <span className="checkmark" />
              </label>

              <label htmlFor="option2" className="radio">
                14 zile
                <input
                  type="radio"
                  id="option2"
                  value="14 zile"
                  checked={selectedOption === '14 zile'}
                  onChange={e => setSelectedOption(e.target.value)}
                />
                <span className="checkmark" />
              </label>

              <label htmlFor="option3" className="radio">
                30 zile
                <input
                  type="radio"
                  id="option3"
                  value="30 zile"
                  checked={selectedOption === '30 zile'}
                  onChange={e => setSelectedOption(e.target.value)}
                />
                <span className="checkmark" />
              </label>
              <label htmlFor="option5" className="radio">
                Nedefinit
                <input
                  type="radio"
                  id="option5"
                  value="Nedefinit"
                  checked={selectedOption === 'Nedefinit'}
                  onChange={e => setSelectedOption(e.target.value)}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="button-wrapper">
              <Link to="/">
                <Button color="white">Anulați</Button>
              </Link>
              <Button
                onClick={() =>
                  dispatch(
                    openConfirmModal({
                      loading: false,
                      error: '',
                      header: 'Confirmați Auto-excluderea',
                      text: `Aceasta înseamnă că nu veți putea să vă conectați la ${selectedOption}.`,
                      buttonText: 'Luați o pauză',
                      onSubmit: () => submitHandler(),
                    }),
                  )
                }
              >
                Luați o pauză
              </Button>
            </div>
          </div>
        )}
      </div>
    </StyledResponsibleGaming>
  );
}

export default ResponsibleGamingContent;
