import React from 'react';

import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import BonusCard from '@/components/bonuses/BonusCard';
import { selectAvailableBonusesForHome } from '@/store/selectors/availableBonusesSelectors';
import { selectUserManualBonuses } from '@/store/selectors/manualBonusesSelectors';

export const HIDDEN_BONUSES = [347, 348, 349, 350, 351, 352, 353, 354];

function AvailableBonuses() {
  const availableBonuses = useSelector(selectAvailableBonusesForHome);
  const manualBonuses = useSelector(selectUserManualBonuses);

  return (
    <div id="available-bonuses">
      <div className="mx-auto max-w-[400px] py-16 px-4 md:max-w-[900px] md:py-24 md:px-8 lg:max-w-[1400px]">
        <h2 className="mb-8 text-2xl sm:text-3xl">Bonusuri disponibile</h2>
        <div className="bonuses grid grid-cols-1 justify-items-center gap-8 md:grid-cols-2 lg:grid-cols-3">
          {availableBonuses?.map(
            bonus =>
              !HIDDEN_BONUSES.includes(bonus.BonusId) && (
                <BonusCard
                  type="regular"
                  header={bonus.Content?.header}
                  description={`<p>${bonus.Content?.description}</p>`}
                  availableUntil={bonus.Content.show_expiration_date ? bonus.DateTo : ''}
                  buttonLink={
                    isMobile ? bonus.Content?.mobile_game_link : bonus.Content?.desktop_game_link
                  }
                  redeemTypeId={bonus.RedeemTypeId}
                  bonusCode={bonus.PromoCodes ? bonus.PromoCodes[0] : ''}
                  key={`available-bonus-${bonus.BonusId}`}
                  terms={bonus.Content?.bonus_terms}
                  image={bonus.Content?.image}
                />
              ),
          )}
          {manualBonuses?.map((bonus, index) => (
            <BonusCard
              type="manual"
              redeemTypeId={1337}
              header={bonus.header}
              description={isMobile ? bonus.description_mobile : bonus.description_desktop}
              availableUntil={bonus.show_expiration_date ? bonus.available_until : ''}
              buttonLink={isMobile ? bonus.mobile_button_link : bonus.button_link}
              buttonText={bonus.button_text}
              // eslint-disable-next-line react/no-array-index-key
              key={`manual-bonus-${index}`}
              terms={bonus.terms}
              image={bonus.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AvailableBonuses;
