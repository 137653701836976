import { useEffect } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '@/store/actions/authActions';
import { openLoginModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated, selectAuthToken } from '@/store/selectors/authSelectors';
import { logException } from '@/utils/sentry-utils';

function usePingLogin(): void {
  const token = useSelector(selectAuthToken);
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (token && isAuthenticated) {
      interval = setInterval(() => {
        axios
          .post('/api/auth/isloggedin', { token })
          .then(response => {
            if (response.data.data === false) {
              dispatch(logout(token));
              dispatch(openLoginModal({ loggedOut: true }));
            }
          })
          .catch(e => {
            logException(e, { isLoggedInFailed: true });
          });
      }, 15000);
    }

    return () => clearInterval(interval);
  }, [token, isAuthenticated]);
}

export default usePingLogin;
