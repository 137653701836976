import React from 'react';

import AnimatedNumbers from 'react-animated-numbers';
import styled from 'styled-components';

import { ReactComponent as IconClubs } from '@/images/club-small.svg';
import { ReactComponent as IconDiamonds } from '@/images/diamond-small.svg';
import { ReactComponent as IconHearts } from '@/images/heart-small.svg';
import { ReactComponent as IconSpades } from '@/images/spades-small.svg';

const StyledJackpotLevel = styled.div`
  position: relative;
  background: white;
  border-radius: 16px;
  padding: 16px 32px;
  box-shadow: ${props => props.theme.boxShadow};

  .jackpot-level-icon {
    position: absolute;
    left: -12px;
    top: 30px;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  .jackpot-level-title {
    font-size: 0.9rem;
    color: ${props => props.theme.lightGray};
    margin-bottom: 4px;
  }

  .jackpot-level-value {
    font-size: 2rem;
    font-weight: bold;
    font-variant-numeric: tabular-nums;
    display: flex;
    align-items: center;
    > span {
      margin-left: 1rem;
    }
  }

  .hearts,
  .diamonds {
    color: ${props => props.theme.red};
  }

  .spades,
  .clubs {
    color: ${props => props.theme.black};
  }
`;

function JackpotLevel({
  value,
  icon,
  title,
}: {
  value: number;
  icon: 'hearts' | 'diamonds' | 'spades' | 'clubs';
  title: string;
}): JSX.Element {
  const iconImage = (icon: string) => {
    switch (icon) {
      case 'hearts':
        return <IconHearts className={icon} />;
      case 'spades':
        return <IconSpades className={icon} />;
      case 'diamonds':
        return <IconDiamonds className={icon} />;
      case 'clubs':
        return <IconClubs className={icon} />;
      default:
        return null;
    }
  };

  return (
    <StyledJackpotLevel>
      <div className="jackpot-level-icon">{iconImage(icon)}</div>
      <div className="jackpot-level-title">{title}</div>
      <div className="jackpot-level-value">
        <AnimatedNumbers
          includeComma
          animateToNumber={value}
          configs={[{ mass: 1, tension: 150, friction: 50 }]}
        />
        <span>RON</span>
      </div>
    </StyledJackpotLevel>
  );
}

export default JackpotLevel;
