import * as React from 'react';

function ToggleSwitch({
  id,
  label,
  name,
  value,
  onBlur,
  onChange,
  disabled,
}: {
  id: string;
  label: string;
  name: string;
  value: boolean;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}): JSX.Element {
  return (
    <label
      htmlFor={name}
      className="!flex items-center justify-between border-b-[1px] border-[rgba(0,0,0,0.1)] pb-1.5 text-[15px] font-bold"
    >
      {label}
      <div className="relative inline-block h-8 w-16">
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={value}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          className="peer h-0 w-0 opacity-0"
        />
        <span
          className="absolute top-0 left-0 right-0 bottom-0 cursor-pointer rounded-lg border-[1px]
          border-[rgba(0,0,0,0.1)] bg-off-white duration-200 before:absolute before:left-[7px] before:bottom-[7px] before:h-4
          before:w-4 before:rounded-[5px] before:border-[1px] before:border-[rgba(0,0,0,0.1)] before:bg-[#173033]
          before:duration-200 peer-checked:bg-mint peer-checked:before:translate-x-8 peer-checked:before:border-[1px]
          peer-checked:before:border-[rgb(119,194,174)] peer-checked:before:bg-white peer-disabled:cursor-not-allowed peer-disabled:opacity-60"
        />
      </div>
    </label>
  );
}

export default ToggleSwitch;
