import { BffGameItem, BffGamesJackpotsResponse, BffGameId } from '@lucky7ventures/bff-types';
import { createSelector } from 'reselect';

import { BFF_GAMES_CATEGORY } from '@/constants/constants';
import { GameCardVM } from '@/models/game-card-vm.model';
import { RootState } from '@/models/root-state.model';
import { searchGames } from '@/utils/game-helpers';

const selectGames = (state: RootState): Record<number, BffGameItem> => state.gamesBff.games;
const selectGamesLastPlayedIds = (state: RootState) => state.gamesBff.lastPlayedIds;
const selectGamesCategories = (state: RootState): Partial<Record<BFF_GAMES_CATEGORY, number[]>> =>
  state.gamesBff.categories;
const selectGamesProviders = (
  state: RootState,
): Record<string, { name: string; games: number[] }> => state.gamesBff.providers;
const selectGamesJackpots = (state: RootState): BffGamesJackpotsResponse => state.gamesBff.jackpots;
export const selectGamesSuccess = (state: RootState): boolean => state.gamesBff.success;
export const selectGameById =
  (id: number) =>
  (state: RootState): BffGameItem =>
    state.gamesBff.games[id];

export const selectGamesByCategory = (category: BFF_GAMES_CATEGORY, limit = 0) =>
  createSelector(
    selectGames,
    selectGamesJackpots,
    selectGamesCategories,
    (games, jackpots, categories): GameCardVM[] => {
      const selectedCategory = categories[category];
      if (!selectedCategory) {
        return [];
      }
      const gameIds =
        limit === 0
          ? selectedCategory
          : selectedCategory.slice(0, Math.min(limit, selectedCategory.length));
      return gameIds.map(id => ({ ...games[id], jackpot: jackpots[id] || null }));
    },
  );

export const selectGamesByProvider = (provider: string) =>
  createSelector(
    selectGames,
    selectGamesJackpots,
    selectGamesProviders,
    (games, jackpots, providers): { name: string; games: GameCardVM[] } | null => {
      const selectedProvider = providers[provider];
      if (!selectedProvider) {
        return null;
      }
      return {
        name: selectedProvider.name,
        games: selectedProvider.games.map(id => ({ ...games[id], jackpot: jackpots[id] || null })),
      };
    },
  );

export const selectAllGames = createSelector(
  selectGamesByCategory(BFF_GAMES_CATEGORY.ALL_GAMES),
  games => games,
);

export const selectLastPlayedGames = createSelector(
  selectGamesLastPlayedIds,
  selectGames,
  selectGamesJackpots,
  (lastPlayedIds, games: Record<BffGameId, BffGameItem>, jackpots): GameCardVM[] => {
    return lastPlayedIds.reduce<GameCardVM[]>((acc, id) => {
      const game = games[id];
      if (!game) {
        return acc;
      }
      acc.push({ ...game, jackpot: jackpots[game.gameId] || null });
      return acc;
    }, []);
  },
);

export const selectGamesSearch = (state: RootState): string => state.gamesBff.search;
export const selectSearchedGames = createSelector(
  selectAllGames,
  selectGamesSearch,
  (games, search) => searchGames(search, games),
);
