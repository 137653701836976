import { ZendeskAPI } from 'react-zendesk';

const openZendesk = (firstName = '', lastName = '', email = ''): void => {
  ZendeskAPI('webWidget', 'show');
  ZendeskAPI('webWidget', 'open');
  ZendeskAPI('webWidget', 'prefill', {
    name: {
      value: firstName && lastName && `${firstName} ${lastName}`,
    },
    email: {
      value: email,
    },
  });
};

export default openZendesk;
