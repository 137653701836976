import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import CloseButton from '@/components/button/CloseButton';
import DrawerHeader from '@/features/drawer/DrawerHeader';
import openZendesk from '@/hooks/openZendesk';
import { DrawerPropsModel } from '@/models/drawer-props.model';
import Faq from '@/pages/help/Faq';
import { loadZendesk } from '@/store/actions/commonActions';
import { selectUser } from '@/store/selectors/userSelectors';

const StyledHelpDrawer = styled.div`
  color: white;
  max-width: 620px;

  > .content {
    padding: 0 32px 48px;

    h2 {
      margin: 3rem 0 1rem;
    }
  }
`;

function HelpDrawer({ closeDrawer }: DrawerPropsModel): JSX.Element {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadZendesk());
  }, []);

  return (
    <StyledHelpDrawer className="drawer" id="e2e_helpDrawer">
      <CloseButton position="left" onClick={closeDrawer} />
      <DrawerHeader>
        <h1>
          Ai nevoie <br /> de ajutor?
        </h1>
        <p>
          Nicio problemă! Consultă secțiunea <br /> Întrebări și Răspunsuri sau contactează-ne.
        </p>
        <Button
          color="ghost"
          className="button"
          onClick={() => openZendesk(user?.FirstName, user?.LastName, user?.Email)}
        >
          Contactează-ne
        </Button>
      </DrawerHeader>
      <div className="content">
        <Faq />
      </div>
    </StyledHelpDrawer>
  );
}

export default HelpDrawer;
