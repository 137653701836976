import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/button/Button';
import useApiV2Request from '@/hooks/useApiV2Request';
import ApiService from '@/shared/api-service';
import { getUserTags } from '@/store/actions/userActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';

const LigaOptIn = ({ closeModal }: { closeModal: () => void }): JSX.Element | null => {
  const { request: optinRequest } = useApiV2Request();
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const optInHandler = () => {
    if (!token) {
      closeModal();
      return;
    }
    optinRequest(ApiService.ligaOptIn, null, () => {
      dispatch(getUserTags(token));
      closeModal();
    });
  };

  return (
    <div className="w-full max-w-[400px] rounded-lg bg-white py-6 px-6 text-center">
      <h1>Optează pentru LigaMagică</h1>
      <p>
        Optează pentru LigaMagică, adună puncte și învârte roțile cu premii din fiecare săptămână
      </p>
      <Button type="button" size="regular" onClick={optInHandler}>
        Optează
      </Button>
    </div>
  );
};

export default LigaOptIn;
