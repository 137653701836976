import React from 'react';

import Button from '@/components/button/Button';

const LigaTOS = ({ closeModal }: { closeModal: () => void }): JSX.Element | null => {
  return (
    <div className="prose flex w-full max-w-[480px] flex-col overflow-hidden rounded-lg bg-white py-6 px-6 text-left sm:px-6">
      <div className="prose-p:mb-8">
        <p>
          1. Promoția „LigaMagică” constă în nouăsprezece roți cu premii care sunt disponibile
          pentru deblocare începând cu ora 00:00 EEST luni și terminându-se la ora 00:00 EEST luni
          în fiecare săptămână. Promoția se realizează în conformitate cu Ordonanța de Urgență a
          Guvernului nr. 77/2009 pentru organizarea și exploatarea jocurilor de noroc și toată
          legislația aplicabilă aferentă de către MJP Interactive Ltd. Numai jucătorii cu vârsta de
          18 ani sau peste pot participa la Promoție în conformitate cu condițiile prezentate.
          Regulile MagicJackpot se vor aplica în mod corespunzător acestei promoții, cu excepția
          cazului în care se specifică în mod expres altfel în Termenii și condițiile prezente.
        </p>
        <p>
          2. Fiecare dintre cele 19 roți cu premii poate fi deblocată și învârtită o dată per
          jucător, pe săptămână, între orele 00:00 EEST luni - 00:00 EEST luni. Roțile cu premii și
          punctele totale ale jucătorului se vor reseta la ora 00:00 EEST luni în fiecare săptămână.
        </p>
        <p>
          3. Pentru a învârti prima roată cu premii din Liga 7, jucătorii trebuie să efectueze cel
          puțin o depunere de la crearea contului MagicJackpot și să-și verifice contul prin
          încărcarea unui act de identitate și dovada adresei.
        </p>

        <p>
          4. Pentru a învârti toate cele 18 roți cu premii (3 per Liga) conținute în Ligile 1, 2, 3,
          4, 5 și 6, jucătorii trebuie să câștige puncte între orele 00:00 EEST luni - 00:00 EEST
          luni în fiecare săptămână în timpul activ al promoției. Punctele se câștigă doar prin
          pariere cu fonduri cash la jocurile de tip slot. 1 leu miză în cash la orice slot = 1
          punct. În scopul acestei promoții, orice „fond blocat” poate fi definit ca miză cash și va
          contribui la totalul de puncte.
        </p>
        <p>
          5. Nu toate jocurile contribuie la totalul de puncte pentru această promoție. Vă rugăm să
          vedeți mai jos jocurile excluse din această promoție. Jocuri excluse - Toate jocurile de
          cazinou live, toate jocurile de masă, toate jocurile keno, toate jocurile de video poker,
          Coin Miner, To Mars and Beyond, Boxes, Coins, Mines.
        </p>
        <p>
          6. În continuare este un rezumat al punctelor care trebuie obținute între orele 00:00 EEST
          luni - 00:00 EEST luni și ceea ce se realizează prin adunarea lor:
        </p>
        <p>Roata cu Premii = Cerința pentru deblocare a Roții cu Premii</p>
        <p>
          Roata cu premii din Liga 7 = Cel puțin o depunere & Verificarea contului (Poză act de
          identitate & Dovada adresei)
        </p>
        <p>
          Roata 1 cu premii din Liga 6 = 375 Puncte în total
          <br />
          Roata 2 cu premii din Liga 6 = 750 Puncte în total
          <br />
          Roata 3 cu premii din Liga 6 = 1,125 Puncte în total
          <br />
        </p>
        <p>
          Roata 1 cu premii din Liga 5 = 1,875 Puncte în total
          <br />
          Roata 2 cu premii din Liga 5 = 2,625 Puncte în total
          <br />
          Roata 3 cu premii din Liga 5 = 3,375 Puncte în total
          <br />
        </p>
        <p>
          Roata 1 cu premii din Liga 4 = 4,875 Puncte în total
          <br />
          Roata 2 cu premii din Liga 4 = 6,375 Puncte în total
          <br />
          Roata 3 cu premii din Liga 4 = 7,875 Puncte în total
          <br />
        </p>
        <p>
          Roata 1 cu premii din Liga 3 = 10,875 Puncte în total
          <br />
          Roata 2 cu premii din Liga 3 = 13,875 Puncte în total
          <br />
          Roata 3 cu premii din Liga 3 = 16,875 Puncte în total
          <br />
        </p>
        <p>
          Roata 1 cu premii din Liga 2 = 22,875 Puncte în total
          <br />
          Roata 2 cu premii din Liga 2 = 28,875 Puncte în total
          <br />
          Roata 3 cu premii din Liga 2 = 34,875 Puncte în total
          <br />
        </p>
        <p>
          Roata 1 cu premii din Liga 1 = 46,875 Puncte în total
          <br />
          Roata 2 cu premii din Liga 1 = 58,875 Puncte în total
          <br />
          Roata 3 cu premii din Liga 1 = 70,875 Puncte în total
          <br />
        </p>
        <p>
          7. Fiecare roată cu premii poate fi învârtită imediat după deblocare. Toate roțile și
          totalurile de puncte se vor reseta la ora 00:00 EEST luni în fiecare săptămână. Dacă un
          jucător nu își acordă suficient timp pentru a învârti o roată cu premii după ce au fost
          atinse cerințele, nu va mai avea șansa de a învârti. Dacă butonul „Învârte” nu este apăsat
          sau dacă nu sunt îndeplinite orice alte condiții individuale ale premiului înainte de ora
          00:00 luni EEST în fiecare săptămână, atunci premiul câștigat nu va fi creditat.
        </p>
        <p>
          8. Jucătorii trebuie să facă clic pe „Revendică” după ce au învârtit fiecare roată
          individuală pentru a fi creditat premiul câștigat. Pentru bonusurile de cazinou, jucătorii
          vor fi direcționați către pagina de pornire și li se va prezenta un card bonus pe pagina
          de pornire pentru a-și revendica instantaneu bonusul de cazinou. Pentru rotiri gratuite,
          jucătorii vor fi direcționați către jocul în care au fost creditate rotirile gratuite.
          Jucătorii trebuie să folosească bonusul sau rotirile gratuite care le-au fost creditate
          imediat înainte de a trece mai departe pentru a revendica următoarea lor roată cu premii
          deblocată.
        </p>

        <p>
          9. MagicJackpot își rezervă dreptul de a face orice jucător ineligibil să participe la
          această promoție în orice moment. Dacă un jucător nu este eligibil pentru această
          promoție, acesta nu va putea accesa pagina /liga-magica și, prin urmare, nu va putea
          participa la promoție.
        </p>

        <p>
          10. Dacă MagicJackpot consideră că jucătorii participanți abuzează de promoție în vreun
          fel în timp ce realizează obiectivele necesare pentru deblocarea roților, MagicJackpot își
          rezervă dreptul de a face acești jucători ineligibili pentru această promoție în orice
          moment. Dacă un jucător nu este eligibil pentru această promoție, acesta nu va putea
          accesa pagina /liga-magica și, prin urmare, nu va putea participa la promoție.
        </p>

        <p>
          11. Pentru a participa la promoție după deblocarea oricăreia dintre roți, jucătorii
          trebuie să apese butonul „Învârte” pentru a învârti roata.
        </p>

        <p>
          12. Roata cu premii din „Liga 7” conține 6 secțiuni posibile pe care jucătorii pot să
          aterizeze: 25 Lei Casino Bonus, 20 Rotiri gratuite, 15 Rotiri gratuite, 10 Rotiri
          gratuite, 5 Rotiri gratuite, Necâștigător. Datorită prezenței secțiunii „Necâștigător”,
          jucătorii care se califică și învârt roata cu premii din Liga 7 nu au garanția să câștige
          un premiu la fiecare rotire.
        </p>

        <p>
          13. Toate roțile cu premii „Liga 6” conțin 6 secțiuni posibile pe care jucătorii pot să
          aterizezi: 30 de lei bonus de cazinou, 5 rotiri mega, 15 rotiri gratuite, 10 rotiri
          gratuite, 5 rotiri gratuite, 3 rotiri gratuite. Fiecare jucător poate câștiga un singur
          premiu după învârtirea unei roți.
        </p>

        <p>
          14. Toate roțile cu premii „Liga 5” conțin 6 secțiuni posibile pe care jucătorii pot să
          aterizeze: 50 de lei bonus de cazino, 10 rotiri mega, 30 de rotiri gratuite, 20 de rotiri
          gratuite, 10 rotiri gratuite, 5 rotiri gratuite. Fiecare jucător poate câștiga un singur
          premiu după învârtirea unei roți.
        </p>

        <p>
          15. Toate roțile cu premii „Liga 4” conțin 6 secțiuni posibile pe care jucătorii pot să
          aterizeze: 125 Lei Casino Bonus, 10 rotiri magic, 10 rotiri mega, 5 rotiri mega, 25 rotiri
          gratuite, 15 rotiri gratuite. Fiecare jucător poate câștiga un singur premiu după
          învârtirea unei roți.
        </p>

        <p>
          16. Toate roțile cu premii „Liga 3” conțin 6 secțiuni posibile pe care jucătorii pot să
          aterizeze: 250 Lei Casino Bonus, 125 Lei Casino Bonus, 10 rotiri magic, 10 rotiri mega, 5
          rotiri mega, 25 rotiri gratuite. Fiecare jucător poate câștiga un singur premiu după
          învârtirea unei roți.
        </p>

        <p>
          17. Toate roțile cu premii „Liga 2” conțin 6 secțiuni posibile pe care jucătorii pot să
          aterizeze: 500 Lei Casino Bonus, 250 Lei Casino Bonus, 10 rotiri vip, 10 rotiri magic, 5
          rotiri magic, 5 rotiri mega. Fiecare jucător poate câștiga un singur premiu după
          învârtirea unei roți.
        </p>

        <p>
          18. Toate roțile cu premii „Liga 1” conțin 6 secțiuni posibile pe care jucătorii pot să
          aterizeze: 1.000 de lei bonus de cazino, 500 de lei bonus de cazino, 250 de lei bonus de
          cazino, 10 rotiri VIP, 5 rotiri VIP, 5 rotiri magic. Fiecare jucător poate câștiga un
          singur premiu după învârtirea unei roți.
        </p>

        <p>
          19. Cerințele de pariere pentru bonusurile de cazinou creditate în timpul acestei promoții
          vor fi de 35x. Cerințele de pariere pentru toate câștigurile la rotiri gratuite creditate
          în timpul acestei promoții vor fi de 35x.
        </p>

        <p>
          20. Jocurile de cazinou contribuie la cerința de pariere după cum urmează: Sloturi: 100%
          Toate jackpot-urile: 0% Toate jocurile live Ruletă și Blackjack live: 10% Toate celelalte
          jocuri de cazinou live: 10% Toate jocurile de masă: 5%
        </p>

        <p>
          21. Nu toate jocurile de sloturi contribuie la cerințele de pariere, iată lista cu
          sloturile excluse de la pariurile bonus: Avalon, Aviator, Aztec Idols, Blood Suckers 2,
          Book of 99, Coin Miner, Coin Miner 2, Cool Buck, Dead or Alive, Dead or Alive 2, Football:
          Champions Cup, Golden Hook, Gus's Gold: Minecart Mayhem, Fly X, Jackpot 6000, Jacks or
          Better, Jacks or Better Multihand, Jacks or Better Double Up, Jet Lucky 2, Jungle Gorilla,
          Le Kaffee Bar, Magic of Sahara, Mines, Multifruit 81, Penalty Champion, Raging Zeus Mines,
          Reactoonz, Reactoonz 2, Secrets of Christmas, Shootout Champion, Steam Tower, Spaceman,
          Stormy Witch, The Wish Master, The Incredible Balloon Machine, Tower Quest, Village
          People: Macho Moves, Multifire Roulette Wildfire, European Roulette, Blackjack Classic.
        </p>

        <p>
          22. Șansele roților cu premii să aterizeze pe orice secțiune sunt aleatorii, generate pe
          baza unui proces automat care produce rezultate variabile.
        </p>

        <p>
          23. Dacă indicatorul se oprește pe o secțiune a oricărei roți cu premii și nu este însoțit
          de apariția mesajului pop-up, nu se va considera că premiul a fost câștigat. Vă rugăm să
          contactați Serviciul Clienți.
        </p>

        <p>
          24. Dacă apar erori tehnice la învârtire, Jucătoru va fi înștiințat printr-un mesaj
          pop-up. Jucătorii ar trebui să reîncarce mai întâi roata cu premii pentru a vedea dacă
          aceasta rezolvă problema. Vă rugăm să contactați Serviciul Clienți dacă întâmpinați
          problema în continuare.
        </p>

        <p>
          25. Pot exista momente în care roțile sunt indisponibile pentru actualizare sau
          întreținere, un mesaj pop-up vă va informa dacă acest lucru se întâmplă, vă rugăm să aveți
          răbdare și să verificați din nou mai târziu.
        </p>

        <p>
          26. MagicJackpot își rezervă dreptul de a elimina promoția „LigaMagică” de pe site complet
          și permanent la propria discreție.
        </p>
        <p>
          27. Vom deduce din valoarea premiului toate taxele sau alte obligații fiscale aferente
          premiilor oferite, inclusiv impozitul reținut la sursă aplicabil veniturilor individuale
          provenite din campanii promoționale și veți primi suma netă a premiului după ce toate
          taxele au fost reţinute.
        </p>
        <p>28. Se aplică toți termenii și condițiile generale MagicJackpot.</p>
      </div>
      <Button type="button" size="regular" onClick={closeModal}>
        OK
      </Button>
    </div>
  );
};

export default LigaTOS;
