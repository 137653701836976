import axios, { AxiosResponse } from 'axios';
import {
  put,
  StrictEffect,
  AllEffect,
  ForkEffect,
  takeEvery,
  all,
  call,
  select,
} from 'redux-saga/effects';

import { WP_URL } from '@/constants/constants';
import { ApiResponseModel } from '@/models/api-response.model';
import { FrontEndUserBonusObject } from '@/models/gig/front-end-user-bonus.model';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';
import { isAxiosResponse } from '@/shared/type-guards';
import {
  ActiveBonusesActionType,
  getActiveBonusContent,
  GetActiveBonusContentAction,
  getActiveBonusContentSuccess,
  GetActiveBonusesAction,
  getActiveBonusesError,
  getActiveBonusesSuccess,
  GetActiveBonusesSuccessAction,
} from '@/store/actions/activeBonusesActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { doNothing, isSuccessfulWpResponse } from '@/utils/helpers';

type ActiveBonusesAxiosResponse = AxiosResponse<ApiResponseModel<FrontEndUserBonusObject[]>>;
type ActiveBonusContentAxiosResponse = AxiosResponse<WpBonusContentModel>;

export function* getActiveBonusesSaga(
  action: GetActiveBonusesAction,
): Generator<StrictEffect, void, ActiveBonusesAxiosResponse | string | null> {
  const token = action.token ? action.token : yield select(selectAuthToken);

  if (!token) {
    yield put(getActiveBonusesError(true));
    return;
  }

  try {
    const response = yield call(axios.post, '/api/bonuses', { token });

    if (isAxiosResponse(response) && response.data.success && response.data.data) {
      const activeBonuses = response.data.data;
      yield put(getActiveBonusesSuccess(activeBonuses));
    } else {
      yield put(getActiveBonusesError(true));
    }
  } catch (error) {
    yield put(getActiveBonusesError(true));
  }
}

export function* getActiveBonusContentSaga(
  action: GetActiveBonusContentAction,
): Generator<StrictEffect, void, ActiveBonusContentAxiosResponse | string | null> {
  const { bonusId } = action;

  try {
    const response = yield call(axios.get, `${WP_URL}/bonuses/${bonusId}`);

    if (isSuccessfulWpResponse<WpBonusContentModel>(response)) {
      yield put(getActiveBonusContentSuccess(response.data, bonusId));
    }
  } catch {
    doNothing();
  }
}

export function* getActiveBonusesSuccessSaga(
  action: GetActiveBonusesSuccessAction,
): Generator<StrictEffect, void> {
  const bonuses = action.activeBonuses;

  for (let index = 0; index < bonuses.length; index += 1) {
    const bonus = bonuses[index];
    yield put(getActiveBonusContent(bonus.BonusId));
  }
}

export function* watchActiveBonuses(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(ActiveBonusesActionType.GetContent, getActiveBonusContentSaga)]);
  yield all([takeEvery(ActiveBonusesActionType.Get, getActiveBonusesSaga)]);
  yield all([takeEvery(ActiveBonusesActionType.Success, getActiveBonusesSuccessSaga)]);
}
