import axios, { AxiosResponse } from 'axios';
import {
  put,
  StrictEffect,
  AllEffect,
  ForkEffect,
  takeEvery,
  all,
  call,
  select,
} from 'redux-saga/effects';

import { WP_URL } from '@/constants/constants';
import { ApiResponseModel } from '@/models/api-response.model';
import { PublicBonusesModel } from '@/models/public-bonuses-object.model';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';
import { isAxiosResponse } from '@/shared/type-guards';
import {
  AvailableBonusesActionType,
  getAvailableBonusContent,
  GetAvailableBonusContentAction,
  getAvailableBonusContentError,
  getAvailableBonusContentSuccess,
  GetAvailableBonusesAction,
  getAvailableBonusesError,
  getAvailableBonusesSuccess,
  GetAvailableBonusesSuccessAction,
} from '@/store/actions/availableBonusesActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { isSuccessfulWpResponse } from '@/utils/helpers';

type AvailableBonusesAxiosResponse = AxiosResponse<ApiResponseModel<PublicBonusesModel[]>>;
type AvailableBonusContentAxiosResponse = AxiosResponse<WpBonusContentModel>;

export function* getAvailableBonusesSaga(
  action: GetAvailableBonusesAction,
): Generator<StrictEffect, void, AvailableBonusesAxiosResponse | string | null> {
  const token = action.token ? action.token : yield select(selectAuthToken);

  if (!token) {
    yield put(getAvailableBonusesError(true));
    return;
  }

  try {
    const response = yield call(axios.post, '/api/bonuses/getavailable', { token, device: 1 });

    if (isAxiosResponse(response) && response.data.success && response.data.data) {
      const availableBonuses = response.data.data;
      yield put(getAvailableBonusesSuccess(availableBonuses));
    } else {
      yield put(getAvailableBonusesError(true));
    }
  } catch (error) {
    yield put(getAvailableBonusesError(true));
  }
}

export function* getAvailableBonusContentSaga(
  action: GetAvailableBonusContentAction,
): Generator<StrictEffect, void, AvailableBonusContentAxiosResponse | string | null> {
  const { bonusId } = action;
  try {
    const response = yield call(axios.get, `${WP_URL}/bonuses/${bonusId}`);

    if (isSuccessfulWpResponse<WpBonusContentModel>(response)) {
      yield put(getAvailableBonusContentSuccess(response.data, bonusId));
    } else {
      yield put(getAvailableBonusContentError(bonusId));
    }
  } catch {
    yield put(getAvailableBonusContentError(bonusId));
  }
}

export function* getAvailableBonusesSuccessSaga(
  action: GetAvailableBonusesSuccessAction,
): Generator<StrictEffect, void> {
  const bonuses = action.availableBonuses;

  for (let index = 0; index < bonuses.length; index += 1) {
    const bonus = bonuses[index];
    yield put(getAvailableBonusContent(bonus.BonusId));
  }
}

export function* watchAvailableBonuses(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(AvailableBonusesActionType.GetContent, getAvailableBonusContentSaga)]);
  yield all([takeEvery(AvailableBonusesActionType.Get, getAvailableBonusesSaga)]);
  yield all([takeEvery(AvailableBonusesActionType.Success, getAvailableBonusesSuccessSaga)]);
}
