export const BFF_URL = process.env.REACT_APP_BFF_URL || '';
export const WP_URL = process.env.REACT_APP_WP_URL;
export const CASHIER_CSS = process.env.REACT_APP_CASHIER_CSS || '';
export const CASHIER_TRANSLATIONS = process.env.REACT_APP_CASHIER_TRANSLATIONS || '';
export const GIG_LOGIC_KYC_WEBHOOK = process.env.REACT_APP_GIG_LOGIC_KYC_WEBHOOK || '';

export const CDN_BASE = 'https://lucky7cdn.com';
export const CDN_IMAGES_PATH = '/magicjackpot/images';
export const CDN_IMAGES_COMMON_PATH = CDN_IMAGES_PATH + '/common';
export const CDN_IMAGES_GAMES_PATH = CDN_IMAGES_PATH + '/games';
export const LIGA_WHEEL_BASE = process.env.REACT_APP_LIGA_WHEEL_URL || '';

export const CDN_DATA = process.env.REACT_APP_CDN_DATA || '';

export const GENERAL_ERROR_MESSAGE = 'A apărut o eroare. Vă rugăm să contactați echipa de suport.';

export enum BFF_GAMES_CATEGORY {
  // Game categories
  ALL_GAMES = 'all-games',
  HALLOWEEN_GAMES = 'halloween-games',
  JACKPOTS = 'jackpots',
  LIVE_CASINO = 'live-casino',
  NONE = 'none',
  NEW_GAMES = 'new-games',
  RECOMMENDED = 'recommended',
  SLOTS = 'slots',
  TABLE_GAMES = 'table-games',
  WINTER_GAMES = 'winter-games',
}

export const GAME_PROVIDERS = [
  'amusnet',
  'egt-digital',
  'pragmatic-play',
  'novomatic',
  'games-global',
  'evolution',
  'play-n-go',
  'playson',
  'relax-gaming',
  'wazdan',
  'isoftbet',
  'nolimit-city',
  'netent',
  'big-time-gaming',
  'reelplay',
  'skywind',
  'g-games',
  'just-for-the-win',
  'triple-edge-studios',
  'gaming-corps',
  'gameburger-studios',
  'snowborn',
  'print-studios',
  'fantasma-games',
  'stormcraft-studios',
  'storm-gaming',
  'fortune-factory',
  'gold-coin-studios',
  'spinplay-games',
  'alchemy-gaming',
  'northern-lights',
  'pearfiction-studios',
  'blue-guru-games',
  'slingshot-studios',
  'real-dealer-studios',
  'neon-valley-studios',
  'foxium',
  'aurum-studios',
  'kalamba-games',
  'nailed-it-games',
  '4theplayer',
  'pulse-8-studios',
  'oros-gaming',
  'high-limit-studio',
  'spribe',
  'max-win-gaming',
  'electric-elephant',
  'rabcat',
  'area-vegas',
  'crazy-tooth-studio',
  'hammertime-games',
  'circular-arrow',
] as const;
