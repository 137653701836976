import * as React from 'react';
import { useState } from 'react';

import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import ArrowDropDownIcon from '@/components/icons/ArrowDropDownIcon';
import ArrowDropUpIcon from '@/components/icons/ArrowDropUpIcon';
import ChangePasswordBox from '@/pages/settings/details/ChangePasswordBox';

const StyledChangePassword = styled.div<{ showChangePassword: boolean }>`
  .change-password-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-top: 24px;
    border-radius: ${props =>
      props.showChangePassword ? '8px 8px 0 0' : props.theme.borderRadius};
    border: 1px solid rgba(0, 0, 0, 0.1);

    .change-button {
      padding: 8px 8px 8px 16px;
      transition: background-color 0.2s ease;
      border-radius: ${props => props.theme.borderRadius};
      background: ${props => props.theme.black};
      color: ${props => props.theme.white};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 700;

      &:hover {
        background-color: ${props => props.theme.offWhiteHover};
      }
    }
  }
`;

function ChangePassword(): JSX.Element {
  const [showChangePassword, setShowChangePassword] = useState(false);

  return (
    <StyledChangePassword showChangePassword={showChangePassword} className="full">
      <h2>Parolă</h2>
      <div className="change-password-wrapper">
        <div className="change-password-toggle">
          <div className="hidden-password">•••••••••••</div>
          <button
            type="button"
            className="change-button"
            // TODO: Change this to useToggle when merged with the input refactoring branch
            onClick={() =>
              showChangePassword ? setShowChangePassword(false) : setShowChangePassword(true)
            }
          >
            Schimbă {!showChangePassword ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </button>
        </div>
        <CSSTransition in={showChangePassword} timeout={200} unmountOnExit classNames="slide-down">
          <ChangePasswordBox setShowChangePassword={setShowChangePassword} />
        </CSSTransition>
      </div>
    </StyledChangePassword>
  );
}

export default ChangePassword;
