// this hook is used to sync certain cookies into the redux to be used across the app
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-use';

import { setBtag, setClickId } from '@/store/actions/cookiesActions';
import CookiesManagerUtils from '@/utils/cookies-manager-utils';
import { isEmpty } from '@/utils/helpers';

/*
  Initially we want to sync whatever is in the cookies into redux,
   as the idea is to use the values from the redux across the app to leverage
   the re-renders as cookie change doesn't trigger a re-render.
  */
function useCookiesSync() {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const btagCookie = CookiesManagerUtils.getBtagCookie();
    const clickIdCookie = CookiesManagerUtils.getClickIdCookie();
    if (btagCookie) {
      dispatch(setBtag(btagCookie));
    }
    if (clickIdCookie) {
      dispatch(setClickId(clickIdCookie));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(search)) {
      const params = new URLSearchParams(search);
      const btag = params.get('btag');
      const clickId = params.get('clickid');

      if (btag) {
        CookiesManagerUtils.setBtagCookie(btag);
        dispatch(setBtag(btag));
      }

      if (clickId) {
        CookiesManagerUtils.setClickIdCookie(clickId);
        dispatch(setClickId(clickId));
      }
    }
  }, [search]);
}

export default useCookiesSync;
