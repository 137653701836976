import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import useSearchParams from '@/hooks/useSearchParams';
import GamePromotion from '@/pages/games/GamePromotion';
import GameProvidersList from '@/pages/games/GameProvidersList';
import LastPlayedGames from '@/pages/games/LastPlayedGames';
import AvailableBonuses from '@/pages/home/AvailableBonuses';
import BonusIntro from '@/pages/home/BonusIntro';
import EGTDigitalBanner from '@/pages/home/EGTDigitalBanner';
import Hero from '@/pages/home/Hero';
import HomeGames from '@/pages/home/HomeGames';
import JackpotBanner from '@/pages/home/JackpotBanner';
import PaymentProviders from '@/pages/home/PaymentProviders';
import Splash from '@/pages/home/Splash';
import LigaBanner from '@/pages/liga/LigaBanner';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectAvailableBonusesForHome } from '@/store/selectors/availableBonusesSelectors';
import { selectUserManualBonuses } from '@/store/selectors/manualBonusesSelectors';
import { isEmpty } from '@/utils/helpers';
import { cdnImage } from '@/utils/image-utils';

function Home(): JSX.Element {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const availableBonuses = useSelector(selectAvailableBonusesForHome);
  const manualBonuses = useSelector(selectUserManualBonuses);
  const { width } = useWindowSize();

  function showAvailableBonuses() {
    // check if user has more than one bonus (it will show in the hero if only one)
    if (!isEmpty(availableBonuses) && availableBonuses.length > 1) {
      return true;
    }
    // check if user has more than one manual bonuse (it will show in the hero if only one)
    if (!isEmpty(manualBonuses) && manualBonuses.length > 1) {
      return true;
    }
    // if user has both a normal and a manual bonus, show available bonuses section
    if (!isEmpty(availableBonuses) && !isEmpty(manualBonuses)) {
      return true;
    }
    if (width < 767 && (!isEmpty(availableBonuses) || !isEmpty(manualBonuses))) {
      return true;
    }
    return false;
  }

  useSearchParams();

  return (
    <>
      <Hero />
      {!isAuthenticated && <JackpotBanner />}
      <EGTDigitalBanner />
      {!isAuthenticated && <GameProvidersList />}
      {!isAuthenticated && <HomeGames />}
      {!isAuthenticated && <BonusIntro />}
      {!isAuthenticated && <LigaBanner />}
      {isAuthenticated && showAvailableBonuses() && <AvailableBonuses />}
      {isAuthenticated && (
        <GamePromotion
          header="Joacă sloturile tale preferate la MagicJackpot"
          text={
            <p>
              Vor fi rotirile roiale și câștigurile de pe{' '}
              <Link to="/joc/shining-crown/26415">
                <strong>
                  <u>Shining Crown</u>
                </strong>
              </Link>
              ? Căldura enormă de pe
              <Link to="/joc/burning-hot/26357">
                <strong>
                  <u> Burning Hot</u>
                </strong>
              </Link>
              ? Sau poate câștigurile delicioase de până la 21,175x pariul de pe
              <Link to="/joc/sweet-bonanza/15508">
                <strong>
                  {' '}
                  <u>Sweet Bonanza</u>
                </strong>
              </Link>
              ! Simte magia și joacă cele mai bune sloturi aici.
            </p>
          }
          buttonText="Joacă acum"
          buttonLink="/jocuri/recomandate"
          imageUrl={cdnImage('game-promotion.png', { width: 'auto', height: '300' })}
          imageAlt="Recommended games promotion"
        />
      )}
      {isAuthenticated && <LastPlayedGames />}
      {!isAuthenticated && <Splash />}
      {!isAuthenticated && <PaymentProviders />}
    </>
  );
}

export default Home;
