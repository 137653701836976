import { KycDocumentResponseDto } from '@lucky7ventures/lucky-components';
import { createSelector } from 'reselect';

import { RootState } from '@/models/root-state.model';

const KycStatus = {
  APPROVED: 1,
  REQUESTED: [2, 5],
  REJECTED: 3,
  PROCESSING: 4,
};

export const selectKyc = (state: RootState): KycDocumentResponseDto[] => state.kyc.kyc;
export const selectKycLoading = (state: RootState): boolean => state.kyc.loading;

export const selectPhotoId = createSelector(selectKyc, (kyc): KycDocumentResponseDto | null => {
  if (!kyc) {
    return null;
  }
  return kyc.filter(kyc => kyc.TypeId === 1).sort((a, b) => b.KycId - a.KycId)[0];
});

export const selectIsPhotoIdApproved = createSelector(selectPhotoId, (photoId): boolean | null => {
  if (!photoId) {
    return false;
  }
  return photoId.Status === KycStatus.APPROVED;
});

export const selectProofOfAddress = createSelector(
  selectKyc,
  (kyc): KycDocumentResponseDto | null => {
    if (!kyc) {
      return null;
    }
    return kyc.filter(kyc => kyc.TypeId === 2).sort((a, b) => b.KycId - a.KycId)[0];
  },
);

export const selectPaymentMethod = createSelector(
  selectKyc,
  (kyc): KycDocumentResponseDto | null => {
    if (!kyc) {
      return null;
    }
    return kyc.filter(kyc => kyc.TypeId === 3).sort((a, b) => b.KycId - a.KycId)[0];
  },
);
