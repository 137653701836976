import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledValidationMessage = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: ${props => props.theme.red};
  margin-top: 8px;
  line-height: 1.2;
  text-align: left;
`;

function ValidationMessage({ type, message }) {
  return <StyledValidationMessage className={`${type}-error`}>{message}</StyledValidationMessage>;
}

ValidationMessage.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ValidationMessage;
