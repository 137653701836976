import React from 'react';

import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import Clock from './Clock';
import LogoLandscape from '@/components/icons/LogoLandscape';
import agelimit from '@/images/18.svg';
import chip from '@/images/chip-pattern.svg';
import onjn from '@/images/onjn.png';
import rombet from '@/images/rombet.png';

const StyledFooter = styled.footer`
  background: ${props => props.theme.black};
  color: white;
  position: relative;
  overflow: hidden;

  .pattern-wrapper-1 {
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;

    .pattern {
      background-image: url(${chip});
      background-size: 32px;
      background-repeat: repeat;
      height: 100%;
      width: 100%;
      z-index: 1;
      transform-origin: top right;
      transform: rotate(-60deg);
    }
  }

  .content {
    display: grid;
    max-width: 1400px;
    margin: 0 auto;
    padding: 4rem 1rem 10rem;
    grid-gap: 2rem;
    position: relative;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 4rem 2rem 6rem;
    }

    .navigation {
      li {
        margin-bottom: 1.5rem;

        a {
          svg {
            width: 160px;
            height: auto;
          }
        }
      }
    }

    .text {
      max-width: 640px;
      grid-column: span 2;
    }

    .logos {
      display: flex;
      margin-top: 2rem;

      .image {
        height: 32px;
        width: auto;
        margin-right: 24px;
      }

      @media (max-width: ${props => props.theme.breakpoints.small}) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.25rem;
      }
    }
  }
`;

function Footer(): JSX.Element {
  return (
    <StyledFooter id="footer">
      <div className="content">
        <nav className="navigation">
          <ul>
            <li>
              <Link to="/" className="logo">
                <LogoLandscape />
              </Link>
            </li>
            <li>
              <NavLink end to="/termeni-si-conditii">
                Termeni și Condiții
              </NavLink>
            </li>
            <li>
              <NavLink end to="/declaratie-de-confidentialitate">
                Declarație de Confidențialitate
              </NavLink>
            </li>
            <li>
              <NavLink end to="/politica-de-utilizare-a-cookie-urilor">
                Politica de utilizare a cookie-urilor
              </NavLink>
            </li>
            <li>
              <NavLink to="/joc-responsabil">Joc Responsabil</NavLink>
            </li>
            <li>
              <NavLink to="/metode-de-plata">Metode de Plată</NavLink>
            </li>
            <li>
              <NavLink to="/politica-impotriva-spalarii-banilor">
                Politica împotriva spălării banilor
              </NavLink>
            </li>
            <li>
              <a
                href="https://magicjackpotaffiliates.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Programul de Afiliați
              </a>
            </li>
            <li>
              <Clock />
            </li>
          </ul>
        </nav>
        <div className="text">
          <p>
            <a
              href="http://magicjackpot.ro/"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-1.5 underline"
            >
              MagicJackpot.ro
            </a>
            este deținut și operat de MJP Interactive Ltd, Număr de Înregistrare C 95830; Level 1
            Burlington Complex, Dragonara Road, St. Julians, Malta, prin MJP Interactive Ltd. St.
            Julians Sucursala Bucuresti, Număr de Înregistrare J40/22589/2023, CUI: 49190978, cu
            sediul in București, sector 5, str, Serg. Ion Nuțu nr. 44, et 8. Drept de organizare în
            baza licenței
            <a
              href="https://lucky7cdn.com/magicjackpot/regulatory/2020.12.23_MJP+Interactive_ONJN+Resolution_License1.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-1.5 underline"
            >
              L1203785W001253cu
            </a>
            valabilitate 01.01.2021-31.12.2030 și în baza autorizației de exploatare obținută prin
            <a
              href="https://lucky7cdn.com/magicjackpot/regulatory/Decision+no.+2506+of+19.12.2023+-+MJP+Interactive+Ltd+-+Authorisation.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-1.5 underline"
            >
              decizia 2506/19.12.2023
            </a>
            . Superbet Betting & Gaming SA este un organizator de jocuri de noroc tradiţionale în
            baza licenței RO3229L001332, care, conform OUG 77/2009, efectuează operaţiuni de
            alimentare sau de retragere a fondurilor în baza licenței de procesator de plăți emisă
            prin Decizia nr. 868/ 28.04.2016.
          </p>
          <p>Accesul persoanelor sub 18 ani este strict interzis.</p>
          <p>
            Este responsabilitatea fiecărui jucător să acționeze în conformitate cu reglementările
            în vigoare precum și a termenilor și condițiilor noastre. Jocurile de noroc implică risc
            financiar, joacă cu grijă.
          </p>
          <div className="logos">
            <a href="http://onjn.gov.ro/" target="_blank" rel="noopener noreferrer">
              <img src={onjn} alt="ONJN" className="image" />
            </a>
            <Link to="/joc-responsabil">
              <img src={agelimit} alt="Age Limit 18+" className="image" />
            </Link>
            <a
              href="http://www.rombet.com/jocul-responsabil"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={rombet} alt="Rombet" className="image" />
            </a>
            <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
              <img
                src="https://images.magicjackpot.ro/cdn-cgi/image/fit=contain,width=auto,height=32,format=auto,dpr=2/assets/SOL-copy.png"
                alt="SOL"
                className="image"
              />
            </a>
            <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener noreferrer">
              <img
                src="https://images.magicjackpot.ro/cdn-cgi/image/fit=contain,width=auto,height=32,format=auto,dpr=2/assets/SAL-copy.png"
                alt="SAL"
                className="image"
              />
            </a>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
}

export default Footer;
