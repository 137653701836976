import React from 'react';

import { GiftIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import CloseButton from '@/components/button/CloseButton';
import CreditCardIcon from '@/components/icons/CreditCardIcon';
import GameIcon from '@/components/icons/GameIcon';
import HomeIcon from '@/components/icons/HomeIcon';
import LigaIcon from '@/components/icons/LigaIcon';
import LogOutIcon from '@/components/icons/LogOutIcon';
import LoginIcon from '@/components/icons/LoginIcon';
import QuestionIcon from '@/components/icons/QuestionIcon';
import SettingsIcon from '@/components/icons/SettingsIcon';
import { useCashierShow } from '@/hooks/useCashier';
import usePromotionsRestriction from '@/hooks/usePromotionsRestriction';
import { ReactComponent as Logo } from '@/images/logo.svg';
import { DrawerPropsModel } from '@/models/drawer-props.model';
import { openLoginModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';

const StyledMenuDrawer = styled.div`
  padding: 96px 32px 64px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  max-width: 620px;
  width: 85vw;
  height: 100%;

  .logo {
    display: block;
    width: 100px;
    height: auto;

    svg {
      width: 100%;
      height: auto;
    }
  }

  ul {
    margin-top: 32px;

    li {
      margin-bottom: 16px;

      a,
      button {
        display: inline-flex;
        align-items: center;
        font-size: 16px;

        &.active {
          font-weight: 600;
          color: ${props => props.theme.red};
        }

        svg {
          color: ${props => props.theme.darkGreen};
          font-size: 22px;
          margin-right: 16px;
        }
      }

      &.margin-top {
        margin-top: 32px;
      }

      &.small {
        color: ${props => props.theme.green};

        a,
        button {
          font-size: 14px;
        }
      }
    }
  }

  .create-account {
    padding: 24px 32px;
    width: 100%;
    margin-top: 32px;
  }
`;

export function MenuDrawer({ closeDrawer }: DrawerPropsModel): JSX.Element {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const dispatch = useDispatch();
  const showCashier = useCashierShow();
  const { promotionsRestricted } = usePromotionsRestriction();
  const navigate = useNavigate();

  return (
    <StyledMenuDrawer className="drawer">
      <CloseButton position="left" onClick={closeDrawer} />
      <div>
        <Link onClick={closeDrawer} to="/" className="logo">
          <Logo />
        </Link>
        <ul>
          <li>
            <NavLink onClick={closeDrawer} end to="/">
              <HomeIcon />
              Acasă
            </NavLink>
          </li>
          <li>
            <NavLink onClick={closeDrawer} end to="/jocuri">
              <GameIcon />
              Jocuri
            </NavLink>
          </li>
          {isAuthenticated && (
            <>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    closeDrawer();
                    setTimeout(() => {
                      showCashier();
                    }, 300);
                  }}
                >
                  <CreditCardIcon />
                  Casierie
                </button>
              </li>
              <li>
                <NavLink onClick={closeDrawer} end to="/setarile-mele">
                  <SettingsIcon />
                  Setările mele
                </NavLink>
              </li>
            </>
          )}

          <li>
            <NavLink onClick={() => closeDrawer()} end to="/liga-magica">
              <LigaIcon className="w-[22px] h-[22px]" />
              LigaMagică
            </NavLink>
          </li>
          {!promotionsRestricted && (
            <li>
              <NavLink onClick={closeDrawer} end to="/promotii">
                <GiftIcon className="h-[22px] w-[22px]" />
                Promoții
              </NavLink>
            </li>
          )}
          <li>
            <Link onClick={closeDrawer} to="/ajutor">
              <QuestionIcon />
              Ajutor
            </Link>
          </li>
          {!isAuthenticated ? (
            <li className="margin-top">
              <button
                type="button"
                onClick={() => {
                  closeDrawer();
                  setTimeout(() => {
                    dispatch(openLoginModal());
                  }, 150);
                }}
              >
                <LoginIcon />
                Intră în cont
              </button>
            </li>
          ) : (
            <>
              <li className="margin-top">
                <Link onClick={closeDrawer} to="/deconectare">
                  <LogOutIcon />
                  Delogare
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <div>
        <ul>
          <li className="small margin-top">
            <Link onClick={closeDrawer} to="/termeni-si-conditii">
              Termeni și Condiții
            </Link>
          </li>
          <li className="small">
            <Link onClick={closeDrawer} to="/declaratie-de-confidentialitate">
              Declarație de Confidențialitate
            </Link>
          </li>
        </ul>
        {!isAuthenticated && (
          <Button
            onClick={() => {
              closeDrawer();
              setTimeout(() => {
                navigate('/inregistrare');
              }, 150);
            }}
            className="create-account"
          >
            Înregistrare
          </Button>
        )}
      </div>
    </StyledMenuDrawer>
  );
}
