import React from 'react';

import Confetti from '@/components/confetti/Confetti';

const RegistrationConfetti = () => {
  return (
    <>
      <Confetti
        className="absolute z-10 w-full h-full"
        options={{
          colors: ['#C7AA68', '#9f2a32', '#F2FAF8', '#1D2E31'],
          shapes: ['star'],
          particleCount: 200,
          angle: 60,
          scalar: 1,
          spread: 85,
          startVelocity: 85,
          origin: { x: 0, y: 1 },
        }}
      />
      <Confetti
        className="absolute z-10 w-full h-full"
        options={{
          colors: ['#C7AA68', '#9f2a32', '#F2FAF8', '#1D2E31'],
          shapes: ['star'],
          particleCount: 200,
          angle: 120,
          scalar: 1,
          spread: 85,
          startVelocity: 85,
          origin: { x: 1, y: 1 },
        }}
      />
    </>
  );
};

export default RegistrationConfetti;
