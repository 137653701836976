import React from 'react';

import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import ArrowDropRightIcon from '@/components/icons/ArrowDropRightIcon';
import GiftIcon from '@/components/icons/GiftIcon';
import { useCashierShow } from '@/hooks/useCashier';
import { HIDDEN_BONUSES } from '@/pages/home/AvailableBonuses';
import { selectAvailableBonusesWithContent } from '@/store/selectors/availableBonusesSelectors';
import { isEmpty } from '@/utils/helpers';

const StyledBonusButton = styled.li`
  position: relative;

  .bonus-button {
    padding: 0.7rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.yellow};
    color: #513600;
    border-radius: ${props => props.theme.borderRadius};
    font-size: 0.9rem;
    font-weight: 700;
    transition: all 0.2s ease;
    box-shadow: ${props => props.theme.boxShadow};

    &:hover {
      background: #f6c765;
    }

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 14px !important;
      font-size: 1rem;
    }

    svg {
      margin-left: 6px;
      font-size: 19px;
    }
  }

  .bonus-info {
    position: absolute;
    top: 100%;
    display: block;
    right: 0;
    opacity: 0;
    visibility: hidden;
    padding-top: 1rem;
    transform: translateY(1rem);
    transition: visibility 0s, opacity 200ms, transform 200ms;
    transition-delay: 200ms, 0s, 0s;

    &:before {
      content: '';
      position: absolute;
      top: calc(1rem - 8px);
      right: 26px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      z-index: 1;
      border-bottom: 8px solid white;
    }

    .bonus {
      display: inline-block;
      background: ${props => props.theme.white};
      padding: 16px;
      width: 260px;
      color: ${props => props.theme.fontBlack};
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      position: relative;

      &:nth-child(1) {
        border-radius: 8px 8px 0 0;
      }

      &:last-child {
        border-radius: 0 0 8px 8px;
      }

      &:only-child {
        border-radius: 8px;
      }

      .header {
        color: ${props => props.theme.black};
        font-weight: 700;
        font-size: 0.9rem;
        margin-bottom: 0.5em;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .text {
        font-size: 0.8rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .button {
        font-size: 14px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding: 0;

        span {
          background: ${props => props.theme.lightMint};
          border-radius: 50vh;
          height: 24px;
          width: 24px;
          margin-left: 8px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  ${props =>
    !props.$isMobile &&
    css`
      &:hover {
        cursor: pointer;

        .bonus-button {
          background-color: ${props => props.theme.green};
        }

        .bonus-info {
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
          transition-delay: 0s, 200ms, 200ms;
        }

        .bonus {
          cursor: auto;
        }
      }
    `}
`;

function BonusButton() {
  const availableBonuses = useSelector(selectAvailableBonusesWithContent);
  const navigate = useNavigate();
  const showCashier = useCashierShow();

  if (isEmpty(availableBonuses)) {
    return null;
  }

  function clickHandler() {
    navigate('/');
    const availableBonusesSection = document.getElementById('available-bonuses');
    if (availableBonusesSection) {
      const y = availableBonusesSection.getBoundingClientRect().top - 64;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  return (
    <StyledBonusButton $isMobile={isMobile}>
      <button type="button" className="bonus-button" onClick={() => clickHandler()}>
        {availableBonuses.length} <GiftIcon />
      </button>
      <div className="bonus-info">
        {availableBonuses.map(
          bonus =>
            !HIDDEN_BONUSES.includes(bonus.BonusId) && (
              <div className="bonus" key={`small-bonus-${bonus.BonusId}`}>
                <p className="header">{bonus.Content.header}</p>
                <p className="text">{bonus.Content.description}</p>
                {(bonus.RedeemTypeId === 5 || bonus.RedeemTypeId === 1) && (
                  <button type="button" onClick={() => showCashier()} className="button">
                    Efectuează o depunere
                    <span>
                      <ArrowDropRightIcon />
                    </span>
                  </button>
                )}
                {/* For game launch bonuses */}
                {bonus.RedeemTypeId === 4 && (
                  <Link
                    to={isMobile ? bonus.Content.mobile_game_link : bonus.Content.desktop_game_link}
                    className="button"
                  >
                    Joacă acum
                    <span>
                      <ArrowDropRightIcon />
                    </span>
                  </Link>
                )}
                {/* For opt in bonuses */}
                {bonus.RedeemTypeId === 2 && (
                  <Link
                    to="/"
                    onClick={() => {
                      if (document.getElementById('available-bonuses') !== null) {
                        document.getElementById('available-bonuses').scrollIntoView({
                          block: 'start',
                          behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <button type="button" className="button">
                      Vezi bonus
                      <span>
                        <ArrowDropRightIcon />
                      </span>
                    </button>
                  </Link>
                )}
              </div>
            ),
        )}
      </div>
    </StyledBonusButton>
  );
}

export default BonusButton;
