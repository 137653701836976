import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import * as animations from '../../styles/animations';
import CloseButton from '@/components/button/CloseButton';
import BonusTermsModal from '@/features/modals/BonusTerms';
import ConfirmModal from '@/features/modals/Confirm';
import ConsentsModal from '@/features/modals/Consents';
import CookiesModal from '@/features/modals/Cookies';
import DepositBalanceUpdateModal from '@/features/modals/DepositBalanceUpdate';
import ExitIntentModal from '@/features/modals/ExitIntent';
import GeneralBonusTermsModal from '@/features/modals/GeneralBonusTerms';
import LigaOptInModal from '@/features/modals/LigaOptIn';
import LigaTOSModal from '@/features/modals/LigaTOS';
import LoginModal from '@/features/modals/login/Login';
import { ModalType } from '@/models/enums/modal-type.enum';
import { setToggleDepositAdded } from '@/store/actions/depositActions';
import { closeModal } from '@/store/actions/modalActions';
import { selectModalName } from '@/store/selectors/modalSelectors';

function ReactModalAdapter({
  className,
  isOpen,
  onRequestClose,
  ariaHideApp,
  closeTimeoutMS,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  children,
}: {
  className: string;
  isOpen: boolean;
  onRequestClose: () => void;
  ariaHideApp: boolean;
  closeTimeoutMS: number;
  shouldCloseOnEsc: boolean;
  shouldCloseOnOverlayClick: boolean;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <Modal
      portalClassName={className}
      className={`${className}-content`}
      overlayClassName={`${className}-overlay`}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={ariaHideApp}
      closeTimeoutMS={closeTimeoutMS}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {children}
    </Modal>
  );
}

const StyledModal = styled(ReactModalAdapter)`
  .asset-1 {
    position: absolute;
    right: calc(100% - 32px);
    top: -32px;
    pointer-events: none;
    width: 200px;
    height: auto;
    z-index: -1;
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      display: block;
    }
  }

  .asset-2 {
    position: absolute;
    left: calc(100% - 32px);
    bottom: -32px;
    pointer-events: none;
    width: 200px;
    height: auto;
    z-index: -1;
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      display: block;
    }
  }

  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.overlayBlack};
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 0;
    animation-duration: 150ms;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    z-index: 120;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 2rem;
    }

    &--after-open {
      // @ts-ignore
      animation-name: ${animations.fadeIn};
    }

    &--before-close {
      // @ts-ignore
      animation-name: ${animations.fadeOut};
    }
  }

  .ReactModal__Content {
    position: relative;
    margin: auto;
    -webkit-overflow-scrolling: touch;
    animation-duration: 150ms;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;

    &--after-open {
      // @ts-ignore
      animation-name: ${animations.scaleIn};
    }

    &--before-close {
      // @ts-ignore
      animation-name: ${animations.scaleOut};
    }
  }
`;

const DELAY = 150;

const HIDDEN_CLOSE_BUTTON_MODALS = [ModalType.Consent, ModalType.Cookies, ModalType.ExitIntent];

function ModalContainer(): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);
  const modalName = useSelector(selectModalName);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(!!modalName);
  }, [modalName]);

  function closeModalHandler() {
    setIsOpen(false);
    setTimeout(() => {
      dispatch(closeModal());
      dispatch(setToggleDepositAdded(false));
    }, DELAY);
  }

  if (!modalName) {
    return null;
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={closeModalHandler}
      ariaHideApp={false}
      closeTimeoutMS={DELAY}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={!HIDDEN_CLOSE_BUTTON_MODALS.includes(modalName)}
      className="ReactModal"
    >
      {!HIDDEN_CLOSE_BUTTON_MODALS.includes(modalName) && (
        <CloseButton position="right" onClick={closeModalHandler} color="black" />
      )}
      {modalName === ModalType.Login && <LoginModal closeModal={closeModalHandler} />}
      {modalName === ModalType.Confirm && <ConfirmModal closeModal={closeModalHandler} />}
      {modalName === ModalType.Terms && <BonusTermsModal />}
      {modalName === ModalType.DepositBalanceUpdate && (
        <DepositBalanceUpdateModal closeModal={closeModalHandler} />
      )}
      {modalName === ModalType.Consent && <ConsentsModal closeModal={closeModalHandler} />}
      {modalName === ModalType.Cookies && <CookiesModal closeModal={closeModalHandler} />}
      {modalName === ModalType.LigaTOS && <LigaTOSModal closeModal={closeModalHandler} />}
      {modalName === ModalType.LigaOptIn && <LigaOptInModal closeModal={closeModalHandler} />}
      {modalName === ModalType.GeneralBonusTerms && <GeneralBonusTermsModal />}
      {modalName === ModalType.ExitIntent && <ExitIntentModal closeModal={closeModalHandler} />}
    </StyledModal>
  );
}

export default ModalContainer;
