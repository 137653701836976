import React from 'react';

import NumberFormat from 'react-number-format';

function CurrencyFormat({
  currency = '',
  amount,
}: {
  currency?: string;
  amount?: number | null;
}): JSX.Element {
  switch (currency) {
    case 'INR':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          thousandsGroupStyle="lakh"
          prefix="₹"
          decimalScale={2}
        />
      );
    case 'CAD':
    case 'NZD':
    case 'USD':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          prefix="$"
          decimalScale={2}
        />
      );
    case 'CHF':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          suffix=" CHf"
          decimalScale={2}
        />
      );
    case 'NOK':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          suffix=" kr"
          decimalScale={2}
        />
      );
    case 'JPY':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          prefix="¥"
          decimalScale={2}
        />
      );
    case 'ZAR':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          prefix="R"
          decimalScale={2}
        />
      );
    case 'RON':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          suffix=" RON"
          decimalScale={2}
        />
      );
    default:
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          prefix="$ "
          decimalScale={2}
        />
      );
  }
}

export default CurrencyFormat;
