import { createSelector } from 'reselect';

import { selectUserId } from './userSelectors';
import { ManualBonusModel } from '@/models/manual-bonus.model';
import { RootState } from '@/models/root-state.model';

export const selectManualBonuses = (state: RootState): ManualBonusModel[] =>
  state.manualBonuses.manualBonuses;
export const selectUserManualBonuses = createSelector(
  selectManualBonuses,
  selectUserId,
  (manualBonuses, userId): ManualBonusModel[] => {
    const now = new Date().toISOString();

    return manualBonuses.filter((bonus: ManualBonusModel) => {
      const expiryDate = bonus.available_until.split(' ').join('T').concat('.000Z');
      if (now > expiryDate) {
        return false;
      }

      if (bonus.available_for_all) {
        return true;
      }

      if (bonus.available_for_list && userId) {
        const availableFor = bonus.available_for_list.split(',');
        return availableFor.includes(userId.toString());
      }

      return false;
    });
  },
);

export const selectFirstManualBonus = createSelector(selectUserManualBonuses, manualBonuses =>
  manualBonuses.length > 0 ? manualBonuses[0] : null,
);
