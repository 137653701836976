import { BffAddressLookupResponse } from '@lucky7ventures/bff-types';

function calculateForeignCitizenDob(yearDigits: number): number | undefined {
  const currentYear = new Date().getFullYear();
  const currentCentury = parseInt(currentYear.toString().substring(0, 2), 10) * 100;
  const currentYearDigits = parseInt(currentYear.toString().substring(2, 4), 10);

  // Underage: users from current year - 18
  if (yearDigits > currentYearDigits - 18 && yearDigits <= currentYearDigits) {
    return 2000 + yearDigits;
  }

  // Users from current year - 100 up to last year of that century
  if (yearDigits > currentYearDigits && yearDigits <= 99) {
    return currentCentury - 100 + yearDigits;
  }

  if (yearDigits >= 0 && yearDigits <= currentYearDigits - 18) {
    return currentCentury + yearDigits;
  }
}

export function extractBirthdateFromCNP(
  cnp: string,
): { day: string; month: string; year: string } | null {
  // Validate CNP length and characters
  if (cnp.length !== 13 || !/^[0-9]+$/.test(cnp)) {
    return null;
  }

  // Extract the year, month, and day from the CNP
  const year = parseInt(cnp.substring(1, 3), 10); // Characters 2 and 3
  const month = cnp.substring(3, 5); // Characters 4 and 5
  const day = cnp.substring(5, 7); // Characters 6 and 7

  // Determine the full year based on the first digit (gender/century code)
  const firstDigit = parseInt(cnp.charAt(0), 10);
  let fullYear: number;

  if (firstDigit === 1 || firstDigit === 2) {
    fullYear = 1900 + year; // 1900-1999
  } else if (firstDigit === 3 || firstDigit === 4) {
    fullYear = 1800 + year; // 1800-1899
  } else if (firstDigit === 5 || firstDigit === 6) {
    fullYear = 2000 + year; // 2000-2099
  } else if (firstDigit === 7 || firstDigit === 8 || firstDigit === 9) {
    const calculatedYear = calculateForeignCitizenDob(year);
    if (calculatedYear) {
      fullYear = calculatedYear;
    } else return null;
  } else {
    return null; // Invalid CNP
  }

  // Return the extracted birthdate
  return { day, month, year: fullYear.toString() };
}

export const otherLabel = 'Nu vă găsiți adresa? Introduceți manual';

export function mapAddressLookupData(data: BffAddressLookupResponse) {
  const mappedData = data.map(({ text, isFinal, id }) => {
    return { label: text, value: text, isFinal, id, isParent: false };
  });
  const firstFinal = mappedData.find(({ isFinal }) => isFinal);
  if (firstFinal) mappedData.push({ ...firstFinal, label: otherLabel, value: otherLabel });
  return mappedData;
}
