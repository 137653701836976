import { useRef, useEffect } from 'react';

import axios, { CancelToken, CancelTokenSource } from 'axios';

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 */
const useCancelToken = (): {
  newCancelToken: () => CancelToken;
  cancelRequest: () => void;
} => {
  const axiosSource = useRef<CancelTokenSource | null>(null);
  const newCancelToken = (): CancelToken => {
    const cancelToken = axios.CancelToken;
    axiosSource.current = cancelToken.source();
    return axiosSource.current.token;
  };

  const cancelRequest = (): void => {
    if (axiosSource.current) {
      axiosSource.current.cancel();
    }
  };

  useEffect(
    () => () => {
      cancelRequest();
    },
    [],
  );

  return { newCancelToken, cancelRequest };
};

export default useCancelToken;
