import axios, { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  ForkEffect,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import { WP_URL } from '@/constants/constants';
import { WpPrivacyPolicy } from '@/models/wp-privacy-policy.model';
import { WpTermsAndConditions } from '@/models/wp-terms-and-conditions.model';
import {
  getPrivacyPolicySuccess,
  getTermsAndConditionsSuccess,
  WordpressActionType,
} from '@/store/actions/wordpressActions';
import {
  selectPrivacyPolicy,
  selectTermsAndConditions,
} from '@/store/selectors/wordpressSelectors';
import { logException } from '@/utils/sentry-utils';

export function* getTermsAndConditionsSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<WpTermsAndConditions>
> {
  const tc = yield select(selectTermsAndConditions);

  if (!tc) {
    try {
      const response = yield call(axios.get, `${WP_URL}/terms_and_conditions`);
      if (response?.data) {
        yield put(getTermsAndConditionsSuccess(response.data));
      }
    } catch (error) {
      logException(error);
    }
  }
}

export function* getPrivacyPolicySaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<WpPrivacyPolicy>
> {
  const pp = yield select(selectPrivacyPolicy);

  if (!pp) {
    try {
      const response = yield call(axios.get, `${WP_URL}/privacy_policy`);
      if (response?.data) {
        yield put(getPrivacyPolicySuccess(response.data));
      }
    } catch (error) {
      logException(error);
    }
  }
}

export function* watchWordpress(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(WordpressActionType.GetTermsAndConditions, getTermsAndConditionsSaga),
    takeEvery(WordpressActionType.GetPrivacyPolicy, getPrivacyPolicySaga),
  ]);
}
