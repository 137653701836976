import axios, { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeEvery,
  call,
  StrictEffect,
} from 'redux-saga/effects';

import { ApiResponseModel } from '@/models/api-response.model';
import { isAxiosResponse } from '@/shared/type-guards';
import {
  DepositActionTypes,
  GetDepositCountAction,
  getDepositCountError,
  getDepositCountSuccess,
} from '@/store/actions/depositActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';

type DepositAxiosResponse = AxiosResponse<ApiResponseModel<number>>;

export function* getDepositCountSaga(
  action: GetDepositCountAction,
): Generator<StrictEffect, void, DepositAxiosResponse | string | null> {
  const token = action.token ? action.token : yield select(selectAuthToken);

  if (!token) {
    yield put(getDepositCountError());
    return;
  }

  try {
    const response = yield call(axios.post, '/api/user/getdepositcount', { token });

    if (isAxiosResponse(response) && response.data.success) {
      // TODO: Check this out. This is a dirty fix. If we checked both response.data.success && response.data.data the if wouldn't be true for new users where depositcount would be 0. I don't know why but there you go.
      const depositCount = response.data.data || 0;
      yield put(getDepositCountSuccess(depositCount));
    } else {
      yield put(getDepositCountError());
    }
  } catch (error) {
    yield put(getDepositCountError());
  }
}

export function* watchDeposit(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(DepositActionTypes.Get, getDepositCountSaga)]);
}
