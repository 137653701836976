import { ErrorCode, UserResponseDto } from '@lucky7ventures/lucky-components';
import { AxiosResponse } from 'axios';
import { isAndroid, isIOS, isMobile, isTablet, isWinPhone } from 'react-device-detect';

import { getGigErrorTranslation } from './gig-utils';
import { UNKNOWN_ERROR } from '@/constants/internal-error-messages';
import { DateRangeModel } from '@/models/date-range.model';
import { ExtendedBonusModel } from '@/models/extended-bonus.model';
import { ExtendedUserModel } from '@/models/extended-user.model';
import { isAxiosResponse, isWpError } from '@/shared/type-guards';

export const concatenateValues = (
  values: (string | number | undefined | null)[],
  separator = ' ',
): string => {
  if (values.filter(i => !i).length > 0) {
    return '';
  }

  return values.join(separator);
};

export const getBirthdateDetails = (
  date: Date | string | undefined | null,
): { year: string; month: string; day: string } => {
  if (!date) {
    return {
      year: '',
      month: '',
      day: '',
    };
  }

  const birthDate = new Date(date);
  const year = birthDate.getFullYear().toString(10);
  const month = (birthDate.getMonth() + 1).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const day = birthDate.getDate().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return {
    year,
    month,
    day,
  };
};

export const getGigErrorMessage = (errors: ErrorCode[] | undefined): string => {
  if (!errors || errors.length === 0) {
    return UNKNOWN_ERROR;
  }

  const translatedError = getGigErrorTranslation(errors[0].ErrorCodeID);
  if (translatedError) {
    return translatedError;
  }

  return errors[0].Message;
};

export const isSuccessfulWpResponse = <T>(response: unknown): response is AxiosResponse<T> => {
  if (!isAxiosResponse(response)) {
    return false;
  }

  return !isWpError(response.data);
};

export const containsPartialItem = (array: string[], term: string): boolean => {
  for (let i = 0; i < array.length; i++) {
    if (term.includes(array[i])) {
      return true;
    }
  }
  return false;
};

export const isXmasBonus = (bonus: ExtendedBonusModel): boolean => {
  if (!bonus || !bonus.PromoCodes || bonus.PromoCodes.length === 0) {
    return false;
  }
  return !!bonus.PromoCodes.find(i => i.includes('XMAS'));
};

export const normalizeDateWithTime = (date?: string | null): string | undefined => {
  if (!date || typeof date.replace !== 'function') {
    return;
  }

  return date.replace(/\s/, 'T');
};

export const mapUser = (user: UserResponseDto): ExtendedUserModel => {
  return {
    Username: user.Username,
    CountryCode: user.Country.AlphaCode2,
    UserID: user.UserId,
    Email: user.Email,
    FirstName: user.FirstName,
    LastName: user.LastName,
    BirthDate: user.BirthDate,
    Phone: user.Phone.PhoneNumber,
    MobilePrefix: user.Phone.MobilePrefix,
    Mobile: user.Phone.MobileNumber,
    Address1: user.Address.Address1,
    City: user.Address.City,
    PostalCode: user.Address.PostalCode,
    LanguageID: user.Language.LanguageId,
    CountryID: user.Country.CountryId,
    Reference: user.Reference,
    CountryName: user.Country.Name,
    Currency: user.Currency.Code,
  };
};

export const isDecember = (): boolean => {
  const today = new Date();
  const month = today.getMonth();
  return month === 11;
};

export const checkDevice = () => {
  if (isTablet && isIOS) {
    return 2;
  }

  if (isMobile && isIOS) {
    return 3;
  }

  if (isMobile && isAndroid) {
    return 4;
  }

  if (isWinPhone) {
    return 6;
  }

  if (isTablet && isAndroid) {
    return 8;
  }

  // return 1 in case of the browser or all other cases
  return 1;
};

export const updateObject = (oldObject: any, updatedProperties: any) => ({
  ...oldObject,
  ...updatedProperties,
});

export function isEmpty(value: any) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export const snakeToCamel = (entry: string) =>
  entry.replace(/([-_][a-z])/gi, $1 => $1.toUpperCase().replace('-', '').replace('_', ''));

export const snakeToTitle = (entry: string) =>
  entry.replace(/([-_][a-z])/gi, $1 => $1.toUpperCase().replace('-', ' ').replace('_', ' '));

export const snakeToSpace = (entry: string) =>
  entry.replace(/([-_][a-z])/gi, $1 => $1.toLowerCase().replace('-', ' ').replace('_', ' '));

export const capitalize = (entry: string) => {
  return entry.charAt(0).toUpperCase() + entry.slice(1);
};

export interface DateFilterModel {
  dateRange: DateRangeModel;
  label: string;
}

/*
  This function can be used to get a date range between two dates.
  In example if you want to get a date range by saying give me
  daysStart - number of days from TODAY where the interval starts
  daysEnd - number of days from TODAY where the interval ends

  Returns {from: string, to: toDate];
 */
export const getDateRangeBetweenDays = (daysStart: number, daysEnd: number) => {
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() + daysStart);
  const toDate = new Date();
  toDate.setDate(toDate.getDate() + daysEnd);
  return { from: fromDate.toUTCString(), to: toDate.toUTCString() };
};

export const DATE_FILTER_OPTIONS: Record<number, DateFilterModel> = {
  1: {
    dateRange: getDateRangeBetweenDays(-30, 0),
    label: 'Ultimele 30 de zile',
  },
  2: {
    dateRange: getDateRangeBetweenDays(-60, -30),
    label: '30 - 60 zile',
  },
  3: {
    dateRange: getDateRangeBetweenDays(-90, -60),
    label: '60 - 90 zile',
  },
};

export const doNothing = () => {};
