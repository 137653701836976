import React, { InputHTMLAttributes } from 'react';

import classNames from 'classnames';

interface FieldLabelProps extends InputHTMLAttributes<HTMLLabelElement> {
  value?: any;
}

const FieldLabel = ({ className, children, value }: FieldLabelProps) => (
  <label
    className={classNames(
      `truncate absolute left-2 top-3.5 px-2 transition-transform duration-150 pointer-events-none
            origin-top-left peer-focus:-translate-y-[25%] peer-focus:scale-75 peer-autofill:text-black peer-focus:font-bold`,
      value && '-translate-y-[25%] scale-75 font-semibold',
      className,
    )}
  >
    {children}
  </label>
);

export default FieldLabel;
