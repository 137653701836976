import { Action } from 'redux';

import { LifetimeDeposits } from '@/models/gig/lifetime-deposits.model';

export enum DepositActionTypes {
  Get = 'GET_DEPOSIT_COUNT',
  GetStart = 'GET_DEPOSIT_COUNT_START',
  GetSuccess = 'GET_DEPOSIT_COUNT_SUCCESS',
  GetError = 'GET_DEPOSIT_COUNT_ERROR',
  ToggleDepositAdded = 'TOGGLE_DEPOSIT_ADDED',
  IsSingleGame = 'IS_SINGLE_GAME',
  IncrementDepositCount = 'INCREMENT_DEPOSIT_COUNT',
  SetLifetimeDeposits = 'SET_LIFETIME_DEPOSITS',
}

export type GetDepositCountAction = Action<DepositActionTypes.Get> & {
  token?: string;
};
export type GetDepositCountSuccessAction = Action<DepositActionTypes.GetSuccess> & {
  depositCount: number;
};
export type GetDepositCountErrorAction = Action<DepositActionTypes.GetError>;
export type SetToggleDepositAddedAction = Action<DepositActionTypes.ToggleDepositAdded> & {
  depositAdded: boolean;
};
export type IsSingleGameAction = Action<DepositActionTypes.IsSingleGame> & {
  isSingleGame: boolean;
};
export type IncrementDepositCount = Action<DepositActionTypes.IncrementDepositCount> & {
  isSingleGame: boolean;
};
export type SetLifetimeDepositsAction = Action<DepositActionTypes.SetLifetimeDeposits> & {
  deposits: LifetimeDeposits;
};

export type DepositActions =
  | GetDepositCountAction
  | GetDepositCountSuccessAction
  | GetDepositCountErrorAction
  | SetToggleDepositAddedAction
  | IncrementDepositCount
  | IsSingleGameAction
  | SetLifetimeDepositsAction;

export const getDepositCount = (token?: string): GetDepositCountAction => ({
  type: DepositActionTypes.Get,
  token,
});

export const getDepositCountSuccess = (depositCount: number): GetDepositCountSuccessAction => ({
  type: DepositActionTypes.GetSuccess,
  depositCount,
});

export const getDepositCountError = (): GetDepositCountErrorAction => ({
  type: DepositActionTypes.GetError,
});

export const setToggleDepositAdded = (depositAdded: boolean): SetToggleDepositAddedAction => ({
  type: DepositActionTypes.ToggleDepositAdded,
  depositAdded,
});

export const setIsSingleGame = (isSingleGame: boolean): IsSingleGameAction => ({
  type: DepositActionTypes.IsSingleGame,
  isSingleGame,
});

export const incrementDepositCount = () => ({
  type: DepositActionTypes.IncrementDepositCount,
});

export const setLifetimeDeposits = (deposits: LifetimeDeposits): SetLifetimeDepositsAction => ({
  type: DepositActionTypes.SetLifetimeDeposits,
  deposits,
});
