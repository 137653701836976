import React, { useEffect } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import ParsedString from '@/components/formats/ParsedString';
import FadeIn from '@/components/transition/FadeIn';
import { useCashierShow } from '@/hooks/useCashier';
import useClaimBonus from '@/hooks/useClaimBonus';
import { getActiveBonuses } from '@/store/actions/activeBonusesActions';
import { getAvailableBonuses } from '@/store/actions/availableBonusesActions';
import { getDepositCount } from '@/store/actions/depositActions';
import {
  closeModal,
  openConfirmModal,
  openTermsModal,
  updateConfirmModalError,
  updateConfirmModalLoading,
} from '@/store/actions/modalActions';
import { getWallet } from '@/store/actions/walletActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { doNothing, getGigErrorMessage } from '@/utils/helpers';

const StyledBonusCard = styled.div`
  max-width: 424px;
  min-height: 400px;
  width: 100%;
  position: relative;
  display: flex;

  .suite {
    display: flex;
    flex-flow: column;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: ${props => props.theme.black};
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    padding: 8px;
    width: 32px;

    &.top {
      top: 0;
      left: 0;
      border-radius: 8px 0 8px 0;
    }

    &.bottom {
      bottom: 0;
      right: 0;
      border-radius: 8px 0 8px 0;
      transform: rotate(180deg);
    }

    svg {
      width: 16px;
      height: auto;
      margin-top: 6px;
    }
  }

  .wrapper {
    background: ${props => props.theme.white};
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    box-shadow: ${props => props.theme.boxShadow};

    .image-wrapper {
      position: relative;
      background: ${props => props.theme.black};
      overflow: hidden;
      display: block;

      &:after {
        content: '';
        display: block;
        padding-bottom: 35.61320754716981%;
      }

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }

    .text {
      padding: 2rem 2rem 2rem;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      flex: 1;

      .header {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
        line-height: 1.3;
      }

      .disclaimer {
        font-size: 0.9rem;
      }

      .description {
        line-height: 1.5;

        a {
          font-weight: 700;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .button-wrapper {
        text-align: center;

        .button {
          margin-top: 2rem;
          width: 100%;
        }

        .terms-button {
          margin-top: 1.5rem;
          font-size: 0.9rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

export interface BonusCardProps {
  header: string;
  description: string;
  availableUntil?: string;
  redeemTypeId: number;
  buttonLink?: string;
  buttonText?: string;
  bonusCode?: string;
  terms: string;
  image: string;
  type: string;
  userBonusId?: number;
}

function BonusCard({
  userBonusId = 0,
  header,
  description,
  availableUntil = '',
  redeemTypeId,
  buttonLink = '',
  buttonText = '',
  bonusCode = '',
  terms = '',
  image,
  type,
}: BonusCardProps): JSX.Element {
  const dispatch = useDispatch();
  const showCashier = useCashierShow();
  const authToken = useSelector(selectAuthToken);
  const {
    loading: claimLoading,
    error: claimError,
    success: claimSuccess,
    claimBonus,
  } = useClaimBonus();
  const date = new Date(availableUntil);

  if (type === 'regular') {
    date.setHours(date.getHours() + 2);
  }

  const formattedAvailableUntil = `${date.toLocaleDateString('ro-RO')} ${date.toLocaleTimeString(
    'ro-RO',
    { hour: '2-digit', minute: '2-digit' },
  )}`;

  const refreshState = () => {
    dispatch(getWallet());
    dispatch(getAvailableBonuses());
    dispatch(getActiveBonuses());
    dispatch(getDepositCount());
  };

  // This gets triggered when the bonus is successfuly claimed
  useEffect(() => {
    if (claimSuccess) {
      refreshState();
      toast(
        <p>
          <span role="img" aria-label="Cookie">
            🎉
          </span>{' '}
          Bonus revendicat cu succes!
        </p>,
        {
          toastId: 'bonusSuccessToast',
          position: 'bottom-center',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        },
      );
    }
  }, [claimSuccess]);

  // This gets triggered when the claim fails
  useEffect(() => {
    if (claimError) {
      toast(<p>Bonusul nu a putut fi revendicat.</p>, {
        toastId: 'bonusErrorToast',
        position: 'bottom-center',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }, [claimError]);

  function forfeitHandler(userBonusId: number): void {
    dispatch(updateConfirmModalLoading(true));
    axios
      .post('/api/bonuses/delete', { token: authToken, userBonusId })
      .then(response => {
        if (response.data.success) {
          refreshState();
          dispatch(closeModal());
        } else {
          dispatch(updateConfirmModalError(getGigErrorMessage(response.data.Errors)));
        }
      })
      .catch(doNothing)
      .finally(() => {
        dispatch(updateConfirmModalLoading(false));
      });
  }

  function openModalHandler(header: string, userBonusId: number): void {
    dispatch(
      openConfirmModal({
        header: 'Sunteți sigură? ',
        text: `Veți pierde bonusul „${header}“?`,
        buttonText: 'Anuleaza Bonus',
        onSubmit: () => forfeitHandler(userBonusId),
        loading: false,
        error: '',
      }),
    );
  }

  return (
    <StyledBonusCard className="bonus-card">
      <div className="wrapper">
        {image && (
          <div className="image-wrapper">
            <FadeIn offset={400}>
              {onload => (
                <img
                  src={`https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=424,format=auto,dpr=2${image}.jpg`}
                  alt={header}
                  onLoad={onload}
                />
              )}
            </FadeIn>
          </div>
        )}
        <div className="text">
          <div>
            <h3 className="header">{header}</h3>
            <div className="description">
              <ParsedString string={description} />
            </div>
            {availableUntil && (
              <p className="disclaimer">Valabil până: {formattedAvailableUntil}</p>
            )}
          </div>
          <div className="button-wrapper">
            {/* For deposit bonuses */}
            {(redeemTypeId === 5 || redeemTypeId === 1) && (
              <Button onClick={() => showCashier()} className="button">
                Efectuează o depunere
              </Button>
            )}
            {/* For opt in bonuses */}
            {redeemTypeId === 2 && (
              <Button className="button" onClick={() => claimBonus(bonusCode)}>
                {claimLoading ? 'Se încarcă...' : 'Revendică Bonusul'}
              </Button>
            )}
            {/* For manual bonuses */}
            {redeemTypeId === 1337 && (
              <Link to={buttonLink}>
                <Button className="button">{buttonText}</Button>
              </Link>
            )}
            {/* For game launch bonuses */}
            {redeemTypeId === 4 && (
              <Link to={buttonLink}>
                <Button className="button">Joacă acum</Button>
              </Link>
            )}
            {/* For active bonuses able to be forfeited */}
            {redeemTypeId === 1336 && (
              <Button className="button" onClick={() => openModalHandler(header, userBonusId)}>
                Anuleaza Bonus
              </Button>
            )}
            {terms && (
              <button
                type="button"
                className="terms-button"
                onClick={() => dispatch(openTermsModal({ terms }))}
              >
                Termenii și condițiile bonusului
              </button>
            )}
          </div>
        </div>
      </div>
    </StyledBonusCard>
  );
}

export default BonusCard;
