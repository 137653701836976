import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { BffAddressLookupResponse, BffAddressRetrieveResponse } from '@lucky7ventures/bff-types';
import classNames from 'classnames';
import * as Yup from 'yup';

import Button from '@/components/button/Button';
import Form from '@/components/form/Form';
import SearchDropdownField from '@/components/form/search-dropdown/SearchDropdownField';
import TextField from '@/components/form/text/TextField';
import Tooltip from '@/components/tooltip/Tooltip';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import useDebounce from '@/hooks/useDebounce';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import BffApiService from '@/shared/bff-api-service';
import {
  AddressRegistrationInfo,
  useAddressInfoData,
  useRegistrationActions,
} from '@/store/registrationStore';
import { isEmpty } from '@/utils/helpers';
import { mapAddressLookupData, otherLabel } from '@/utils/registration-utils';

interface MappedAddressLookupData {
  label: string;
  value: string;
  isFinal: boolean;
  isParent?: boolean;
  id: string;
}

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Necesar')
    .max(25, 'Vă rugăm să utilizați doar până la 25 de caractere')
    .matches(/^[^\d\s]+(?:\s+[^\d\s]+)+$/, {
      message: 'Vă rugăm să ne furnizați numele dumneavoastră complet',
      excludeEmptyString: true,
    })
    .trim()
    .test(
      'has-two-words',
      'Numele complet trebuie să includă cel puțin numele și prenumele (două cuvinte)',
      value => {
        if (!value) return false;
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount >= 2; // Ensure at least two words are present
      },
    ),
  postalCode: Yup.string()
    .required('Necesar')
    .min(6, 'Trebuie să aibă cel puțin 6 caractere')
    .matches(/^\d{6}$/, 'Cod poștal nevalid')
    .trim(),
  address: Yup.string().required('Necesar').trim(),
  houseNumber: Yup.string().required('Necesar').trim(),
  city: Yup.string()
    .matches(/^\D+$/, 'Oraș invalid furnizat')
    .min(1)
    .max(28, 'Oraș invalid furnizat')
    .required('Necesar')
    .trim(),
});

const AddressInfoForm = ({
  stepBack,
  stepForward,
}: {
  stepBack: () => void;
  stepForward: () => void;
}) => {
  const { fullName, address, houseNumber, postalCode, city } = useAddressInfoData();
  const { updateField, updateFields } = useRegistrationActions();

  const initialValues: AddressRegistrationInfo = {
    fullName,
    address,
    houseNumber,
    postalCode,
    city,
  };

  const [activeOptions, setActiveOptions] = useState<MappedAddressLookupData[] | [] | undefined>(
    [],
  );
  const [backupOptions, setBackupOptions] = useState<MappedAddressLookupData[] | [] | undefined>(
    [],
  );
  const [showDropdown, setShowDropdown] = useState(true);
  const [focusDropdown, setFocusDropdown] = useState(false);
  const [searchedAddressValue, setSearchedAddressValue] = useState<string | undefined>(undefined);
  const debouncedSearchValue = useDebounce(searchedAddressValue, 300);
  const [activeSearchValue, setActiveSearchValue] = useState<string>('');

  const { request: addressLookupRequest, loading: addressLookupLoading } =
    useBffApiRequest<BffAddressLookupResponse>();
  const { request: addressRetrievalRequest, loading: addressRetrievalLoading } =
    useBffApiRequest<BffAddressRetrieveResponse>();

  const visibleOptions = useMemo(() => {
    if (!isEmpty(activeOptions)) {
      return activeOptions?.map(({ isFinal, id, isParent, ...option }) => {
        if (isFinal) {
          const finalOnClick = () => {
            addressRetrievalRequest({
              apiMethod: BffApiService.addressRetrieval,
              payload: {
                id: id,
              },
              successCallback: ({ street1, houseNumber, city, postalCode }) => {
                setShowDropdown(false);
                if (option.label !== otherLabel) {
                  updateFields({ address: street1, houseNumber, city, postalCode });
                } else {
                  updateFields({ address: street1 });
                }
              },
            });
          };

          return { ...option, finalOnClick };
        } else if (isParent) {
          const endIcon = <ChevronLeftIcon width={10} height={10} className="text-brand-gray" />;
          const nestedOnClick = () => {
            setActiveOptions(backupOptions);
          };
          return { ...option, nestedOnClick, endIcon };
        } else {
          const endIcon = <ChevronRightIcon width={10} height={10} className="text-brand-gray" />;
          const nestedOnClick = () =>
            addressLookupRequest({
              apiMethod: BffApiService.addressLookup,
              payload: {
                search: activeSearchValue,
                country: 'ro',
                language: 'ro',
                container: id,
              },
              successCallback: data => {
                const underlyingData = mapAddressLookupData(data);
                underlyingData.unshift({ ...option, isFinal, id, isParent: true });
                setActiveOptions(underlyingData);
              },
            });
          return { ...option, nestedOnClick, endIcon };
        }
      });
    } else {
      return [];
    }
  }, [activeOptions, backupOptions, activeSearchValue]);

  useEffect(() => {
    setFocusDropdown(false);
    if (activeSearchValue !== debouncedSearchValue && !isEmpty(debouncedSearchValue)) {
      addressLookupRequest({
        apiMethod: BffApiService.addressLookup,
        payload: {
          search: debouncedSearchValue,
          country: 'ro',
          language: 'ro',
        },
        successCallback: data => {
          const addressLookupData = mapAddressLookupData(data);
          setActiveOptions(addressLookupData);
          setBackupOptions(addressLookupData);
          setActiveSearchValue(debouncedSearchValue);
        },
      });
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (focusDropdown) {
      const elements = document.getElementsByClassName('focusable-element');
      (elements[0] as HTMLElement).focus();
    }
  }, [focusDropdown]);

  const handleOnSubmit = useCallback(
    (values: AddressRegistrationInfo) => {
      triggerGTag(GTagEvents.reg_step2_continue_cta_click);
      updateFields({
        ...values,
      });
      stepForward();
    },
    [stepForward],
  );

  return (
    <div className="w-full h-full flex flex-col mt-7">
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        className="flex flex-col justify-between gap-8"
      >
        {() => (
          <>
            <div className="flex flex-col gap-6">
              <TextField
                data-cy="registration-address-info-fullName"
                label="Nume complet"
                labelClassName="text-brand-gray"
                name="fullName"
                placeholder="Roman Romanescu"
                onBlur={({ target: { value } }) => updateField('fullName', value)}
                onValidationError={error => triggerGTag(GTagEvents.reg_full_name_error, { error })}
                onFocus={() => triggerGTag(GTagEvents.reg_full_name_click)}
                className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-brand-gray
                  [&>input]:capitalize [&>input]:placeholder:normal-case"
              />

              {visibleOptions !== undefined && showDropdown && (
                <SearchDropdownField
                  loading={addressLookupLoading || addressRetrievalLoading}
                  placeholder="Introduceți strada și selectați adresa"
                  options={visibleOptions}
                  data-cy="registration-address-info-address-dropdown"
                  label="Stradă"
                  labelClassName="text-brand-gray"
                  name="address"
                  onChange={e => {
                    setSearchedAddressValue(e);
                  }}
                  onFocus={() => triggerGTag(GTagEvents.reg_street_click)}
                  inputClassName="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-brand-gray font-light"
                  tooltip={
                    <Tooltip
                      position="left"
                      text="Introdu adresa și folosește funcția de căutare a adreselor pentru a completa automat datele. Dacă adresa nu poate fi găsită, o poți intruduce manual."
                      className="max-w-[240px] sm:max-w-[400px]"
                    >
                      <InformationCircleIcon className="w-5 h-5 text-brand-gray" />
                    </Tooltip>
                  }
                />
              )}
              <div className={classNames(showDropdown ? 'hidden' : 'block')}>
                <TextField
                  label="Stradă"
                  labelClassName="text-brand-gray"
                  data-cy="registration-address-info-address-textfield"
                  name="address"
                  onChange={e => {
                    setShowDropdown(true);
                    setSearchedAddressValue(e.target.value);
                    setFocusDropdown(true);
                    updateFields({ postalCode: '', houseNumber: '', city: '' });
                  }}
                  prefillValue={address}
                  onBlur={({ target: { value } }) => updateField('address', value)}
                  onValidationError={error => triggerGTag(GTagEvents.reg_street_error, { error })}
                  onFocus={() => triggerGTag(GTagEvents.reg_street_click)}
                  className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-brand-gray"
                  endIcon={
                    <Tooltip
                      position="left"
                      text="Nu ți-am găsit adresa în baza de date. Te rugăm să introduci manual adresa."
                      className="max-w-[240px] sm:max-w-[400px]"
                    >
                      <InformationCircleIcon className="w-5 h-5 text-brand-gray" />
                    </Tooltip>
                  }
                />
              </div>
              <div className="grid grid-cols-2 gap-x-[16px] sm:gap-x-[24px]">
                <TextField
                  label="Cod poștal"
                  labelClassName="text-brand-gray"
                  data-cy="registration-address-info-postalCode"
                  name="postalCode"
                  maxLength={6}
                  prefillValue={postalCode}
                  inputMode="numeric"
                  onBlur={({ target: { value } }) => updateField('postalCode', value)}
                  onValidationError={error => triggerGTag(GTagEvents.reg_zip_code_error, { error })}
                  onFocus={() => triggerGTag(GTagEvents.reg_zip_code_click)}
                  className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-brand-gray"
                />
                <TextField
                  label="Numărul casei"
                  labelClassName="text-brand-gray"
                  data-cy="registration-address-info-houseNumber"
                  name="houseNumber"
                  prefillValue={houseNumber}
                  inputMode="numeric"
                  onBlur={({ target: { value } }) => updateField('houseNumber', value)}
                  onValidationError={error => triggerGTag(GTagEvents.reg_house_error, { error })}
                  onFocus={() => triggerGTag(GTagEvents.reg_house_click)}
                  className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-brand-gray"
                />
              </div>
              <TextField
                data-cy="registration-address-info-city"
                label="Oraș"
                labelClassName="text-brand-gray"
                name="city"
                prefillValue={city}
                onBlur={({ target: { value } }) => updateField('city', value)}
                onValidationError={error => triggerGTag(GTagEvents.reg_town_error, { error })}
                onFocus={() => triggerGTag(GTagEvents.reg_town_click)}
                className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-brand-gray"
              />
            </div>
            <div className="w-2/3 mx-auto grid grid-cols-2 gap-x-[16px] sm:gap-x-[24px] mt-4 sm:mt-6 mb-3">
              <Button
                type="button"
                color="ghostBlack"
                onClick={() => {
                  triggerGTag(GTagEvents.reg_step2_back_cta_click);
                  stepBack();
                }}
              >
                Spate
              </Button>
              <Button data-cy="registration-address-info-submit" type="submit">
                Continua
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default AddressInfoForm;
