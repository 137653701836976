import React, { useState } from 'react';

import { Link, NavLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { GAME_PROVIDERS } from '@/constants/constants';
import { gameProviderImg } from '@/utils/image-utils';

export const NO_HOVER_INVERT_LOGOS = ['gameburger-studios, blue-guru-games', 'snowborn'];

function ProviderCategoryNav(): JSX.Element {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { width } = useWindowSize();

  const getEndIndex = () => {
    const COLLAPSED_COUNT = 10;

    if (width < 640) {
      return COLLAPSED_COUNT;
    }

    return isCollapsed ? COLLAPSED_COUNT : GAME_PROVIDERS.length;
  };

  const handleExpand = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="my-16 mx-auto max-w-[1400px] py-0 px-8 sm:py-0 sm:px-8">
      <div className="mb-4 flex flex-col items-start justify-center gap-2 sm:mb-8 sm:items-center sm:justify-center sm:gap-4 sm:text-center">
        <h1 className="m-0 text-2xl font-bold sm:text-[2rem]">Furnizorii de Jocuri</h1>
      </div>
      <div className="relative my-0 -mx-4 overflow-auto sm:m-0">
        <ul className="after:content-[' '] grid list-none grid-flow-col grid-cols-[repeat(auto-fit,minmax(min-content,1fr))] gap-1.5 overflow-y-auto pl-4 scrollbar-hide after:p-4 sm:my-0 sm:mx-auto sm:max-w-[674px] sm:grid-flow-row sm:grid-cols-[repeat(5,1fr)] sm:grid-rows-[repeat(2,100px)] sm:p-0">
          {GAME_PROVIDERS.slice(0, getEndIndex()).map(provider => (
            <li
              className={`group relative min-w-[130px] overflow-hidden bg-zinc-100 text-center first:rounded-l-[8px] hover:bg-red-gradient sm:first:rounded-bl-none sm:last-of-type:rounded-r-[8px] sm:last-of-type:rounded-tr-none sm:[&:nth-of-type(5)]:rounded-tr-[8px]  ${
                isCollapsed ? 'sm:[&:nth-of-type(6)]:rounded-bl-[8px]' : ''
              }`}
              key={provider}
            >
              <NavLink end to={`/jocuri/${provider}`} id={provider}>
                <span className="flex h-[100px] w-full items-center justify-center transition-all duration-200 ease-in-out">
                  <img
                    src={`${gameProviderImg(provider)}`}
                    alt={provider}
                    className={`max-h-[60px] max-w-[100px] grayscale ${
                      NO_HOVER_INVERT_LOGOS.some(providerId => providerId.includes(provider))
                        ? ''
                        : 'group-hover:brightness-0 group-hover:invert'
                    }`}
                  />
                </span>
              </NavLink>
            </li>
          ))}
          <Link
            to="/furnizorii"
            className="flex h-[100px] w-[130px] items-center justify-center rounded-tr-[8px] rounded-br-[8px] border-2 border-zinc-100 sm:hidden"
          >
            <span className="text-center text-sm font-bold">Vezi toți furnizorii</span>
          </Link>
        </ul>
      </div>
      <div className="text-center">
        <span
          className="hidden cursor-pointer text-[0.8rem] font-[600] leading-[1.3] hover:underline sm:block"
          onClick={handleExpand}
        >
          {isCollapsed ? 'Vezi toți furnizorii' : 'Restrângeți toți furnizorii'}
        </span>
      </div>
    </div>
  );
}

export default ProviderCategoryNav;
