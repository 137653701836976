import React, { ReactNode, useEffect } from 'react';

import { Switch } from '@headlessui/react';
import { CheckIcon, LockClosedIcon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useField } from 'formik';

import FieldError from '../field/FieldError';

interface SwitchFieldProps {
  name: string;
  label: string | ReactNode;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  onValidationError?: (error: string) => void;
  'data-cy'?: string;
}

const SwitchField = ({
  name,
  label,
  onChange,
  disabled = false,
  className,
  labelClassName,
  onValidationError,
  ...props
}: SwitchFieldProps): JSX.Element => {
  const [field, { error, touched }, helpers] = useField(name);
  const isValidationError = touched && error;

  const getIcon = () => {
    if (field.value) {
      if (disabled) {
        return <LockClosedIcon className="h-3 w-3 text-brand-gold" />;
      }
      return <CheckIcon className="h-3 w-3 text-brand-gold" />;
    }
    return <XMarkIcon className="h-3 w-3 text-gray-300" />;
  };

  const handleOnChange = (checked: boolean) => {
    if (!disabled) {
      helpers.setValue(checked);
      onChange?.(checked);
    }
  };

  useEffect(() => {
    if (isValidationError) {
      onValidationError?.(error!);
    }
  }, [isValidationError]);

  return (
    <div className={classNames('relative', className)}>
      <Switch.Group>
        <div className="grid gap-6 grid-cols-[fit-content(100%)_fit-content(100%)] items-center justify-between relative overflow-hidden">
          <Switch.Label
            onClickCapture={(e: any) => e.stopPropagation()}
            className={classNames('text-sm sm:text-base', labelClassName)}
          >
            {label}
          </Switch.Label>
          <Switch
            onChange={handleOnChange}
            onClickCapture={() => helpers.setTouched(true)}
            checked={field.value}
            className={classNames(
              'relative inline-flex h-[28px] w-[56px] items-center rounded-full bg-gray-300 opacity-100 flex-1',
              { '!bg-brand-gold': field.value },
              { 'cursor-not-allowed': disabled },
            )}
            data-cy={props['data-cy']}
          >
            <span
              className={classNames(
                'flex h-[20px] w-[20px] transform items-center justify-center rounded-full bg-white transition translate-x-[4px]',
                { '!translate-x-[32px]': field.value },
              )}
            >
              {getIcon()}
            </span>
          </Switch>
        </div>
      </Switch.Group>
      <FieldError error={error} show={Boolean(error && touched)} />
    </div>
  );
};

export default SwitchField;
