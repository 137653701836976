import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import { selectModalConfirmProps } from '@/store/selectors/modalSelectors';

const StyledConfirmModal = styled.div`
  text-align: center;
  background: ${props => props.theme.white};
  padding: 4rem 2rem 3rem;
  max-width: 400px;
  border-radius: 0.5rem;

  h1 {
    font-size: 1.7rem;
    margin-bottom: 1.5rem;
  }

  .error {
    color: ${props => props.theme.red};
    padding: 1rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  .button-wrapper {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;

    button {
      margin-top: 1rem;
    }
  }
`;

function Confirm({ closeModal }: { closeModal: () => void }): JSX.Element | null {
  const confirmModalProps = useSelector(selectModalConfirmProps);

  if (!confirmModalProps) {
    return null;
  }

  const { header, text, error, onSubmit, loading, buttonText } = confirmModalProps;

  return (
    <StyledConfirmModal>
      <h1>{header}</h1>
      <p>{text}</p>
      {error && <p className="error">{error}</p>}
      <div className="button-wrapper">
        <Button onClick={onSubmit}>{loading ? 'Se încarcă...' : buttonText}</Button>
        <Button color="ghostBlack" onClick={closeModal}>
          Anulează
        </Button>
      </div>
    </StyledConfirmModal>
  );
}

export default Confirm;
