import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/button/Button';
import CloseButton from '@/components/button/CloseButton';
import LogoLandscape from '@/components/icons/LogoLandscape';
import { useCashierHide, useDepositTabEffect } from '@/hooks/useCashier';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { setFtdExitIntentShown } from '@/store/actions/modalActions';

const ExitIntent = ({ closeModal }: { closeModal: () => void }) => {
  const navigate = useNavigate();
  const { toggleDepositTabEffectTrigger } = useDepositTabEffect();
  const hideCashier = useCashierHide();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFtdExitIntentShown());
  }, []);

  return (
    <div
      className="transition-all ease-in-out duration-1000 w-[350px] rounded-lg bg-slate-50"
      data-cy="exit-intent"
    >
      <CloseButton
        position="right"
        color="black"
        onClick={() => {
          closeModal();
          hideCashier();
          triggerGTag(GTagEvents.ftd_exit_intent_closed);
        }}
      />
      <div className="flex flex-col items-center justify-center relative p-8 space-y-[32px]">
        <LogoLandscape className="w-full h-[60px] mt-0" />
        <div className="text-2xl font-bold">Inainte sa pleci!</div>
        <div className="text-lg font-extralight text-center">
          <span>Ține minte </span>
          <span className="text-brand-red font-bold">bonusul tău de bun venit de 2.400 de lei</span>
          <span> te așteaptă!</span>
        </div>
        <div className="flex flex-col items-center space-y-[16px] w-[200px]">
          <Button
            className="w-full"
            onClick={() => {
              toggleDepositTabEffectTrigger();
              closeModal();
              triggerGTag(GTagEvents.ftd_exit_intent_deposit);
            }}
          >
            Depune acum
          </Button>
          <Button
            color="white"
            className="w-full"
            onClick={() => {
              closeModal();
              hideCashier();
              triggerGTag(GTagEvents.ftd_exit_intent_closed);
              navigate('/jocuri');
            }}
          >
            Vezi toate jocurile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExitIntent;
