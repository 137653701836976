import { Action } from 'redux';

export enum CookiesActionType {
  SetBtag = 'SET_BTAG',
  RemoveBtag = 'REMOVE_BTAG',
  SetClickId = 'SET_CLICK_ID',
}

export type SetBtagAction = Action<CookiesActionType.SetBtag> & {
  btag: string;
};

export type RemoveBtagAction = Action<CookiesActionType.RemoveBtag>;

export type SetClickIdAction = Action<CookiesActionType.SetClickId> & {
  clickId: string;
};

export type CookiesActions = SetBtagAction | SetClickIdAction | RemoveBtagAction;

export const setBtag = (btag: string): SetBtagAction => ({
  type: CookiesActionType.SetBtag,
  btag,
});

export const removeBtag = (): RemoveBtagAction => ({
  type: CookiesActionType.RemoveBtag,
});

export const setClickId = (clickId: string): SetClickIdAction => ({
  type: CookiesActionType.SetClickId,
  clickId,
});
