import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import PageHeader from '@/components/header/PageHeader';
import openZendesk from '@/hooks/openZendesk';
import Faq from '@/pages/help/Faq';
import { loadZendesk } from '@/store/actions/commonActions';
import { selectUser } from '@/store/selectors/userSelectors';

const StyledHelp = styled.div`
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem 4rem;
  }
`;

function Help(): JSX.Element {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadZendesk());
  }, []);

  return (
    <StyledHelp>
      <PageHeader>
        <h1>Ai nevoie de ajutor?</h1>
        <p>Nicio problemă! Consultă secțiunea Întrebări și Răspunsuri sau contactează-ne.</p>
        <Button
          color="ghost"
          size="small"
          onClick={() => openZendesk(user?.FirstName, user?.LastName, user?.Email)}
        >
          Contactează-ne
        </Button>
      </PageHeader>
      <div className="container">
        <Faq />
      </div>
    </StyledHelp>
  );
}

export default Help;
