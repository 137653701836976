import * as React from 'react';

const LigaPrizeWheelIcon = ({ className }: { className: string }): JSX.Element => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 169.3 219.6" className={className}>
      <path
        d="M108.5,178.9c35.1-10.3,60.8-42.7,60.8-81.2c0-40.1-27.9-73.6-65.2-82.4c0.8-1.9,1.7-3.9,2.5-5.9
	c1.1-2.6,0.5-4.1-2-5.2c-1-0.5-2.1-1-3.2-1.2C97.1,2,92.7,1,88.4,0c-3.2,0-6.3,0-9.5,0c-0.4,0.1-0.8,0.3-1.2,0.4
	c-5.5,0.6-10.7,2-15.7,4.3c-3.6,1.6-4,2.8-2.3,6.4c0.8,1.7,1.6,3.4,2.4,5c-0.2,0.1-0.4,0.1-0.6,0.2l0.1,0C26.1,26.2,0,58.9,0,97.7
	c0,34.7,20.8,64.4,50.7,77.5l-1.2-0.1c3.3,1.5,6.8,2.7,10.3,3.8c0.7,0.2,1.7,0.9,1.7,1.4c0.1,4.1,0.1,8.3,0.1,12.6c-1.2,0-2.1,0-3,0
	c-12.8,0.1-22.2,9.5-22.4,22.3c0,2,0.5,3.5,2.3,4.4c30.7,0,61.4,0,92.1,0c0.8-1.1,2.1-2.2,2.2-3.4c0.8-10.1-5.9-19.7-15.8-22.4
	c-3-0.8-6.2-0.9-9.6-1.3c0-3.7,0.1-7.5,0-11.4C107.4,180,107.7,179.3,108.5,178.9z M120.9,166.8c-8.5-17.7-16.9-35.2-25.3-52.7
	c2.3-1.4,4.2-3.2,5.8-5.4c17.5,7.7,35.2,15.5,53.1,23.4C146.8,147.2,135.7,158.7,120.9,166.8z M84.9,111.6
	c-8.2,0-14.9-6.7-14.9-14.9c0-8.2,6.7-14.9,14.9-14.9s14.9,6.7,14.9,14.9C99.8,104.9,93.1,111.6,84.9,111.6z M156.9,126
	c-17.6-7.7-35.2-15.5-52.5-23.1c0-0.1,0-0.2,0-0.2c0.6-1.9,0.9-3.9,0.9-6c0-1.9-0.3-3.6-0.7-5.4c4.4-2.1,9.1-4.4,13.7-6.6l0.8-0.4
	c0.2-0.1,0.5-0.2,0.7-0.3l16.4-7.9l0,0c2.2-1,4.4-2.1,6.6-3.1c4.4-2.1,8.8-4.2,13.3-6.3C164.7,85.7,164.3,109.4,156.9,126z
	 M153.3,60.7c-0.7,0.4-1.4,0.8-2,1.1c-6.6,3.2-13.2,6.3-19.8,9.5c-0.3,0.1-0.6,0.3-0.9,0.4l-15.9,7.6l0,0c-2.6,1.2-5.1,2.4-7.6,3.6
	c-1.8,0.8-3.6,1.7-5.3,2.5c-1.6-2.4-3.8-4.5-6.2-6c7.6-17.3,15.3-34.7,23-52.3C133.8,34.7,145.2,45.8,153.3,60.7z M99.3,9.2
	l-3.7,8.2l0,0c-4,9.1-8,18.2-12.1,27.5c-4.4-9.2-8.7-18.1-12.9-27l0,0l-4-8.1C77.5,5.6,88.3,5.4,99.3,9.2z M56.6,24.2
	c2.6-0.9,5.2-1.7,7.8-2.4c0.4-0.1,1.3,0.8,1.7,1.4c4.7,9.6,9.2,19.2,13.9,28.8c0.2,0.4,0.4,0.8,0.5,1.2c0.6,1.5,1.6,2.6,3.3,2.6
	c1.8,0,2.6-1.2,3.3-2.6c3.5-7.9,7-15.9,10.5-23.8c1.2-2.7,2.4-5.4,3.7-8.4c3.8,1.1,7.5,2.3,11.5,3.5C104.9,42.3,97.2,59.9,89.6,77
	c-1.5-0.4-3.1-0.6-4.7-0.6c-2.3,0-4.6,0.4-6.7,1.1c-0.2-0.2-0.3-0.4-0.4-0.5c-4.5-9.3-8.9-18.6-13.4-27.9
	c-3.7-7.7-7.3-15.4-11.3-23.5C54.3,25.1,55.5,24.7,56.6,24.2z M17.3,57.1c1.3-2,2.7-4,4-6c5.3-7.2,11.7-13.4,19.1-18.4
	c0.3-0.2,0.6-0.4,0.9-0.6c2-1.3,4-2.5,6.1-3.7c1.4,3,2.7,5.6,4,8.2c7,14.5,13.9,29.1,20.8,43.6c0.1,0.1,0.1,0.3,0.2,0.5
	c-2,1.6-3.8,3.6-5.1,5.9C49.6,78.8,31.9,71,14,63.1C15,61.1,16.1,59.1,17.3,57.1z M6,97.7C6,87.6,7.9,78,11.4,69.1
	c7.8,3.5,15.5,6.8,23.1,10.2c10,4.4,19.9,8.8,29.9,13.1c0.2,0.1,0.4,0.2,0.6,0.3c-0.3,1.3-0.4,2.7-0.4,4.1c0,2.1,0.3,4.2,0.9,6.1
	c-17.3,8.3-35.1,16.8-53.3,25.5C8.2,118.9,6,108.6,6,97.7z M49.6,167.9c-4.7-2.4-9.1-5.1-13.1-8.1c-0.1-0.1-0.3-0.2-0.4-0.3
	c-0.5-0.4-1.1-0.8-1.6-1.3c-8-6.6-14.6-14.8-19.4-24c18.1-8.6,35.9-17.2,53.4-25.6c1.4,1.9,3.1,3.6,5.1,4.9
	C65.6,131.5,57.6,149.6,49.6,167.9z M111.1,199.4c7.8,0,14.3,6,15.1,13.7c-27.8,0-55.5,0-83.4,0c0.4-3.6,1.8-6.5,4.2-8.9
	c3-3.1,6.7-4.7,10.9-4.7C75.7,199.4,93.4,199.3,111.1,199.4z M68.2,192.8c0-4,0-7.8,0-11.9c9.7,1.8,19.3,1.9,29,0.4
	c0.8-0.1,1.5-0.3,2.3-0.4c0.4-0.1,0.8-0.1,1.2-0.2c0,4.1,0,8,0,12C89.9,192.8,79.1,192.8,68.2,192.8z M55.5,170.5
	c1.1-2.6,2.2-4.9,3.2-7.2c6.7-15.2,13.4-30.4,20-45.6c0.3-0.6,0.5-1,0.8-1.3c1.7,0.5,3.5,0.7,5.3,0.7c1.5,0,3-0.2,4.5-0.5
	c0.3,0.3,0.6,0.6,0.8,1.2c7.4,15.6,14.9,31.1,22.3,46.7c0.8,1.6,1.5,3.2,2.4,5.1C95.2,177.7,75.5,178,55.5,170.5z"
      />
    </svg>
  );
};

export default LigaPrizeWheelIcon;
