import React, { ElementType } from 'react';

import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

const StepLabel = ({
  step,
  isActiveStep,
  isCompleted,
  icon: Icon,
}: {
  step: number;
  isActiveStep: boolean;
  isCompleted: boolean;
  icon: ElementType | null;
}) => {
  const getIcon = () => {
    return Icon ? <Icon className="w-5 h-5 text-white" /> : step;
  };

  return (
    <div className="flex items-center px-4 first-of-type:pl-0 last-of-type:pr-0">
      <span
        className={classNames(
          'flex items-center justify-center w-7 h-7 rounded-full text-white bg-gray-400 text-sm md:w-9 md:h-9',
          { '!bg-brand-red': isActiveStep || isCompleted },
          {
            'animate-scaleBounce': isActiveStep,
          },
        )}
      >
        {isCompleted ? (
          <CheckIcon className="bg-brand-red w-4 h-4 rounded-full text-white" />
        ) : (
          getIcon()
        )}
      </span>
    </div>
  );
};

export default StepLabel;
