import React from 'react';

import { use100vh } from 'react-div-100vh';
import LazyLoad from 'react-lazyload';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import { grandPrizeTerms } from '@/lib/grandPrizeTerms';
import { openTermsModal } from '@/store/actions/modalActions';
import { selectUser } from '@/store/selectors/userSelectors';

const StyledGrandPrize = styled.section<{ height: number | null }>`
  background-color: ${props => props.theme.red};
  height: calc(${props => props.height}px - 64px);
  width: 100%;
  position: relative;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    height: 100vh;
  }

  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    object-position: 0 0;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      object-position: 50% 50%;
    }
  }

  .container {
    display: grid;
    margin: 0 auto;
    height: 100%;
    padding: 1rem;
    justify-items: center;
    align-items: center;
    justify-content: center;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 2rem;
      max-width: 1400px;
    }

    .text {
      justify-content: center;
      color: white;
      text-align: center;
      position: relative;

      .magic-wheel {
        width: 100%;
        margin: 0 auto;
        max-width: 200px;
        height: auto;
        display: block;
      }

      button {
        text-decoration: none;
        margin-top: 2rem;
      }

      .terms-button {
        text-decoration: underline;
        margin-top: 1.5rem;
        font-size: 0.9rem;
        font-weight: bold;
      }

      h1 {
        color: inherit;
        font-size: 1.8rem;
        line-height: 1.2;
        margin-bottom: 1rem;
        margin-top: 1rem;

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          font-size: 2.5rem;
        }
      }

      p {
        font-size: 16px;
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
      }
    }
  }
`;

function GrandPrize(): JSX.Element {
  const user = useSelector(selectUser);
  const height = use100vh();
  const dispatch = useDispatch();

  const backgroundUrl =
    'https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=1440,format=auto,dpr=2/assets/hero-without-asset.png';

  return (
    <StyledGrandPrize height={height}>
      <img src={backgroundUrl} alt="Magic Jackpot Hero" className="background-image" />
      <div className="container">
        <div className="text">
          <LazyLoad height={210}>
            <img
              src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=200,format=auto,dpr=2/assets/grand-prize-wheel.png"
              className="magic-wheel"
              alt="Magic Wheel"
            />
          </LazyLoad>
          <h1>Felicitări{!user ? '...' : `, ${user.FirstName}`}</h1>
          <p>
            Simte magia, tocmai ai câștigat 50,000 RON MagicJackpot! Te rugăm să contactezi
            serviciul de Suport Clienți pentru a revendica premiul.
          </p>
          <Link to="/ajutor">
            <Button className="button">Suport Clienți</Button>
          </Link>
          <div className="wheel-promotion">
            <button
              type="button"
              className="terms-button"
              onClick={() =>
                dispatch(
                  openTermsModal({
                    terms: grandPrizeTerms,
                  }),
                )
              }
            >
              Termeni și Condiții
            </button>
          </div>
        </div>
      </div>
    </StyledGrandPrize>
  );
}

export default GrandPrize;
