import axios, { AxiosResponse } from 'axios';
import { all, AllEffect, call, ForkEffect, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { WP_URL } from '@/constants/constants';
import { ManualBonusModel } from '@/models/manual-bonus.model';
import { WpError } from '@/models/wp-error.model';
import {
  getManualBonusesError,
  getManualBonusesSuccess,
  ManualBonusesActionType,
} from '@/store/actions/manualBonusesActions';
import { isSuccessfulWpResponse } from '@/utils/helpers';

type ManualBonusesAxiosResponse = AxiosResponse<ManualBonusModel[] | WpError>;

export function* getManualBonusesSaga(): Generator<StrictEffect, void, ManualBonusesAxiosResponse> {
  try {
    const response = yield call(axios.get, `${WP_URL}/manual_bonuses`);

    if (isSuccessfulWpResponse<ManualBonusModel[]>(response)) {
      yield put(getManualBonusesSuccess(response.data));
    } else {
      yield put(getManualBonusesError());
    }
  } catch (error) {
    yield put(getManualBonusesError());
  }
}

export function* watchManualBonuses(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(ManualBonusesActionType.Get, getManualBonusesSaga)]);
}
