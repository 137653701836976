import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { useLigaStore } from '@/store/useLigaStore';

function useLigaReset() {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const { reset } = useLigaStore();

  useEffect(() => {
    if (!isAuthenticated) {
      reset();
    }
  }, [isAuthenticated]);
}

export default useLigaReset;
