import React, { useEffect, useState, Dispatch, SetStateAction, useCallback } from 'react';

import { BffAuthRequest } from '@lucky7ventures/bff-types';
import { AuthResponseDto } from '@lucky7ventures/lucky-components';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import * as Yup from 'yup';

import Anchor from '@/components/anchor/Anchor';
import Button from '@/components/button/Button';
import Input from '@/components/form/Input';
import ValidationMessage from '@/components/form/ValidationMessage';
import LogoLandscape from '@/components/icons/LogoLandscape';
import useApiV2Request from '@/hooks/useApiV2Request';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { ErrorCodes } from '@/models/enums/error-codes.enum';
import { LoginSectionEnum } from '@/models/enums/login-section.enum';
import { ApiV2Error } from '@/shared/api-error-handler';
import ApiService from '@/shared/api-service';
import { loginSuccess, resetAuth } from '@/store/actions/authActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { getLoginErrorMessage } from '@/utils/login-utils';

interface LoginFormProps {
  closeModal: () => void;
  setLoginSection: Dispatch<SetStateAction<LoginSectionEnum>>;
  loggedOut?: boolean;
  setCredentials: Dispatch<SetStateAction<BffAuthRequest>>;
}

interface FormValues {
  email: string;
  password: string;
}

function LoginForm({
  closeModal,
  setLoginSection,
  loggedOut = false,
  setCredentials,
}: LoginFormProps): JSX.Element {
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [error, setError] = useState<string>('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { loading, request } = useApiV2Request<AuthResponseDto>();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleRegistrationClick = useCallback(() => {
    closeModal();
    !pathname?.includes('inregistrare') && navigate('/inregistrare');
  }, []);

  const initialValues: FormValues = {
    email: '',
    password: '',
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().required('Te rugăm introdu emailul tău').email('Introdu un email valid'),
    password: Yup.string()
      .required('Te rugăm introdu parola ta')
      .min(6, 'Parola să conțină cel puțin 6 caractere'),
  });

  useEffect(() => {
    dispatch(resetAuth());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      closeModal();
    }
  }, [isAuthenticated]);

  async function submitHandler({ email, password }: FormValues) {
    const handleOnSuccess = ({ Token, Result }: AuthResponseDto) => {
      if (Result === 'TWO_FACTOR_FIRST_STEP_SUCCESSFUL') {
        setCredentials({ username: email, password });
        setLoginSection(LoginSectionEnum.Otp);
        triggerGTag(GTagEvents.login_2fa);
      } else {
        triggerGTag(GTagEvents.login_success);
        dispatch(loginSuccess(Token));
      }
    };

    const handleOnError = (error: ApiV2Error) => {
      const errorMessage = getLoginErrorMessage(error.error);

      triggerGTag(GTagEvents.login_error, { error: error.error });

      if (error.error === ErrorCodes.USER_NOT_FOUND) {
        setLoginAttempts(prevState => prevState + 1);
        if (loginAttempts > 1) {
          setLoginSection(LoginSectionEnum.FailedLogins);
        }
      }
      if (
        error.error === ErrorCodes.ACCOUNT_COOLDOWN ||
        error.error === ErrorCodes.USER_SELF_EXCLUDED ||
        error.error === ErrorCodes.ACCOUNT_BLOCKED ||
        error.error === ErrorCodes.TOO_MANY_REQUESTS
      ) {
        setIsSubmitDisabled(true);
      }

      setError(errorMessage);
    };

    request(ApiService.login, { username: email, password }, handleOnSuccess, handleOnError);
  }

  return (
    <div className="login">
      <LogoLandscape className="logo" />
      {loggedOut && (
        <p className="logged-out">
          Ați fost deconectat. Acest lucru poate fi cauzat de inactivitate sau dacă v-ați conectat
          de pe un alt dispozitiv.
        </p>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={submitHandler}
        validateOnMount
      >
        {({ isValid }) => (
          <Form>
            <Field name="email">
              {({ field }: FieldProps) => (
                <>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="email"
                    placeholder="Emailul tău"
                    label="Email"
                    autoComplete="email"
                    autoFocus
                  />
                  <ErrorMessage name="email">
                    {message => <ValidationMessage type="email" message={message} />}
                  </ErrorMessage>
                </>
              )}
            </Field>
            <Field name="password">
              {({ field }: FieldProps) => (
                <>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="password"
                    placeholder="Parola ta"
                    label="Parolă"
                    autoComplete="password"
                    setLoginSection={setLoginSection}
                  />
                  <ErrorMessage name="password">
                    {message => <ValidationMessage type="password" message={message} />}
                  </ErrorMessage>
                </>
              )}
            </Field>
            <div className="button-wrapper">
              {error && <p className="error">{error}</p>}
              <Button
                disabled={!isValid || loading || isSubmitDisabled}
                type="submit"
                className="submit"
              >
                {loading ? 'Se încarcă...' : 'Intră în cont'}
              </Button>
              <p className="create-account">
                Nu ai încă un cont?{' '}
                <Anchor onClick={handleRegistrationClick}>Creaza-ți unul!</Anchor>
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LoginForm;
