import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';

import CloseIcon from '@/components/icons/CloseIcon';

const CashierProgressBar = ({ percent }: { percent: number }): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="group relative">
      <button
        type="button"
        className="h-[32px] bg-white rounded-lg cursor-pointer overflow-hidden w-full"
        onClick={() => {
          if (isMobile) setShowTooltip(!showTooltip);
        }}
      >
        <div
          style={{ width: `${percent}%` }}
          className={`bg-mint h-full relative rounded-lg bg-clip-padding`}
        >
          <span
            className={`absolute font-bold text-sm top-1/2 -translate-y-1/2 py-[2px] px-2 ${
              percent <= 50 ? 'left-full' : 'left-auto'
            }
              ${percent > 50 ? 'right-0 text-white' : 'right-auto text-black'}`}
          >
            {percent}%
          </span>
        </div>
      </button>
      <div
        style={{
          transition: 'visibility 0s, opacity 200ms, transform 200ms',
          transitionDelay: showTooltip ? '0s, 200ms, 200ms' : '200ms, 0s, 0s',
        }}
        className={`bg-white text-[#36575d] rounded-lg absolute top-16 left-[calc(50%-120px)] z-10 w-[240px] p-4
        shadow-[0_0_10px_rgba(0,0,0,0.1)] text-[0.8rem] pointer-events-none opacity-0 invisible translate-y-4 font-normal
        before:absolute before:-top-2 before:right-[calc(50%-8px)] before:w-0 before:h-0 before:border-l-8
        before:border-l-transparent before:border-r-8 before:border-r-transparent before:z-[1] before:border-b-8
        before:border-b-white ${
          showTooltip ? 'translate-y-0 visible opacity-100 pointer-events-auto' : ''
        } ${
          !isMobile
            ? 'group-hover:translate-y-0 group-hover:visible group-hover:opacity-100 group-hover:!delay-[0s,200ms,200ms]'
            : ''
        }`}
      >
        {isMobile && (
          <button
            type="button"
            className="absolute top-0 right-0 text-black p-2"
            onClick={() => setShowTooltip(false)}
          >
            <CloseIcon />
          </button>
        )}
        <strong>Progresul câștigurilor (turnover).</strong> Bonusul se va transforma în fonduri
        reala care se vor putea retrage după îndeplinirea cerinței de câștig.
      </div>
    </div>
  );
};

export default CashierProgressBar;
