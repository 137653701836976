import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { openConsentModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectIsPPConsented, selectIsTCConsented } from '@/store/selectors/consentsSelectors';

function useConsents() {
  const dispatch = useDispatch();
  const isPPConsented = useSelector(selectIsPPConsented);
  const isTCConsented = useSelector(selectIsTCConsented);
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);

  useEffect(() => {
    // Open the consent modal if the user have pending consents when logging in
    if ((isPPConsented === false || isTCConsented === false) && isAuthenticated) {
      dispatch(openConsentModal());
    }
  }, [isAuthenticated, isPPConsented, isTCConsented]);
}

export default useConsents;
