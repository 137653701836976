import * as React from 'react';

function BackIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" width="1em" height="1em">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
      />
    </svg>
  );
}

export default BackIcon;
