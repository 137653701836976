import React from 'react';

import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Snowfall from 'react-snowfall';

import BonusCard from '@/components/bonuses/BonusCard';
import Button from '@/components/button/Button';
import SuperbetLogo from '@/components/icons/SuperbetLogo';
import { CDN_BASE, CDN_IMAGES_PATH } from '@/constants/constants';
import usePromotionsRestriction from '@/hooks/usePromotionsRestriction';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { RootState } from '@/models/root-state.model';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectAvailableBonusesForHome } from '@/store/selectors/availableBonusesSelectors';
import { selectHeroBonus } from '@/store/selectors/combinedSelectors';
import { selectUser } from '@/store/selectors/userSelectors';
import { isEmpty } from '@/utils/helpers';
import { isLigaWinterPeriod } from '@/utils/liga-utils';

const isLigaWinter = isLigaWinterPeriod();
function Hero(): JSX.Element {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const user = useSelector(selectUser);
  const availableBonuses = useSelector(selectAvailableBonusesForHome);
  const bonus = useSelector((state: RootState) => selectHeroBonus(state, isMobile));
  const { promotionsRestricted } = usePromotionsRestriction();
  const navigate = useNavigate();

  const MOBILE_HERO = `${CDN_BASE}${CDN_IMAGES_PATH}/common/hero-mobile.jpg`;
  const TABLET_HERO = `${CDN_BASE}${CDN_IMAGES_PATH}/common/hero-tablet.jpg`;
  const DESKTOP_HERO = `${CDN_BASE}${CDN_IMAGES_PATH}/common/hero-desktop.jpg`;

  return (
    <section
      className={`overflow-hidden relative z-10 flex  w-full bg-brand-red md:min-h-[calc(100vh)]
        ${isAuthenticated ? 'min-h-auto h-[540px]' : 'min-h-[calc(100vh-64px)]'}
      `}
    >
      <div className="absolute inset-0 w-full h-full bg-black/40 sm:bg-black/30 z-10"></div>
      <picture>
        <source media="(max-width: 760px)" srcSet={MOBILE_HERO} />
        <source media="(max-width: 1100px)" srcSet={TABLET_HERO} />
        <source media="(max-width: 1440px)" srcSet={DESKTOP_HERO} />
        <img
          className="pointer-events-none absolute h-full w-full object-cover"
          src={DESKTOP_HERO}
          alt="Magic Jackpot Hero"
        />
      </picture>
      {isLigaWinter && (
        <Snowfall
          // Applied to the canvas element
          style={{ zIndex: 1 }}
          // Controls the number of snowflakes that are created (default 150)
          snowflakeCount={100}
        />
      )}
      {!isAuthenticated ? (
        <div className="mx-auto flex w-full max-w-[1400px] flex-col justify-around px-8 pt-16 text-center text-white sm:justify-center sm:text-left z-40">
          <div className="mb-4">
            <h1 className="mb-4 text-2xl text-white sm:mb-6 sm:text-4xl md:text-5xl">
              Bine ai venit la <br /> Cazinoul MagicJackpot
            </h1>
            <p className="mb-0 max-w-full text-2xl sm:max-w-md sm:text-2xl md:max-w-xl lg:max-w-2xl">
              Welcome Bonus NOU
              <br />
              <span className="font-bold text-yellow-400">600 Rotiri Gratuite</span> la Shining
              Crown, Hot to Burn, 20 Super Hot & Burning Hot! Până la{' '}
              <span className="font-bold text-yellow-400">2,400 lei</span> BONUS!
            </p>
            <p className="mt-4 mb-0 hidden text-xl sm:block md:text-2xl">
              Start magic pentru prima ta depunere!
            </p>
          </div>
          <div className="mb-4">
            <div
              id="e2e_ctaContainer"
              className="flex flex-col justify-center gap-4 sm:mt-10 sm:flex-row sm:justify-start"
            >
              <Button
                size="big"
                onClick={() => {
                  triggerGTag(GTagEvents.reg_home_register_click);
                  navigate('/inregistrare');
                }}
              >
                Înregistrare
              </Button>
              {!promotionsRestricted && (
                <Button
                  size="big"
                  color="ghost"
                  onClick={() => navigate('/promotii')}
                  className="!hidden sm:!inline-flex"
                >
                  Promoții
                </Button>
              )}
            </div>
            <Link
              to="/termeni-si-conditii"
              className="mt-6 block underline sm:mt-8"
              onClick={e => {
                e.preventDefault();
                if (document.getElementById('bonus-intro') !== null) {
                  document.getElementById('bonus-intro')?.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                  });
                }
              }}
            >
              Mai multe detalii
            </Link>
          </div>
          <div className="flex flex-col items-center pb-4 text-xs italic md:hidden">
            parte din <SuperbetLogo className="mt-1 h-auto w-[80px]" />
          </div>
        </div>
      ) : (
        <div className="relative z-40 my-0 mx-auto flex h-full w-full items-center justify-center p-4 sm:max-w-[1400px] sm:p-8 md:justify-around">
          <div className="relative justify-end text-center text-white sm:text-left">
            <h1 className="mb-4 text-3xl text-inherit sm:mb-6 sm:text-5xl">
              Bună{!user ? '...' : `, ${user.FirstName}`}
            </h1>
            {!isEmpty(availableBonuses) && (
              <p className="mb-0 text-xl">Ai {availableBonuses.length} bonusuri de revendicat.</p>
            )}
            <p className="mt-0 text-xl">Mult noroc azi.</p>
            <div className="mt-8 flex flex-col items-center gap-4 sm:mt-10 md:flex-row">
              <div>
                <Link to="/jocuri">
                  <Button>Joacă acum</Button>
                </Link>
              </div>
              {!promotionsRestricted && (
                <div className="hidden md:block">
                  <Button color="ghost" onClick={() => navigate('/promotii')}>
                    Promoții
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="ml-12 hidden md:block">
            {bonus && (
              <div id={`e2e_heroBonusCard_${bonus.type}`}>
                <BonusCard
                  header={bonus.header}
                  description={bonus.description}
                  availableUntil={bonus.availableUntil}
                  buttonLink={bonus.buttonLink}
                  redeemTypeId={bonus.redeemTypeId}
                  bonusCode={bonus.bonusCode}
                  buttonText={bonus.buttonText}
                  terms={bonus.terms}
                  image={bonus.image}
                  type={bonus.type}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default Hero;
