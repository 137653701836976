import React from 'react';

import { CDN_BASE, CDN_IMAGES_PATH } from '@/constants/constants';

const DESKTOP_BG = `${CDN_BASE}${CDN_IMAGES_PATH}/common/reg-desktop.jpg`;
const TABLET_BG = `${CDN_BASE}${CDN_IMAGES_PATH}/common/reg-tablet.jpg`;
const MOBILE_BG = `${CDN_BASE}${CDN_IMAGES_PATH}/common/reg-mobile.jpg`;

const HeroBgImage = () => (
  <div
    className="absolute left-0 right-0 bottom-0 top-0 before:top-0 before:right-0 before:bottom-0
        bg-brand-red before:left-0 before:absolute before:bg-black/40"
  >
    <picture>
      <source media="(max-width: 760px)" srcSet={MOBILE_BG} />
      <source media="(max-width: 1100px)" srcSet={TABLET_BG} />
      <img
        className="w-full h-full object-cover object-right-bottom min-h-[1200px]"
        src={DESKTOP_BG}
        alt="MagicJackpot Hero"
      />
    </picture>
  </div>
);

export default HeroBgImage;
