import { ExtendedUserModel } from '@/models/extended-user.model';
import { UserTag } from '@/models/user-tag.model';
import {
  GetUserSuccessAction,
  GetUserTagsSuccessAction,
  UserActions,
  UserActionType,
} from '@/store/actions/userActions';

export interface UserStateModel {
  user: ExtendedUserModel | null;
  loading: boolean;
  error: boolean;
  tags: UserTag[];
  tagsLoaded: boolean;
  tagsLoading: boolean;
}

const initialState: UserStateModel = {
  user: null,
  loading: false,
  error: false,
  tags: [],
  tagsLoaded: false,
  tagsLoading: false,
};

const getUser = (state: UserStateModel) => ({
  ...state,
  loading: true,
  error: false,
});

const getUserSuccess = (state: UserStateModel, action: GetUserSuccessAction) => ({
  ...state,
  user: action.user,
  loading: false,
});

const getUserTagsSuccess = (state: UserStateModel, action: GetUserTagsSuccessAction) => ({
  ...state,
  tags: action.tags,
  tagsLoaded: true,
  tagsLoading: false,
});

const getUserError = (state: UserStateModel) => ({
  ...state,
  loading: false,
  error: true,
});

export const userReducer = (state = initialState, action: UserActions): UserStateModel => {
  switch (action.type) {
    case UserActionType.Get:
      return getUser(state);
    case UserActionType.Success:
      return getUserSuccess(state, action);
    case UserActionType.GetUserTags:
      return {
        ...state,
        tagsLoading: true,
      };
    case UserActionType.GetUserTagsSuccess:
      return getUserTagsSuccess(state, action);
    case UserActionType.GetUserTagsError:
      return {
        ...state,
        tagsLoading: false,
        tagsLoaded: false,
      };
    case UserActionType.Error:
      return getUserError(state);
    case UserActionType.ClearUser:
      return initialState;
    default:
      return state;
  }
};
