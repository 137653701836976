import {
  BffAuthOtpCodeRequest,
  BffAuthResponse,
  BffAuthenticationMethodResponse,
  BffGamesJackpotsRequest,
  BffGamesJackpotsResponse,
  BffGamesRequest,
  BffGamesResponse,
  BffRegisterMjpRequest,
  BffRegisterResponse,
  BffSetAuthenticationMethodPathParam,
  BffValidateEmailPhoneRequestType,
  BffValidateEmailPhoneResponse,
  L7Brand,
  BffAddressLookupRequest,
  BffAddressLookupResponse,
  BffAddressRetrieveRequest,
  BffAddressRetrieveResponse,
} from '@lucky7ventures/bff-types';
import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { Store } from 'redux';

import { BFF_API_URLS } from '@/constants/bff-api-urls';
import { BFF_URL } from '@/constants/constants';
import { RootState } from '@/models/root-state.model';

const axiosInstance = axios.create({
  baseURL: BFF_URL,
});

export function bffApiServiceInterceptor(store: Store) {
  axiosInstance.interceptors.request.use(request => {
    const state: RootState = store.getState();
    const token = state.auth.token;
    let headers = request.headers;

    // A singular place in the app where we attach the token to the request for BFF
    if (token) {
      headers = { ...headers, 'l7-t': `${token}` };
    }
    headers = {
      ...headers,
      'l7-b': L7Brand.Magicjackpot,
      'l7-locale': 'ro',
    };
    request.headers = headers;
    return request;
  });
}

interface BffApiServiceMethodConfig {
  cancelToken?: CancelToken;
}

export type BffApiServicePostMethod<P, R> = ({
  payload,
  config,
}: {
  payload: P;
  config?: AxiosRequestConfig;
}) => Promise<AxiosResponse<R>>;

export type BffServiceGetMethodWithPathParams<P, R> = ({
  pathParams,
  config,
}: {
  pathParams: P;
  config?: AxiosRequestConfig;
}) => Promise<AxiosResponse<R>>;

export type ApiServiceGetMethod = (config?: BffApiServiceMethodConfig) => Promise<AxiosResponse>;

export interface BffApiServiceI {
  getAllGames: BffApiServicePostMethod<BffGamesRequest, BffGamesResponse>;
  getGamesJackpots: BffApiServicePostMethod<BffGamesJackpotsRequest, BffGamesJackpotsResponse>;
  getAuthenticationMethod: BffApiServicePostMethod<null, BffAuthenticationMethodResponse>;
  setAuthenticationMethod: BffServiceGetMethodWithPathParams<
    BffSetAuthenticationMethodPathParam,
    null
  >;
  loginWithOtp: BffApiServicePostMethod<BffAuthOtpCodeRequest, BffAuthResponse>;
  getGamesLastPlayed: ApiServiceGetMethod;
  register: BffApiServicePostMethod<BffRegisterMjpRequest, BffRegisterResponse>;
  addressLookup: BffApiServicePostMethod<BffAddressLookupRequest, BffAddressLookupResponse>;
  addressRetrieval: BffApiServicePostMethod<BffAddressRetrieveRequest, BffAddressRetrieveResponse>;
  validateEmailPhone: BffApiServicePostMethod<
    BffValidateEmailPhoneRequestType,
    BffValidateEmailPhoneResponse
  >;
}

const apiPostCall = <P>(url: string, payload: P, config?: BffApiServiceMethodConfig) => {
  return axiosInstance.post(url, payload, config);
};

const BffApiService: BffApiServiceI = {
  getAllGames: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.GET_ALL_GAMES, payload, config);
  },
  getGamesJackpots: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.GET_GAMES_JACKPOTS, payload, config);
  },
  getAuthenticationMethod: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.GET_AUTHENTICATION_METHOD, payload, config);
  },
  setAuthenticationMethod: ({ pathParams, config }) => {
    return apiPostCall(BFF_API_URLS.SET_AUTHENTICATION_METHOD(pathParams), null, config);
  },
  loginWithOtp: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.LOGIN_WITH_OTP, payload, config);
  },
  validateEmailPhone: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.VALIDATE_EMAIL_PHONE, payload, config);
  },
  register: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.REGISTER, payload, config);
  },
  addressLookup: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.ADDRESS_LOOKUP, payload, config);
  },
  addressRetrieval: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.ADDRESS_RETRIEVAL, payload, config);
  },
  getGamesLastPlayed: arg => apiPostCall(BFF_API_URLS.GET_LAST_PLAYED, null, arg),
};

export default BffApiService;
