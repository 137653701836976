import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { impersonate } from '@/store/actions/authActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';

function Impersonate(): JSX.Element | null {
  const { token } = useParams<{ token: string }>();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(impersonate(token ?? ''));
  }, []);

  return isAuthenticated ? <Navigate to="/" /> : null;
}

export default Impersonate;
