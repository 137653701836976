import React from 'react';

function DepositBalanceUpdate({ closeModal }: { closeModal: () => void }): JSX.Element | null {
  return (
    <div className="prose flex w-full max-w-sm flex-col overflow-hidden rounded-lg bg-white p-11 text-left">
      <h1 className="text-center">Va reamintim rapid!</h1>
      <p className="text-center">
        Noul dvs.sold în joc va fi afișat după ce apăsați butonul de rotire sau redeschideti pagina
      </p>
      <button
        type="button"
        onClick={closeModal}
        className="border-3 mt-4 flex w-full items-center justify-center rounded-lg border-solid border-black  p-4 font-bold
        text-black transition hover:bg-black hover:text-white"
      >
        Ok, am inteles!
      </button>
    </div>
  );
}

export default DepositBalanceUpdate;
