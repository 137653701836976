import { UNKNOWN_ERROR } from '@/constants/internal-error-messages';
import { ErrorCodes } from '@/models/enums/error-codes.enum';

export function getLoginErrorMessage(error: string): string {
  switch (error) {
    // User is not allowed to login outside of Romania
    case ErrorCodes.FORBIDDEN:
      return 'Se pare că încerci să accesezi site-ul din afara României, Te rugăm să încerci din nou de pe o adresă de IP din România.';
    // Cloudflare rate limit - too many requests
    case ErrorCodes.TOO_MANY_REQUESTS:
      return 'Accesul tău a fost limitat. Te rugăm să contactezi serviciul de suport clienți înainte de a reîncerca să intri în contul tău.';
    case ErrorCodes.ACCOUNT_COOLDOWN:
      return 'Ai încercat de prea multe ori să te conectezi cu date incorecte. Te rugăm să aștepți 30 minute sau contactează Serviciul Clienți pentru ajutor.';
    case ErrorCodes.ACCOUNT_BLOCKED:
      return 'Contul tău a fost blocat. Te rugăm să contactezi serviciul de Suport Clienți pentru ajutor.';
    case ErrorCodes.USER_NOT_FOUND:
      return 'Username și / sau parola este greșită';
    case ErrorCodes.USER_SELF_EXCLUDED:
      return 'Nu te poți loga până când perioada de auto excludere va lua sfârșit. Te rugăm să contactezi serviciul de Suport Clienți pentru ajutor.';
    default:
      return UNKNOWN_ERROR;
  }
}
