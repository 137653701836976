import React, { useState } from 'react';

import LazyLoad from 'react-lazyload';
import { Transition } from 'react-transition-group';

const getStyle = (): React.CSSProperties => ({
  transition: `opacity 200ms ease-in-out`,
  opacity: 0,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '0',
  right: '0',
  left: '0',
  bottom: '0',
});

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

function FadeIn({
  children,
  overflow,
  offset,
}: {
  children(onLoad: () => void): React.ReactNode;
  overflow?: boolean;
  offset?: number;
}): JSX.Element {
  const [loaded, setLoaded] = useState(false);

  function onLoad() {
    setLoaded(true);
  }

  return (
    <LazyLoad overflow={overflow} offset={offset}>
      <Transition in={loaded} timeout={200}>
        {state => (
          <div
            style={{
              ...getStyle(),
              ...transitionStyles[state],
            }}
          >
            {children(onLoad)}
          </div>
        )}
      </Transition>
    </LazyLoad>
  );
}

export default FadeIn;
