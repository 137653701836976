import * as React from 'react';

import { useSelector } from 'react-redux';

import GameList from './GameList';
import NoGames from '@/pages/games/NoGames';
import { selectLastPlayedGames } from '@/store/selectors/gamesSelectors';

function GamesLastPlayed(): JSX.Element {
  const lastPlayedGames = useSelector(selectLastPlayedGames);

  return (
    <>
      {lastPlayedGames && lastPlayedGames.length > 0 ? (
        <GameList games={lastPlayedGames} header="Ultimul jucat" />
      ) : (
        <NoGames text="Se pare că, încă nu te-ai jucat un joc!" />
      )}
    </>
  );
}

export default GamesLastPlayed;
