import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = (props: SkeletonProps) => (
  <Skeleton className="rounded-lg" baseColor="#cfd0d1" {...props} />
);

export default SkeletonLoader;
