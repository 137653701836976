// we want to present Winter assets for Liga from December 4th to January 3rd

import { CDN_BASE, CDN_IMAGES_PATH } from '@/constants/constants';
import {
  LigaLevelsEnum,
  LigaLevelStatus,
  LigaPrizeWheels,
  LigaWheelLevelsEnum,
} from '@/models/liga.model';

export const isLigaWinterPeriod = (): boolean => {
  const today = new Date();
  const month = today.getMonth();
  const dayOfMonth = today.getDate();
  return month === 11 && dayOfMonth >= 1;
};

export const ligaBadgeChipsUrl = (level: LigaLevelsEnum | 'NoLiga'): string => {
  const isLigaWinter = isLigaWinterPeriod();
  const imgBase = `${CDN_BASE}/cdn-cgi/image/fit=cover,width=300px,height=auto,gravity=1x0,dpr=1,format=auto${CDN_IMAGES_PATH}/common/liga/`;

  const imgName = (level: LigaLevelsEnum | 'NoLiga'): string => {
    switch (level) {
      case LigaLevelsEnum.Liga1:
        return !isLigaWinter ? 'logo_liga1.png' : 'logo_liga1-xmas.png';
      case LigaLevelsEnum.Liga2:
        return !isLigaWinter ? 'logo_liga2.png' : 'logo_liga2-xmas.png';
      case LigaLevelsEnum.Liga3:
        return !isLigaWinter ? 'logo_liga3.png' : 'logo_liga3-xmas.png';
      case LigaLevelsEnum.Liga4:
        return !isLigaWinter ? 'logo_liga4.png' : 'logo_liga4-xmas.png';
      case LigaLevelsEnum.Liga5:
        return !isLigaWinter ? 'logo_liga5.png' : 'logo_liga5-xmas.png';
      case LigaLevelsEnum.Liga6:
        return !isLigaWinter ? 'logo_liga6.png' : 'logo_liga6-xmas.png';
      case 'NoLiga':
      case LigaLevelsEnum.Liga7:
        return !isLigaWinter ? 'logo_liga7.png' : 'logo_liga7-xmas.png';
      default:
        return '';
    }
  };

  return imgBase + imgName(level);
};

export const ligaCardBackgroundUrl = (level: LigaLevelsEnum): string => {
  const imgBase = `${CDN_BASE}/cdn-cgi/image/fit=cover,width=640px,height=auto,gravity=1x0,dpr=1,format=auto${CDN_IMAGES_PATH}/common/liga/`;
  switch (level) {
    case LigaLevelsEnum.Liga1:
      return imgBase + 'liga-card-bg-1.jpg';
    case LigaLevelsEnum.Liga2:
      return imgBase + 'liga-card-bg-2.jpg';
    case LigaLevelsEnum.Liga3:
      return imgBase + 'liga-card-bg-3.jpg';
    case LigaLevelsEnum.Liga4:
      return imgBase + 'liga-card-bg-4.jpg';
    case LigaLevelsEnum.Liga5:
      return imgBase + 'liga-card-bg-5.jpg';
    case LigaLevelsEnum.Liga6:
      return imgBase + 'liga-card-bg-6.jpg';
    case LigaLevelsEnum.Liga7:
      return imgBase + 'liga-card-bg-7.jpg';
    default:
      return '';
  }
};

export const ligaPrizes = (level: LigaLevelsEnum): string[] => {
  switch (level) {
    case LigaLevelsEnum.Liga1:
      return [
        '1000 Lei Bonus',
        '500 Lei Bonus',
        '250 Lei Bonus',
        '10 VIP Spins',
        '5 VIP Spins',
        '5 Magic Spins',
      ];
    case LigaLevelsEnum.Liga2:
      return [
        '500 Lei Bonus',
        '250 Lei Bonus',
        '10 VIP Spins',
        '10 Magic Spins',
        '5 Magic Spins',
        '5 Mega Spins',
      ];
    case LigaLevelsEnum.Liga3:
      return [
        '250 Lei Bonus',
        '125 Lei Bonus',
        '10 Magic Spins',
        '10 Mega Spins',
        '5 Mega Spins',
        '25 Free Spins',
      ];
    case LigaLevelsEnum.Liga4:
      return [
        '125 Lei Bonus',
        '10 Magic Spins',
        '10 Mega Spins',
        '5 Mega Spins',
        '25 Free Spins',
        '15 Free Spins',
      ];
    case LigaLevelsEnum.Liga5:
      return [
        '50 Lei Bonus',
        '10 Mega Spins',
        '30 Free Spins',
        '20 Free Spins',
        '10 Free Spins',
        '5 Free Spins',
      ];
    case LigaLevelsEnum.Liga6:
      return [
        '30 Lei Bonus',
        '5 Mega Spins',
        '15 Free Spins',
        '10 Free Spins',
        '5 Free Spins',
        '3 Free Spins',
      ];
    case LigaLevelsEnum.Liga7:
      return ['25 Lei Bonus', '20 Free Spins', '15 Free Spins', '10 Free Spins', '5 Free Spins'];
  }
};

export const ligaPrizesTitle = (level: LigaLevelsEnum): string => {
  switch (level) {
    case LigaLevelsEnum.Liga1:
      return 'Toate Roțile Liga 1';
    case LigaLevelsEnum.Liga2:
      return 'Toate Roțile Liga 2';
    case LigaLevelsEnum.Liga3:
      return 'Toate Roțile Liga 3';
    case LigaLevelsEnum.Liga4:
      return 'Toate Roțile Liga 4';
    case LigaLevelsEnum.Liga5:
      return 'Toate Roțile Liga 5';
    case LigaLevelsEnum.Liga6:
      return 'Toate Roțile Liga 6';
    case LigaLevelsEnum.Liga7:
      return 'Toate Roțile Liga 7';
  }
};

const LIGA_PRIZE_WHEELS = {
  [LigaLevelsEnum.Liga7]: [LigaWheelLevelsEnum.Liga7Wheel],
  [LigaLevelsEnum.Liga6]: [
    LigaWheelLevelsEnum.Liga6Wheel1,
    LigaWheelLevelsEnum.Liga6Wheel2,
    LigaWheelLevelsEnum.Liga6Wheel3,
  ],
  [LigaLevelsEnum.Liga5]: [
    LigaWheelLevelsEnum.Liga5Wheel1,
    LigaWheelLevelsEnum.Liga5Wheel2,
    LigaWheelLevelsEnum.Liga5Wheel3,
  ],
  [LigaLevelsEnum.Liga4]: [
    LigaWheelLevelsEnum.Liga4Wheel1,
    LigaWheelLevelsEnum.Liga4Wheel2,
    LigaWheelLevelsEnum.Liga4Wheel3,
  ],
  [LigaLevelsEnum.Liga3]: [
    LigaWheelLevelsEnum.Liga3Wheel1,
    LigaWheelLevelsEnum.Liga3Wheel2,
    LigaWheelLevelsEnum.Liga3Wheel3,
  ],
  [LigaLevelsEnum.Liga2]: [
    LigaWheelLevelsEnum.Liga2Wheel1,
    LigaWheelLevelsEnum.Liga2Wheel2,
    LigaWheelLevelsEnum.Liga2Wheel3,
  ],
  [LigaLevelsEnum.Liga1]: [
    LigaWheelLevelsEnum.Liga1Wheel1,
    LigaWheelLevelsEnum.Liga1Wheel2,
    LigaWheelLevelsEnum.Liga1Wheel3,
  ],
};
export const loggedOutLigaLevels = (): LigaPrizeWheels => {
  return Object.keys(LIGA_PRIZE_WHEELS).reduce((acc, ligaLevel) => {
    const levelPrizeWheels = LIGA_PRIZE_WHEELS[ligaLevel as LigaLevelsEnum];
    return {
      ...acc,
      [ligaLevel]: levelPrizeWheels.map(i => ({
        status: LigaLevelStatus.Ineligible,
        wheel: i,
      })),
    };
  }, {}) as LigaPrizeWheels;
};
