import React from 'react';

import { BanknotesIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';

import CashierProgressBar from './CashierProgressBar';
import RemainingDepositLimit from './RemainingDepositLimit';
import CurrencyFormat from '@/components/formats/CurrencyFormat';
import TotalBalance from '@/components/total-balance/TotalBalance';
import { selectUserCurrency } from '@/store/selectors/userSelectors';
import { selectWalletWithBonuses } from '@/store/selectors/walletSelectors';

function CashierHeader() {
  const wallet = useSelector(selectWalletWithBonuses);
  const currency = useSelector(selectUserCurrency);

  return (
    <header className="px-4 pt-16 pb-8 bg-red-gradient text-white">
      <div className="flex flex-col gap-4">
        <h1 className="text-[38px] font-bold mb-0 text-white">
          <div className="text-sm flex items-center">
            <BanknotesIcon className="w-8 h-8 mr-2" /> Balanța ta
          </div>
          <TotalBalance />
        </h1>
        {wallet && currency && (
          <div className="text-sm">
            <div className="mb-1">
              Sold retragere:{' '}
              <span className="font-bold">
                <CurrencyFormat amount={wallet.withdrawableBalance} currency={currency} />
              </span>
            </div>
            <div className="mb-1">
              Sold bonus:{' '}
              <span className="font-bold">
                {' '}
                <CurrencyFormat amount={wallet.bonusBalance} currency={currency} />
              </span>
            </div>
            {wallet.showTurnover && <CashierProgressBar percent={wallet.turnoverPercentage} />}
          </div>
        )}
        <RemainingDepositLimit />
      </div>
    </header>
  );
}

export default CashierHeader;
