import React, { ReactNode } from 'react';

import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface FieldIconProps {
  icon: ReactNode;
  onClick?: () => void;
  valid?: boolean;
}

const FieldIcon = ({ icon, onClick, valid }: FieldIconProps) => (
  <div className="absolute right-2.5 top-1/3 flex gap-x-1 transition-all duration-200 ease-in-out">
    {icon && (
      <div
        onClick={onClick}
        className="w-[20px] h-[20px] cursor-pointer peer-autofill:[&>svg]:text-black"
      >
        {icon}
      </div>
    )}
    {valid && <CheckCircleIcon width={20} height={20} className="text-green-600" />}
  </div>
);

export default FieldIcon;
