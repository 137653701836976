import { ModalType } from '@/models/enums/modal-type.enum';
import { ConfirmModalProps, LoginModalProps, TermsModalProps } from '@/models/modal-props.model';
import { RootState } from '@/models/root-state.model';

export const selectModalName = (state: RootState): ModalType | null => state.modal.name;
export const selectModalLoginProps = (state: RootState): LoginModalProps | undefined =>
  state.modal.loginModalProps;
export const selectModalConfirmProps = (state: RootState): ConfirmModalProps | undefined =>
  state.modal.confirmModalProps;
export const selectModalTermsProps = (state: RootState): TermsModalProps | undefined =>
  state.modal.termsModalProps;
export const selectIsFtdExitIntentShown = (state: RootState): boolean =>
  state.modal.showFtdExitIntent;
