import { keyframes } from 'styled-components/macro';

export const spinAnimation = keyframes`
	0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

export const fadeOut = keyframes`
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
`;

export const scaleIn = keyframes`
	from {
		transform: scale(0.7);
	}

	to {
		transform: scale(1);
	}
`;

export const scaleOut = keyframes`
	from {
		transform: scale(1);
	}

	to {
		transform: scale(0.7);
	}
`;

export const checkmark = keyframes`
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}
	20% {
		height: 0;
		width: 14px;
		opacity: 1;
	}
	40% {
		height: 24px;
		width: 14px;
		opacity: 1;
	}
	100% {
		height: 24px;
		width: 14px;
		opacity: 1;
	}
`;

export const loaderSpin = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

export const slideUp = keyframes`
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0);
	}
`;
