import React from 'react';

import styled from 'styled-components/macro';

import CloseButton from '@/components/button/CloseButton';
import useHeaderMessage from '@/hooks/useHeaderMessage';

export const StyledHeaderMessage = styled.div`
  background: ${props => props.theme.white};
  font-size: 0.8rem;
  text-align: center;
  padding: 0.6rem 2rem;

  p {
    line-height: 1.2;
    max-width: 800px;
    margin: 0 auto;
  }
`;

export function HeaderMessage(): JSX.Element | null {
  const { headerMessage, closeHeaderMessage } = useHeaderMessage();

  if (!headerMessage) {
    return null;
  }

  return (
    <StyledHeaderMessage id="headerMessageWrapper">
      <p>{headerMessage}</p>
      <CloseButton position="right" onClick={closeHeaderMessage} color="black" />
    </StyledHeaderMessage>
  );
}
