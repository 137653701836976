import * as React from 'react';
import { useEffect, useState } from 'react';

import { useUnmount } from 'react-use';
import styled from 'styled-components';

import { isFileImage } from '@/utils/upload-utils';

const StyledFilePreview = styled.div`
  img {
    max-width: 100%;
    max-height: 300px;
  }

  .no-preview {
    svg {
      width: 96px;
      height: 96px;
    }
  }

  .preview-file-name {
    padding: 0.5rem;
    font-size: 13px;
    font-weight: bold;
  }
`;

function FilePreview({ file }: { file: File | null }): JSX.Element | null {
  const [preview, setPreview] = useState<string | null>();

  useEffect(() => {
    if (preview) {
      URL.revokeObjectURL(preview);
    }

    if (file && isFileImage(file)) {
      setPreview(URL.createObjectURL(file));
    } else {
      setPreview(null);
    }
  }, [file]);

  useUnmount(() => {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
  });

  if (!file) {
    return null;
  }

  return (
    <StyledFilePreview>
      {preview && <img src={preview} alt={file.name} />}
      {!preview && (
        <div className="no-preview">
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,10.5H13V13.5H12V10.5M7,11.5H8V10.5H7V11.5M20,6V18A2,2 0 0,1 18,20H6A2,2 0 0,1 4,18V6A2,2 0 0,1 6,4H18A2,2 0 0,1 20,6M9.5,10.5A1.5,1.5 0 0,0 8,9H5.5V15H7V13H8A1.5,1.5 0 0,0 9.5,11.5V10.5M14.5,10.5A1.5,1.5 0 0,0 13,9H10.5V15H13A1.5,1.5 0 0,0 14.5,13.5V10.5M18.5,9H15.5V15H17V13H18.5V11.5H17V10.5H18.5V9Z"
            />
          </svg>
        </div>
      )}
      <div className="preview-file-name">{file.name}</div>
    </StyledFilePreview>
  );
}

export default FilePreview;
