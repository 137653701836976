import axios from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, AllEffect, call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  getUserError,
  getUserSuccess,
  GetUserTagsAction,
  getUserTagsError,
  getUserTagsSuccess,
  UserActionType,
} from '../actions/userActions';
import ApiService from '@/shared/api-service';
import { isAxiosResponse } from '@/shared/type-guards';
import { mapUser } from '@/utils/helpers';

export function* getUserSaga(): SagaIterator {
  try {
    const response = yield call(ApiService.getUser, null);

    if (isAxiosResponse(response)) {
      const user = response.data;
      yield put(getUserSuccess(mapUser(user)));
    } else {
      yield put(getUserError());
    }
  } catch (error) {
    yield put(getUserError());
  }
}

export function* getUserTagsSaga(action: GetUserTagsAction): SagaIterator {
  const token = action.token;
  try {
    const response = yield call(axios.post, '/api/user/gettags', { token });
    if (isAxiosResponse(response)) {
      yield put(getUserTagsSuccess(response.data));
    } else {
      yield put(getUserTagsError());
    }
  } catch (error) {
    yield put(getUserTagsError());
  }
}

export function* watchUser(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(UserActionType.Get, getUserSaga),
    takeEvery(UserActionType.GetUserTags, getUserTagsSaga),
  ]);
}
