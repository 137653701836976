import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { put, PutEffect, AllEffect, ForkEffect, all, takeEvery } from 'redux-saga/effects';

import { ApiResponseModel } from '@/models/api-response.model';
import { getActiveBonuses, resetActiveBonuses } from '@/store/actions/activeBonusesActions';
import {
  AuthActionTypes,
  CheckIfLoggedInAction,
  ImpersonateAction,
  loginSuccess,
  LoginSuccessAction,
  resetAuth,
} from '@/store/actions/authActions';
import {
  getAvailableBonuses,
  resetAvailableBonuses,
} from '@/store/actions/availableBonusesActions';
import { getConsents } from '@/store/actions/consentsActions';
import { removeBtag } from '@/store/actions/cookiesActions';
import { getDepositCount } from '@/store/actions/depositActions';
import { clearKycState } from '@/store/actions/kycActions';
import { getManualBonuses } from '@/store/actions/manualBonusesActions';
import { clearUserState, getUser, getUserTags } from '@/store/actions/userActions';
import { clearWalletState, getWallet } from '@/store/actions/walletActions';
import CookiesManagerUtils from '@/utils/cookies-manager-utils';
import { doNothing } from '@/utils/helpers';

export function* checkIfLoggedInSaga({
  token,
}: CheckIfLoggedInAction): Generator<
  PutEffect | Promise<AxiosResponse>,
  void,
  AxiosResponse<ApiResponseModel<{ data: boolean }>>
> {
  try {
    const { data: responseData } = yield axios.post('/api/auth/isloggedin', { token });
    const { data } = responseData;

    if (data) {
      yield put(loginSuccess(token));
    } else {
      CookiesManagerUtils.removeTokenCookie();
    }
  } catch {
    CookiesManagerUtils.removeTokenCookie();
  }
}

export function* logoutSaga({
  token,
}: CheckIfLoggedInAction): Generator<
  PutEffect | Promise<AxiosResponse>,
  void,
  AxiosResponse<ApiResponseModel<{ data: boolean }>>
> {
  try {
    yield axios.post('/api/auth/logout', { token });
  } catch {
    doNothing();
  }
  toast.dismiss('freeSpinsToast');
  toast.dismiss('depositLimitToast');
  toast.dismiss('verifyToast');
  CookiesManagerUtils.removeTokenCookie();

  yield put(resetAvailableBonuses());
  yield put(resetActiveBonuses());
  yield put(resetAuth());
  yield put(clearUserState());
  yield put(clearWalletState());
  yield put(clearKycState());
  yield put(removeBtag());
}

export function* impersonateSaga({ token }: ImpersonateAction): Generator<PutEffect, void> {
  CookiesManagerUtils.setTokenCookie(token);
  yield put(loginSuccess(token));
}

export function* loginSuccessSaga({ token }: LoginSuccessAction): Generator<PutEffect, void> {
  CookiesManagerUtils.setTokenCookie(token);
  yield put(getUser());
  yield put(getAvailableBonuses(token));
  yield put(getManualBonuses());
  yield put(getActiveBonuses(token));
  yield put(getDepositCount(token));
  yield put(getWallet(token));
  yield put(getConsents());
  yield put(getUserTags(token));
}

export function* watchAuth(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(AuthActionTypes.CheckIfLoggedIn, checkIfLoggedInSaga),
    takeEvery(AuthActionTypes.Logout, logoutSaga),
    takeEvery(AuthActionTypes.Impersonate, impersonateSaga),
    takeEvery(AuthActionTypes.LoginSuccess, loginSuccessSaga),
  ]);
}
