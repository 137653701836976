import TagManager from 'react-gtm-module';

export enum GTagEvents {
  'deposit_success' = 'deposit_success',
  'first_deposit' = 'first_deposit',
  'ftd_exit_intent_closed' = 'ftd_exit_intent_closed',
  'ftd_exit_intent_deposit' = 'ftd_exit_intent_deposit',
  'kyc_id_approved' = 'kyc_id_approved',
  'kyc_id_screening_expired' = 'kyc_id_screening_expired',
  'kyc_id_screening_failed' = 'kyc_id_screening_failed',
  'kyc_id_screening_mismatch' = 'kyc_id_screening_mismatch',
  'kyc_id_upload' = 'kyc_id_upload',
  'kyc_id_upload_started' = 'kyc_id_upload_started',
  'login_2fa' = 'login_2fa',
  'login_2fa_error' = 'login_2fa_error',
  'login_2fa_invalid' = 'login_2fa_invalid',
  'login_2fa_success' = 'login_2fa_success',
  'login_error' = 'login_error',
  'login_success' = 'login_success',
  'open_category' = 'open_category',
  'open_game' = 'open_game',
  'reg_2fa_error' = 'reg_2fa_error',
  'reg_2fa_invalid' = 'reg_2fa_invalid',
  'reg_2fa_started' = 'reg_2fa_started',
  'reg_2fa_success' = 'reg_2fa_success',
  'reg_cnp_click' = 'reg_cnp_click',
  'reg_cnp_error' = 'reg_cnp_error',
  'reg_dob_click' = 'reg_dob_click',
  'reg_dob_error' = 'reg_dob_error',
  'reg_email_click' = 'reg_email_click',
  'reg_email_valid' = 'reg_email_valid',
  'reg_email_error' = 'reg_email_error',
  'reg_error_help_click' = 'reg_error_help_click',
  'reg_error_home_click' = 'reg_error_home_click',
  'reg_error_step1_help_click' = 'reg_error_step1_help_click',
  'reg_step1_validate_error' = 'reg_step1_validate_error',
  'reg_step1_validate_success' = 'reg_step1_validate_success',
  'reg_error_step3_help_click' = 'reg_error_step3_help_click',
  'reg_failed' = 'reg_failed',
  'reg_failed_acc_blocked' = 'reg_failed_acc_blocked',
  'reg_failed_email_already_exist' = 'reg_failed_email_already_exist',
  'reg_full_name_click' = 'reg_full_name_click',
  'reg_full_name_error' = 'reg_full_name_error',
  'reg_header_register_click' = 'reg_header_register_click',
  'reg_home_register_click' = 'reg_home_register_click',
  'reg_house_click' = 'reg_house_click',
  'reg_house_error' = 'reg_house_error',
  'reg_invalid_2fa_help_click' = 'reg_invalid_2fa_help_click',
  'reg_login' = 'reg_login',
  'reg_login_failed_open_login_modal_click' = 'reg_login_failed_open_login_modal_click',
  'reg_marketing_click' = 'reg_marketing_click',
  'reg_mobile_click' = 'reg_mobile_click',
  'reg_mobile_error' = 'reg_mobile_error',
  'reg_mobile_exists_error' = 'reg_mobile_exists_error',
  'reg_mobile_invalid_error' = 'reg_mobile_invalid_error',
  'reg_otp_click' = 'reg_otp_click',
  'reg_password_click' = 'reg_password_click',
  'reg_password_error' = 'reg_password_error',
  'reg_pep_hio_consent_click' = 'reg_pep_hio_consent_click',
  'reg_pep_hio_consent_error' = 'reg_pep_hio_consent_error',
  'reg_privacy_click' = 'reg_privacy_click',
  'reg_privacy_error' = 'reg_privacy_error',
  'reg_step1_continue_cta_click' = 'reg_step1_continue_cta_click',
  'reg_step2_back_cta_click' = 'reg_step2_back_cta_click',
  'reg_step2_continue_cta_click' = 'reg_step2_continue_cta_click',
  'reg_step3_back_cta_click' = 'reg_step3_back_cta_click',
  'reg_step3_submit_cta_click' = 'reg_step3_submit_cta_click',
  'reg_street_click' = 'reg_street_click',
  'reg_street_error' = 'reg_street_error',
  'reg_success_login_error_login_click' = 'reg_success_login_error_login_click',
  'reg_terms_click' = 'reg_terms_click',
  'reg_terms_error' = 'reg_terms_error',
  'reg_town_click' = 'reg_town_click',
  'reg_town_error' = 'reg_town_error',
  'reg_zip_code_click' = 'reg_zip_code_click',
  'reg_zip_code_error' = 'reg_zip_code_error',
  'registration_success' = 'registration_success',
  'reg_success_login_failed' = 'reg_success_login_failed',
  'user_id_set' = 'user_id_set',
  'user_id_unset' = 'user_id_unset',
}

type GTagParams = {
  error: string;
  userId: number;
  amount: number;
  openFrom: string;
  categoryName: string;
  position: number;
  gameName: string;
};

export const triggerNetReferer = (
  action: string,
  btag: string | undefined,
  userId: number | undefined,
) => {
  const cid = '005770a1-d14b-4834-85b7-688caf9ab944';
  const an = action;
  const b = 'all';
  const s = 'all';
  const to = 'both';
  const tag = btag;
  const customerID = userId;

  const fp = new Image();
  fp.src =
    'https://tracking.netrefer.com/Tracking.svc/Track?' +
    'to=' +
    to +
    '&clientID=' +
    cid +
    '&ActionName=' +
    an +
    '&b=' +
    b +
    '&s=' +
    s +
    '&TrackingTag=' +
    tag +
    '&customerID=' +
    customerID;
};

export function triggerGTag(event: string, params?: Partial<GTagParams>) {
  TagManager.dataLayer({ dataLayer: { event, ...params } });
}
