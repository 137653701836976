import React, { FocusEvent, useEffect } from 'react';

import { useField } from 'formik';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { FieldEndIcon } from '@/components/form/field/Field';
import FieldError from '@/components/form/field/FieldError';
import FieldIcon from '@/components/form/field/FieldIcon';
import FieldLabel from '@/components/form/field/FieldLabel';
import { appendDefaultContainerClassNames } from '@/components/form/field/helpers';

interface PhoneNumberFieldProps extends PhoneInputProps, FieldEndIcon {
  className?: string;
  name: string;
  label: string;
  labelClassName?: string;
  onValidationError?: (error: string) => void;
  'data-cy'?: string;
}

interface CountryValue {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

const PhoneNumberField = ({
  className,
  disabled,
  label,
  labelClassName,
  name,
  onValidationError,
  endIcon,
  endIconAction,
  ...props
}: PhoneNumberFieldProps) => {
  const [{ value, onChange: _onChange, ...field }, { error: errorObj, touched }, { setValue }] =
    useField(name);

  const error = errorObj as unknown as { wholeNumber: string; number: string };
  const errorMessage = error?.wholeNumber || error?.number;
  const isValidationError = touched && errorMessage;

  function onChangeHandler(value: string, country: CountryValue) {
    const updatedPhone = {
      number: country.dialCode ? value.slice(country.dialCode.length) : '',
      prefix: `+${country.dialCode}`,
      country: country.countryCode,
      wholeNumber: value,
    };

    setValue(updatedPhone);
  }

  useEffect(() => {
    if (isValidationError) {
      onValidationError?.(errorMessage!);
    }
  }, [isValidationError]);

  return (
    <div
      data-cy={props['data-cy']}
      className={appendDefaultContainerClassNames(className, errorMessage, touched)}
    >
      <PhoneInput
        country={value?.country}
        value={value.prefix + value.number}
        onChange={onChangeHandler}
        disabled={disabled}
        specialLabel=""
        inputClass="border-0 focus:outline-0 focus:border-none focus:ring-0 autofill:!bg-none peer font-light disabled:cursor-not-allowed disabled:text-gray-400"
        inputStyle={{
          border: '1px',
          background: 'transparent',
          width: '100%',
          borderRadius: 5,
          paddingBottom: '1.125rem',
          paddingLeft: '3.75rem',
          paddingRight: '0.25rem',
          paddingTop: '2.25rem',
          fontSize: '15px',
          lineHeight: 1,
        }}
        dropdownStyle={{
          background: 'white',
          color: 'black',
          scrollbarWidth: 'none',
        }}
        dropdownClass="[&_li>.flag]:!top-2"
        buttonStyle={{
          border: 0,
          margin: 0,
          marginTop: 34,
          marginLeft: '0.4rem',
          padding: 0,
          height: 'fit-content',
        }}
        {...props}
        inputProps={{
          ...field,
          onBlur: (event: FocusEvent<HTMLInputElement>) => {
            props.onBlur?.(event, {});
            field.onBlur(event);
          },
        }}
      />
      <FieldLabel className={labelClassName} value={true}>
        {label}
      </FieldLabel>
      <FieldError show={Boolean(isValidationError)} error={errorMessage} />
      <FieldIcon icon={endIcon} onClick={endIconAction} valid={!Boolean(error)} />
    </div>
  );
};

export default PhoneNumberField;
