export const API_URLS = {
  FORGOT_PASSWORD: '/api/v2/auth/forgotpassword',
  LOGIN: '/api/v2/auth/login',
  REGISTER: '/api/v2/register',
  GET_USER: '/api/v2/user',
  GET_LIGA: '/api/v2/liga',
  LIGA_OPT_IN: '/api/v2/liga/opt-in',
  GET_JACKPOTS: '/api/v2/jackpots',
  GET_EGT_JACKPOTS: '/api/v2/jackpots/egt',
  GET_LIFETIME_DEPOSITS: '/api/v2/user/lifetime-deposits',
  GET_CONSENTS: '/api/v2/consents',
  SAVE_CONSENTS: '/api/v2/consents/save',
};
