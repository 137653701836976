import React, { useEffect, useState } from 'react';

import { forceCheck } from 'react-lazyload';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import PageHeader from '@/components/header/PageHeader';
import Search from '@/components/search/Search';
import GameCategoryNav from '@/pages/games/GameCategoryNav';
import SearchGameList from '@/pages/games/SearchGameList';
import { searchGames } from '@/store/actions/gamesActions';
import { selectSearchedGames } from '@/store/selectors/gamesSelectors';
import { isEmpty } from '@/utils/helpers';

const StyledGames = styled.div`
  min-height: 100vh;
`;

function Games(): JSX.Element {
  const [searchValue, setSearchValue] = useState('');
  const searchedGames = useSelector(selectSearchedGames);
  const dispatch = useDispatch();
  const location = useLocation();
  // Clear search box when changing route
  useEffect(() => {
    setSearchValue('');
  }, [location]);

  function updateSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const trimmedValue = event.target.value.substr(0, 20);
    setSearchValue(trimmedValue);
    dispatch(searchGames(trimmedValue));
  }

  useEffect(() => {
    if (searchedGames) {
      forceCheck();
    }
  }, [searchedGames]);

  return (
    <StyledGames>
      <PageHeader>
        <Search
          name="gameSearch"
          placeholder="Caută un joc sau studio"
          value={searchValue}
          onChange={event => updateSearch(event)}
          onClose={() => setSearchValue('')}
        />
      </PageHeader>
      <GameCategoryNav />
      {!isEmpty(searchValue) ? (
        <SearchGameList games={searchedGames} searchString={searchValue} />
      ) : (
        <Outlet />
      )}
    </StyledGames>
  );
}

export default Games;
