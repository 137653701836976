import { UserWalletPublic } from '@lucky7ventures/lucky-components';
import { createSelector } from 'reselect';

import { ExtendedUserWalletModel } from '@/models/extended-user-wallet-model';
import { RootState } from '@/models/root-state.model';
import { selectActiveBonuses } from '@/store/selectors/activeBonusesSelectors';

export const selectWallet = (state: RootState): UserWalletPublic | null => state.wallet.wallet;

export const selectWalletWithdrawableBalance = createSelector(selectWallet, (wallet): number => {
  return wallet ? wallet.Balance : 0;
});

export const selectWalletTotalBalance = createSelector(selectWallet, (wallet): number | null => {
  if (!wallet) {
    return null;
  }

  if (!wallet.UserBonuses || wallet.UserBonuses.length === 0) {
    return wallet.Balance;
  }

  return wallet.UserBonuses.reduce((acc, bonus) => {
    if (!bonus.Bonuses || bonus.Bonuses.length === 0) {
      return acc;
    }

    return (
      acc + bonus.Bonuses.reduce((acc, bonus) => acc + bonus.BonusMoney + bonus.RealBonusMoney, 0)
    );
  }, wallet.Balance);
});

export const selectWalletWithBonuses = createSelector(
  selectWalletTotalBalance,
  selectWalletWithdrawableBalance,
  selectActiveBonuses,
  (totalBalance, withdrawableBalance, bonuses): ExtendedUserWalletModel | null => {
    if (totalBalance === null) {
      return null;
    }

    const defaultWaletValues: ExtendedUserWalletModel = {
      totalBalance: totalBalance,
      withdrawableBalance: withdrawableBalance,
      bonusBalance: 0,
      turnoverPercentage: 0,
      showTurnover: false,
    };

    if (!bonuses || bonuses.length === 0) {
      return defaultWaletValues;
    }

    let totalTurnover = 0;
    let leftToTurnover = 0;
    let bonusBalance = 0;

    bonuses.forEach(bonus => {
      bonusBalance += bonus.BonusMoney + bonus.RealBonusMoney;
      leftToTurnover += bonus.WageringAmount;
      totalTurnover += bonus.BonusWagering;
    });

    const stakedAmount = totalTurnover - leftToTurnover;
    const turnoverPercentage = Math.floor((stakedAmount / totalTurnover) * 100);

    return {
      ...defaultWaletValues,
      bonusBalance,
      showTurnover: true,
      turnoverPercentage,
    };
  },
);
