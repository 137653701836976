import React, { forwardRef, InputHTMLAttributes } from 'react';

import classNames from 'classnames';

interface FieldInputProps extends InputHTMLAttributes<HTMLInputElement> {
  controlledFocus: boolean;
}

const FieldInput = forwardRef<HTMLInputElement, FieldInputProps>(
  ({ className, controlledFocus, ...props }, _ref) => (
    <input
      onKeyPress={event => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      type="text"
      className={classNames(
        'text-center w-[80px] pt-7 text-[15px] pb-1 bg-transparent font-light disabled:cursor-not-allowed disabled:text-gray-700',
        'rounded-md border-0 focus:outline-0 focus:border-none focus:ring-0 autofill:!bg-none',
        'placeholder:text-[14px] sm:placeholder:text-base transition-colors duration-150 focus:placeholder:!text-gray-700/60',
        className,
        controlledFocus ? 'placeholder:!text-gray-700/60' : 'placeholder:text-transparent',
      )}
      {...props}
    />
  ),
);

export interface DOBInputProps {
  controlledFocus: boolean;
  disabled?: boolean;
  value?: string;
  setValue: (val: string) => void;
  fieldName: string;
  onFocus: (event: any) => void;
  onBlur: (event: any) => void;
  onChange: (event?: any) => void;
  nextID?: string;
  prevID?: string;
}

export const DayInput = ({
  fieldName,
  onBlur,
  onChange,
  onFocus,
  value,
  setValue,
  controlledFocus,
  nextID,
  prevID,
  disabled,
}: DOBInputProps) => {
  const focusable = nextID ? document.getElementById(nextID) : undefined;
  const returnable = prevID ? document.getElementById(prevID) : undefined;

  return (
    <FieldInput
      name={`${fieldName}.day`}
      inputMode="tel"
      id="day"
      placeholder="Zi"
      onBlur={event => {
        const val = event?.target?.value;
        if (val.length === 1) {
          event.target.value = '0' + val;
          onChange(event);
        }
        onBlur(event);
      }}
      onKeyDown={keyboardEvent => {
        if (keyboardEvent.code === 'Backspace' && !Boolean(value) && returnable) {
          returnable.focus();
        }
        if (keyboardEvent.code === 'Tab' && Boolean(value) && value?.length === 1 && focusable) {
          setValue('0' + value);
        }
      }}
      onChange={event => {
        const val = event?.target?.value;
        const firstChar = parseInt(val.charAt(0));
        if (parseInt(val) > 31) {
          event.target.value = '31';
          onChange(event);
          if (event?.target?.value.length === 2) {
            focusable?.focus();
          }
        } else {
          if (firstChar > 3) {
            event.target.value = '0' + firstChar;
            focusable?.focus();
            onChange(event);
          } else if (val.length === 2) {
            focusable?.focus();
            onChange(event);
          } else {
            onChange(event);
          }
        }
      }}
      onFocus={onFocus}
      value={value}
      controlledFocus={controlledFocus}
      onKeyPress={event => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      disabled={disabled}
    />
  );
};

export const MonthInput = ({
  fieldName,
  onBlur,
  onChange,
  onFocus,
  value,
  setValue,
  controlledFocus,
  nextID,
  prevID,
  disabled,
}: DOBInputProps) => {
  const focusable = nextID ? document.getElementById(nextID) : undefined;
  const returnable = prevID ? document.getElementById(prevID) : undefined;
  return (
    <FieldInput
      name={`${fieldName}.month`}
      inputMode="tel"
      id="month"
      placeholder="Lună"
      onBlur={event => {
        const val = event?.target?.value;
        if (val.length === 1) {
          event.target.value = '0' + val;
          onChange(event);
        }
        onBlur(event);
      }}
      onKeyDown={keyboardEvent => {
        if (keyboardEvent.code === 'Backspace' && !Boolean(value) && returnable) {
          returnable.focus();
        }
        if (keyboardEvent.code === 'Tab' && Boolean(value) && value?.length === 1) {
          setValue('0' + value);
        }
      }}
      onChange={event => {
        const val = event?.target?.value;
        const firstChar = parseInt(val.charAt(0));
        if (parseInt(val) > 12) {
          event.target.value = '12';
          onChange(event);
        } else {
          if (firstChar > 1) {
            event.target.value = '0' + firstChar;
            focusable?.focus();
          } else if (val.length === 2) {
            focusable?.focus();
          }
          onChange(event);
        }
      }}
      onFocus={onFocus}
      value={value}
      controlledFocus={controlledFocus}
      disabled={disabled}
    />
  );
};

export const YearInput = ({
  fieldName,
  onBlur,
  onChange,
  onFocus,
  value,
  controlledFocus,
  nextID,
  prevID,
  disabled,
}: Omit<DOBInputProps, 'setValue'>) => {
  const focusable = nextID ? document.getElementById(nextID) : undefined;
  const returnable = prevID ? document.getElementById(prevID) : undefined;
  return (
    <FieldInput
      name={`${fieldName}.year`}
      inputMode="tel"
      id="year"
      placeholder="An"
      onBlur={onBlur}
      onKeyDown={keyboardEvent => {
        if (keyboardEvent.code === 'Backspace' && !Boolean(value) && returnable) {
          returnable.focus();
        }
      }}
      onChange={event => {
        const val = event?.target?.value;
        const centuries = parseInt(val.slice(0, 2));
        const current = new Date().getFullYear();
        if (val.length === 1 && parseInt(val) === 0) {
          event.target.value = '20' + val;
          onChange(event);
        } else if (val.length === 1 && parseInt(val) > 2) {
          event.target.value = '19' + val;
          onChange(event);
        } else if (val.length > 1 && centuries < 19) {
          event.target.value = '19';
          onChange(event);
        } else if (val.length > 1 && centuries > 20) {
          event.target.value = '20';
          onChange(event);
        } else if (val.length > 3 && parseInt(val) > current) {
          event.target.value = current.toString();
          focusable?.focus();
          onChange(event);
        } else if (val.length > 3 && parseInt(val) < current - 100) {
          event.target.value = (current - 100).toString();
          focusable?.focus();
          onChange(event);
        } else if (val.length === 4) {
          onChange(event);
          focusable?.focus();
        } else {
          onChange(event);
        }
      }}
      onFocus={onFocus}
      value={value}
      controlledFocus={controlledFocus}
      disabled={disabled}
    />
  );
};

export const Separator = ({ separator }: { separator?: string }) => (
  <div className="flex items-end justify-center pb-[3px] text-brand-red">
    {separator ? separator : '/'}
  </div>
);
