import React from 'react';

import { ToastContainer as ReactToastifyContainer } from 'react-toastify';
import styled from 'styled-components/macro';

const StyledToastContainer = styled(ReactToastifyContainer)`
  padding: 0;
  width: calc(100% - 2rem);
  // bottom-selector is also set in globalstyles based on body class
  left: 1rem;
  bottom: calc(64px + 1rem + env(safe-area-inset-bottom));
  z-index: 22;

  @media (min-width: 480px) {
    min-width: 260px;
    max-width: 400px;
    left: 50%;
    bottom: calc(64px + env(safe-area-inset-bottom));
  }

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    bottom: 1rem;
  }

  .Toastify {
    &__toast {
      font-family: 'Work Sans', sans-serif;
      line-height: 1.4;
      padding: 1rem 2rem 1rem calc(1rem + 5px);
      font-size: 0.9rem;
      color: ${props => props.theme.black};
      background: ${props => props.theme.white};
      cursor: default;
      position: relative;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 100;
      border-radius: 0.5rem;
      margin-top: 1rem;

      &:before {
        content: '';
        display: block;
        width: 5px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: ${props => props.theme.yellow};
      }

      &--warning {
        cursor: pointer;

        &:before {
          background: ${props => props.theme.red};
        }
      }

      &--success {
        z-index: 9999;

        &:before {
          background: ${props => props.theme.mint};
        }
      }
    }

    &__toast-body {
      p {
        margin-bottom: 0;
      }

      a,
      button {
        text-decoration: underline;
        font-size: 1em;
      }
    }

    &__close-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem;
      opacity: 0.7;
      color: ${props => props.theme.black};

      svg {
        height: 15px;
        width: 15px;
      }
    }

    &__progress-bar {
      height: 2px;
      left: 5px;
      width: calc(100% - 5px);
      background: rgba(255, 255, 255, 0.7);
    }
  }
`;

function ToastContainer() {
  return <StyledToastContainer />;
}

export default ToastContainer;
