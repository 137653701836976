import * as React from 'react';

import LazyLoad from 'react-lazyload';

import { GameCardVM } from '@/models/game-card-vm.model';
import GameCard from '@/pages/games/GameCard';
import { StyledGameList } from '@/pages/games/GameList';
import { isEmpty } from '@/utils/helpers';

function SearchGameList({
  games,
  searchString,
}: {
  games: GameCardVM[];
  searchString: string;
}): JSX.Element | null {
  return (
    <StyledGameList>
      <div className="header">
        <h1>Caută: {searchString}</h1>
        {!isEmpty(searchString) && <span>{games.length} jocuri</span>}
      </div>
      <div className="games">
        {!isEmpty(games) ? (
          games.map((game, index) => (
            <LazyLoad key={game.gameId} offset={400}>
              <GameCard game={game} position={index + 1} category="Search" />
            </LazyLoad>
          ))
        ) : (
          <div className="no-games-found">Niciun joc găsit</div>
        )}
      </div>
    </StyledGameList>
  );
}

export default SearchGameList;
