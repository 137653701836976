import React from 'react';

import { CheckIcon } from '@heroicons/react/24/solid';

import LigaPrizeWheelIcon from '@/components/icons/LigaPrizeWheelIcon';
import { LigaLevelStatus } from '@/models/liga.model';

const LigaPrizeWheelBtn = ({
  status,
  isAuthenticated,
  onClick,
  nextTarget,
}: {
  status: LigaLevelStatus;
  isAuthenticated: boolean;
  onClick: () => void;
  nextTarget: boolean;
}): JSX.Element => {
  const classForStatus = (status: LigaLevelStatus) => {
    switch (status) {
      case LigaLevelStatus.Claimed:
        return 'bg-gray-400 shadow-lg opacity-80';
      case LigaLevelStatus.Eligible:
        return 'bg-brand-gold shadow-[0_8px_10px_2px_rgba(255,255,255,0.4)]';
      case LigaLevelStatus.Ineligible:
        if (nextTarget) {
          return 'bg-brand-gold shadow-lg';
        }
        return 'bg-brand-gold opacity-80 shadow-lg';
    }
  };

  return (
    <button
      type="button"
      className={`${classForStatus(status)} relative p-2 rounded-lg ${
        isAuthenticated ? 'opacity-100' : 'opacity-80'
      }`}
      onClick={onClick}
    >
      <LigaPrizeWheelIcon
        className={`w-12 h-12 fill-white ${
          status === LigaLevelStatus.Eligible ? 'animate-bounce' : ''
        }`}
      />
      {status === LigaLevelStatus.Claimed && (
        <div className="border-2 absolute right-2.5 bottom-2.5 rounded-full border-white bg-gray-400">
          <CheckIcon className="w-4 h-4" />
        </div>
      )}
    </button>
  );
};

export default LigaPrizeWheelBtn;
