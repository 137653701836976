import React, { ReactNode } from 'react';

import classNames from 'classnames';

interface ITooltipProps {
  position: 'top' | 'right' | 'bottom' | 'left' | 'bottom-left' | 'top-left';
  text: string | ReactNode;
  className?: string;
  children: ReactNode;
}

const Tooltip = ({ position, text, className, children }: ITooltipProps) => {
  return (
    <div className="z-99 relative inline-block">
      <div className="peer">{children}</div>
      <div
        className={classNames(
          `absolute z-20 w-max rounded bg-black py-1.5 px-2.5 text-xs
          font-medium text-white transition ease-in opacity-0 peer-hover:opacity-100 pointer-events-none`,
          { 'bottom-full left-1/2 mb-3 -translate-x-1/2': position === 'top' },
          { 'left-full top-1/2 ml-3 -translate-y-1/2': position === 'right' },
          { 'top-full left-1/2 mt-3 -translate-x-1/2': position === 'bottom' },
          { 'right-full top-1/2 mr-3 -translate-y-1/2': position === 'left' },
          { 'right-full top-0 mr-3 -translate-y-7': position === 'bottom-left' },
          { 'right-full bottom-0 mr-3 translate-y-7': position === 'top-left' },
          className,
        )}
      >
        <span
          className={classNames(
            'translate absolute z-10 h-2 w-2 rotate-45 rounded-sm bg-black',
            { 'bottom-[-3px] left-1/2 -translate-x-1/2': position === 'top' },
            { 'left-[-3px] top-1/2 -translate-y-1/2': position === 'right' },
            { 'top-[-3px] left-1/2 -translate-x-1/2': position === 'bottom' },
            { 'right-[-3px] top-1/2 -translate-y-1/2': position === 'left' },
            { 'right-[-3px] top-0 translate-y-8': position === 'bottom-left' },
            { 'right-[-3px] bottom-0 -translate-y-8': position === 'top-left' },
          )}
        />
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
