import * as React from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@/components/button/Button';
import ToggleSwitch from '@/components/form/ToggleSwitch';
import useApiV2Request from '@/hooks/useApiV2Request';
import ApiService from '@/shared/api-service';
import { getConsents } from '@/store/actions/consentsActions';
import {
  selectIsEmailConsented,
  selectIsTextMessageConsented,
  selectSmsEmailConsents,
} from '@/store/selectors/consentsSelectors';

const StyledOffersAndPromotions = styled.div``;

interface FormValues {
  allowEmail: boolean;
  allowSMS: boolean;
}

function OffersAndPromotions(): JSX.Element {
  const { request, error, loading, success } = useApiV2Request();
  const isSmsConsented = useSelector(selectIsTextMessageConsented);
  const isEmailConsented = useSelector(selectIsEmailConsented);
  const consents = useSelector(selectSmsEmailConsents);
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    allowEmail: !!isEmailConsented,
    allowSMS: !!isSmsConsented,
  };

  function submitHandler({ allowEmail, allowSMS }: FormValues) {
    const updatedConsents = [...consents].map(consent => {
      return {
        ConsentId: consent.ConsentId,
        Version: consent.Version,
        Consented: consent.Name === 'TEXT_MESSAGE' ? allowSMS : allowEmail,
      };
    });

    request(ApiService.saveConsents, updatedConsents, () => {
      dispatch(getConsents());
    });
  }

  return (
    <StyledOffersAndPromotions>
      <h2>Accept oferte și promoții</h2>
      <Formik
        enableReinitialize // this line makes it so initialValues is updating :)
        initialValues={initialValues}
        onSubmit={submitHandler}
        validateOnMount
      >
        {({ dirty }) => (
          <Form>
            <Field name="allowEmail">
              {({ field }: FieldProps) => (
                <ToggleSwitch
                  id="allowEmail"
                  label="Email"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            </Field>
            <Field name="allowSMS">
              {({ field }: FieldProps) => (
                <ToggleSwitch
                  id="allowSMS"
                  label="SMS"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            </Field>
            {error && <p className="error">Error: {error.error}</p>}
            {success && <p className="success">Detalii Salvate!</p>}
            <div className="button-wrapper">
              <Button type="submit" className="submit" disabled={!dirty || loading}>
                {loading ? 'Se încarcă...' : 'Salvează setările'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </StyledOffersAndPromotions>
  );
}

export default OffersAndPromotions;
