import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/button/Button';
import ParsedString from '@/components/formats/ParsedString';
import { useCashierShow } from '@/hooks/useCashier';
import { WpPromotion } from '@/models/wp-promotion.model';
import { openLoginModal, openTermsModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';

interface IPromotionCard extends WpPromotion {
  focused?: boolean;
}

const PromotionCard = ({
  header,
  bannerUrl,
  headline,
  description,
  ctaText,
  ctaLink,
  terms,
  focused,
}: IPromotionCard): JSX.Element => {
  const dispatch = useDispatch();
  const showCashier = useCashierShow();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);

  const handleOnCtaClick = () => {
    if (!isAuthenticated) {
      dispatch(openLoginModal());
    } else {
      if (ctaLink) {
        // link is absolute path
        if (ctaLink.startsWith('http')) {
          window.location.href = ctaLink;
        } else {
          // link is relative path
          navigate(ctaLink);
        }
      } else {
        showCashier();
      }
    }
  };

  const handleOnSecondaryClick = () => {
    dispatch(
      openTermsModal({
        terms: terms,
      }),
    );
  };

  return (
    <div className="relative flex w-full flex-col overflow-hidden rounded-lg bg-white shadow-xl sm:rounded-tl-none">
      {header && (
        <div
          title={header}
          className="z-[2] truncate rounded-tr-lg rounded-tl-lg bg-[#D9AA68] py-1 px-2 text-center text-sm font-bold uppercase text-white sm:absolute sm:top-0 sm:right-0 sm:max-w-[520px] sm:rounded-tl-none sm:rounded-bl-lg sm:px-8 sm:text-base sm:shadow-[-5px_5px_30px_#D9AA68]"
        >
          {header}
        </div>
      )}
      <div className="relative w-full overflow-hidden sm:rounded-lg">
        <div className="pointer-events-none absolute left-0 top-0 right-0 bottom-0 flex flex-col justify-center pl-6 pb-6">
          <span
            className="text-lg font-bold text-white line-clamp-2 sm:pt-16 sm:text-2xl"
            title={headline}
          >
            <ParsedString string={headline} />
          </span>
          <div className="mt-auto hidden items-center gap-5 sm:flex">
            <Button
              onClick={handleOnCtaClick}
              className="!block max-w-[200px] !justify-start overflow-hidden overflow-ellipsis whitespace-nowrap sm:pointer-events-none"
            >
              {ctaText}
            </Button>
            <button
              type="button"
              className="pointer-events-auto text-sm font-normal text-white underline"
              onClick={handleOnSecondaryClick}
            >
              Vezi toți T&C
            </button>
          </div>
        </div>
        <img
          src={`https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=auto,height=auto,gravity=1x0,format=auto${bannerUrl}`}
          alt=""
          onClick={handleOnCtaClick}
          className="pointer-events-none h-[140px] w-full cursor-pointer rounded-bl-lg rounded-br-lg object-cover sm:pointer-events-auto sm:h-[275px]"
        />
      </div>
      <div
        className={`${
          focused ? 'bg-[#861810] sm:bg-white' : 'bg-white'
        } -mt-4 flex w-full flex-col items-center gap-5 p-6 pt-10`}
      >
        <span
          className={`${
            focused ? 'text-white sm:text-[#173033]' : 'text-[#173033]'
          } self-start text-base font-light leading-[22px] sm:text-sm`}
        >
          {description}
        </span>
        <div className="flex w-full flex-col items-center gap-5 sm:hidden">
          <Button
            className="!block w-full !justify-start overflow-hidden overflow-ellipsis whitespace-nowrap"
            onClick={handleOnCtaClick}
          >
            {ctaText}
          </Button>
          <button
            type="button"
            className={`${
              focused ? 'text-white' : 'text-[#173033]'
            } w-full text-sm font-normal underline`}
            onClick={handleOnSecondaryClick}
          >
            Vezi toți T&C
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromotionCard;
