import React from 'react';

import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet';
import { NavLink, useNavigate } from 'react-router-dom';

import Button from '@/components/button/Button';
import PageHeader from '@/components/header/PageHeader';
import { GAME_PROVIDERS } from '@/constants/constants';
import GameCategoryNav from '@/pages/games/GameCategoryNav';
import { NO_HOVER_INVERT_LOGOS } from '@/pages/games/ProviderCategoryNav';
import { gameProviderImg } from '@/utils/image-utils';

const GameProviders = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <body className="header-background overflow-x-hidden" />
      </Helmet>
      <PageHeader>
        <h1>Furnizorii de Jocuri</h1>
      </PageHeader>
      <GameCategoryNav />
      <div className="pt-6 sm:pt-12">
        <div className="mx-auto grid grid-cols-2 gap-2 overflow-y-auto p-4 sm:max-w-[960px] sm:grid-flow-row sm:grid-cols-[repeat(3,1fr)] md:grid-cols-[repeat(4,1fr)] lg:grid-cols-[repeat(5,1fr)]">
          {GAME_PROVIDERS.map(provider => (
            <div className="w-full" key={provider}>
              <NavLink
                className="group block rounded-sm bg-zinc-100 hover:bg-red-gradient"
                end
                to={`/jocuri/${provider}`}
                id={provider}
              >
                <span className="flex h-[100px] w-full items-center justify-center transition-all duration-200 ease-in-out">
                  <img
                    src={`${gameProviderImg(provider)}`}
                    alt={provider}
                    className={`max-h-[60px] max-w-[100px] grayscale ${
                      NO_HOVER_INVERT_LOGOS.some(providerId => providerId.includes(provider))
                        ? ''
                        : 'group-hover:brightness-0 group-hover:invert'
                    }`}
                  />
                </span>
              </NavLink>
              <div className="mt-1 mb-2 text-center text-sm">{provider}</div>
            </div>
          ))}
        </div>
        <div className="flex justify-center py-12">
          <Button onClick={() => navigate('/jocuri')}>
            <ArrowLeftIcon /> Casino Lobby
          </Button>
        </div>
      </div>
    </>
  );
};

export default GameProviders;
