import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';

import cash from '@/images/cash.png';
import mastercard from '@/images/mastercard.svg';
import neteller from '@/images/neteller.svg';
import paysafecard from '@/images/paysafecard.svg';
import skrill from '@/images/skrill.svg';
import visa from '@/images/visa.svg';

const StyledPaymentMethods = styled.div`
  .content {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    padding: 86px 0 0;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 148px 32px;
    }
    .wrapper {
      padding: 86px 32px 32px;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        padding: 0;
      }
    }

    .buttons {
      button {
        padding: 16px 32px;
        font-weight: 700;
        border-bottom: 4px solid ${props => props.theme.black};

        &.active {
          color: ${props => props.theme.red};
          border-color: ${props => props.theme.red};
        }
      }
    }
  }

  table {
    width: 100%;
    margin-top: 32px;
    overflow: hidden;

    img {
      max-width: 100px;
      max-height: 24px;
      height: auto;
      width: 100%;
      display: block;
      margin: 8px 0;
    }

    tr {
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: grid;
      padding: 1rem;
      @media (min-width: ${props => props.theme.breakpoints.small}) {
        display: table-row;
        padding: 0;
      }
      &:nth-child(odd) {
        background: ${props => props.theme.offWhite};
      }
    }

    td {
      padding: 24px;
      line-height: 1.2;
      vertical-align: middle;
      &.images {
        padding: 8px;
        &.paysafe,
        &.neteller {
          img {
            max-width: 160px;
            @media (min-width: ${props => props.theme.breakpoints.small}) {
              max-width: 100px;
            }
          }
        }
        &.skrill {
          img {
            @media (min-width: ${props => props.theme.breakpoints.small}) {
              max-width: 75px;
            }
          }
        }
        @media (min-width: ${props => props.theme.breakpoints.small}) {
          padding: 24px;
        }
      }
    }

    small {
      font-size: 0.8em;
    }

    &.center {
      text-align: center;
    }

    a {
      font-weight: 700;
      font-size: 1em;
    }

    th {
      font-weight: 700;
      background: #eee;
    }
  }

  .info {
    font-size: 14px;

    p {
      max-width: 720px;
    }
  }
`;

function PaymentMethods(): JSX.Element {
  const [type, setType] = useState('deposit');

  return (
    <StyledPaymentMethods>
      <Helmet>
        <body className="header-background" />
      </Helmet>
      <div className="content">
        <div className="wrapper">
          <h1 className="h1">Metode de Plată</h1>
          <div className="buttons">
            <button
              type="button"
              className={type === 'deposit' ? 'active' : ''}
              onClick={() => setType('deposit')}
            >
              Depunere
            </button>
            <button
              type="button"
              className={type === 'withdrawal' ? 'active' : ''}
              onClick={() => setType('withdrawal')}
            >
              Retragere
            </button>
          </div>
        </div>
        {type === 'deposit' ? (
          <table>
            <tbody>
              <tr>
                <td className="images">
                  <img src={visa} alt="Visa" />
                  <img src={mastercard} alt="Mastercard" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Instant
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  2%
                </td>
                <td>
                  <small>Depunere Minimă</small> <br />
                  50 RON
                </td>
                <td>
                  <small>Depunere maximă (Cont Verificat)</small> <br />
                  5.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Visa şi MasterCard sunt branduri recunoscute la nivel mondial care folosesc cele
                    mai noi tehnologii de securitate și de plată, sunt acceptate de mai mult de 24
                    de milioane de comercianți și oferă o gamă largă de instrumente de prevenire a
                    fraudei. Folosiți cardul de debit/credit pentru depuneri instante!
                  </p>
                </td>
              </tr>
              <tr>
                <td className="images paysafe">
                  <img src={paysafecard} alt="Paysafe card" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Instant
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  2%
                </td>
                <td>
                  <small>Depunere Minimă</small> <br />
                  50 RON
                </td>
                <td>
                  <small>Depunere maximă (Cont Verificat)</small> <br />
                  5.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Paysafecard este liderul cardurilor de plată preplătite pentru plăți pe
                    internet. Transferul de fonduri în contul dvs. se va realiza instant. Rețineți!
                    Depunerea cu Paysafecart este posibilă numai accesând pagina “My Pasafecard”.
                    Acest serviciu vă permite să efectuați plăți rapid și ușor! Deschideți acum un
                    cont gratuit sau cumpărați un COD PIN de la orce punct de vânzare. Pentru mai
                    multe detalii, vizitați link-ul:{' '}
                    <a href="https://my.paysafecard.com" target="_blank" rel="noopener noreferrer">
                      https://my.paysafecard.com
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="images skrill">
                  <img src={skrill} alt="Skrill" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Instant
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  2%
                </td>
                <td>
                  <small>Depunere Minimă</small> <br />
                  50 RON
                </td>
                <td>
                  <small>Depunere maximă (Cont Verificat)</small> <br />
                  5.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Skrill (Moneybookers) este un portofel electronic care vă permite să efectuați
                    tranzacții sigure și rapide (depuneri, retrageri și transferuri de bani online).
                    Puteți să alimentați contul Skrill printr-un card de debit / credit, prin
                    transfer bancar online, prin direct banking sau prin cec. Transferul de fonduri
                    în contul dvs. are loc instant, atât timp cât contul dvs. Skrill este alimentat
                    cu fonduri.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="images neteller">
                  <img src={neteller} alt="Neteller" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Instant
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  2%
                </td>
                <td>
                  <small>Depunere Minimă</small> <br />
                  50 RON
                </td>
                <td>
                  <small>Depunere maximă (Cont Verificat)</small> <br />
                  5.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Neteller este un portofel electronic care vă permite să efectuați electronic
                    depuneri, retrageri și transferuri de fonduri. Neteller oferă plăți instante și
                    transferuri directe către / de la site-urile comercianților și între deținătorii
                    de cont Neteller. Alimentați contul dvs. la toate modalitățile plăți de plată,
                    cum ar fi Visa, MasterCard, Maestro, transferuri bancare internaționale și
                    locale.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <img style={{ maxHeight: '60px', width: 'auto' }} src={cash} alt="Cash" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Instant
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  2%
                </td>
                <td>
                  <small>Depunere Minimă</small> <br />
                  50 RON
                </td>
                <td>
                  <small>Depunere maximă (Cont Verificat)</small> <br />
                  5.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Poți depune bani rapid și în siguranță în agențiile Superbet. Ai nevoie doar de
                    un cont de utilizator pe{' '}
                    <a href="https://magicjackpot.ro" className="text-blue-500 underline">
                      magicjackpot.ro
                    </a>{' '}
                    și de un act de identitate. Depunerile în agenții se fac instant, iar
                    retragerile sunt programate pe site și se ridică de la orice agenție Superbet în
                    24 de ore de la aprobarea lor de către echipa noastră de plăți. Conform OUG
                    114/2018, se percepe un comision de administrare (CDA) în valoare de 2% din
                    fiecare depunere.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table>
            <tbody>
              <tr>
                <td className="images">
                  <img src={visa} alt="Visa" />
                  <img src={mastercard} alt="Mastercard" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Între 3 şi 5 zile lucrătoare
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  3%
                </td>
                <td>
                  <small>Retragere Minimă</small> <br />
                  100 RON
                </td>
                <td>
                  <small>Retragere maximă</small> <br />
                  10.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Carduri de credit / debit: Puteți solicita o retragere pe cardul dvs. Visa /
                    Master Card dacă ați efectuat cu succes o depunere în ultimele 180 de zile și
                    ați verificat detaliile despre proprietarul cardului. Fondurile sunt creditate
                    direct pe cardul dvs. în timp de 3-5 zile lucrătoare.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="images skrill">
                  <img src={skrill} alt="Skrill" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Instant
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  3%
                </td>
                <td>
                  <small>Retragere Minimă</small> <br />
                  100 RON
                </td>
                <td>
                  <small>Retragere maximă</small> <br />
                  10.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Skrill (Moneybookers): Puteți retrage fonduri în contul dvs. Skrill dacă ați
                    efectuat anterior o depunere cu succes. Transferul de fonduri în contul dvs. se
                    procesează instant.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="images neteller">
                  <img src={neteller} alt="Neteller" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Instant
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  3%
                </td>
                <td>
                  <small>Retragere Minimă</small> <br />
                  100 RON
                </td>
                <td>
                  <small>Retragere maximă (Cont Verificat)</small> <br />
                  10.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Neteller: Puteți să optați pentru retragerea fondurilor în contul dvs. Neteller,
                    dacă ați efectuat anterior o depunere cu succes. Transferul de fonduri în contul
                    dvs. se procesează instant.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <img style={{ maxHeight: '60px', width: 'auto' }} src={cash} alt="Cash" />
                </td>
                <td>
                  <small>Durata Tranzactiei</small> <br />
                  Între 3 şi 5 zile lucrătoare
                </td>
                <td>
                  <small>Taxa pentru Tranzacţie</small> <br />
                  3%
                </td>
                <td>
                  <small>Depunere Minimă</small> <br />
                  50 RON
                </td>
                <td>
                  <small>Depunere maximă (Cont Verificat)</small> <br />
                  1.000 RON
                </td>
              </tr>
              <tr className="info">
                <td colSpan={5}>
                  <p>
                    Odată ce o retragere de numerar este solicitată prin casieria site-ului nostru,
                    veți fi notificat imediat ce echipa noastră de plăți va procesa cererea. Banii
                    sunt ridicați a doua zi de la orice agenție Superbet, cu excepția sărbătorilor
                    legale. Retragerile sunt limitate la una pe zi, pentru fiecare membru
                    înregistrat!
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </StyledPaymentMethods>
  );
}

export default PaymentMethods;
