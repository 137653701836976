import * as React from 'react';

import styled from 'styled-components/macro';

export const StyledAnchor = styled.button`
  display: inline;
  border: none;
  background: none;
  text-decoration: underline;
  color: inherit;
  padding: 0;
  margin: 0;
  line-height: 1.5;
`;

function Anchor({
  children,
  onClick,
  className,
}: {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}): JSX.Element {
  return (
    <StyledAnchor type="button" onClick={onClick} className={className}>
      {children}
    </StyledAnchor>
  );
}

export default Anchor;
