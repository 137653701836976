import { useEffect, useState } from 'react';

import axios from 'axios';

import { WP_URL } from '@/constants/constants';
import { doNothing } from '@/utils/helpers';

function useHeaderMessage(): {
  headerMessage: string | null;
  closeHeaderMessage: () => void;
} {
  const [headerMessage, setHeaderMessage] = useState<string | null>(null);
  // This hook takes a message from WordPress and displays it as a bar above the header to show the user in case of maintenance and such.
  useEffect(() => {
    axios
      .get(`${WP_URL}/header_message`)
      .then(response => {
        if (response?.data?.text) {
          setHeaderMessage(response.data.text);
        }
      })
      .catch(() => doNothing());
  }, []);

  const closeHeaderMessage = () => {
    setHeaderMessage(null);
  };

  return { headerMessage, closeHeaderMessage };
}

export default useHeaderMessage;
