import { Action } from 'redux';

import { CommonDrawerTypes } from '@/models/enums/drawer.enum';

export enum DrawerActionTypes {
  Open = 'OPEN_DRAWER',
  Close = 'CLOSE_DRAWER',
}

export type OpenDrawerAction = Action<DrawerActionTypes.Open> & {
  name: CommonDrawerTypes;
  props: unknown;
};
export type CloseDrawerAction = Action<DrawerActionTypes.Close>;

export type DrawerActions = OpenDrawerAction | CloseDrawerAction;

export const openDrawer = (name: CommonDrawerTypes, props?: unknown): OpenDrawerAction => ({
  type: DrawerActionTypes.Open,
  name,
  props,
});

export const closeDrawer = (): CloseDrawerAction => ({
  type: DrawerActionTypes.Close,
});
