import { AxiosResponse } from 'axios';

import { WpError } from '@/models/wp-error.model';

export const isAxiosResponse = (response: unknown): response is AxiosResponse => {
  return !!(response as AxiosResponse).data;
};

export const isWpError = (response: unknown): response is WpError => {
  const r = response as WpError;
  return !!(r.code && r.message && r.data && r.data.status);
};
