import React from 'react';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { isDecember } from '@/utils/helpers';

function GameCategoryNav(): JSX.Element {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);

  const NAV_ITEMS = [
    {
      to: '/jocuri',
      id: 'Casino Lobby',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Casino Lobby',
        }),
      isDisplayed: true,
    },
    {
      to: 'ultimul-jucat',
      id: 'Ultimul jucat',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Ultimul jucat',
        }),
      isDisplayed: isAuthenticated,
    },
    {
      to: '/jocuri/jocuri-de-iarna',
      id: 'Jocuri de iarnă',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Jocuri de iarnă',
        }),
      isDisplayed: isDecember(),
    },
    {
      to: '/jocuri/recomandate',
      id: 'Recomandate',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Recomandate',
        }),
      isDisplayed: true,
    },
    {
      to: '/jocuri/jocuri-noi',
      id: 'Jocuri noi',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Jocuri noi',
        }),
      isDisplayed: true,
    },
    {
      to: '/jocuri/cazinou-live',
      id: 'Cazinou Live',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Cazinou Live',
        }),
      isDisplayed: true,
    },
    {
      to: '/jocuri/sloturi',
      id: 'Sloturi',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Sloturi',
        }),
      isDisplayed: true,
    },
    {
      to: '/jocuri/jackpoturi',
      id: 'Jackpoturi',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Jackpoturi',
        }),
      isDisplayed: true,
    },
    {
      to: '/jocuri/jocuri-de-masa',
      id: 'Jocuri de masă',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Jocuri de masă',
        }),
      isDisplayed: true,
    },
    {
      to: '/jocuri/toate-jocurile',
      id: 'Toate jocurile',
      onClick: () =>
        triggerGTag(GTagEvents.open_category, {
          openFrom: 'Game lobby',
          categoryName: 'Toate jocurile',
        }),
      isDisplayed: true,
    },
  ];

  return (
    <nav className="sticky top-16 z-[2] flex h-[52px] justify-center bg-white sm:top-[72px]">
      <ul className="flex w-full max-w-[calc(1400px-64px)] items-center justify-between overflow-x-scroll shadow-[0px_-4px_0px_0px_inset] scrollbar-hide">
        {NAV_ITEMS.map(
          item =>
            item.isDisplayed && (
              <li className="inline-block h-full" key={item.id}>
                <NavLink
                  end
                  to={item.to}
                  id={item.id}
                  onClick={item.onClick}
                  className={({ isActive }) =>
                    `relative flex h-full items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap px-4 text-center text-sm font-[600] sm:text-base ${
                      isActive &&
                      'text-brand-red after:absolute after:bottom-0 after:left-0 after:right-0 after:h-1 after:w-full after:bg-brand-red after:transition-transform after:duration-200 after:ease-in-out'
                    }`
                  }
                >
                  {item.id}
                </NavLink>
              </li>
            ),
        )}
      </ul>
    </nav>
  );
}

export default GameCategoryNav;
