import { UNKNOWN_ERROR } from '@/constants/internal-error-messages';

export function getGigErrorTranslation(errorCodeId: number): string {
  switch (errorCodeId) {
    case 1:
      return 'Username și / sau parola este greșită';
    case 17:
      return 'Contul este închis. Te rugăm să încerci din nou sau să contactezi serviciul de suport clienți.';
    default:
      return UNKNOWN_ERROR;
  }
}
