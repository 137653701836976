import React, { useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

import Field, { FieldProps } from '../field/Field';

interface PasswordFieldProps extends Omit<FieldProps, 'type'> {
  iconClassName?: string;
}

const PasswordField = ({ iconClassName, ...props }: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Field
      type={showPassword ? 'text' : 'password'}
      endIcon={
        showPassword ? (
          <EyeSlashIcon className={iconClassName} />
        ) : (
          <EyeIcon className={iconClassName} />
        )
      }
      endIconAction={() => setShowPassword(!showPassword)}
      {...props}
    />
  );
};

export default PasswordField;
