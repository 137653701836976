export enum DocumentTypeEnum {
  Id = 1,
  ProofOfAddress = 2,
  PaymentMethod = 3,
  SourceOfWealthQuestionnaire = 5,
  SourceOfWealthSupportingDocument = 6,
  SourceOfFunds = 7,
  SocialSecurityNumber = 8,
  KycApproval = 4,
}
