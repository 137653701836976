import * as React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import GameList from './GameList';
import NoGames from '@/pages/games/NoGames';
import { selectGamesByProvider } from '@/store/selectors/gamesSelectors';

function GamesByProvider(): JSX.Element {
  const { provider } = useParams<{ provider: string }>();
  const selectedProvider = useSelector(selectGamesByProvider(provider || ''));

  return (
    <>
      {selectedProvider ? (
        <GameList games={selectedProvider.games} header={selectedProvider.name} />
      ) : (
        <NoGames text="Niciun joc găsit" />
      )}
    </>
  );
}

export default GamesByProvider;
