import React, { ReactElement } from 'react';

import { Formik, Form as FormikForm, FormikProps, FormikConfig, FormikHelpers } from 'formik';

interface FormProps<T> extends FormikConfig<T> {
  className?: string;
  children: ((props: FormikProps<T>) => ReactElement) | ReactElement;
  onSubmit: (props: T, helpers: FormikHelpers<T>) => void;
  initialValues: T;
  noValidate?: boolean;
}

const Form = <T,>({ children, className, noValidate, ...props }: FormProps<T>) => (
  <Formik
    {...props}
    children={props => (
      <FormikForm className={className} noValidate={noValidate}>
        {typeof children === 'function' ? children({ ...props }) : children}
      </FormikForm>
    )}
  />
);
export default Form;
