import React from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';

const StyledAntiMoneyLaundering = styled.div`
  position: relative;

  .content {
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
    padding: 86px 0 0;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 148px 32px;
    }
    .wrapper {
      padding: 86px 32px 32px;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        padding: 0;
      }
    }

    h2,
    h3,
    h4 {
      display: inline-block;
      font-size: 24px;
      margin-bottom: 16px;
      margin-top: 32px;
    }

    ul {
      list-style: disc;
      padding-left: 1em;

      li {
        line-height: 1.5;
        margin-bottom: 1em;
      }
    }
  }
  table {
    width: 100%;
    margin-top: 32px;
    overflow: hidden;

    tr {
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: grid;
      padding: 1rem;
      @media (min-width: ${props => props.theme.breakpoints.small}) {
        display: table-row;
        padding: 0;
      }
      &:nth-child(odd) {
        background: ${props => props.theme.offWhite};
      }
    }

    td {
      padding: 24px;
      line-height: 1.2;
      vertical-align: middle;
    }
  }
`;

function AntiMoneyLaundering() {
  return (
    <StyledAntiMoneyLaundering>
      <Helmet>
        <body className="header-background" />
      </Helmet>
      <div className="content">
        <div className="wrapper">
          <h1>Politica împotriva spălării banilor</h1>
          <p>
            MJP Interactive aplică în mod permanent proceduri de prevenire a spălării banilor, de
            detectare și raportare a oricărui incident, inclusiv a tranzacțiilor suspecte, către
            autorități, în conformitate cu reglementările aplicabile privind spălarea banilor.
          </p>
          <p>
            MJP Interactive poate să suspende, să blocheze sau să închidă orice cont de jucător și
            să rețină fondurile din aceste conturi, dacă este necesar, în conformitate cu legislația
            aplicabilă spălării banilor.
          </p>
          <p>
            Pentru legislația împotriva spălării banilor, Clientul declară și garantează
            următoarele:
          </p>
          <ul>
            <li>Numele și adresa furnizate la înregistrarea unui cont de joc sunt corecte.</li>
            <li>
              Este proprietarul fondurilor și a mijloacelor de depunere folosite pentru a finanța
              contul în orice moment.
            </li>
            <li>
              Este singurul deținător beneficiar al tuturor tranzacțiilor și operațiunilor de pe
              platforma de jocuri de noroc.
            </li>
          </ul>
        </div>
        <table>
          <tbody>
            <tr>
              <td>„Acord”</td>
            </tr>
            <tr>
              <td>
                înseamnă contractul de licență care cuprinde Regulile MagicJackpot încheiate între
                Companie și Utilizator în conformitate cu Legislația Aplicabilă.
              </td>
            </tr>
            <tr>
              <td>„Legislație Aplicabilă”</td>
            </tr>
            <tr>
              <td>
                înseamnă toate legislațiile, reglementările și oricare și toate directivele și/sau
                liniile directoare ale ONJN și ale oricărei autorități de reglementare sau
                guvernamentale aplicabile (în funcție de context) care se referă la obligațiile
                fiecărei părți în temeiul și / sau în conformitate cu prezentul Acord.
              </td>
            </tr>
            <tr>
              <td>„Bonus”</td>
            </tr>
            <tr>
              <td>
                va avea semnificația pe care i-o atribuie articolul 2 litera f) din Normele
                Metodologice pentru punerea în aplicare a Ordonanței de Urgență a Guvernului nr.
                77/2009 privind organizarea și funcționarea jocurilor de noroc.
              </td>
            </tr>
            <tr>
              <td>„Servicii MJP”</td>
            </tr>
            <tr>
              <td>
                va însemna Software-ul și toate jocurile, serviciile, produsele și orice conținut
                conex, inclusiv Conținutul Terțelor Părți, indiferent dacă este sau nu descărcabil
                de dvs., accesibil sau utilizat în alt mod de dvs. prin intermediul Website-ului și
                permițându-vă să participați la servicii furnizate de MJP.
              </td>
            </tr>
            <tr>
              <td>„ONJN”</td>
            </tr>
            <tr>
              <td>înseamnă Oficiul Național pentru Jocuri de Noroc România.</td>
            </tr>
            <tr>
              <td>„Website”</td>
            </tr>
            <tr>
              <td>înseamnă website-ul companiei accesibil de pe www.magicjackpot.ro</td>
            </tr>
            <tr>
              <td>„Furnizori de Servicii Terțe-Părți”</td>
            </tr>
            <tr>
              <td>
                înseamnă acei furnizori Terțe-Părți care furnizează MJP Conținut de la Terțe Părți.
              </td>
            </tr>
            <tr>
              <td>„Conținut de la Terțe Părți”</td>
            </tr>
            <tr>
              <td>
                înseamnă acele elemente ale Serviciilor MJP achiziționate de MJP de la Furnizori de
                Servicii Terțe Părți pentru a vă oferi Serviciile MJP.
              </td>
            </tr>
            <tr>
              <td>„Termeni și Condiții privind Terțe-Părți”</td>
            </tr>
            <tr>
              <td>va avea semnificația prevăzută în Clauza 4.1. de mai jos.</td>
            </tr>
            <tr>
              <td>„Software”</td>
            </tr>
            <tr>
              <td>
                „va însemna software-ul licențiat de MJP pentru dvs., inclusiv: a) toate programele,
                bazele de date sau orice alt conținut derivat sau orice aplicații software conexe pe
                care MJP le oferă sau le pune la dispoziție prin intermediul Website-ului, inclusiv
                Conținut de la Terțe-Părți, ce vă permit să jucați jocuri online și care trebuie
                descărcate pe dispozitivul personal al Utilizatorului; b) toate serviciile/produsele
                auxiliare ale Software-ului.”
              </td>
            </tr>
            <tr>
              <td>„Utilizator”</td>
            </tr>
            <tr>
              <td>
                înseamnă orice persoană care îndeplinește cerințele stabilite în Clauza 2.1. de mai
                jos, care folosește Serviciile MJP în temeiul prezentului Acord și al Regulilor
                MagicJackpot din Teritoriu.
              </td>
            </tr>
            <tr>
              <td>„Cont Utilizator”</td>
            </tr>
            <tr>
              <td>înseamnă după cum este definit în Clauza 2.9. de mai jos.</td>
            </tr>
            <tr>
              <td>„Teritoriu”</td>
            </tr>
            <tr>
              <td>va însemna România.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </StyledAntiMoneyLaundering>
  );
}

export default AntiMoneyLaundering;
