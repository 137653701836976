import axios from 'axios';
import { Store } from 'redux';

import { API_URLS } from '@/constants/api-urls';
import { ApiServiceI, ApiServiceMethodConfig } from '@/models/api-service.model';
import { RootState } from '@/models/root-state.model';

const axiosInstance = axios.create();

export function apiServiceInterceptor(store: Store) {
  // In API V2 we expect each request to have x-locale header
  // we can attach it here in the singular place so that we don't need to do it across the whole app code
  axiosInstance.interceptors.request.use(request => {
    const state: RootState = store.getState();
    const token = state.auth.token;

    let headers = request.headers;

    // A singular place in the app where we attach the token to the request for API V2
    if (token) {
      headers = { ...headers, 'x-token': `${token}` };
    }

    request.headers = { ...headers };
    return request;
  });
}

const apiPostCall = <P>(url: string, payload: P, config?: ApiServiceMethodConfig) => {
  return axiosInstance.post(url, payload, config);
};

const ApiService: ApiServiceI = {
  forgotPassword: (payload, config) => apiPostCall(API_URLS.FORGOT_PASSWORD, payload, config),
  login: (payload, config) => apiPostCall(API_URLS.LOGIN, payload, config),
  register: (payload, config) => apiPostCall(API_URLS.REGISTER, payload, config),
  getUser: (payload, config) => apiPostCall(API_URLS.GET_USER, payload, config),
  getLiga: (payload, config) => apiPostCall(API_URLS.GET_LIGA, payload, config),
  ligaOptIn: (payload, config) => apiPostCall(API_URLS.LIGA_OPT_IN, payload, config),
  getJackpots: (payload, config) => apiPostCall(API_URLS.GET_JACKPOTS, payload, config),
  getEGTJackpots: (payload, config) => apiPostCall(API_URLS.GET_EGT_JACKPOTS, payload, config),
  getLifetimeDeposits: (payload, config) =>
    apiPostCall(API_URLS.GET_LIFETIME_DEPOSITS, payload, config),
  getConsents: config => apiPostCall(API_URLS.GET_CONSENTS, null, config),
  saveConsents: (payload, config) => apiPostCall(API_URLS.SAVE_CONSENTS, payload, config),
};

export default ApiService;
