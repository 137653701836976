import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Drawer from './Drawer';
import HelpDrawer from './HelpDrawer';
import { MenuDrawer } from './MenuDrawer';
import { SearchDrawer } from './SearchDrawer';
import { DrawerPropsModel } from '@/models/drawer-props.model';
import { RootState } from '@/models/root-state.model';
import { closeDrawer } from '@/store/actions/drawerActions';

const DRAWERS: { [key: string]: (props: DrawerPropsModel) => JSX.Element } = {
  help: HelpDrawer,
  search: SearchDrawer,
  menu: MenuDrawer,
};

const DELAY = 250;

function DrawerContainer(): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);
  const drawerName = useSelector((state: RootState) => state.drawer.name);
  const dispatch = useDispatch();

  const closeDrawerHandler = () => {
    setIsOpen(false);
    document.body.classList.remove('drawer-open');
    setTimeout(() => {
      dispatch(closeDrawer());
    }, DELAY);
  };

  useEffect(() => {
    if (drawerName) {
      setIsOpen(true);
      document.body.classList.add('drawer-open');
    }
  }, [drawerName]);

  if (!drawerName) {
    return null;
  }

  const SelectedDrawer = DRAWERS[drawerName];

  return (
    <Drawer isVisible={isOpen} closeDrawer={closeDrawerHandler}>
      <SelectedDrawer closeDrawer={closeDrawerHandler} />
    </Drawer>
  );
}

export default DrawerContainer;
