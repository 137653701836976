import { KycDocumentResponseDto } from '@lucky7ventures/lucky-components';
import { Action } from 'redux';

export enum KycActionsTypes {
  Get = 'GET_KYC',
  GetSuccess = 'GET_KYC_SUCCESS',
  GetError = 'GET_KYC_ERROR',
  ClearKycState = 'CLEAR_KYC_STATE',
}

export type GetKycAction = Action<KycActionsTypes.Get>;
export type GetKycActionSuccess = Action<KycActionsTypes.GetSuccess> & {
  kyc: KycDocumentResponseDto[];
};
export type GetKycActionError = Action<KycActionsTypes.GetError>;
export type ClearKycStateAction = Action<KycActionsTypes.ClearKycState>;
export type KycActions =
  | GetKycAction
  | GetKycActionSuccess
  | GetKycActionError
  | ClearKycStateAction;

export const getKyc = (): GetKycAction => ({
  type: KycActionsTypes.Get,
});

export const getKycSuccess = (kyc: KycDocumentResponseDto[]): GetKycActionSuccess => ({
  type: KycActionsTypes.GetSuccess,
  kyc,
});

export const getKycError = (): GetKycActionError => ({
  type: KycActionsTypes.GetError,
});

export const clearKycState = (): ClearKycStateAction => ({
  type: KycActionsTypes.ClearKycState,
});
