import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { logout } from '@/store/actions/authActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';

const StyledLogout = styled.section`
  height: 100vh;
  width: 100%;
  position: relative;
  background: ${props => props.theme.red};

  .logout-loader {
    color: white;
    font-size: 20px;
    font-weight: 600;
  }
`;

function Logout(): JSX.Element {
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(logout(token));
    }
  }, []);

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <StyledLogout>
      <div className="logout-loader absolute-center">Delogare...</div>
    </StyledLogout>
  );
}

export default Logout;
