import * as React from 'react';

function RegisterIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" width="1em" height="1em">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M14 14.252v2.09A6 6 0 006 22l-2-.001a8 8 0 0110-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 6v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3z"
      />
    </svg>
  );
}

export default RegisterIcon;
