import * as React from 'react';

import LigaCTABox from './LigaCTABox';
import LigaRecap from './LigaRecap';
import { Liga, LigaPrizeWheel } from '@/models/liga.model';
import { isLigaWinterPeriod } from '@/utils/liga-utils';

const isLigaWinter = isLigaWinterPeriod();

const LigaHero = ({
  isAuthenticated,
  isVerified,
  isDepositor,
  data,
  openWheel,
  scrollToLigas,
  loading,
}: {
  isAuthenticated: boolean;
  data: Liga | null;
  loading: boolean;
  isVerified: boolean;
  isDepositor: boolean;
  openWheel: (ligaWheel: LigaPrizeWheel) => void;
  scrollToLigas: () => void;
}): JSX.Element => {
  return (
    <div
      id="e2e_ligahero"
      className="h-[540px] sm:h-[600px] bg-center relative bg-brand-liga-red bg-liga-hero-sm sm:bg-liga-hero-xl2 bg-cover bg-no-repeat w-full box-content flex justify-center md:pt-0 md:justify-start md:items-center overflow-hidden"
    >
      <div className="rounded-[50%] absolute z-0 top-0 -left-64 -right-64 bg-brand-liga-red bottom-0 -translate-y-[60%] sm:-translate-y-[60%] lg:-translate-x-16"></div>
      <div className="z-3 max-w-7xl w-full mx-auto relative pt-20 h-full flex justify-stretch flex-col lg:flex-row lg:items-center">
        <div
          className={`z-1 bg-center lg:bg-right bottom-0 right-0 ${
            isAuthenticated ? 'lg:right-16' : 'lg:right-48'
          } xl:right-48 left-0 top-36 lg:top-32 bg-contain ${
            !isLigaWinter
              ? 'bg-liga-hero-wheel'
              : 'bg-liga-hero-wheel-mobile-xmas md:bg-liga-hero-wheel-xmas'
          } absolute bg-no-repeat`}
        ></div>
        <div className="px-4 md:px-8 m-0 relative z-50">
          <h1 className="z-2 text-white text-3xl md:text-5xl text-center lg:text-left relative lg:mt-4 lg:mb-8">
            LigaMagică
          </h1>
          <div className="hidden lg:block">
            {isAuthenticated && data && (
              <LigaRecap
                data={data}
                isVerified={isVerified}
                isDepositor={isDepositor}
                openWheel={openWheel}
                availablePrizesClicked={scrollToLigas}
              />
            )}

            {!data && <LigaCTABox loading={loading} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LigaHero;
