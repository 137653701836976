import React from 'react';

import styled from 'styled-components/macro';

const StyledPageHeader = styled.section<{ isDrawer: boolean }>`
  min-height: 220px;
  width: 100%;
  background: ${props => props.theme.redGradient};
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  position: relative;
  overflow: hidden;
  padding: 1rem 0;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    min-height: 280px;
    padding: 1rem;
  }

  .search {
    margin-top: ${props => (props.isDrawer ? '0' : '4rem')};
  }

  .number-of-games {
    position: absolute;
    bottom: 51px;
    right: 64px;
    font-weight: 600;
    font-size: 14px;
    color: ${props => props.theme.fontBlack};
  }

  .log-out {
    position: absolute;
    top: 64px;
    right: 0;
  }

  .content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-flow: column;
    padding: 2rem 1rem;
    max-width: ${props => (props.isDrawer ? '420px' : '100%')};
    text-align: center;

    h1 {
      margin-top: ${props => (props.isDrawer ? '0' : '3rem')};
      color: ${props => props.theme.white};
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    > button {
      margin-top: 0.5rem;
    }
  }
`;

function PageHeader({
  children,
  isDrawer = false,
}: {
  children: React.ReactNode;
  isDrawer?: boolean;
}): JSX.Element {
  return (
    <StyledPageHeader isDrawer={isDrawer}>
      <div className="content">{children}</div>
    </StyledPageHeader>
  );
}

export default PageHeader;
