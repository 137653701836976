import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components/macro';

import App from './components/App';
import { theme } from './styles/theme';
import store from '@/store/main';
import './index.css';
import { initializeNetworkAnalytics } from '@/utils/network-utils';

if (!window.Cypress) {
  initializeNetworkAnalytics();
}

if (!window.Cypress && process.env.REACT_APP_SENTRY_ENV) {
  // Init Sentry.io
  Sentry.init({
    dsn: 'https://ea3dbe8c71d54d74a15af3d51eb60954@o1297139.ingest.sentry.io/6543571',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'xhr') {
        const url = breadcrumb.data.url;
        // remove isloggedin from breadcrumbs
        if (url === '/api/auth/isloggedin') {
          return null;
        }
        // remove mixpanel
        if (url.contains('mixpanel.com')) {
          return null;
        }
        // remove google analytics
        if (url.contains('google-analytics.com')) {
          return null;
        }
        // remove snapchat trackers
        if (url.contains('snapchat.com')) {
          return null;
        }
        // remove doubleclick.net
        if (url.contains('doubleclick.net')) {
          return null;
        }
        // remove stackadapt
        if (url.contains('stackadapt.com')) {
          return null;
        }
      }
      return breadcrumb;
    },
  });
}

smoothscroll.polyfill();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
