import * as React from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import { useCookie } from 'react-use';

function PrivateRoute(): JSX.Element {
  const [cookieToken] = useCookie('mjp_token');
  return !cookieToken ? <Navigate to="/" /> : <Outlet />;
}

export default PrivateRoute;
