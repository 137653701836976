import * as React from 'react';

function GiftIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" width="1em" height="1em">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M14.5 2a3.5 3.5 0 013.163 5.001L21 7a1 1 0 011 1v4a1 1 0 01-1 1h-1v8a1 1 0 01-1 1H5a1 1 0 01-1-1v-8H3a1 1 0 01-1-1V8a1 1 0 011-1l3.337.001a3.5 3.5 0 015.664-3.95A3.48 3.48 0 0114.5 2zM18 13H6v7h12v-7zm2-4H4v2h16V9zM9.5 4a1.5 1.5 0 00-.144 2.993L9.5 7H11V5.5a1.5 1.5 0 00-1.356-1.493L9.5 4zm5 0l-.144.007a1.5 1.5 0 00-1.35 1.349L13 5.5V7h1.5l.144-.007a1.5 1.5 0 000-2.986L14.5 4z"
      />
    </svg>
  );
}

export default GiftIcon;
