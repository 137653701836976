import { Action } from 'redux';

import { ModalType } from '@/models/enums/modal-type.enum';
import { ConfirmModalProps, LoginModalProps, TermsModalProps } from '@/models/modal-props.model';

export enum ModalActionType {
  OpenConfirm = 'OPEN_CONFIRM_MODAL',
  OpenTerms = 'OPEN_TERMS_MODAL',
  OpenLogin = 'OPEN_LOGIN_MODAL',
  UpdateConfirmModalLoading = 'UPDATE_CONFIRM_MODAL_LOADING',
  UpdateConfirmModalError = 'UPDATE_CONFIRM_MODAL_ERROR',
  OpenDepositBalanceUpdate = 'DEPOSIT_BALANCE_UPDATE_MODAL',
  OpenConsentModal = 'OPEN_CONSENT_MODAL',
  OpenCookiesModal = 'OPEN_COOKIES_MODAL',
  OpenLigaTOSModal = 'OPEN_LIGATOS_MODAL',
  OpenLigaOptInModal = 'OPEN_LIGAOPTIN_MODAL',
  SetFtdExitIntentShown = 'SET_FTD_EXIT_INTENT_SHOWN',
  Close = 'CLOSE_MODAL',
  OpenModal = 'OPEN_MODAL',
}

export type OpenDepositBalanceUpdateModal = Action<ModalActionType.OpenDepositBalanceUpdate> & {
  depositBalanceUpdateModal: boolean;
};
export type OpenConfirmModalAction = Action<ModalActionType.OpenConfirm> & {
  props?: ConfirmModalProps;
};
export type OpenTermsModalAction = Action<ModalActionType.OpenTerms> & {
  props?: TermsModalProps;
};
export type OpenLoginModalAction = Action<ModalActionType.OpenLogin> & {
  props?: LoginModalProps;
};
export type UpdateConfirmModalLoading = Action<ModalActionType.UpdateConfirmModalLoading> & {
  loading: boolean;
};
export type UpdateConfirmModalError = Action<ModalActionType.UpdateConfirmModalError> & {
  error: string;
};
export type OpenModalAction = Action<ModalActionType.OpenModal> & {
  modal: ModalType;
};
export type OpenConsentModalAction = Action<ModalActionType.OpenConsentModal>;
export type OpenCookiesModalAction = Action<ModalActionType.OpenCookiesModal>;
export type OpenLigaTOSModalAction = Action<ModalActionType.OpenLigaTOSModal>;
export type OpenLigaOptInModalAction = Action<ModalActionType.OpenLigaOptInModal>;
export type CloseModalAction = Action<ModalActionType.Close>;
export type SetFtdExitIntentShownAction = Action<ModalActionType.SetFtdExitIntentShown>;

export type ModalActions =
  | OpenConfirmModalAction
  | OpenTermsModalAction
  | OpenLoginModalAction
  | CloseModalAction
  | UpdateConfirmModalLoading
  | UpdateConfirmModalError
  | OpenDepositBalanceUpdateModal
  | OpenConsentModalAction
  | OpenLigaTOSModalAction
  | OpenLigaOptInModalAction
  | OpenCookiesModalAction
  | OpenModalAction
  | SetFtdExitIntentShownAction;

export const openDepositBalanceUpdateModal = (
  depositBalanceUpdateModal: boolean,
): OpenDepositBalanceUpdateModal => ({
  type: ModalActionType.OpenDepositBalanceUpdate,
  depositBalanceUpdateModal,
});

export const openConfirmModal = (props?: ConfirmModalProps): OpenConfirmModalAction => ({
  type: ModalActionType.OpenConfirm,
  props,
});

export const openLoginModal = (props?: LoginModalProps): OpenLoginModalAction => ({
  type: ModalActionType.OpenLogin,
  props,
});

export const openTermsModal = (props?: TermsModalProps): OpenTermsModalAction => ({
  type: ModalActionType.OpenTerms,
  props,
});

export const updateConfirmModalLoading = (loading: boolean): UpdateConfirmModalLoading => ({
  type: ModalActionType.UpdateConfirmModalLoading,
  loading,
});

export const updateConfirmModalError = (error: string): UpdateConfirmModalError => ({
  type: ModalActionType.UpdateConfirmModalError,
  error,
});

export const openConsentModal = (): OpenConsentModalAction => ({
  type: ModalActionType.OpenConsentModal,
});

export const openCookiesModal = (): OpenCookiesModalAction => ({
  type: ModalActionType.OpenCookiesModal,
});

export const openLigaTOSModal = (): OpenLigaTOSModalAction => ({
  type: ModalActionType.OpenLigaTOSModal,
});

export const openLigaOptInModal = (): OpenLigaOptInModalAction => ({
  type: ModalActionType.OpenLigaOptInModal,
});

export const openModal = (modal: ModalType): OpenModalAction => ({
  type: ModalActionType.OpenModal,
  modal,
});

export const closeModal = (): CloseModalAction => ({
  type: ModalActionType.Close,
});

export const setFtdExitIntentShown = () => ({
  type: ModalActionType.SetFtdExitIntentShown,
});
