import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect, useWindowScroll } from 'react-use';
import styled, { css } from 'styled-components/macro';

import Header from '@/components/header/Header';
import CloseIcon from '@/components/icons/CloseIcon';
import CreditCardIcon from '@/components/icons/CreditCardIcon';
import HomeIcon from '@/components/icons/HomeIcon';
import LoginIcon from '@/components/icons/LoginIcon';
import RegisterIcon from '@/components/icons/RegisterIcon';
import Loader from '@/components/loader/Loader';
import { useCashierShow, useCashierShown } from '@/hooks/useCashier';
import { ReactComponent as Fullscreen } from '@/images/fullscreen.svg';
import { ReactComponent as SwipeUp } from '@/images/swipe-up.svg';
import { openFullscreen } from '@/lib/fullScreen';
import { setIsSingleGame } from '@/store/actions/depositActions';
import { getLastPlayedGames } from '@/store/actions/gamesActions';
import { openDepositBalanceUpdateModal, openLoginModal } from '@/store/actions/modalActions';
import { getWallet } from '@/store/actions/walletActions';
import { selectAuthIsAuthenticated, selectAuthToken } from '@/store/selectors/authSelectors';
import { selectDepositAdded } from '@/store/selectors/depositSelectors';
import { selectGameById } from '@/store/selectors/gamesSelectors';
import { selectUserIsBonusRestricted, selectUserLigaOptIn } from '@/store/selectors/userSelectors';
import { useLigaStore } from '@/store/useLigaStore';

const StyledGame = styled.div<{ isMobile: boolean; isLandscape: boolean }>`
  height: 100vh;

  ${props =>
    !props.isMobile &&
    css`
      overflow: hidden;

      #header {
        display: flex !important;
        flex-flow: column;
      }
    `}
  .wrapper {
    background: #000;
    top: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-rows: 32px 1fr;
    padding-left: env(safe-area-inset-left, 32px);
    padding-top: env(safe-area-inset-top);

    ${props =>
      props.isMobile &&
      props.isLandscape &&
      css`
        grid-template-rows: auto;
        grid-template-columns: 32px 1fr;
      `}
    ${props =>
      !props.isMobile &&
      css`
        display: flex;
        grid-template-columns: none;
        padding: 0;
        position: relative;
        background: ${props => props.theme.redGradient};
        flex-flow: column;
      `}
    .game-header {
      height: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      flex: auto;
      z-index: 2;

      ${props =>
        !props.isMobile &&
        css`
          display: none;
        `}
      div {
        display: flex;
        justify-content: center;
        align-items: center;

        ${props =>
          props.isMobile &&
          props.isLandscape &&
          css`
            flex-flow: column;
          `}
      }

      svg {
        height: 22px;
        width: 22px;
      }

      button,
      a {
        font-family: inherit;
        height: 24px;
        min-width: 24px;
        display: inline-flex;
        align-items: center;
        font-size: 13px;
        margin: 0 4px;
        font-weight: 700;
        border-radius: 4px;
        color: black;
        background: white;
        text-align: center;
        letter-spacing: -1px;

        span {
          margin-right: 4px;
        }

        &.register,
        &.cashier {
          background: ${props => props.theme.gold};
          padding: 0 4px;
          color: black;
          margin-right: 8px;
        }

        &.login {
          padding: 0 4px;
        }
      }

      ${props =>
        props.isMobile &&
        props.isLandscape &&
        css`
          flex-flow: column;
          height: 100%;
          justify-content: flex-start;

          button,
          a {
            margin: 8px auto 0 !important;
            width: 24px;
            display: flex;
            justify-content: center;

            &.register,
            &.login {
              padding: 0;
            }
          }
        `}
    }

    .game-wrapper {
      padding: 0;
      height: 100%;
      display: flex;
      position: relative;
      flex-grow: 1;
      background: #000;
      color: #fff;

      ${props =>
        props.isMobile &&
        props.isLandscape &&
        css`
          display: block;
        `}
      .game-container {
        height: 100%;
        width: 100%;

        .game-iframe {
          height: 100%;
          max-height: 100%;
          width: 100%;
          display: block;
          border: none;
        }
      }

      ${props =>
        !props.isMobile &&
        css`
          justify-content: center;
          align-items: center;
          background: none;
          padding: 0 4rem;

          .game-container {
            position: relative;
            background: #000;
            display: block;
            cursor: pointer;
            height: auto;
            max-width: calc((100vh - 200px) * 1.777);
            margin: 0 auto;

            &:after {
              content: '';
              display: block;
              padding-bottom: 56.27%;
            }

            .buttons {
              position: absolute;
              top: 0;
              right: -40px;

              button,
              a {
                width: 40px;
                background-color: ${props => props.theme.black};
                border-radius: 0 8px 8px 0;
                height: 40px;
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                margin-bottom: 2px;
                transition: all 0.15s ease;

                &:hover {
                  background-color: ${props => props.theme.blackHover};
                }

                svg {
                  width: 24px;
                  height: 24px;
                }
              }
            }

            .login-to-play {
              position: absolute;
              top: 100%;
              right: 0;
              background: ${props => props.theme.black};
              color: white;
              font-size: 14px;
              display: flex;
              align-items: center;
              padding: 0.5rem 1rem;
              border-radius: 0 0 8px 8px;

              p {
                margin-bottom: 0;
                padding: 0;
                display: inline;
              }

              button,
              a {
                font-size: 1em;
                text-decoration: underline;
                display: inline;
              }
            }

            .game-iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: auto;
            }
          }
        `}
    }

    .swipe-up-overlay {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      height: 3000px;
      background: rgba(0, 0, 0, 0.8);
      width: 100vw;
      z-index: 1;

      svg {
        height: 100vh;
      }
    }
  }
`;

function Game(): JSX.Element {
  const [gameUrl, setGameUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | boolean>(false);
  const [loading, setLoading] = useState(false);
  const { gameId } = useParams<{ gameId: string }>();
  const gameid = gameId ?? '';
  const game = useSelector(selectGameById(+gameid));
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const isBonusRestricted = useSelector(selectUserIsBonusRestricted);
  const hasLigaOptIn = useSelector(selectUserLigaOptIn);
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const showCashier = useCashierShow();
  const { pathname } = useLocation();
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const { y } = useWindowScroll();
  const { isLandscape } = useMobileOrientation();
  const isDrawerOpen = useCashierShown();
  const depositAdded = useSelector(selectDepositAdded);
  const navigate = useNavigate();
  const { fetch: fetchLiga } = useLigaStore();

  function getGameUrl() {
    setGameUrl(null);
    setLoading(true);
    const payload = isAuthenticated
      ? {
          language: 'ro',
          playForFun: false,
          token,
        }
      : {
          language: 'ro',
          playForFun: true,
        };

    axios
      .post(`/api/games/url/${gameId}`, payload)
      .then(response => {
        if (response.data.success) {
          setGameUrl(response.data.data);
          setLoading(false);
        } else {
          setError(response.data.error[0].Message);
          setLoading(false);
        }
      })
      .catch(() => {
        setError('error');
        setLoading(false);
      });
  }

  useEffect(() => {
    dispatch(setIsSingleGame(true));
    return () => {
      dispatch(setIsSingleGame(false));
    };
  }, []);

  useEffect(() => {
    if (!isDrawerOpen && depositAdded) {
      dispatch(openDepositBalanceUpdateModal(true));
    }
  }, [isDrawerOpen, depositAdded]);

  useEffect(() => {
    if (isMobile) {
      if (window?.innerHeight !== window?.outerHeight) {
        setShowScrollIndicator(true);
      } else {
        setShowScrollIndicator(false);
      }
    }
  }, [y]);

  useUpdateEffect(() => {
    getGameUrl();
  }, [isAuthenticated, pathname]);

  useEffect(() => {
    getGameUrl();

    return () => {
      if (isAuthenticated) {
        dispatch(getWallet());
        dispatch(getLastPlayedGames());
        // If user is not Bonus Restricted and has Opted In For Liga - fetch the liga data to see if there are any prizes to be claimed
        if (!isBonusRestricted && hasLigaOptIn) {
          fetchLiga();
        }
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <body className="single-game" />
      </Helmet>
      {game && (
        <Helmet>
          <title>
            {`${game.name} la MagicJackpot - Cazinou Online | Prinde 600 Rotiri Gratuite și până la 2400 Lei BONUS`}
          </title>
          <meta
            name="description"
            content={`Joacă ${game.name} la MagicJackpot | Înregistrează-te acum pentru a obține bonusul de bun venit!`}
          />
        </Helmet>
      )}
      <StyledGame isMobile={isMobile} isLandscape={isLandscape}>
        <div className="wrapper">
          <Header />
          <div className="game-header">
            <div>
              <Link to="/jocuri" className="home-link">
                <HomeIcon />
              </Link>
            </div>
            {!isAuthenticated ? (
              <div>
                <button type="button" className="login" onClick={() => dispatch(openLoginModal())}>
                  {!isLandscape && <span>Intră în cont</span>}
                  <LoginIcon />
                </button>
                <button
                  type="button"
                  className="register"
                  onClick={() => navigate('/inregistrare')}
                >
                  {!isLandscape && <span>Înregistrare</span>}
                  <RegisterIcon />
                </button>
              </div>
            ) : (
              <div>
                <button type="button" className="cashier" onClick={() => showCashier()}>
                  {!isLandscape && <span>Casierie</span>}
                  <CreditCardIcon />
                </button>
              </div>
            )}
          </div>
          <div className="game-wrapper">
            {error && <p>{error}</p>}
            {loading && <Loader />}
            <div className="game-container">
              {gameUrl && (
                <iframe
                  key={gameUrl}
                  title="game-iframe"
                  className="game-iframe"
                  id="game-iframe"
                  src={gameUrl}
                  frameBorder="0"
                  style={{ height: '100%', maxHeight: '100%' }}
                />
              )}
              <div className="buttons">
                <Link to="/jocuri">
                  <CloseIcon />
                </Link>
                <button type="button" onClick={() => openFullscreen()}>
                  <Fullscreen />
                </button>
              </div>
              {!isAuthenticated && !isMobile && (
                <div className="login-to-play">
                  <p>
                    Joci de distracție.{' '}
                    <button type="button" onClick={() => navigate('/inregistrare')}>
                      Înregistrează-te pentru a juca cu bani reali!
                    </button>
                  </p>
                </div>
              )}
            </div>
          </div>
          {showScrollIndicator && isMobileOnly && isLandscape && (
            <div className="swipe-up-overlay">
              <SwipeUp />
            </div>
          )}
        </div>
      </StyledGame>
    </>
  );
}

export default Game;
