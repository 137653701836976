import { create } from 'zustand';

import { Liga } from '@/models/liga.model';
import ApiService from '@/shared/api-service';

interface LigaState {
  data: Liga | null;
  loading: boolean;
  error: boolean;
  fetch: () => void;
  reset: () => void;
}

export const useLigaStore = create<LigaState>(set => ({
  data: null,
  loading: false,
  error: false,
  fetch: async () => {
    set({
      loading: true,
      error: false,
    });
    try {
      const response = await ApiService.getLiga(null);
      set({ loading: false, error: false, data: response.data });
    } catch (e) {
      set({
        loading: false,
        error: true,
      });
    }
  },
  reset: () => {
    set({
      data: null,
      loading: false,
      error: false,
    });
  },
}));
