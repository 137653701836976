import React, { InputHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import { DayInput, DOBInputProps, MonthInput, Separator, YearInput } from './Fields';
import { FieldEndIcon } from '../field/Field';
import FieldIcon from '../field/FieldIcon';
import FieldError from '@/components/form/field/FieldError';
import FieldLabel from '@/components/form/field/FieldLabel';
import { appendDefaultContainerClassNames } from '@/components/form/field/helpers';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { isEmpty } from '@/utils/helpers';

function focusTarget(value: { day?: string; month?: string; year?: string }) {
  if (value.day && !value.month) {
    document.getElementById('month')?.focus();
  } else if (value.month && !value.year && value.day) {
    document.getElementById('year')?.focus();
  } else if (!value.day) {
    document.getElementById('day')?.focus();
  }
}

interface DateOfBirthFieldProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onBlur'>,
    FieldEndIcon {
  name: string;
  label: string;
  labelClassName?: string;
  onValidationError?: (error: string) => void;
  onBlur?: ({ day, month, year }: { day: string; month: string; year: string }) => void;
  onFocus?: () => void;
  prefillValue?: { day: string; month: string; year: string };
}

function getDateFormat({
  day,
  month,
  year,
}: {
  day: DOBInputProps;
  month: DOBInputProps;
  year: Omit<DOBInputProps, 'setValue'>;
}) {
  return (
    <>
      <DayInput {...day} nextID="month" />
      {day.value || month.value ? <Separator /> : null}
      <MonthInput {...month} nextID="year" prevID="day" />
      {month.value ? <Separator /> : null}
      <YearInput {...year} prevID="month" />
    </>
  );
}

const DateOfBirthField = ({
  className,
  name,
  label,
  labelClassName,
  endIcon,
  endIconAction,
  onValidationError,
  onBlur,
  onFocus,
  disabled,
  prefillValue,
  ...props
}: DateOfBirthFieldProps) => {
  const [
    { value, onBlur: fieldOnBlur, onChange: fieldOnChange },
    { error: errorObj },
    { setValue },
  ] = useField(name);
  const [controlledFocus, setControlledFocus] = useState(false);
  const [gtmErrorTriggered, setGtmErrorTriggered] = useState(false);
  const error = errorObj as unknown as { day?: string; month?: string; year?: string };
  const errorMessage = error?.day || error?.month || error?.year || errorObj;
  const [dobFocused, setDobFocused] = useState(false);
  const [dobTouched, setDobTouched] = useState(false);
  const dobRef = useRef(null);

  const isReadOnly = useMemo(
    () =>
      !isEmpty(prefillValue?.day) && !isEmpty(prefillValue?.month) && !isEmpty(prefillValue?.year),
    [prefillValue],
  );

  useEffect(() => {
    if (
      !isEmpty(prefillValue?.day) &&
      !isEmpty(prefillValue?.month) &&
      !isEmpty(prefillValue?.year) &&
      !isEmpty(value?.day) &&
      !isEmpty(value?.month) &&
      !isEmpty(value?.year) &&
      errorMessage !== 'Necesar'
    ) {
      setDobTouched(true);
    }
    setValue(prefillValue);
  }, [prefillValue?.day, prefillValue?.month, prefillValue?.year, value, errorMessage]);

  useOnClickOutside(
    dobRef,
    () => {
      if (dobFocused && (!value.month || !value.day || !value.year)) {
        setDobTouched(true);
      }
      setDobFocused(false);
    },
    [dobFocused],
  );

  const handleOnFocus = () => {
    onFocus?.();
    setControlledFocus(true);
  };
  const handleOnBlur = (event: any) => {
    !(value.day || value.month || value.year) && setControlledFocus(false);
    onBlur?.({ day: value.day, month: value.month, year: value.year });
    fieldOnBlur(event);
  };

  const defaultProps = {
    controlledFocus: controlledFocus,
    fieldName: name,
    onBlur: handleOnBlur,
    onChange: !isReadOnly ? fieldOnChange : () => {},
    onFocus: handleOnFocus,
    disabled,
  };

  useEffect(() => {
    if (dobTouched && errorMessage) {
      onValidationError?.(errorMessage!);
    }
  }, [errorMessage, dobTouched]);

  return (
    <div
      ref={dobRef}
      className={appendDefaultContainerClassNames(
        className,
        errorMessage,
        dobTouched && !!errorMessage,
      )}
      {...props}
    >
      <div
        id="dob-fields"
        className={classNames('flex', disabled ? 'cursor-not-allowed' : 'cursor-text')}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          focusTarget(value);
          !disabled && setDobFocused(true);
        }}
        onBlur={() => {
          if (!gtmErrorTriggered && dobTouched && errorMessage) {
            onValidationError?.(errorMessage!);
            setGtmErrorTriggered(true);
          }
        }}
      >
        {getDateFormat({
          day: {
            ...defaultProps,
            value: value.day ?? '',
            setValue: (newVal: string) => setValue({ ...value, day: newVal }),
          },
          month: {
            ...defaultProps,
            value: value.month ?? '',
            setValue: (newVal: string) => setValue({ ...value, month: newVal }),
          },
          year: {
            ...defaultProps,
            value: value.year ?? '',
          },
        })}
      </div>
      <FieldLabel
        className={labelClassName}
        value={value.day || value.month || value.year || controlledFocus}
      >
        {label}
      </FieldLabel>
      <FieldError error={errorMessage} show={Boolean(dobTouched && errorMessage)} />
      <FieldIcon icon={endIcon} onClick={endIconAction} valid={!Boolean(error)} />
    </div>
  );
};

export default DateOfBirthField;
