import * as React from 'react';

import styled from 'styled-components';

const StyledDrawerHeader = styled.header`
  padding: 4rem 1rem;
  background: ${props => props.theme.redGradient};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 4rem 2rem;
  }

  .content {
    position: relative;

    h1 {
      font-size: 32px;
      display: block;
      font-weight: 700;
      margin-bottom: 0;
      color: white;
    }

    p {
      margin-top: 1rem;
    }

    > button {
      margin-top: 1rem;
    }

    .number-of-games {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 64px;
      font-weight: 600;
      font-size: 14px;
      color: ${props => props.theme.fontBlack};
    }
  }
`;

function DrawerHeader({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <StyledDrawerHeader>
      <div className="content">{children}</div>
    </StyledDrawerHeader>
  );
}

export default DrawerHeader;
