import { Action } from 'redux';

import { ExtendedUserModel } from '@/models/extended-user.model';
import { UserTag } from '@/models/user-tag.model';

export enum UserActionType {
  Get = 'GET_USER',
  Success = 'GET_USER_SUCCESS',
  Error = 'GET_USER_ERROR',
  GetUserTags = 'GET_USER_TAGS',
  GetUserTagsSuccess = 'GET_USER_TAGS_SUCCESS',
  GetUserTagsError = 'GET_USER_TAGS_ERROR',
  ClearUser = 'CLEAR_USER',
}

export type GetUserAction = Action<UserActionType.Get>;
export type GetUserTagsAction = Action<UserActionType.GetUserTags> & {
  token: string;
};

export type GetUserTagsSuccessAction = Action<UserActionType.GetUserTagsSuccess> & {
  tags: UserTag[];
};
export type GetUserSuccessAction = Action<UserActionType.Success> & {
  user: ExtendedUserModel;
};

export type GetUserErrorAction = Action<UserActionType.Error>;
export type GetUserTagsErrorAction = Action<UserActionType.GetUserTagsError>;
export type ClearUserAction = Action<UserActionType.ClearUser>;

export type UserActions =
  | GetUserAction
  | GetUserSuccessAction
  | GetUserErrorAction
  | GetUserTagsAction
  | GetUserTagsSuccessAction
  | GetUserTagsErrorAction
  | ClearUserAction;

export const getUserTags = (token: string): GetUserTagsAction => ({
  type: UserActionType.GetUserTags,
  token,
});

export const getUserTagsSuccess = (tags: UserTag[]): GetUserTagsSuccessAction => ({
  type: UserActionType.GetUserTagsSuccess,
  tags,
});

export const getUser = (): GetUserAction => ({
  type: UserActionType.Get,
});

export const getUserSuccess = (user: ExtendedUserModel): GetUserSuccessAction => ({
  type: UserActionType.Success,
  user,
});

export const getUserError = (): GetUserErrorAction => ({
  type: UserActionType.Error,
});

export const getUserTagsError = (): GetUserTagsErrorAction => ({
  type: UserActionType.GetUserTagsError,
});

export const clearUserState = (): ClearUserAction => ({
  type: UserActionType.ClearUser,
});
