import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components/macro';

const StyledDrawer = styled.div`
  position: relative;
  z-index: 110;
  display: none;

  .drawer-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${props => props.theme.overlayBlack};
    z-index: 1;
    transition: opacity 250ms;
  }

  .drawer-content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.white};
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transition: transform 250ms;
  }

  &.drawer-transition-enter {
    display: block;
    .drawer-backdrop {
      opacity: 0;
    }

    .drawer-content {
      transform: translateX(100%);
    }
  }

  &.drawer-transition-enter-active {
    display: block;
    .drawer-backdrop {
      opacity: 1;
    }

    .drawer-content {
      transform: translateX(0);
    }
  }

  &.drawer-transition-enter-done {
    display: block;
    .drawer-backdrop {
      opacity: 1;
    }

    .drawer-content {
      transform: translateX(0);
    }
  }

  &.drawer-transition-exit {
    display: block;
    .drawer-backdrop {
      opacity: 1;
    }

    .drawer-content {
      transform: translateX(0);
    }
  }

  &.drawer-transition-exit-active {
    display: block;
    .drawer-backdrop {
      opacity: 0;
    }

    .drawer-content {
      transform: translateX(100%);
    }
  }
`;

export type DrawerHandle = {
  scrollToTop: () => void;
};

type DrawerProps = {
  isVisible: boolean;
  children: React.ReactNode;
  closeDrawer: () => void;
};

const Drawer = forwardRef<DrawerHandle, DrawerProps>(
  ({ isVisible, children, closeDrawer }: DrawerProps, ref): JSX.Element => {
    const drawerContentRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          scrollToTop() {
            if (drawerContentRef.current) {
              drawerContentRef.current.scrollTo(0, 0);
            }
          },
        };
      },
      [],
    );

    return (
      <CSSTransition in={isVisible} timeout={250} classNames="drawer-transition">
        <StyledDrawer>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="drawer-backdrop" onClick={closeDrawer} />
          <div className="drawer-content" ref={drawerContentRef}>
            {children}
          </div>
        </StyledDrawer>
      </CSSTransition>
    );
  },
);

export default Drawer;
