import React, { InputHTMLAttributes } from 'react';

import classNames from 'classnames';

interface FieldInputProps extends InputHTMLAttributes<HTMLInputElement> {}

const FieldInput = (props: FieldInputProps) => (
  <input
    className={classNames(
      `peer w-full px-3.5 pt-6 pb-1 text-[15px] bg-transparent font-light disabled:cursor-not-allowed
      disabled:text-gray-700 rounded-md border-0 focus:outline-0 focus:border-none focus:ring-0 autofill:bg-transparent placeholder:text-transparent
      placeholder:text-[14px] sm:placeholder:text-base transition-colors duration-150 focus:placeholder:!text-gray-700/60`,
    )}
    {...props}
  />
);

export default FieldInput;
