import React from 'react';

import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';

import { GAME_PROVIDERS } from '@/constants/constants';
import { gameProviderImg } from '@/utils/image-utils';

const PROVIDERS: typeof GAME_PROVIDERS[number][] = [
  'amusnet',
  'pragmatic-play',
  'novomatic',
  'games-global',
  'evolution',
  'play-n-go',
  'playson',
  'relax-gaming',
  'wazdan',
  'egt-digital',
];

function GameProvidersList(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div
      data-cy="GAME_PROVIDERS_LIST"
      className="mx-auto flex max-w-[1400px] flex-col items-center px-4 pt-16 pb-8 md:px-10 md:pt-24"
    >
      <h1 className="mb-10">Peste 2176 de jocuri</h1>
      <div className="grid w-full grid-cols-3 gap-10 xl:grid-cols-5">
        {PROVIDERS.map(provider => (
          <div
            onClick={() => navigate(`/jocuri/${provider}`)}
            className="flex items-center justify-center"
            key={provider}
          >
            <LazyLoad offset={400}>
              <img
                src={`${gameProviderImg(provider)}`}
                alt={provider}
                className="w-full max-w-[100px] grayscale"
              />
            </LazyLoad>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GameProvidersList;
