import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/button/Button';
import Terms from '@/components/formats/Terms';
import BackIcon from '@/components/icons/BackIcon';
import Loader from '@/components/loader/Loader';
import useApiV2Request from '@/hooks/useApiV2Request';
import { WpPrivacyPolicy } from '@/models/wp-privacy-policy.model';
import { WpTermsAndConditions } from '@/models/wp-terms-and-conditions.model';
import ApiService from '@/shared/api-service';
import { logout } from '@/store/actions/authActions';
import { getConsents } from '@/store/actions/consentsActions';
import { getPrivacyPolicy, getTermsAndConditions } from '@/store/actions/wordpressActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import {
  selectIsPPConsented,
  selectIsTCConsented,
  selectMandatoryConsents,
} from '@/store/selectors/consentsSelectors';
import {
  selectPrivacyPolicy,
  selectTermsAndConditions,
} from '@/store/selectors/wordpressSelectors';

export enum ConsentSectionEnum {
  Index = 'Index',
  TermsAndConditions = 'TermsAndConditions',
  PrivacyPolicy = 'PrivacyPolicy',
}

const Consents = ({ closeModal }: { closeModal: () => void }): JSX.Element | null => {
  const [activeSection, setActiveSection] = useState(ConsentSectionEnum.Index);
  const { loading, request: saveConsents } = useApiV2Request();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const terms = useSelector(selectTermsAndConditions);
  const pp = useSelector(selectPrivacyPolicy);
  const isTCConsented = useSelector(selectIsTCConsented);
  const isPPConsented = useSelector(selectIsPPConsented);
  const consents = useSelector(selectMandatoryConsents);

  const handleOnLegalClick = (
    active: ConsentSectionEnum,
    legal: WpTermsAndConditions | WpPrivacyPolicy | null,
  ) => {
    if (legal) {
      setActiveSection(active);
    }
  };

  const handleLogout = () => {
    if (token) {
      closeModal();
      dispatch(logout(token));
    }
  };

  const handleUpdateConsents = async () => {
    const updatedUserConsents = consents.map(consent => {
      return {
        ConsentId: consent.ConsentId,
        Version: consent.Version,
        Consented: true,
      };
    });

    saveConsents(ApiService.saveConsents, updatedUserConsents, () => {
      dispatch(getConsents());
      closeModal();
    });
  };

  useEffect(() => {
    dispatch(getTermsAndConditions());
    dispatch(getPrivacyPolicy());
  }, []);

  return (
    <div
      id="e2e_consentModal"
      className="prose flex w-full flex-col overflow-hidden rounded-lg bg-white py-6 px-6 text-left sm:px-6"
    >
      {activeSection !== ConsentSectionEnum.Index && (
        <div className="sticky top-0 bg-white">
          <button
            type="button"
            onClick={() => setActiveSection(ConsentSectionEnum.Index)}
            className="flex items-center"
          >
            <BackIcon />
            <span className="ml-4">Înapoi</span>
          </button>
        </div>
      )}
      {activeSection === ConsentSectionEnum.Index && (
        <div className="max-w-[360px]">
          <h1 className="text-center text-xl">Noutăți de la MagicJackpot</h1>
          <p className="text-base">
            Am actualizat termenii noștri. Te rugăm să citești și să accepți noile modificări pentru
            a-ți continua sesiunea în Cazinou.
          </p>
          <ul>
            {!isTCConsented && (
              <li
                onClick={() => handleOnLegalClick(ConsentSectionEnum.TermsAndConditions, terms)}
                className="cursor-pointer font-bold underline"
              >
                Termeni și Condiții
              </li>
            )}
            {!isPPConsented && (
              <li
                onClick={() => handleOnLegalClick(ConsentSectionEnum.PrivacyPolicy, terms)}
                className="cursor-pointer font-bold underline"
              >
                Politica de Confidențialitate și Cookies
              </li>
            )}
          </ul>
          <div>
            <Button className="relative mt-8 w-full" onClick={handleUpdateConsents}>
              {loading ? (
                <div className="py-3.5">
                  <Loader />
                </div>
              ) : (
                <span>Confirma</span>
              )}
            </Button>
            <Button color="ghostBlack" className="mt-5 w-full" onClick={handleLogout}>
              Delogare
            </Button>
          </div>
        </div>
      )}
      {(activeSection === ConsentSectionEnum.TermsAndConditions ||
        activeSection === ConsentSectionEnum.PrivacyPolicy) && (
        <div className="max-w-[330px] sm:max-w-fit [&>div]:my-0 [&>div]:px-0">
          <Terms
            terms={
              activeSection === ConsentSectionEnum.TermsAndConditions
                ? terms?.content || ''
                : pp?.content || ''
            }
          />
        </div>
      )}
    </div>
  );
};

export default Consents;
