import { ManualBonusModel } from '@/models/manual-bonus.model';
import {
  GetManualBonusesSuccessAction,
  ManualBonusesAction,
  ManualBonusesActionType,
} from '@/store/actions/manualBonusesActions';

export interface ManualBonusesStateModel {
  manualBonuses: ManualBonusModel[];
  success: boolean;
  error: boolean;
}

const initialState: ManualBonusesStateModel = {
  manualBonuses: [],
  success: false,
  error: false,
};

const getManualBonuses = (state: ManualBonusesStateModel) => ({
  ...state,
  error: false,
});

const getManualBonusesSuccess = (
  state: ManualBonusesStateModel,
  action: GetManualBonusesSuccessAction,
) => ({
  ...state,
  manualBonuses: action.manualBonuses,
});

const getManualBonusesError = (state: ManualBonusesStateModel): ManualBonusesStateModel => ({
  ...state,
  error: true,
});

export const manualBonusesReducer = (
  state = initialState,
  action: ManualBonusesAction,
): ManualBonusesStateModel => {
  switch (action.type) {
    case ManualBonusesActionType.Get:
      return getManualBonuses(state);
    case ManualBonusesActionType.Success:
      return getManualBonusesSuccess(state, action);
    case ManualBonusesActionType.Error:
      return getManualBonusesError(state);
    default:
      return state;
  }
};
