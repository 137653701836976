import React, { useState } from 'react';

import { BffAuthRequest, BffAuthResponse } from '@lucky7ventures/bff-types';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button from '@/components/button/Button';
import Input from '@/components/form/Input';
import ValidationMessage from '@/components/form/ValidationMessage';
import LogoLandscape from '@/components/icons/LogoLandscape';
import { GENERAL_ERROR_MESSAGE } from '@/constants/constants';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { BffApiError } from '@/shared/api-error-handler';
import BffApiService from '@/shared/bff-api-service';
import { loginSuccess } from '@/store/actions/authActions';

interface OtpAuthenticationFormProps {
  credentials: BffAuthRequest;
  closeModal: () => void;
}

interface FormValues {
  otp: string;
}

const initialValues: FormValues = {
  otp: '',
};

const validationSchema = Yup.object().shape({
  otp: Yup.string().required('Codul SMS este necesar'),
});

const OtpAuthenticationForm = ({ credentials, closeModal }: OtpAuthenticationFormProps) => {
  const { request, loading, error } = useBffApiRequest<BffAuthResponse>();
  const [isResultNotSuccess, setIsResultNotSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleOnSubmit = ({ otp }: FormValues) => {
    const handleOnSuccess = ({ token, result }: BffAuthResponse) => {
      if (result === 'SUCCESSFUL') {
        setIsResultNotSuccess(false);
        triggerGTag(GTagEvents.login_2fa_success);
        dispatch(loginSuccess(token));
        closeModal();
      } else {
        triggerGTag(GTagEvents.login_2fa_invalid, { error: result });
        setIsResultNotSuccess(true);
      }
    };

    const handleOnError = (error: BffApiError) => {
      triggerGTag(GTagEvents.login_2fa_error, { error: `bff-error-${error.code}` });
    };

    request({
      apiMethod: BffApiService.loginWithOtp,
      payload: { username: credentials.username, password: credentials.password, otp },
      successCallback: handleOnSuccess,
      errorCallback: handleOnError,
    });
  };

  return (
    <div className="login">
      <LogoLandscape className="logo" />
      <p className="info">
        Introdu codul trimis la numărul de telefon înregistrat prin SMS pentru a te autentifica la
        contul tău
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        validateOnMount
      >
        {({ isValid }) => (
          <Form data-cy="e2e_otpAuthForm">
            <Field name="otp">
              {({ field }: FieldProps) => (
                <>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="text"
                    placeholder="Codul SMS"
                    label="Codul SMS"
                  />
                  <ErrorMessage name="otp">
                    {message => <ValidationMessage type="otp" message={message} />}
                  </ErrorMessage>
                </>
              )}
            </Field>
            <div className="button-wrapper">
              {(error || isResultNotSuccess) && <p className="error">{GENERAL_ERROR_MESSAGE}</p>}
              <Button disabled={!isValid || loading} type="submit" className="submit">
                {loading ? 'Se încarcă...' : 'Intră în cont'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OtpAuthenticationForm;
