import React from 'react';

import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Anchor from '@/components/anchor/Anchor';
import Button from '@/components/button/Button';
import Input from '@/components/form/Input';
import ValidationMessage from '@/components/form/ValidationMessage';
import LogoLandscape from '@/components/icons/LogoLandscape';
import { GENERAL_ERROR_MESSAGE } from '@/constants/constants';
import useApiV2Request from '@/hooks/useApiV2Request';
import { LoginSectionEnum } from '@/models/enums/login-section.enum';
import ApiService from '@/shared/api-service';

interface FormValues {
  email: string;
}

function ForgotPasswordForm({
  setLoginSection,
  closeModal,
}: {
  setLoginSection: React.Dispatch<React.SetStateAction<LoginSectionEnum>>;
  closeModal: () => void;
}): JSX.Element {
  const { loading, success, error, request } = useApiV2Request();

  const initialValues: FormValues = {
    email: '',
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().required('Te rugăm introdu emailul tău').email('Introdu un email valid'),
  });

  function submitHandler(values: FormValues) {
    request(ApiService.forgotPassword, { email: values.email });
  }

  function getButtonText() {
    if (loading) {
      return 'Se încarcă...';
    }

    return 'Trimite linkul de resetare';
  }

  return (
    <div className="login">
      {success && !loading ? (
        <div className="flex h-full flex-col items-center justify-between pt-8">
          <p className="mb-0 text-green-500">Resetarea parolei trimisă!</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-24 w-24 sm:h-32 sm:w-32"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
            />
          </svg>
          <p className="info">
            Ți-am trimis un email ce conține link-ul pentru resetarea parolei. Dacă nu ai primit
            email-ul te rugăm să verifici folderul spam sau să contactezi serviciul nostru de suport
            clienți.
          </p>
          <Button onClick={() => closeModal()} className="submit">
            Închide
          </Button>
        </div>
      ) : (
        <>
          <LogoLandscape className="logo" />
          <p className="info">
            Ai uitat parola? Nu fi îngrijorat. Introdu adresa de email asociată contului tău și noi
            îți trimitem linkul de resetare.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={values => submitHandler(values)}
            validateOnMount
          >
            {({ isValid }) => (
              <Form>
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        type="email"
                        placeholder="Emailul tău"
                        label="Email"
                        autoComplete="email"
                      />
                      <ErrorMessage name="email">
                        {message => <ValidationMessage type="email" message={message} />}
                      </ErrorMessage>
                    </>
                  )}
                </Field>
                <div className="button-wrapper">
                  {error && <p className="error">{GENERAL_ERROR_MESSAGE}</p>}
                  <Button disabled={!isValid} type="submit" className="submit">
                    {getButtonText()}
                  </Button>
                  <p className="create-account">
                    Îți aminteși parola?{' '}
                    <Anchor onClick={() => setLoginSection(LoginSectionEnum.Login)}>
                      Autentifică-te!
                    </Anchor>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

export default ForgotPasswordForm;
