import { Action } from 'redux';

import { ManualBonusModel } from '@/models/manual-bonus.model';

export enum ManualBonusesActionType {
  Get = 'GET_MANUAL_BONUSES',
  Success = 'GET_MANUAL_BONUSES_SUCCESS',
  Error = 'GET_MANUAL_BONUSES_ERROR',
}

export type GetManualBonusesAction = Action<ManualBonusesActionType.Get>;

export type GetManualBonusesSuccessAction = Action<ManualBonusesActionType.Success> & {
  manualBonuses: ManualBonusModel[];
};

export type GetManualBonusesErrorAction = Action<ManualBonusesActionType.Error>;

export type ManualBonusesAction =
  | GetManualBonusesAction
  | GetManualBonusesSuccessAction
  | GetManualBonusesErrorAction;

export const getManualBonuses = (): GetManualBonusesAction => ({
  type: ManualBonusesActionType.Get,
});

export const getManualBonusesSuccess = (
  manualBonuses: ManualBonusModel[],
): GetManualBonusesSuccessAction => ({
  type: ManualBonusesActionType.Success,
  manualBonuses,
});

export const getManualBonusesError = (): GetManualBonusesErrorAction => ({
  type: ManualBonusesActionType.Error,
});
