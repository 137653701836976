import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  selectIdVerified,
  selectKycVerified,
  selectStreamerUser,
  selectTargetedBtagUser,
  selectUser,
  selectUserTags,
} from '@/store/selectors/userSelectors';

function useFreeSpinsToast(): void {
  const user = useSelector(selectUser);
  const userTags = useSelector(selectUserTags);
  const streamerUser = useSelector(selectStreamerUser);
  const kycVerified = useSelector(selectKycVerified);
  const idVerified = useSelector(selectIdVerified);
  const targetedBtagUser = useSelector(selectTargetedBtagUser);

  useEffect(() => {
    //if user is not logged in or userTags is empty the toast will not display
    if (!user || userTags.length === 0) {
      return;
    }
    // if user-object has tags kycVerified or idVerified, user has passed KYC and toast will not display
    if (kycVerified || idVerified) {
      return;
    }
    // if user-object contains tags "Streamer" or if user has targetedBtags toast will not display
    if (streamerUser || targetedBtagUser) {
      return;
    }

    toast(
      <p>
        Prinde 200 Rotiri Gratuite la Burning Hot! Cum? Depune acum min. 50 Lei și{' '}
        <Link to="/setarile-mele/verificare">verifică-ți contul</Link>!
      </p>,
      {
        toastId: 'freeSpinsToast',
        position: 'bottom-center',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      },
    );
  }, [user, streamerUser, kycVerified, idVerified, targetedBtagUser, userTags]);
}

export default useFreeSpinsToast;
