import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-use';

import { useCashierShow } from '@/hooks/useCashier';
import { DrawerEnum } from '@/models/enums/drawer.enum';
import { openDrawer } from '@/store/actions/drawerActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';

function useParamsDrawer(): void {
  const dispatch = useDispatch();
  const showCashier = useCashierShow();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const drawer = params.get('d');

  useEffect(() => {
    if (drawer === 'casierie' && isAuthenticated) {
      showCashier();
    }

    if (drawer === 'ajutor') {
      dispatch(openDrawer(DrawerEnum.Help));
    }
  }, [drawer, isAuthenticated]);
}

export default useParamsDrawer;
