import React, { SyntheticEvent, useState } from 'react';

import axios, { AxiosResponse, CancelToken } from 'axios';
import { DropzoneRootProps, FileRejection, useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';
import styled from 'styled-components/macro';

import FilePreview from './FilePreview';
import Button from '@/components/button/Button';
import UploadIcon from '@/components/icons/UploadIcon';
import Spinner from '@/components/loader/Spinner';
import useCancelToken from '@/hooks/useCancelToken';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { ApiResponseModel } from '@/models/api-response.model';
import { ExtendedUserModel } from '@/models/extended-user.model';
import { ParsedIdentityCardModel } from '@/models/parsed-identity-card.model';
import { getKyc } from '@/store/actions/kycActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { selectUser } from '@/store/selectors/userSelectors';
import { doNothing } from '@/utils/helpers';
import {
  comparePhotoIdAndUserDetails,
  docNoFromScannedCard,
  getExtensionFromFileType,
  isPhotoIdExpired,
} from '@/utils/upload-utils';

const StyledUploadBox = styled.div<DropzoneRootProps>`
  min-height: 204px;
  color: ${props => (props.isDragReject ? props.theme.red : props.theme.green)};
  border-radius: ${props => props.theme.borderRadius};
  border: 3px dashed rgba(0, 0, 0, 0.2);
  background: white;
  padding: 3rem 2rem;
  margin-top: 2rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .loading {
    margin-top: 16px;
  }

  .upload-confirmation {
    margin-top: 24px;
  }

  .upload-actions {
    margin-top: 2rem;
    display: flex;
    flex-flow: column;
  }

  .box-content {
    max-width: 420px;
    margin: 0 auto;

    .error {
      margin-top: 1rem;
      font-size: 14px;
      color: ${props => props.theme.red};
    }

    svg,
    .loading-spinner {
      margin: 0 auto 1rem;
    }

    p {
      margin-bottom: 0;
    }
  }
`;

const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onabort = error => reject(error);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.split(',')[1]);
        return;
      }
      reject(new Error('Error with file reader'));
    };
    reader.onerror = error => reject(error);
  });

async function approveKyc(
  kycId: number,
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse> {
  return axios.post('/api/user/kyc/approve', { kycId, token }, { cancelToken });
}

async function approvePhotoIdKycStatus(
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse> {
  return axios.post(
    '/api/user/updatekycstatus',
    {
      token,
    },
    { cancelToken },
  );
}

async function uploadPhotoId(
  token: string,
  file: File,
  kycId: number,
  extension: string,
  cancelToken: CancelToken,
  expiryDate: Date,
  docNo: string,
): Promise<boolean> {
  const fileData = await getBase64(file);

  try {
    const req = await axios.post<boolean>(
      '/api/user/kyc/photoid',
      {
        token,
        data: fileData,
        kycId,
        extension,
        expiryDate,
        docNo,
      },
      { cancelToken },
    );
    return req.data;
  } catch (e) {
    throw e;
  }
}

async function uploadKyc(
  token: string,
  file: File,
  kycId: number,
  extension: string,
  cancelToken: CancelToken,
): Promise<boolean> {
  const fileData = await getBase64(file);

  const { data } = await axios.post<ApiResponseModel<boolean>>(
    '/api/user/kyc/upload',
    {
      token,
      data: fileData,
      kycId,
      extension,
    },
    { cancelToken },
  );

  if (data?.error && data.error.length > 0) {
    throw new Error(data.error[0].Message);
  }

  return true;
}

async function screenIdentityCard(
  file: File,
  user: ExtendedUserModel,
  cancelToken: CancelToken,
): Promise<ParsedIdentityCardModel> {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  try {
    const res = await axios({
      method: 'post',
      url: `/api/screening`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      cancelToken,
    });
    return res.data;
  } catch {
    return {
      status: 'false',
    } as ParsedIdentityCardModel;
  }
}

function KycUpload({ kycId, type }: { kycId: number; type: string }): JSX.Element {
  const user = useSelector(selectUser);
  const token = useSelector(selectAuthToken);
  const { newCancelToken, cancelRequest } = useCancelToken();
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<string | null>(null);
  const dispatch = useDispatch();

  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]): void => {
    setError(null);

    if (rejectedFiles && rejectedFiles.length > 0) {
      setError(rejectedFiles[0].errors[0].message);
      setFile(null);
      return;
    }

    if (!acceptedFiles || acceptedFiles.length === 0) {
      return;
    }

    const acceptedFile = acceptedFiles[0];
    setFile(acceptedFile);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/jpeg', 'image/png', 'application/pdf'],
    minSize: 0,
    maxSize: 10485760,
    maxFiles: 1,
    onDrop,
  });

  function resetFlow(): void {
    cancelRequest();
    setError(null);
    setFile(null);
  }

  function handleCancel(e: SyntheticEvent): void {
    e.stopPropagation();

    resetFlow();
  }

  function uploadHandler(e: SyntheticEvent): void {
    e.stopPropagation();

    if (!file || !user || !token) {
      return;
    }

    if (type === 'photoId') {
      setLoading('Încărcare și verificare buletin...');
    } else {
      setLoading('Încărcare document...');
    }

    const extension = getExtensionFromFileType(file.type);

    if (!extension) {
      setLoading(null);
      setError(`Unfortunately, we don't accept the provided file type.`);
      return;
    }

    const cancelToken = newCancelToken();

    const finalizeFlow = () => {
      dispatch(getKyc());
      setLoading(null);
    };

    // There are two flows, one for PhotoId and other for other documents
    // For other documents just upload the document
    if (type !== 'photoId' || extension === '.pdf') {
      uploadKyc(token, file, kycId, extension, cancelToken)
        .catch((e: Error) => {
          setError(e.message);
        })
        .finally(finalizeFlow);
      return;
    }

    const today = new Date();

    // for PhotoId trigger the auto-screening process
    triggerGTag(GTagEvents.kyc_id_upload_started);
    screenIdentityCard(file, user, cancelToken)
      .then(parsedData => {
        if (parsedData.status !== 'success') {
          triggerGTag(GTagEvents.kyc_id_screening_failed);
          // if the screening process failed we upload the document for manual approval
          return uploadKyc(token, file, kycId, extension, cancelToken)
            .then(() => triggerGTag(GTagEvents.kyc_id_upload))
            .catch((e: Error) => {
              setError(e.message);
            });
        } else {
          // if the screening process is successful we have several flows that can happen
          if (isPhotoIdExpired(parsedData.expirationDate, today)) {
            // if the photoID is expired we don't upload the document
            triggerGTag(GTagEvents.kyc_id_screening_expired);
            setError(
              'Cartea ta de identitate a expirat, te rugăm să uploadezi o Carte de Identitate validă.',
            );
            return;
          }
          // in all other cases we need to upload the document for manual approval
          return uploadPhotoId(
            token,
            file,
            kycId,
            extension,
            cancelToken,
            new Date(parsedData.expirationDate),
            docNoFromScannedCard(parsedData),
          )
            .then(() => triggerGTag(GTagEvents.kyc_id_upload))
            .then(() => {
              if (comparePhotoIdAndUserDetails(user, parsedData, today)) {
                return approveKyc(kycId, token, cancelToken)
                  .then(() => approvePhotoIdKycStatus(token, cancelToken))
                  .then(() => triggerGTag(GTagEvents.kyc_id_approved))
                  .catch(doNothing);
              } else {
                triggerGTag(GTagEvents.kyc_id_screening_mismatch);
              }
            })
            .catch((e: Error) => {
              setError(e.message);
            });
        }
      })
      .catch(doNothing)
      .finally(finalizeFlow);
  }

  useUnmount(() => {
    resetFlow();
  });

  return (
    <StyledUploadBox
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...getRootProps({
        isDragAccept,
        isDragReject,
        isDragActive,
      })}
    >
      <div className="box-content">
        <FilePreview file={file} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        {loading && (
          <div className="loading">
            <Spinner />
            <p>
              {loading} <br />
              Acest proces necesită doar câteva secunde.
            </p>
          </div>
        )}
        {!loading && !file && (
          <>
            <UploadIcon />
            {!isDragActive && (
              <p>
                Trage și eliberează documentul sau click aici pentru a selecta manual fișierul pe
                care dorești să îl încarci.
              </p>
            )}
            {isDragActive && !isDragReject && <p>Imaginea arată bine!</p>}
            {isDragReject && <p>Fișierul încărcat nu este un format acceptat...</p>}
          </>
        )}
        {error && !isDragActive && <p className="error">{error}</p>}
        {file && (
          <div className="upload-confirmation">
            {!loading && (
              <p>
                Documentul încărcat arată bine. Click pe „Încărcare” pentru a transmite fișierul
                pentru verificare.
              </p>
            )}
            <div className="upload-actions">
              <Button onClick={e => uploadHandler(e)}>
                {loading ? 'Se încarcă...' : 'Încarcă'}
              </Button>
              <Button color="white" onClick={e => handleCancel(e)}>
                Anulează
              </Button>
            </div>
          </div>
        )}
      </div>
    </StyledUploadBox>
  );
}

export default KycUpload;
