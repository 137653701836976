import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, NavLink, useNavigate } from 'react-router-dom';
import { useWindowScroll } from 'react-use';

import BonusButton from '@/components/button/BonusButton';
import Button from '@/components/button/Button';
import { HeaderMessage } from '@/components/header/HeaderMessage';
import CreditCardIcon from '@/components/icons/CreditCardIcon';
import LigaIcon from '@/components/icons/LigaIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import SettingsIcon from '@/components/icons/SettingsIcon';
import SuperbetLogo from '@/components/icons/SuperbetLogo';
import TotalBalance from '@/components/total-balance/TotalBalance';
import { useCashierShow } from '@/hooks/useCashier';
import { ReactComponent as Logo } from '@/images/logo.svg';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { DrawerEnum } from '@/models/enums/drawer.enum';
import { openDrawer } from '@/store/actions/drawerActions';
import { openLoginModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectAvailableBonusesWithContent } from '@/store/selectors/availableBonusesSelectors';
import { isEmpty } from '@/utils/helpers';

function Header(): JSX.Element {
  const { pathname } = useLocation();
  const isRegistrationRoute = useMemo(() => pathname.includes('inregistrare'), [pathname]);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const availableBonuses = useSelector(selectAvailableBonusesWithContent);
  const [scrolled, setScrolled] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const { y } = useWindowScroll();
  const navigate = useNavigate();
  const isSingleGame = pathname.indexOf('/joc/') >= 0;
  const showCashier = useCashierShow();

  useEffect(() => {
    if (isRegistrationRoute) {
      setShowRegister(false);
    } else {
      setShowRegister(true);
    }

    if (window.innerWidth <= 939) {
      if (y > 500 && !isRegistrationRoute) {
        setShowRegister(true);
      } else {
        setShowRegister(false);
      }
    }

    if (y !== 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [y, isRegistrationRoute]);

  return (
    <header id="header" className="fixed top-0 right-0 left-0 z-[100] block ">
      <HeaderMessage />
      <div
        className={`max-w-full text-white flex justify-between items-center transition-all duration-200 ease-in-out
        px-[0.8rem] min-h-[64px] w-full ${
          scrolled
            ? 'bg-red-gradient min-[940px]:min-h-[72px]'
            : 'bg-transparent min-[940px]:min-h-[86px]'
        }
        lg:px-8`}
      >
        <nav className="inline-flex items-center">
          <Link to="/" className="inline-flex">
            <Logo className="block w-[80px] h-[41px] min-[940px]:mr-4 xl:mr-10 min-[940px]:w-[100px] min-[940px]:h-[51px] min-[940px]:block" />
          </Link>
          <ul className="hidden min-[940px]:inline-flex gap-2">
            <li className="inline-flex">
              <NavLink
                end
                to="/"
                title="Acasă"
                className={({ isActive }) =>
                  'inline-flex flex-col items-center justify-between p-4 no-underline leading-[1] hover:font-bold ' +
                  'before:block before:font-bold before:h-0 before:overflow-hidden before:invisible before:content-[attr(title)] ' +
                  (isActive && 'font-bold')
                }
              >
                Acasă
              </NavLink>
            </li>
            <li className="inline-flex">
              <NavLink
                to="/jocuri"
                title="Jocuri"
                className={({ isActive }) =>
                  'inline-flex flex-col items-center justify-between p-4 no-underline leading-[1] hover:font-bold ' +
                  'before:block before:font-bold before:h-0 before:overflow-hidden before:invisible before:content-[attr(title)] ' +
                  (isActive && 'font-bold')
                }
              >
                Jocuri
              </NavLink>
            </li>
            <li className="inline-flex">
              <button
                type="button"
                onClick={() => dispatch(openDrawer(DrawerEnum.Help))}
                title="Ajutor"
                className="inline-flex flex-col items-center justify-between p-4 no-underline leading-[1] hover:font-bold
                  before:block before:font-bold before:h-0 before:overflow-hidden before:invisible before:content-[attr(title)]"
              >
                Ajutor
              </button>
            </li>
          </ul>
        </nav>
        <nav className="inline-flex items-center">
          <ul className="inline-flex items-center">
            <li className="hidden min-[940px]:inline-flex ml-4">
              <Link to="/liga-magica">
                <Button size="icon" color="transparent" className="!p-[11px] !whitespace-nowrap">
                  <LigaIcon className="w-8 h-8 fill-white" />
                </Button>
              </Link>
            </li>
            {!isAuthenticated ? (
              <>
                <li className="items-center text-[11px] italic flex-col hidden min-[940px]:inline-flex mx-4">
                  parte din <SuperbetLogo className="mt-0.5 w-[80px] h-auto" />
                </li>
                <li className="ml-4">
                  <Button
                    data-cy="login-modal-button"
                    size="small"
                    color="ghost"
                    onClick={() => dispatch(openLoginModal())}
                    className="!p-[0.7rem] !whitespace-nowrap min-[940px]:!py-[14px] min-[940px]:!px-6"
                  >
                    Intră în cont
                  </Button>
                </li>
                {showRegister && (
                  <li
                    className={`transition-all duration-200 ease-in-out min-[940px]:ml-4
                  min-[940px]:max-w-[200px] min-[940px]:transition-none max-w-[118px] ml-4`}
                  >
                    <Button
                      data-cy="registration-module-button"
                      size="small"
                      onClick={() => {
                        triggerGTag(GTagEvents.reg_header_register_click);
                        navigate('/inregistrare');
                      }}
                      className="!p-[0.7rem] !whitespace-nowrap min-[940px]:!py-[14px] min-[940px]:!px-6"
                    >
                      Înregistrare
                    </Button>
                  </li>
                )}
                <li className="hidden ml-4 min-[940px]:inline-flex">
                  <Button
                    size="icon"
                    color="transparent"
                    className="p-[11px] whitespace-nowrap min-[940px]:py-[14px]"
                    onClick={() => dispatch(openDrawer(DrawerEnum.Search))}
                    id="e2e_searchButton"
                  >
                    <SearchIcon />
                  </Button>
                </li>
              </>
            ) : (
              <>
                {!isEmpty(availableBonuses) && <BonusButton />}
                <li>
                  <Button
                    size="small"
                    className="[&>svg]:ml-1.5 text-sm items-center ml-4 !p-[0.7rem] !whitespace-nowrap min-[940px]:text-base
                    [&>svg]:text-[19px] min-[940px]:!py-[14px] min-[940px]:!px-6"
                    onClick={() => showCashier()}
                    data-cy="navigation-cashier"
                    id="e2e_cashierButton"
                  >
                    {!isSingleGame ? <TotalBalance /> : <span className="funds">+ / -</span>}
                    <CreditCardIcon />
                  </Button>
                </li>
                <li className="hidden min-[940px]:inline-flex ml-4">
                  <Link id="e2e_headerSettings" to="/setarile-mele">
                    <Button
                      size="icon"
                      color="transparent"
                      className="!p-[11px] !whitespace-nowrap"
                    >
                      <SettingsIcon />
                    </Button>
                  </Link>
                </li>
                <li className="hidden min-[940px]:inline-flex ml-4">
                  <Button
                    size="icon"
                    color="transparent"
                    className="!p-[11px] !whitespace-nowrap"
                    onClick={() => dispatch(openDrawer(DrawerEnum.Search))}
                    id="e2e_searchButton"
                  >
                    <SearchIcon />
                  </Button>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
