import React from 'react';

import { useSelector } from 'react-redux';

import GameList from './GameList';
import { selectLastPlayedGames } from '@/store/selectors/gamesSelectors';

function LastPlayedGames(): JSX.Element | null {
  const lastPlayedGames = useSelector(selectLastPlayedGames);

  if (!lastPlayedGames || lastPlayedGames.length === 0) {
    return null;
  }

  return (
    <section data-cy="LAST_PLAYED_GAMES">
      <GameList
        header="Ultimul jucat"
        games={lastPlayedGames}
        maxLength={6}
        slug="/jocuri/ultimul-jucat"
      />
    </section>
  );
}

export default LastPlayedGames;
