import React from 'react';

import { useDispatch } from 'react-redux';
import { shallow } from 'zustand/shallow';

import Button from '@/components/button/Button';
import { openCookiesModal } from '@/store/actions/modalActions';
import { useCookieConsentStore } from '@/store/cookieConsentStore';

const CookieBanner = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { essential, acceptCookies } = useCookieConsentStore(
    state => ({ essential: state.essential, acceptCookies: state.acceptCookies }),
    shallow,
  );

  const openCookieSettings = () => {
    dispatch(openCookiesModal());
  };

  const handleAcceptClick = () => {
    // if the user accepts from the banner we allow all cookies
    acceptCookies(true);
  };

  if (essential) {
    return null;
  }

  return (
    <div className="bottom-20 fixed left-[50%] z-50 flex w-full max-w-[95%] -translate-x-1/2 justify-center lg:bottom-2">
      <div className="flex flex-col items-center justify-between gap-3 rounded-md bg-white p-3 text-sm shadow-lg md:flex-row">
        <div className="mb-0">
          <div className="mb-1">
            <span className="text-sm font-bold">MagicJackpot utilizează cookie-uri</span> 🍪
          </div>
          <div className="text-xs">
            Utilizăm cookie-uri pentru a vă oferi cea mai bună experiență pe site-ul nostru de
            Casino
          </div>
        </div>
        <div className="flex gap-3 self-end">
          <Button size="small" color="ghostBlack" onClick={openCookieSettings} className="text-sm">
            Setări cookie-uri
          </Button>
          <Button size="small" color="black" onClick={handleAcceptClick} className="text-sm">
            Acceptă
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
