import * as React from 'react';

import parse, { DOMNode, HTMLReactParserOptions, Element, Text, Node } from 'html-react-parser';
import { Link } from 'react-router-dom';

const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === 'tag';
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
};

const isValidAnchor = (element: Element): boolean => {
  const isAnchor = element.name === 'a';
  const hasHref = element.attribs.href !== undefined;

  return isAnchor && hasHref;
};

const isTextNode = (element: Node): element is Text => {
  const isText = element.type === 'text';
  const hasData = (element as Text).data !== undefined;

  return isText && hasData;
};

function ParsedString({ string }: { string: string }): JSX.Element | null {
  const options: HTMLReactParserOptions = {
    // eslint-disable-next-line consistent-return
    replace: (domNode: DOMNode) => {
      if (isElement(domNode) && isValidAnchor(domNode)) {
        const { attribs, children } = domNode;
        const textNode = children[0];
        if (isTextNode(textNode)) {
          const href = attribs.href;
          if (href.startsWith('/')) {
            return <Link to={`${attribs.href}`}>{textNode.data}</Link>;
          }
          return <a {...attribs}>{textNode.data}</a>;
        }
      }
    },
    trim: true,
  };

  return string ? <>{parse(string.toString(), options)}</> : null;
}

export default ParsedString;
