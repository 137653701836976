import * as React from 'react';

import { useSelector } from 'react-redux';
import ZendeskContainer, { ZendeskAPI } from 'react-zendesk';

import { selectLoadZendesk } from '@/store/selectors/commonSelectors';

const settings = {
  contactForm: {
    attachments: false,
  },
  helpCenter: {
    originalArticleButton: false,
  },
  contactOptions: {
    enabled: true,
  },
  navigation: {
    popoutButton: {
      enabled: false,
    },
  },
  chat: {
    departments: {
      enabled: [],
    },
  },
};

function Zendesk(): JSX.Element | null {
  const loadZendesk = useSelector(selectLoadZendesk);

  if (!process.env.REACT_APP_ZENDESK_KEY || !loadZendesk) {
    return null;
  }

  return (
    <ZendeskContainer
      zendeskKey={process.env.REACT_APP_ZENDESK_KEY || ''}
      {...settings}
      onLoaded={() => {
        ZendeskAPI('webWidget', 'hide');
        ZendeskAPI('webWidget', 'setLocale', 'ro');
      }}
    />
  );
}

export default Zendesk;
