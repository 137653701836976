import React, { useEffect } from 'react';

import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCookie } from 'react-use';

import { GIG_LOGIC_KYC_WEBHOOK } from '@/constants/constants';
import { selectDepositCount } from '@/store/selectors/depositSelectors';
import {
  selectIdVerified,
  selectKycVerified,
  selectUser,
  selectUserTags,
} from '@/store/selectors/userSelectors';
import CookiesManagerUtils from '@/utils/cookies-manager-utils';

// TODO: should I completely remove this hook?

function useKycToast(): void {
  const user = useSelector(selectUser);
  const [kycClosed] = useCookie('mjp_kyc_notification_closed');
  const depositCount = useSelector(selectDepositCount);
  const kycVerified = useSelector(selectKycVerified);
  const idVerified = useSelector(selectIdVerified);
  const userTags = useSelector(selectUserTags);

  useEffect(() => {
    // if toast is closed (kycClosed) a coockie will be placed and we will not show kyc-toast
    if (kycClosed) {
      return;
    }
    // if not logged in toasts will not display
    if (!user || userTags.length === 0) {
      return;
    }
    // if user has not made any deposits, the toast will not show
    if (depositCount === 0) {
      return;
    }
    // if user-object has tags kycVerified or idVerified, user has passed KYC and toast will not display
    if (kycVerified || idVerified) {
      return;
    }

    axios
      .post(GIG_LOGIC_KYC_WEBHOOK, {
        UserId: user.UserID,
      })
      .then(response => {
        if (response?.data?.output) {
          const { DaysSinceDep1 } = response.data.output;
          if (parseInt(DaysSinceDep1, 10) > 17) {
            toast(
              <p>
                Atenție! Mai ai <strong>{28 - parseInt(DaysSinceDep1, 10)} zile</strong>
                pentru a-ți <Link to="/setarile-mele/verificare">verifica contul</Link>.
              </p>,
              {
                toastId: 'verifyToast',
                position: 'bottom-center',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                onClose: () => CookiesManagerUtils.setKycNotificationClosedCookie(),
              },
            );
          }
        }
      })
      .catch(error => console.log(error));
  }, [user, kycClosed, depositCount, idVerified, kycVerified, userTags]);
}

export default useKycToast;
