import classNames from 'classnames';

type AppendDefaultContainerClassNameArgs = (
  className?: string,
  error?: string,
  touched?: boolean,
) => string;

export const appendDefaultContainerClassNames: AppendDefaultContainerClassNameArgs = (
  className,
  error,
  touched,
) =>
  classNames(
    'relative w-full rounded-md border-0 leading-tight transition-all duration-300',
    { '!border-red-600 border-1 mb-2.5': error && touched },
    className,
  );
