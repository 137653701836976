import * as React from 'react';

function SuperbetLogo({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      width="200"
      height="39"
      viewBox="0 0 200 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.3478 11.6877C20.1909 9.85292 19.3829 8.56827 16.9061 8.18961C14.5424 7.82489 13.4196 8.58803 13.2244 9.80465C13.0001 11.2047 13.9365 11.8879 16.3778 12.641C17.4132 12.9634 18.4496 13.2826 19.4875 13.599C25.6357 15.4764 27.5971 18.0021 27.0351 22.3029C26.4171 27.1065 22.0168 30.374 14.0845 29.1723C6.19395 27.9597 2.21966 24.115 3.72266 18.0957C6.20928 18.5296 8.69815 18.9467 11.189 19.3469C10.6881 21.2301 11.4501 22.8755 15.1185 23.4387C17.9371 23.8671 19.2579 23.1854 19.4661 21.7456C19.6482 20.4889 18.9326 19.747 16.8005 19.087C15.7782 18.7693 14.7569 18.4487 13.7369 18.1255C7.53631 16.1653 5.07067 13.2453 5.84794 8.80248C6.59468 4.56478 10.4522 1.29709 17.9011 2.45019C24.9463 3.52273 28.2542 7.12977 27.6482 12.6682C25.2132 12.3576 22.7798 12.0307 20.3478 11.6877Z"
        fill="white"
      />
      <path
        d="M29.5212 20.2911C30.1762 15.1558 30.8312 10.0207 31.4863 4.88525C33.922 5.19588 36.3594 5.49017 38.7981 5.76791C38.225 10.8002 37.6519 15.8327 37.0787 20.865C36.7341 23.8684 37.9185 25.5114 40.7069 25.8094C43.4956 26.1048 44.9983 24.7479 45.2938 21.7395C45.7929 16.6993 46.292 11.6592 46.7911 6.61884C49.2328 6.86049 51.6756 7.08595 54.1195 7.29479C53.6787 12.4529 53.2379 17.611 52.7971 22.7691C52.2212 29.7181 47.7641 33.3482 39.9916 32.5287C32.2204 31.6932 28.6208 27.2054 29.5212 20.2911Z"
        fill="white"
      />
      <path
        d="M55.4365 33.4374C56.1197 24.8154 56.8029 16.1936 57.4861 7.57178C61.3529 7.87818 65.2222 8.14344 69.0932 8.36737C75.1474 8.71635 79.1729 12.3285 78.9669 18.0029C78.7625 23.6325 74.3236 26.9916 68.0365 26.6302C66.5653 26.545 65.0944 26.4542 63.6238 26.3575C63.4565 28.9033 63.2892 31.4486 63.122 33.9944C60.5592 33.826 57.9974 33.6403 55.4365 33.4374ZM64.0302 20.1735C65.204 20.2507 66.378 20.3242 67.5521 20.3938C69.8129 20.5274 71.0613 19.4316 71.1552 17.6426C71.2469 15.8893 70.1353 14.6377 67.7881 14.4987C66.6642 14.4319 65.5405 14.3612 64.417 14.2876C64.288 16.2494 64.159 18.2117 64.0302 20.1735Z"
        fill="white"
      />
      <path
        d="M80.666 34.877C80.9682 26.2335 81.2706 17.5899 81.5728 8.94629C87.909 9.16781 94.2481 9.27922 100.587 9.28032C100.587 11.3959 100.586 13.5114 100.586 15.627C96.6524 15.6262 92.7187 15.5837 88.7858 15.4991C88.7609 16.6561 88.736 17.8132 88.7111 18.9702C92.0707 19.0426 95.4307 19.0843 98.7908 19.0958C98.7839 21.1491 98.7768 23.2025 98.7699 25.2558C95.3726 25.2443 91.9753 25.202 88.5786 25.1288C88.5526 26.3357 88.5267 27.5426 88.5008 28.7494C92.5797 28.8369 96.6593 28.8807 100.739 28.8803C100.739 30.9959 100.739 33.1114 100.74 35.227C94.0474 35.2277 87.3551 35.111 80.666 34.877Z"
        fill="white"
      />
      <path
        d="M127.26 28.0335C127.361 30.1838 127.461 32.3346 127.562 34.4851C126.943 34.7015 126.045 34.8542 125.181 34.8909C120.393 35.0954 116.438 34.0466 113.649 27.5005C113.54 27.2541 113.431 27.0076 113.322 26.7611C112.6 26.7773 111.877 26.7921 111.155 26.8055C111.206 29.5802 111.257 32.3548 111.309 35.1294C108.742 35.177 106.176 35.2074 103.609 35.2204C103.565 26.5717 103.521 17.9229 103.477 9.27409C107.49 9.25359 111.503 9.18899 115.515 9.08013C121.614 8.91388 125.951 11.7582 126.237 17.6537C126.424 21.6428 124.464 24.245 120.787 25.3118C122.556 28.2379 124.47 28.235 126.021 28.1651C126.487 28.1441 126.951 28.0853 127.26 28.0335ZM110.941 15.2715C110.976 17.1503 111.01 19.029 111.045 20.9078C112.412 20.8825 113.778 20.8521 115.144 20.8168C117.405 20.7573 118.479 19.5617 118.424 17.8835C118.371 16.2407 117.233 15.123 114.885 15.1843C113.57 15.218 112.255 15.247 110.941 15.2715Z"
        fill="white"
      />
      <path
        d="M130.158 34.4691C129.713 25.8317 129.268 17.1943 128.823 8.55685C132.695 8.35729 136.565 8.11665 140.433 7.83483C146.599 7.3802 149.989 9.82262 150.377 14.0291C150.655 17.078 148.691 19.2053 146.405 20.0289C149.57 20.5928 151.805 22.1833 152.107 25.4556C152.555 30.2452 149.347 33.1867 143.182 33.6494C138.844 33.9722 134.502 34.2455 130.158 34.4691ZM136.831 18.3741C138.065 18.2939 139.298 18.2095 140.531 18.1211C142.364 17.9897 143.204 16.9489 143.086 15.4235C142.969 13.8974 141.99 13.0382 140.177 13.1686C138.954 13.2563 137.732 13.3398 136.51 13.4192C136.616 15.0709 136.724 16.7224 136.831 18.3741ZM137.505 28.7309C138.748 28.6501 139.991 28.5651 141.235 28.4761C143.373 28.3225 144.388 27.3047 144.261 25.6677C144.132 24.0291 142.983 23.1855 140.866 23.3373C139.635 23.4255 138.403 23.5095 137.171 23.5898C137.282 25.3035 137.394 27.0173 137.505 28.7309Z"
        fill="white"
      />
      <path
        d="M155.25 32.6229C154.426 24.0133 153.602 15.4037 152.778 6.79406C159.033 6.19563 165.281 5.48921 171.517 4.67466C171.791 6.77225 172.066 8.87021 172.34 10.9678C168.493 11.4701 164.644 11.9318 160.791 12.3528C160.916 13.5034 161.042 14.6537 161.168 15.8043C164.512 15.4387 167.856 15.0427 171.195 14.6165C171.456 16.6533 171.716 18.69 171.975 20.7268C168.599 21.1578 165.219 21.5583 161.837 21.9278C161.968 23.1277 162.099 24.3279 162.231 25.5277C166.292 25.0838 170.35 24.5961 174.403 24.0641C174.678 26.1617 174.953 28.2592 175.229 30.3568C168.579 31.2294 161.919 31.9848 155.25 32.6229ZM184.395 29.0785C183.441 22.6152 182.486 16.152 181.532 9.68885C179.26 10.0243 176.989 10.3454 174.715 10.6524C174.431 8.55587 174.148 6.45937 173.865 4.36287C180.832 3.42231 187.785 2.3468 194.72 1.13623C195.084 3.22019 195.448 5.3043 195.811 7.3884C193.51 7.79003 191.208 8.17692 188.902 8.54931C189.944 14.9991 190.986 21.4487 192.027 27.8985C189.485 28.3092 186.941 28.7024 184.395 29.0785Z"
        fill="white"
      />
    </svg>
  );
}

export default SuperbetLogo;
