import React from 'react';

import { Link } from 'react-router-dom';

import Button from '@/components/button/Button';
import { cdnImage } from '@/utils/image-utils';

const EGTDigitalBanner = () => {
  return (
    <div
      className="bg-brand-red h-[800px] lg:h-[400px] flex flex-col lg:flex-row object-contain"
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left bottom',
        backgroundImage: `url(${cdnImage('egt_digital_bg.png', {
          width: '1333',
          height: '400',
        })}`,
      }}
    >
      <div className="w-0 lg:w-[60%] 2xl:w-0" />
      <div className="flex-col lg:items-start 2xl:items-center items-center w-full lg:w-[40%] 2xl:w-full py-[32px] px-[24px] flex text-white">
        <div className="flex flex-col justify-center items-center space-y-[32px] h-full">
          <img
            src={cdnImage('egt_digital_game_types.png', {
              width: 'auto',
              height: '80',
            })}
            className="object-none lg:w-auto"
            alt="Game Types"
          />
          <div>
            <div className="text-5xl font-bold text-center">59</div>
            <div className="text-3xl font-bold">Sloturi EGT Digital</div>
          </div>
          <Link to="/jocuri/egt-digital">
            <Button className="max-w-[200px]" size="small">
              Joacă Acum
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EGTDigitalBanner;
