import React, { useEffect } from 'react';

import { shallow } from 'zustand/shallow';

import { useExcludedGamesStore } from '@/store/excludedGamesStore';

const ModalBody = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return <div className="w-full max-w-lg rounded-md bg-white p-8">{children}</div>;
};

const GeneralBonusTerms = (): JSX.Element => {
  const { excludedGames, fetchExcludedGames } = useExcludedGamesStore(
    state => ({
      excludedGames: state.excludedGames,
      fetchExcludedGames: state.fetch,
    }),
    shallow,
  );

  useEffect(() => {
    fetchExcludedGames();
  }, []);

  return (
    <ModalBody>
      <div className="text-left">
        <p>
          <strong>
            Termeni și condiții specifici Campaniei Promoționale ,,Bonus de Bun-Venit”
          </strong>
        </p>
        <p>
          În continuare puteți consulta termenii și condițiile aplicabile pentru Campania
          Promoțională „Bonus de Bun-Venit” (în continuare, „Campania Promoțională”) desfășurată pe
          www.magicjackpot.ro („Website”).
        </p>
        <p>
          <strong>Condiții de acordare a Bonusului de Bun-Venit</strong>
        </p>
        <p>
          1. Pentru a obține Bonusul de Bun-Venit, trebuie să-l activați mai întâi fie din secțiunea
          „Acasă”, fie din pagina de depunere de pe Website.
        </p>
        <p>
          2. Pentru a putea beneficia de Bonusul de Bun-Venit este necesară efectuarea unei depuneri
          a cărei valoare să fie de minim 50 LEI.
        </p>
        <p>
          3. Bonusul de Bun-Venit se acordă, după cum urmează:
          <br />- La prima depunere, MagicJackpot vă oferă un Bonus de 100% din valoarea depunerii,
          fără ca Bonusul să depășească 1000 LEI;
          <br />- La cea de-a doua depunere, MagicJackpot vă oferă un Bonus de 80% din valoarea
          depunerii, fără ca Bonusul să depășească 800 LEI.
          <br />- La cea de-a treia depunere, MagicJackpot vă oferă un Bonus de 60% din valoarea
          depunerii, fără ca Bonusul să depășească 600 LEI.
        </p>
        <p>
          4. În plus față de Bonusul de Bun-Venit, sunteți eligibil și pentru 600 de rotiri bonus
          („Rotiri Bonus”) pe care le veți putea utiliza la jocurile Shining Crown, Hot to Burn, 20
          Super Hot & Burning Hot. Cele 600 Rotiri Gratuite sunt acordate și pot fi revendicate după
          cum urmează:
          <br />- 200 Rotiri Gratuite pentru Prima Depunere - La prima depunere, MagicJackpot vă
          oferă 200 Rotiri Gratuite la Shining Crown - 40 Rotiri Gratuite în fiecare zi timp de 5
          zile.
          <br />- 100 Rotiri Gratuite pentru a Doua Depunere - La a doua depunere, MagicJackpot vă
          oferă 100 Rotiri Gratuite la Hot to Burn - toate creditate instant.
          <br />- 100 Rotiri Gratuite pentru a Treia Depunere - La a treia depunere, MagicJackpot vă
          oferă 100 Rotiri Gratuite la 20 Super Hot - toate creditate instant.
          <br />- 200 Rotiri Gratuite pentru Verificare - La verificarea completă KYC a contului,
          MagicJackpot vă oferă 200 Rotiri Gratuite la Burning Hot, toate creditate instant.
        </p>
        <p>
          5a. Pentru a revendica 200 Rotiri Gratuite pentru Prima Depunere, este necesară o depunere
          de cel puțin 50 LEI.
        </p>
        <p>
          5b. Pentru a revendica 100 Rotiri Gratuite pentru a Doua Depunere, este necesară o
          depunere de cel puțin 50 LEI.
        </p>
        <p>
          5c. Pentru a revendica 100 Rotiri Gratuite pentru a Treia Depunere, este necesară o
          depunere de cel puțin 50 LEI.
        </p>
        <p>
          5d. Pentru a revendica 200 Rotiri Gratuite pentru Verificare, trebuie să vă verificați
          complet contul în secțiunea “Setările mele” și să fi efectuat cel puțin o depunere.
        </p>
        <p>
          6a. Rotirile Gratuite pentru Prima Depunere sunt adăugate automat la joc după depunere.
          Pentru a le revendica, accesați jocul.
        </p>
        <p>
          6b. Rotirile Gratuite pentru a Doua Depunere sunt adăugate automat la joc după depunere.
          Pentru a le revendica, accesați jocul.
        </p>
        <p>
          6c. Rotirile Gratuite pentru a Treia Depunere sunt adăugate automat la joc după depunere.
          Pentru a le revendica, accesați jocul.
        </p>
        <p>
          6d. Rotirile Gratuite pentru Verificare sunt disponibile pentru utilizare după accesarea
          cardului bonus de pe pagina de pornire, doar după ce contul dvs. a fost complet verificat
          și ați efectuat cel puțin o depunere.
        </p>
        <p>6e. Fiecare Rotire Gratuită pentru Prima Depunere are o valoare de 0.20 LEI.</p>' + '
        <p>6f. Fiecare Rotire Gratuită pentru a Doua Depunere are o valoare de 0.25 LEI.</p>
        <p>6g. Fiecare Rotire Gratuită pentru a Treia Depunere are o valoare de 0.20 LEI.</p>
        <p>6h. Fiecare Rotire Gratuită pentru Verificare are o valoare de 0.20 LEI.</p>
        <p>
          <strong>Condiții de valabilitate a bonusului</strong>
        </p>
        <p>7. Oferta de Bonus de Bun-Venit va expira la 30 de zile de la înregistrare.</p>
        <p>
          8a. În fiecare zi din perioada activă a bonusului de 200 Rotiri Gratuite pentru Prima
          Depunere vor fi creditate 40 Rotiri Gratuite timp de 5 zile și vor expira la 1 zi după ce
          au fost creditate. Cerințele de rulaj trebuie îndeplinite în termen de 30 de zile de la
          creditarea bonusului.
        </p>
        <p>
          8b. Cele 100 Rotiri Gratuite pentru a Doua Depunere vor fi creditate instantaneu și vor
          expira la 3 zile după creditare. Cerințele de rulaj trebuie îndeplinite în termen de 3
          zile de la creditarea bonusului.
        </p>
        <p>
          8c. Cele 100 Rotiri Gratuite pentru a Treia Depunere vor fi creditate instantaneu și vor
          expira la 3 zile după creditare. Cerințele de rulaj trebuie îndeplinite în termen de 3
          zile de la creditarea bonusului.
        </p>
        <p>
          8d. Cele 200 Rotiri Gratuite pentru Verificare vor fi creditate instantaneu și vor expira
          la 3 zile după creditare. Cerințele de rulaj trebuie îndeplinite în termen de 3 zile de la
          creditarea bonusului.
        </p>
        <p>
          <strong>Condiții de rulare a bonusului</strong>
        </p>
        <p>
          9a. Bonusul de Bun-Venit (B) și depunerea (D) trebuie sa fie rulate de 35 de ori ((D + B)
          x35) înainte de a se putea face o retragere.
        </p>
        <p>
          9b. Pentru depunerile efectuate fizic la o sucursală Superbet, Bonusul de Bun-Venit va fi
          adăugat doar la cererea jucătorului și doar dacă suma depusă nu a fost folosită.
        </p>
        <p>
          10. Câștigurile obținute din Rotirile Bonus sunt supuse unei condiții de rulaj de 35X.
        </p>
        <p>11. Contribuția la cerința de pariere începe la prima rotire după primirea bonusului.</p>
        <p>
          12. Retragerile pot fi solicitate înainte de îndeplinirea cerințelor de pariere. Cu toate
          acestea, formularea unei cereri de retragere înainte de îndeplinirea condițiilor de rulare
          va anula atât valoarea Bonusului de Bun-Venit, a Rotirilor Bonus, cât și a câștigurilor
          derivate din acestea.
        </p>
        <p>
          13. Miza maximă, singulară, care poate fi plasată cu fondurile din balanța Bonus este de
          25 RON, iar aceasta se aplică și pentru Bonus Buy. MagicJackpot își rezervă dreptul de a
          anula orice Bonus (Bonus Buy) și câștigurile derivate din acestea, în cazul în care
          utilizatorul plasează o miză ce depășește 25 RON în timpul cerinței de rulaj.
        </p>
        <p>
          14. Orice bonus activ este automat eliminat în momentul în care nu mai există fonduri
          bonus în soldul unui jucător. „Stivuirea fondurilor bonus” este complet interzisă în
          timpul îndepliniri cerințelor de rulaj. „Stivuirea fondurilor bonus“ este definită ca
          plasarea unui pariu cu fonduri bonus și lăsând runde de joc neterminate în interiorul
          jocurilor, pentru a reveni ulterior la momentul în care parierea este completă sau aproape
          de finalizare.
        </p>
        <p>
          Acest termen se aplică și jocurilor care au o opțiune de cumpărare a unui bonus. Dacă un
          astfel de bonus este achiziționat cu fonduri bonus sau blocate, iar totalul pentru
          cumpărarea bonusului este mai mare de 25 LEI, atunci aceasta este clasificată ca miză
          unică și, prin urmare, este peste miza unică maximă admisă cu bonus sau fonduri blocate de
          25 LEI.
        </p>
        <p>
          14. Orice bonus activ este automat eliminat în momentul în care nu mai există fonduri
          bonus în soldul unui jucător. „Stivuirea fondurilor bonus” este complet interzisă în
          timpul îndepliniri cerințelor de rulaj .„ Stivuirea fondurilor bonus “este definită ca
          plasarea unui pariu cu fonduri bonus și lăsând runde de joc neterminate în interiorul
          jocurilor, pentru a reveni ulterior la momentul în care parierea este completă sau aproape
          de finalizare.
        </p>
        <p>
          ”Stivuirea bonusului”, adică lăsarea rundelor neterminate în cadrul jocurilor pentru a
          reveni mai târziu, când parierea bonusului s-a finalizat pentru colectarea eventualelor
          câștiguri, nu este permisa la Magic Jackpot.
        </p>
        <p>
          15. În cazul în care există fonduri în balanța reală a jucătorului, atunci când se
          plasează un pariu, în vederea îndeplinirii condițiilor de rulaj, vor fi utilizați întâi
          banii din balanța reală, iar apoi banii din bonusul acordat.
        </p>
        <p>
          16. Jocurile de Cazino contribuie la cerința de pariere, după cum urmează: Sloturi: 100%
          Toate Jackpoturile: 0% Toate jocurile Live Roulette și Live Blackjack: 10% Toate celelalte
          jocuri Cazinou Live: 10% Toate jocurile de masă: 5%
        </p>
        <p>
          17. Activitatea de joc în cadrul următoarelor jocuri nu contribuie la condițiile de rulare
          a Bonusului: {excludedGames}.
        </p>
        <p>Alte Jocuri pot fi excluse periodic de la condițiile de rulare a Bonusurilor.</p>
        <p>
          <strong>Alte prevederi</strong>
        </p>
        <p>
          18. Prezenta Campanie Promoțională se supune Termenilor si Condițiilor Generale
          MagicJackpot.
        </p>
        <p>
          19. Societatea poate anula, modifica sau suspenda Campania Promoțională și toate
          bonusurile și beneficiile oferite în cadrul acesteia, incluzând dar fără a se limita la
          motive ce țin de securitate, legi și reglementări în vigoare, ca urmare a unor abuzuri sau
          alte motive care împiedică desfășurarea promoției așa cum este intenționat, ca urmare a
          unor circumstanțe excepționale. Anularea, modificarea sau suspendarea Campaniei
          Promoționale se va face cu notificarea prealabilă scrisă a Utilizatorilor.
        </p>
        <p>
          20. Refuzul de a furniza informațiile solicitate de MJP cu privire la Utilizator, poate
          determina anularea Bonusului și implicit a câștigurilor obținute folosind acest Bonus.
        </p>
        <p>
          21. Retragerile utilizând opțiunea “Store Pickup” din casieria contului MagicJackpot sunt
          eligibile doar pentru jucătorii care au depus în prealabil fizic prin intermediul
          sucursalelor Superbet.
        </p>
        <p>
          22. MJP Interactive Limited, o companie înregistrată în Malta sub Numărul Uniform de
          Identificare al Companiei C95830 și cu sediul în Level 1 Burlington Complex, Dragonara
          Road, St. Julians, Malta, prin MJP Interactive Ltd. St. Julians Sucursala Bucuresti, Număr
          de Înregistrare J40/22589/2023, CUI: 49190978, cu sediul in București, sector 5, str,
          Serg. Ion Nuțu nr. 44, et 8 (denumită în acești termeni „ MagicJackpot”, „noi, ”sau„ al
          nostru).
        </p>
      </div>
    </ModalBody>
  );
};

export default GeneralBonusTerms;
