import { WpPrivacyPolicy } from '@/models/wp-privacy-policy.model';
import { WpTermsAndConditions } from '@/models/wp-terms-and-conditions.model';
import { WordpressActions, WordpressActionType } from '@/store/actions/wordpressActions';

export interface WordpressStateModel {
  termsAndConditions: WpTermsAndConditions | null;
  privacyPolicy: WpPrivacyPolicy | null;
}

const initialState: WordpressStateModel = {
  termsAndConditions: null,
  privacyPolicy: null,
};

export const wordpressReducer = (
  state = initialState,
  action: WordpressActions,
): WordpressStateModel => {
  switch (action.type) {
    case WordpressActionType.GetTermsAndConditionsSuccess:
      return {
        ...state,
        termsAndConditions: action.terms,
      };
    case WordpressActionType.GetPrivacyPolicySuccess:
      return { ...state, privacyPolicy: action.privacyPolicy };
    default:
      return state;
  }
};
