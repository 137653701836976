import React from 'react';

import { useSelector } from 'react-redux';

import BonusCard from '@/components/bonuses/BonusCard';
import { selectActiveBonusesWithContent } from '@/store/selectors/activeBonusesSelectors';
import { isEmpty } from '@/utils/helpers';

function ActiveBonuses(): JSX.Element {
  const activeBonuses = useSelector(selectActiveBonusesWithContent);

  return (
    <div id="e2e_activeBonuses" className="mx-auto py-16">
      <div className="mb-12">
        <h1 className="mb-8 text-2xl md:text-3xl">Bonusuri active</h1>
        <p>
          Aici îți găsești toate bonusurile active. Nu uita că poți să anulezi bonusurile, dacă nu
          dorești să le utilizezi.
        </p>
      </div>
      {!isEmpty(activeBonuses) ? (
        <div className="bonuses grid grid-cols-1 justify-items-center gap-8 md:grid-cols-2">
          {activeBonuses.map(bonus => (
            <BonusCard
              userBonusId={bonus.UserBonusId}
              type="regular"
              header={bonus.Content?.header || bonus.Name}
              description={`<p>${bonus.Content?.description || bonus.Description}</p>`}
              // 1336 is type to be able to forfeit bonus
              redeemTypeId={1336}
              bonusCode={bonus.PromoCode}
              key={`active-bonus-${bonus.BonusId}`}
              terms={bonus.Content?.bonus_terms}
              image={bonus.Content?.image}
            />
          ))}
        </div>
      ) : (
        <p id="e2e_noActiveBonuses">Nu ai bonusuri active</p>
      )}
    </div>
  );
}

export default ActiveBonuses;
