import { createSelector } from 'reselect';

import { targetedBtags } from '@/lib/showCustomBtagContent';
import { ExtendedUserModel } from '@/models/extended-user.model';
import { RootState } from '@/models/root-state.model';
import { UserTag } from '@/models/user-tag.model';

export const selectUser = (state: RootState): ExtendedUserModel | null => state.user.user;

export const selectUserTags = (state: RootState): UserTag[] => state.user.tags;
export const selectUserTagsLoaded = (state: RootState): boolean => state.user.tagsLoaded;
export const selectUserTagsLoading = (state: RootState): boolean => state.user.tagsLoading;

export const selectUserId = createSelector(selectUser, user => user?.UserID);
export const selectUserCurrency = createSelector(selectUser, user => user?.Currency);
export const selectUserMobilePrefix = createSelector(selectUser, user => user?.MobilePrefix);
export const selectUserMobileNumber = createSelector(selectUser, user => user?.Mobile);

export const selectStreamerUser = createSelector(selectUserTags, userTags =>
  userTags.some(tag => tag.Name === 'Streamer'),
);

export const selectKycVerified = createSelector(selectUserTags, userTags =>
  userTags.some(tag => tag.Name === 'KYCVerified'),
);

export const selectIdVerified = createSelector(selectUserTags, userTags =>
  userTags.some(tag => tag.Name === 'IDVerified'),
);

export const selectTargetedBtagUser = createSelector(selectUser, user => {
  if (!user) {
    return false;
  }
  return targetedBtags.some(tag => user.Reference?.includes(tag));
});
export const selectUserLigaOptIn = createSelector(selectUserTags, userTags => {
  if (userTags.length === 0) {
    return false;
  }
  return userTags.some(tag => tag.Name === 'LigaMagicaOptIn');
});

export const selectUserIsDepositor = createSelector(selectUserTags, userTags => {
  if (userTags.length === 0) {
    return false;
  }
  return userTags.some(tag => tag.Name === 'Depositor');
});

export const selectUserHasKYCVerified = createSelector(selectUserTags, userTags => {
  if (userTags.length === 0) {
    return false;
  }
  return userTags.some(tag => tag.Name === 'KYCVerified');
});

export const selectUserHasIDVerified = createSelector(selectUserTags, userTags => {
  if (userTags.length === 0) {
    return false;
  }
  return userTags.some(tag => tag.Name === 'IDVerified');
});

export const selectUserIsBonusRestricted = createSelector(selectUserTags, userTags => {
  if (userTags.length === 0) {
    return false;
  }
  return userTags.some(tag => tag.Name === 'Bonus Restricted');
});

export const selectUserIsLigaVerified = createSelector(
  selectUserHasIDVerified,
  selectUserHasKYCVerified,
  (idVerified, kycVerified) => {
    return idVerified || kycVerified;
  },
);
