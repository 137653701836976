import React from 'react';

import { Outlet, NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledHistory = styled.div`
  padding: 1rem 0 4rem;

  .navigation {
    margin: 3rem 0 2rem;
    display: flex;
    align-items: center;

    h1 {
      margin: 0 2rem 0 0;
    }

    a {
      padding: 0.8rem 1rem;
      border: 2px solid ${props => props.theme.black};
      border-radius: 8px;
      margin-left: 1rem;
      font-weight: 700;
      font-size: 15px;

      &.active {
        background: ${props => props.theme.black};
        color: white;
      }
    }
  }

  p {
    max-width: 520px;
    margin-bottom: 2rem;
  }
`;

function History(): JSX.Element {
  return (
    <StyledHistory>
      <div className="intro">
        <div className="navigation">
          <h1>Istoric</h1>
          <div>
            <NavLink end to="/setarile-mele/istoric">
              Jocuri
            </NavLink>
            <NavLink end to="/setarile-mele/istoric/plati">
              Plăți
            </NavLink>
          </div>
        </div>
        <p>
          Verificați istoricul jocurilor și al plăților. În prezent, putem furniza istoricul
          tranzacțiilor cu până la 90 de zile în trecut.
        </p>
      </div>

      <Outlet />
    </StyledHistory>
  );
}

export default History;
