import React, { useEffect, useState } from 'react';

import { AuthMethod, BffAuthenticationMethodResponse } from '@lucky7ventures/bff-types';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import Button from '@/components/button/Button';
import ToggleSwitch from '@/components/form/ToggleSwitch';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import BffApiService from '@/shared/bff-api-service';
import { selectUserMobileNumber, selectUserMobilePrefix } from '@/store/selectors/userSelectors';

interface FormValues {
  allow2fa: boolean;
}

const TwoFactorAuthentication = () => {
  const [authMethod, setAuthMethod] = useState(-1);
  const mobilePrefix = useSelector(selectUserMobilePrefix);
  const mobileNumber = useSelector(selectUserMobileNumber);
  const { request: getAuthenticationMethod } = useBffApiRequest<BffAuthenticationMethodResponse>();
  const {
    request: setAuthenticationMethod,
    loading: setAuthLoading,
    error: setAuthError,
    success: setAuthSuccess,
  } = useBffApiRequest<null>();

  const initialValues: FormValues = {
    allow2fa: authMethod === AuthMethod.TwoFactorBySms,
  };

  const handleOnSubmit = (values: FormValues) => {
    setAuthenticationMethod({
      apiMethod: BffApiService.setAuthenticationMethod,
      pathParams: values.allow2fa ? AuthMethod.TwoFactorBySms : AuthMethod.OneFactor,
    });
    setAuthMethod(values.allow2fa ? AuthMethod.TwoFactorBySms : AuthMethod.OneFactor);
  };

  useEffect(() => {
    const handleOnSuccess = (method: BffAuthenticationMethodResponse) => {
      setAuthMethod(method);
    };
    getAuthenticationMethod({
      apiMethod: BffApiService.getAuthenticationMethod,
      successCallback: handleOnSuccess,
    });
  }, []);

  return (
    <div className="gap flex flex-col">
      <h2>Cod SMS</h2>
      <p className="mt-6">
        Pentru o protecție suplimentară a contului, puteți opta să primiți un cod de fiecare dată
        când vă conectați. Acest cod va trebui introdus împreună cu numele de utilizator și parola.
      </p>
      <div className="text-sm opacity-50">
        Numărul de mobil înregistrat: {`${mobilePrefix} ${mobileNumber}`}
      </div>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleOnSubmit}>
        {({ dirty }) => (
          <Form>
            <Field name="allow2fa">
              {({ field }: FieldProps) => (
                <ToggleSwitch
                  id="allow2fa"
                  label="Prin SMS"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            </Field>
            {setAuthError && <p className="error">Error: {setAuthError}</p>}
            {setAuthSuccess && <p className="success">Detalii Salvate!</p>}
            <div className="button-wrapper">
              <Button type="submit" className="submit" disabled={!dirty || setAuthLoading}>
                {setAuthLoading ? 'Se încarcă...' : 'Salvează setările'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TwoFactorAuthentication;
