import { createSelector } from 'reselect';

import { BonusCardProps } from '@/components/bonuses/BonusCard';
import { RootState } from '@/models/root-state.model';
import {
  selectAvailableBonusesFetched,
  selectFirstAvailableBonus,
} from '@/store/selectors/availableBonusesSelectors';
import { selectBtag } from '@/store/selectors/cookiesSelectors';
import { selectFirstManualBonus } from '@/store/selectors/manualBonusesSelectors';
import { selectUser } from '@/store/selectors/userSelectors';
import { normalizeDateWithTime } from '@/utils/helpers';

export const selectHeroBonus = createSelector(
  selectFirstAvailableBonus,
  selectFirstManualBonus,
  selectAvailableBonusesFetched,
  (_state: RootState, isMobile: boolean) => isMobile,
  (availableBonus, manualBonus, availableBonusesFetched, isMobile): BonusCardProps | null => {
    if (!availableBonusesFetched) {
      return null;
    }

    if (availableBonus) {
      return {
        header: availableBonus.Content.header,
        description: availableBonus.Content.description,
        availableUntil: availableBonus.Content.show_expiration_date ? availableBonus.DateTo : '',
        buttonLink: isMobile
          ? availableBonus.Content.mobile_game_link
          : availableBonus.Content.desktop_game_link,
        redeemTypeId: availableBonus.RedeemTypeId,
        bonusCode: availableBonus.PromoCodes ? availableBonus.PromoCodes[0] : '',
        terms: availableBonus.Content.bonus_terms,
        type: 'regular',
        image: availableBonus.Content.image,
      };
    }

    if (manualBonus) {
      return {
        header: manualBonus.header,
        description: isMobile ? manualBonus.description_mobile : manualBonus.description_desktop,
        availableUntil: manualBonus.show_expiration_date
          ? normalizeDateWithTime(manualBonus.available_until)
          : '',
        buttonLink: isMobile ? manualBonus.mobile_button_link : manualBonus.button_link,
        redeemTypeId: 1337,
        buttonText: manualBonus.button_text,
        terms: manualBonus.terms,
        type: 'manual',
        image: manualBonus.image,
      };
    }

    return null;
  },
);

export const selectUserBtag = createSelector(selectUser, selectBtag, (user, btag) => {
  return user ? user.Reference : btag;
});
