import React from 'react';

import styled from 'styled-components/macro';

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
`;

function Loader(): JSX.Element {
  return <StyledLoader className="loader">Se încarcă...</StyledLoader>;
}

export default Loader;
