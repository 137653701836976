import { KycDocumentResponseDto } from '@lucky7ventures/lucky-components';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  PutEffect,
  SelectEffect,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { ApiResponseModel } from '@/models/api-response.model';
import { DocumentTypeEnum } from '@/models/enums/document-type.enum';
import { getKycError, getKycSuccess, KycActionsTypes } from '@/store/actions/kycActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';

export function* getKycSaga(): Generator<
  PutEffect | Promise<AxiosResponse> | SelectEffect,
  void,
  AxiosResponse<ApiResponseModel<KycDocumentResponseDto[]>>
> {
  const token = yield select(selectAuthToken);

  if (!token) {
    yield put(getKycError());
    return;
  }

  try {
    const { data } = yield axios.post<ApiResponseModel<KycDocumentResponseDto[]>>('/api/user/kyc', {
      token,
    });

    if (!data?.success) {
      yield put(getKycError());
      return;
    }

    // Check if user has KYC's
    if (data?.data && data.data.length > 0) {
      // User has KYC's -> return them
      yield put(getKycSuccess(data.data));
      return;
    }

    // User doesn't have any KYC's.
    // If so, add KYC document request for the Id
    // Check if user has KYC's again else return empty KYC array
    const { data: kycRequirementsData } = yield axios.post<ApiResponseModel<boolean>>(
      '/api/user/kycdocumentrequest',
      { token, documentType: DocumentTypeEnum.Id },
    );

    if (!kycRequirementsData.success) {
      yield put(getKycError());
      return;
    }

    const { data: retryData } = yield axios.post<ApiResponseModel<KycDocumentResponseDto[]>>(
      '/api/user/kyc',
      { token },
    );

    if (retryData?.data && retryData.data.length > 0) {
      yield put(getKycSuccess(retryData.data));
    } else {
      yield put(getKycError());
    }
  } catch (error) {
    yield put(getKycError());
  }
}

export function* watchKyc(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(KycActionsTypes.Get, getKycSaga)]);
}
