import { Action } from 'redux';

export enum AuthActionTypes {
  LoginSuccess = 'LOGIN_SUCCESS',
  CheckIfLoggedIn = 'CHECK_IF_LOGGED_IN',
  Logout = 'LOGOUT',
  Impersonate = 'IMPERSONATE',
  ResetAuth = 'RESET_AUTH',
}

export type LoginSuccessAction = Action<AuthActionTypes.LoginSuccess> & {
  token: string;
};
export type CheckIfLoggedInAction = Action<AuthActionTypes.CheckIfLoggedIn> & { token: string };
export type LogoutAction = Action<AuthActionTypes.Logout> & { token: string };
export type ImpersonateAction = Action<AuthActionTypes.Impersonate> & { token: string };
export type ResetAuthAction = Action<AuthActionTypes.ResetAuth>;

export type AuthActions =
  | LoginSuccessAction
  | CheckIfLoggedInAction
  | LogoutAction
  | ImpersonateAction
  | ResetAuthAction;

export const loginSuccess = (token: string): LoginSuccessAction => ({
  type: AuthActionTypes.LoginSuccess,
  token,
});

export const checkIfLoggedIn = (token: string): CheckIfLoggedInAction => ({
  type: AuthActionTypes.CheckIfLoggedIn,
  token,
});

export const logout = (token: string): LogoutAction => ({
  type: AuthActionTypes.Logout,
  token,
});

export const impersonate = (token: string): ImpersonateAction => ({
  type: AuthActionTypes.Impersonate,
  token,
});

export const resetAuth = (): ResetAuthAction => ({
  type: AuthActionTypes.ResetAuth,
});
