import * as React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Anchor from '@/components/anchor/Anchor';
import Input from '@/components/form/Input';
import openZendesk from '@/hooks/openZendesk';
import { selectUser } from '@/store/selectors/userSelectors';
import { concatenateValues, getBirthdateDetails } from '@/utils/helpers';

const StyledUserDetails = styled.div`
  width: 100%;

  .grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 1rem;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      grid-column-gap: 2rem;
    }

    .full {
      grid-column: 1/-1;
    }

    .two {
      grid-column: 1 / 3;
    }
  }

  .contact-support {
    font-size: 0.8rem;
    text-align: right;
    margin-top: 16px;

    button {
      font-weight: 600;
      font-size: 1em;
      color: ${props => props.theme.green};
    }
  }
`;

function UserDetails(): JSX.Element {
  const user = useSelector(selectUser);
  const { year, month, day } = getBirthdateDetails(user?.BirthDate);

  return (
    <StyledUserDetails>
      <h2>Detalii personale</h2>
      <div className="grid">
        <Input
          name="fullName"
          type="text"
          placeholder="-"
          label="Numele complet"
          disabled
          className="full"
          value={concatenateValues([user?.FirstName, user?.LastName])}
        />
        <Input name="year" type="text" placeholder="-" label="Anul" disabled value={year} />
        <Input name="month" type="text" placeholder="-" label="Luna" disabled value={month} />
        <Input name="day" type="text" placeholder="-" label="Ziua" disabled value={day} />
        <Input
          name="address"
          type="text"
          placeholder="-"
          label="Adresa"
          disabled
          className="full"
          value={concatenateValues([user?.Address1, user?.City], ', ')}
        />
        <Input
          name="country"
          type="text"
          placeholder="-"
          label="Țara"
          disabled
          className="two"
          value={user?.CountryName || ''}
        />
        <Input
          name="postCode"
          type="text"
          placeholder="-"
          label="Cod Poștal"
          disabled
          value={user?.PostalCode || ''}
        />
        <Input
          name="mobile"
          type="text"
          placeholder="-"
          label="Telefon mobil"
          disabled
          className="full"
          value={concatenateValues([user?.MobilePrefix, user?.Mobile])}
        />
        <Input
          name="email"
          type="text"
          placeholder="-"
          label="Email"
          disabled
          className="full"
          value={user?.Email || ''}
        />
      </div>
      <p className="full contact-support">
        Trebuie să actualizezi datele?{' '}
        <Anchor onClick={() => openZendesk(user?.FirstName, user?.LastName, user?.Email)}>
          Te rugăm contactează serviciul clienți.
        </Anchor>
      </p>
    </StyledUserDetails>
  );
}

export default UserDetails;
