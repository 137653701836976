import React from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@/components/button/Button';
import { cdnImage } from '@/utils/image-utils';

const LigaBanner = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div
      data-cy="LIGA_BANNER"
      className="bg-center relative bg-brand-liga-red bg-liga-hero-sm sm:bg-liga-hero-xl bg-cover bg-no-repeat overflow-hidden flex justify-center px-8 md:px-16"
    >
      <div className="hidden md:block absolute z-0 -top-80 shadow-lg -bottom-80 -right-80 -left-80 translate-x-[30%] lg:translate-x-[20%] xl:translate-x-[10%] -translate-y-64 rotate-45 bg-brand-liga-red"></div>
      <div className="block md:hidden rounded-[50%] absolute z-0 top-0 -left-64 -right-64 bg-brand-liga-red bottom-0 -translate-y-[40%] -translate-x-24"></div>
      <div className="xs:left-8 absolute top-[50%] md:right-[55%] md:top-8 bottom-0 md:bottom-4 object-cover">
        <img
          src={cdnImage('liga/liga-banner-wheel.png', {
            height: '450',
            width: 'auto',
          })}
          alt="Liga Wheel"
          className="max-h-full"
        />
      </div>
      <div className="mx-auto text-center md:text-left md:ml-[60%] lg:ml-[60%] xl:ml-[50%] relative text-white my-12 lg:my-16 xl:my-24 pb-80 md:pb-0">
        <div className="text-4xl md:text-5xl font-bold mb-8">LigaMagică</div>
        <div className="text-md md:text-lg mb-8 max-w-sm">
          Joacă, învârte și câștigă la MagicJackpot! Fii recompensat cu premii garantate în fiecare
          săptămână la LigaMagică.
        </div>
        <Button size="regular" color="black" onClick={() => navigate('/liga-magica')}>
          Joacă Acum
        </Button>
      </div>
    </div>
  );
};

export default LigaBanner;
