import { CookiesActions, CookiesActionType } from '@/store/actions/cookiesActions';

export interface CookiesState {
  btag: string | undefined;
  clickId: string | undefined;
}

const initialState: CookiesState = {
  btag: undefined,
  clickId: undefined,
};

export const cookiesReducer = (state = initialState, action: CookiesActions): CookiesState => {
  switch (action.type) {
    case CookiesActionType.SetBtag:
      return { ...state, btag: action.btag };
    case CookiesActionType.RemoveBtag:
      return { ...state, btag: undefined };
    case CookiesActionType.SetClickId:
      return { ...state, clickId: action.clickId };
    default:
      return state;
  }
};
