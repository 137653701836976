import { BffGameItem } from '@lucky7ventures/bff-types';

import {
  CDN_BASE,
  CDN_IMAGES_COMMON_PATH,
  CDN_IMAGES_GAMES_PATH,
  CDN_IMAGES_PATH,
} from '@/constants/constants';

interface CDNImageProps {
  width: string;
  height: string;
  fit?: string;
  gravity?: string;
  dpr?: string;
  path?: string;
}

export const cdnImage = (img: string, props: CDNImageProps): string => {
  return `${CDN_BASE}/cdn-cgi/image/fit=${props.fit || 'contain'},width=${props.width},height=${
    props.height
  },gravity=${props.gravity || '1x0'},format=auto,dpr=${props.dpr || '1'}${
    props.path || CDN_IMAGES_COMMON_PATH
  }/${img}`;
};

export const gameThumbImg = (game: BffGameItem): string => {
  return cdnImage(`${game.slug}.jpg`, {
    width: '167',
    height: '205',
    dpr: '2',
    path: CDN_IMAGES_GAMES_PATH,
  });
};

export const gameProviderImg = (slug: string) =>
  `${CDN_BASE}/cdn-cgi/image/fit=cover,width=200px,height=auto,gravity=1x0,format=auto${CDN_IMAGES_PATH}/game-providers/${slug}.png`;

export const paymentProviderImg = (slug: string) =>
  `${CDN_BASE}/cdn-cgi/image/fit=contain,width=400px,height=auto,gravity=1x0,format=auto${CDN_IMAGES_PATH}/common/payment-providers/${slug}.png`;
