import * as React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import ParsedString from '@/components/formats/ParsedString';
import { selectModalTermsProps } from '@/store/selectors/modalSelectors';

const StyledBonusTerms = styled.div`
  background: ${props => props.theme.white};
  width: 320px;
  min-height: 600px;
  display: block;
  padding: 4rem 2rem;
  overflow: hidden;
  position: relative;
  text-align: left;
  margin-bottom: 32px;
  border-radius: 0.5rem;

  @media (min-width: 400px) {
    width: 380px;
  }

  h1 {
    font-size: 1.5rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
    max-width: 520px;
  }
`;

function BonusTerms(): JSX.Element | null {
  const termsModalProps = useSelector(selectModalTermsProps);

  if (!termsModalProps) {
    return null;
  }

  return (
    <StyledBonusTerms>
      {termsModalProps.header && (
        <div className="mb-2 text-xl font-bold">{termsModalProps.header}</div>
      )}
      <ParsedString string={termsModalProps.terms} />
    </StyledBonusTerms>
  );
}

export default BonusTerms;
