import React from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useCashierHide } from '@/hooks/useCashier';
import { selectLifetimeDepositsAmount } from '@/store/selectors/depositSelectors';
import { selectIsPhotoIdApproved } from '@/store/selectors/kycSelectors';
import { selectUserHasIDVerified, selectUserHasKYCVerified } from '@/store/selectors/userSelectors';

const RemainingDepositLimit = () => {
  const lifetimeDepositsAmount = useSelector(selectLifetimeDepositsAmount) ?? 0;
  const kycVerified = useSelector(selectUserHasKYCVerified);
  const hasIDVerifiedTag = useSelector(selectUserHasIDVerified);
  const photoIdVerified = useSelector(selectIsPhotoIdApproved);
  const closeCashier = useCashierHide();

  // We don't want to show the remaining deposit limit if user has fully KYCed or just photoIDed
  if (kycVerified || photoIdVerified || hasIDVerifiedTag) {
    return null;
  }

  const remainingDepositAmount = lifetimeDepositsAmount < 950 ? 950 - lifetimeDepositsAmount : 0;

  return (
    <div
      className="flex flex-col items-start gap-3 bg-gradient-to-b from-brand-gray to-brand-gray/30 px-4 py-4 w-fit
      rounded-md font-normal text-sm"
    >
      <div className="flex items-center gap-2">
        <ExclamationCircleIcon className="text-white h-5 w-5" />
        <span>Limită la depunere</span>
      </div>
      <div className="w-full flex flex-col items-start gap-1">
        <span className="font-normal">
          Mai aveți <span className="text-brand-gold font-bold">{remainingDepositAmount} RON</span>{' '}
          de depus
        </span>
        <div className="h-2 bg-white rounded-sm w-full overflow-hidden">
          <div
            className="bg-green-500 h-full"
            style={{ width: `${(lifetimeDepositsAmount / 950) * 100}%` }}
          ></div>
        </div>
      </div>
      <span>
        Vă rugăm să vă{' '}
        <Link
          to="/setarile-mele/verificare"
          onClick={() => closeCashier()}
          className="text-sm underline"
        >
          verificați contul
        </Link>{' '}
        pentru a elimina limita
      </span>
    </div>
  );
};

export default RemainingDepositLimit;
