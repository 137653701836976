import { useEffect } from 'react';

import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';

import { GTagEvents } from '@/lib/gTagManager';
import { useCookieConsentStore } from '@/store/cookieConsentStore';
import { selectUserBtag } from '@/store/selectors/combinedSelectors';
import { selectUserCurrency, selectUserId } from '@/store/selectors/userSelectors';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

function gtag() {
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  dataLayer.push(arguments);
}

const defaultGTMConsents = (): void => {
  // @ts-ignore
  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  });
};

const updateGTMConsents = (granted: boolean): void => {
  const consent = granted ? 'granted' : 'denied';
  // @ts-ignore
  gtag('consent', 'update', {
    ad_storage: consent,
    ad_user_data: consent,
    ad_personalization: consent,
    analytics_storage: consent,
  });
  // Important! This is sending an event to GTM called consent_update, and we use it to trigger certain tags
  if (granted) {
    // @ts-ignore
    gtag('event', 'consents_granted');
  }
};

const useTagManagerInit = (): void => {
  const performanceCookiesAccepted = useCookieConsentStore(state => state.performance);
  const btag = useSelector(selectUserBtag);
  const userId = useSelector(selectUserId);
  const userCurrency = useSelector(selectUserCurrency);

  useEffect(() => {
    defaultGTMConsents();
    // @ts-ignore
    if (!window.Cypress) {
      TagManager.initialize({
        gtmId: 'GTM-5LDNKNQ',
      });
    }
  }, []);

  useEffect(() => {
    if (performanceCookiesAccepted) {
      updateGTMConsents(performanceCookiesAccepted);
    }
  }, [performanceCookiesAccepted]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      btag,
    });
  }, [btag]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    if (userId) {
      window.dataLayer.push({
        event: GTagEvents.user_id_set,
        userId,
      });
    } else {
      window.dataLayer.push({
        event: GTagEvents.user_id_unset,
        userId: undefined,
      });
    }
  }, [userId]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      currency: userCurrency,
    });
  }, [userCurrency]);
};

export default useTagManagerInit;
