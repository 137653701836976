import { Action } from 'redux';

import { FrontEndUserBonusObject } from '@/models/gig/front-end-user-bonus.model';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';

export enum ActiveBonusesActionType {
  Get = 'GET_BONUSES',
  Success = 'GET_BONUSES_SUCCESS',
  Error = 'GET_BONUSES_ERROR',
  Reset = 'RESET_BONUSES',
  GetContent = 'GET_ACTIVE_BONUS_CONTENT',
  GetContentSuccess = 'GET_ACTIVE_BONUS_CONTENT_SUCCESS',
}

export type GetActiveBonusesAction = Action<ActiveBonusesActionType.Get> & {
  token?: string;
};

export type GetActiveBonusesSuccessAction = Action<ActiveBonusesActionType.Success> & {
  activeBonuses: FrontEndUserBonusObject[];
};

export type GetActiveBonusesErrorAction = Action<ActiveBonusesActionType.Error> & {
  error: boolean;
};

export type ResetActiveBonuses = Action<ActiveBonusesActionType.Reset>;

export type GetActiveBonusContentAction = Action<ActiveBonusesActionType.GetContent> & {
  bonusId: number;
};

export type GetActiveBonusContentSuccessAction =
  Action<ActiveBonusesActionType.GetContentSuccess> & {
    bonusId: number;
    content: WpBonusContentModel;
  };

export type ActiveBonusesAction =
  | GetActiveBonusesAction
  | GetActiveBonusesSuccessAction
  | GetActiveBonusesErrorAction
  | ResetActiveBonuses
  | GetActiveBonusContentAction
  | GetActiveBonusContentSuccessAction;

export const getActiveBonuses = (token?: string): GetActiveBonusesAction => ({
  type: ActiveBonusesActionType.Get,
  token,
});

export const getActiveBonusesSuccess = (
  activeBonuses: FrontEndUserBonusObject[],
): GetActiveBonusesSuccessAction => ({
  type: ActiveBonusesActionType.Success,
  activeBonuses,
});

export const getActiveBonusesError = (error: boolean): GetActiveBonusesErrorAction => ({
  type: ActiveBonusesActionType.Error,
  error,
});

export const resetActiveBonuses = (): ResetActiveBonuses => ({
  type: ActiveBonusesActionType.Reset,
});

export const getActiveBonusContent = (bonusId: number): GetActiveBonusContentAction => ({
  type: ActiveBonusesActionType.GetContent,
  bonusId,
});

export const getActiveBonusContentSuccess = (
  content: WpBonusContentModel,
  bonusId: number,
): GetActiveBonusContentSuccessAction => ({
  type: ActiveBonusesActionType.GetContentSuccess,
  content,
  bonusId,
});
