import { createSelector } from 'reselect';

import { PromotionVisibility } from '@/models/enums/promotion-visibility.enum';
import { RootState } from '@/models/root-state.model';
import { WpPromotion } from '@/models/wp-promotion.model';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';

export const selectPromotions = (state: RootState): WpPromotion[] => state.promotions.promotions;
export const selectPromotionsLoading = (state: RootState): boolean => state.promotions.loading;

export const selectVisiblePromotions = createSelector(
  selectAuthIsAuthenticated,
  selectPromotions,
  (isAuthenticated, promotions) => {
    return promotions
      .filter(
        promotion =>
          (promotion.visibility === PromotionVisibility.LoggedIn && isAuthenticated) ||
          (promotion.visibility === PromotionVisibility.LoggedOut && !isAuthenticated) ||
          promotion.visibility === PromotionVisibility.Both,
      )
      .filter(Boolean) as WpPromotion[];
  },
);

export const selectFocusedPromotion = createSelector(selectVisiblePromotions, visiblePromotions => {
  if (visiblePromotions.length === 0) return;

  return visiblePromotions[0];
});
