import { Action } from 'redux';

export enum CommonActionType {
  LoadZendesk = 'LOAD_ZENDESK',
}

export type LoadZendeskAction = Action<CommonActionType.LoadZendesk>;

export type CommonActions = LoadZendeskAction;

export const loadZendesk = (): LoadZendeskAction => ({
  type: CommonActionType.LoadZendesk,
});
