import * as React from 'react';

import Anchor from '@/components/anchor/Anchor';
import EyeClosedIcon from '@/components/icons/EyeClosedIcon';
import EyeOpenIcon from '@/components/icons/EyeOpenIcon';
import LockIcon from '@/components/icons/LockIcon';
import useToggle from '@/hooks/useToggle';
import { LoginSectionEnum } from '@/models/enums/login-section.enum';

interface InputProps {
  type: string;
  name: string;
  label: string;
  placeholder: string;
  autoComplete?: string;
  setLoginSection?: React.Dispatch<React.SetStateAction<LoginSectionEnum>>;
  disabled?: boolean;
  value: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  className?: string;
}

function Input({
  type,
  name,
  label,
  placeholder,
  autoComplete = 'off',
  setLoginSection,
  disabled = false,
  value,
  onBlur,
  onChange,
  autoFocus = false,
  className,
  ...props
}: InputProps): JSX.Element {
  const [showPassword, toggleShowPassword] = useToggle();

  return (
    <label
      htmlFor={name}
      className={`relative block w-full text-left font-bold text-[15px] ${className}`}
    >
      {label}
      <input
        type={showPassword ? 'text' : type}
        autoComplete={autoComplete}
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        autoFocus={autoFocus}
        className="ease font-normal text-base w-full mt-2 p-4 rounded-lg bg-none text-inherit
          transition-[background-color] duration-200 border-[1px] border-solid border-[rgba(0,0,0,0.1)] text-ellipsis
          focus:enabled:border-[rgba(0,0,0,0.2)] active:enabled:border-[rgba(0,0,0,0.2)] hover:enabled:border-[rgba(0,0,0,0.2)]
          disabled:pr-10 disabled:text-[rgba(0,0,0,0.64)] disabled:cursor-not-allowed"
        {...props}
      />
      {type === 'password' && (
        <>
          {setLoginSection && (
            <Anchor
              onClick={() => setLoginSection(LoginSectionEnum.ForgotPassword)}
              className="absolute right-0 top-0 text-[12px] font-semibold !no-underline hover:!underline"
            >
              Ai uitat?
            </Anchor>
          )}
          <button
            type="button"
            className="absolute top-[55%] right-0 border-none bg-none text-2xl flex justify-center items-center
            py-0 pl-0 pr-4"
            onClick={toggleShowPassword}
            tabIndex={-1}
          >
            {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
          </button>
        </>
      )}
      {disabled && (
        <div
          className="absolute top-[55%] right-0 border-none bg-none text-xl flex justify-center items-center
            py-0 pl-0 pr-4"
        >
          <LockIcon />
        </div>
      )}
    </label>
  );
}

export default Input;
