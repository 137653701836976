import React, { FocusEvent, memo, useEffect, useMemo, useState } from 'react';

import { Combobox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useField } from 'formik';

import BorderBeam from '@/components/border-beam/BorderBeam';
import FieldError from '@/components/form/field/FieldError';

export interface SearchDropdownValue {
  endIcon?: JSX.Element;
  label: string;
  value: string;
  nestedOnClick?: () => void;
  finalOnClick?: () => void;
}

export interface SearchDropdownProps {
  name: string;
  label: string;
  onChange?: (newValue: string) => void;
  onFocus?: () => void;
  options: Array<SearchDropdownValue>;
  onValidationError?: (error: string) => void;
  inputClassName?: string;
  labelClassName?: string;
  optionsClassName?: string;
  selectorIconClassName?: string;
  tooltip?: JSX.Element;
  placeholder?: string;
  loading?: boolean;
  'data-cy'?: string;
}

const SearchDropdownField = memo(
  function SearchDropdown({
    name,
    label,
    onChange,
    onFocus,
    options,
    onValidationError,
    inputClassName,
    labelClassName,
    optionsClassName,
    selectorIconClassName,
    tooltip,
    placeholder,
    loading,
    ...props
  }: SearchDropdownProps) {
    const [field, meta, helpers] = useField<string>(name);
    const [searchTerm, setSearchTerm] = useState('');
    const [focused, setFocused] = useState(false);
    const isValidationError = meta.touched && meta.error;

    const filteredOptions = useMemo(() => {
      return options.map((option, index) => (
        <Combobox.Option
          onClick={e => {
            if (option.nestedOnClick) {
              e.preventDefault();
              option.nestedOnClick();
            } else if (option.finalOnClick) {
              option.finalOnClick();
            } else {
              helpers.setValue(options[index].label);
              setSearchTerm(options[index].label);
            }
          }}
          className="m-0 p-0 cursor-pointer text-left flex justify-between"
          key={option.label + index}
          onFocus={onFocus}
          value={option.value}
        >
          {({ active }) => (
            <div
              className={classNames(
                'px-3.5 py-2 m-0 rounded-md flex w-full justify-between items-center',
                active && 'bg-slate-100',
              )}
            >
              {option.label}
              {option.endIcon}
            </div>
          )}
        </Combobox.Option>
      ));
    }, [options, searchTerm, onFocus]);

    const endIcon = useMemo(
      () =>
        options.find(
          option => option.value.toLocaleUpperCase() === field.value?.toLocaleUpperCase(),
        )?.endIcon,
      [field.value, options],
    );

    const handleOnFocus = () => {
      setFocused(true);
      onFocus?.();
    };

    const handleOnChange = (newValue: string) => {
      helpers.setValue(newValue);
      onChange?.(newValue);
      setFocused(false);
    };

    useEffect(() => {
      if (isValidationError) {
        onValidationError?.(meta.error!);
      }
    }, [isValidationError]);

    return (
      <Combobox
        value={field.value}
        onChange={newValue => {
          const option = options
            .reverse() // This is reversed so find can find manual option
            .find(option => option.label.toLocaleLowerCase() === newValue.toLocaleLowerCase());
          if (option?.nestedOnClick) {
            option.nestedOnClick();
          } else if (option?.finalOnClick) {
            option.finalOnClick();
            onChange?.(newValue); // Call the onChange prop, if provided
          }
        }}
        /*@ts-ignore*/
        onKeyUpCapture={(e: any) => {
          if (e.code === 'Enter') {
            const newEvent = new KeyboardEvent('keydown', {
              code: 'ArrowDown',
              key: 'ArrowDown',
              bubbles: true,
            });
            e.target.dispatchEvent(newEvent);
          }
        }}
      >
        <div data-cy={`search-dropdown-${label}`} className="relative">
          {loading && <BorderBeam size={300} duration={3.3} />}
          {field.value && endIcon && <div className="absolute right-12 top-4">{endIcon}</div>}
          <label
            className={classNames(
              `absolute left-2.5 top-[0px] px-2 transition-transform text-base duration-150 pointer-events-none
            origin-top-left flex items-center peer-focus:translate-y-1/3 peer-focus:scale-75 peer-focus:font-semibold`,
              field.value?.length > 0 || searchTerm.length > 0 || focused
                ? 'translate-y-1/3 scale-75 font-semibold'
                : 'translate-y-[60%]',
              labelClassName,
            )}
          >
            {label}
          </label>
          <FieldError error={meta.error} show={Boolean(isValidationError)} />
          <Combobox.Button className="w-full">
            <Combobox.Input
              {...field}
              data-cy={props['data-cy']}
              onFocus={handleOnFocus}
              onBlur={(event: FocusEvent) => {
                setFocused(false);
                field.onBlur(event);
              }}
              placeholder={focused ? placeholder : ''}
              className={classNames(
                `focusable-element peer w-full px-[16px] pr-6 pt-[23px] pb-1 bg-transparent disabled:cursor-not-allowed text-[15px]
                disabled:text-gray-400 rounded-md border-0 focus:outline-0 focus:ring-0 autofill:!bg-none`,
                { '!border-1 !border-red-500': meta.error && meta.touched },
                inputClassName,
              )}
              onChange={change => {
                setSearchTerm(change.target.value);
                if (!change.target.value) helpers.setValue('');
                handleOnChange(change.target.value);
              }}
              autoComplete="off"
            />
            {tooltip && <div className="absolute top-5 right-2">{tooltip}</div>}
            <ChevronUpDownIcon
              className={classNames(
                'absolute top-5 pr-2 z-10 h-[20px] max-w-min text-brand-gold',
                tooltip ? 'right-7' : 'right-0',
                selectorIconClassName,
              )}
            />
          </Combobox.Button>
          <Combobox.Options
            className={classNames(
              'no-scrollbar absolute bg-white overflow-auto text-black w-full mt-1 rounded-md z-50 max-h-52 flex flex-col space-y-1 px-0',
              optionsClassName,
            )}
          >
            {filteredOptions.length > 0 && filteredOptions}
          </Combobox.Options>
        </div>
      </Combobox>
    );
  },
  // Current conditionals for re-rendering. Add new conditionals to list if necessary for future development
  (
    { options: prevOptions, loading: prevLoading },
    { options: nextOptions, loading: nextLoading },
  ) => {
    return prevOptions === nextOptions && nextLoading === prevLoading;
  },
);
export default SearchDropdownField;
