import React from 'react';

import { paymentProviderImg } from '@/utils/image-utils';

const Line = () => <div className="col-span-1 h-0.5 rounded-sm self-center bg-gray-500" />;

const RegistrationFooter = () => {
  return (
    <div className="grid grid-cols-6 w-full mx-auto max-w-[300px] pb-3">
      <Line />
      <p className="col-span-4 m-0 font-bold text-sm text-center">Metode de depunere</p>
      <Line />
      <div className="col-span-6">
        <img alt="deposit-methods" src={paymentProviderImg('deposit-methods')} />
      </div>
    </div>
  );
};

export default RegistrationFooter;
