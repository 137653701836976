import { ConsentsStateModel } from '@/models/consents-state.model';
import {
  ConsentsActions,
  ConsentsActionsTypes,
  GetConsentsErrorAction,
  GetConsentsSuccessAction,
} from '@/store/actions/consentsActions';
import { updateObject } from '@/utils/helpers';

const initialState: ConsentsStateModel = {
  consents: [],
  loading: false,
  error: null,
};

const getConsents = (state: ConsentsStateModel) =>
  updateObject(state, {
    loading: true,
  });

const getConsentsSuccess = (state: ConsentsStateModel, action: GetConsentsSuccessAction) =>
  updateObject(state, {
    consents: action.consents,
    loading: false,
  });

const getConsentsError = (state: ConsentsStateModel, action: GetConsentsErrorAction) =>
  updateObject(state, {
    loading: false,
    error: action.error,
  });

export const consentsReducer = (
  state = initialState,
  action: ConsentsActions,
): ConsentsStateModel => {
  switch (action.type) {
    case ConsentsActionsTypes.Get:
      return getConsents(state);
    case ConsentsActionsTypes.Success:
      return getConsentsSuccess(state, action);
    case ConsentsActionsTypes.Error:
      return getConsentsError(state, action);
    default:
      return state;
  }
};
