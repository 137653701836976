import {
  BffGamesJackpotsResponse,
  BffGamesResponse,
  BffGamesLastPlayedResponse,
} from '@lucky7ventures/bff-types';
import { Action } from 'redux';

export enum GamesActionsTypes {
  Get = 'GET_GAMES',
  GetSuccess = 'GET_GAMES_SUCCESS',
  GetError = 'GET_GAMES_ERROR',
  Search = 'SEARCH_GAMES',
  GetLastPlayedGames = 'GET_LAST_PLAYED_GAMES',
  GetLastPlayedGamesSuccess = 'GET_LAST_PLAYED_GAMES_SUCCESS',
  GetGamesJackpots = 'GET_GAMES_JACKPOTS',
  GetGamesJackpotsSuccess = 'GET_GAMES_JACKPOTS_SUCCESS',
}

export type GetGamesAction = Action<GamesActionsTypes.Get>;
export type GetGamesSuccessAction = Action<GamesActionsTypes.GetSuccess> & BffGamesResponse;
export type GetGamesErrorAction = Action<GamesActionsTypes.GetError>;
export type SearchGamesAction = Action<GamesActionsTypes.Search> & {
  search: string;
};
export type GetLastPlayedGamesAction = Action<GamesActionsTypes.GetLastPlayedGames>;
export type GetLastPlayedGamesSuccessAction =
  Action<GamesActionsTypes.GetLastPlayedGamesSuccess> & {
    lastPlayedIds: BffGamesLastPlayedResponse;
  };
export type GetGamesJackpotsAction = Action<GamesActionsTypes.GetGamesJackpots>;
export type GetGamesJackpotsSuccessAction = Action<GamesActionsTypes.GetGamesJackpotsSuccess> & {
  data: BffGamesJackpotsResponse;
};

export type GamesActions =
  | GetGamesAction
  | GetGamesSuccessAction
  | GetGamesErrorAction
  | SearchGamesAction
  | GetLastPlayedGamesAction
  | GetLastPlayedGamesSuccessAction
  | GetGamesJackpotsAction
  | GetGamesJackpotsSuccessAction;

export const getGames = (): GetGamesAction => ({
  type: GamesActionsTypes.Get,
});

export const getGamesSuccess = ({
  games,
  providers,
  categories,
}: BffGamesResponse): GetGamesSuccessAction => {
  return {
    type: GamesActionsTypes.GetSuccess,
    games,
    providers,
    categories,
  };
};

export const getGamesError = (): GetGamesErrorAction => ({
  type: GamesActionsTypes.GetError,
});

export const searchGames = (search: string): SearchGamesAction => ({
  type: GamesActionsTypes.Search,
  search,
});

export const getLastPlayedGames = (): GetLastPlayedGamesAction => ({
  type: GamesActionsTypes.GetLastPlayedGames,
});

export const getLastPlayedGamesSuccess = (
  lastPlayedIds: BffGamesLastPlayedResponse,
): GetLastPlayedGamesSuccessAction => ({
  type: GamesActionsTypes.GetLastPlayedGamesSuccess,
  lastPlayedIds,
});

export const getGamesJackpots = (): GetGamesJackpotsAction => ({
  type: GamesActionsTypes.GetGamesJackpots,
});

export const getGamesJackpotsSuccess = (
  data: BffGamesJackpotsResponse,
): GetGamesJackpotsSuccessAction => ({
  type: GamesActionsTypes.GetGamesJackpotsSuccess,
  data,
});
