import { PublicBonusesModel } from '@/models/public-bonuses-object.model';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';
import {
  AvailableBonusesAction,
  AvailableBonusesActionType,
  GetAvailableBonusContentErrorAction,
  GetAvailableBonusContentSuccessAction,
  GetAvailableBonusesSuccessAction,
} from '@/store/actions/availableBonusesActions';

export interface AvailableBonusesStateModel {
  availableBonuses: PublicBonusesModel[];
  error: boolean;
  loading: boolean;
  content: Record<number, WpBonusContentModel | null>;
}

const initialState: AvailableBonusesStateModel = {
  availableBonuses: [],
  error: false,
  loading: false,
  content: {},
};

const getAvailableBonuses = (state: AvailableBonusesStateModel) => ({
  ...state,
  loading: true,
  error: false,
});

const getAvailableBonusesSuccess = (
  state: AvailableBonusesStateModel,
  action: GetAvailableBonusesSuccessAction,
) => ({
  ...state,
  loading: false,
  availableBonuses: action.availableBonuses,
});

const getAvailableBonusesError = (
  state: AvailableBonusesStateModel,
): AvailableBonusesStateModel => ({
  ...state,
  loading: false,
  error: true,
});

const getAvailableBonusContentSuccess = (
  state: AvailableBonusesStateModel,
  action: GetAvailableBonusContentSuccessAction,
): AvailableBonusesStateModel => ({
  ...state,
  content: {
    ...state.content,
    [action.bonusId]: action.content,
  },
});

const getAvailableBonusContentError = (
  state: AvailableBonusesStateModel,
  action: GetAvailableBonusContentErrorAction,
): AvailableBonusesStateModel => ({
  ...state,
  content: {
    ...state.content,
    [action.bonusId]: null,
  },
});

const resetAvailableBonuses = (): AvailableBonusesStateModel => initialState;

export const availableBonusesReducer = (
  state = initialState,
  action: AvailableBonusesAction,
): AvailableBonusesStateModel => {
  switch (action.type) {
    case AvailableBonusesActionType.Get:
      return getAvailableBonuses(state);
    case AvailableBonusesActionType.Success:
      return getAvailableBonusesSuccess(state, action);
    case AvailableBonusesActionType.Error:
      return getAvailableBonusesError(state);
    case AvailableBonusesActionType.GetContentSuccess:
      return getAvailableBonusContentSuccess(state, action);
    case AvailableBonusesActionType.Reset:
      return resetAvailableBonuses();
    case AvailableBonusesActionType.GetContentError:
      return getAvailableBonusContentError(state, action);
    default:
      return state;
  }
};
