import { useCallback, useState } from 'react';

import axios from 'axios';
import { useSelector } from 'react-redux';

import { selectAuthToken } from '@/store/selectors/authSelectors';

interface Return {
  success: boolean;
  loading: boolean;
  error: boolean;
  claimBonus: (bonusCode: string) => void;
}

function useClaimBonus(): Return {
  const token = useSelector(selectAuthToken);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const claimBonus = useCallback(
    (bonusCode: string) => {
      axios
        .post(`/api/bonuses/credit`, { token, bonusCode })
        .then(response => {
          if (response.data.success) {
            setSuccess(true);
          } else {
            setError(true);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    [token],
  );

  return { success, loading, error, claimBonus };
}

export default useClaimBonus;
