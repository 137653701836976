import * as React from 'react';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CashierHeader from './CashierHeader';
import CashierIframe from './CashierIframe';
import CashierSkeletonLoader from './CashierSkeletonLoader';
import CloseButton from '@/components/button/CloseButton';
import useApiV2Request from '@/hooks/useApiV2Request';
import { useCashierShown } from '@/hooks/useCashier';
import { DrawerPropsModel } from '@/models/drawer-props.model';
import { LifetimeDeposits } from '@/models/gig/lifetime-deposits.model';
import ApiService from '@/shared/api-service';
import { getActiveBonuses } from '@/store/actions/activeBonusesActions';
import { setLifetimeDeposits } from '@/store/actions/depositActions';
import { getWallet } from '@/store/actions/walletActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectAvailableBonusesFetched } from '@/store/selectors/availableBonusesSelectors';

function CashierDrawer({ closeDrawer }: DrawerPropsModel): JSX.Element {
  const dispatch = useDispatch();
  const availableBonusesFetched = useSelector(selectAvailableBonusesFetched);
  const shownCashier = useCashierShown();
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const { request } = useApiV2Request<LifetimeDeposits>();

  function updateUser() {
    dispatch(getWallet());
    dispatch(getActiveBonuses());
  }

  useEffect(() => {
    if (shownCashier) {
      updateUser();
    }
  }, [shownCashier]);

  useEffect(() => {
    if (isAuthenticated) {
      const handleOnSuccess = (deposits: LifetimeDeposits) => {
        dispatch(setLifetimeDeposits(deposits));
      };
      request(ApiService.getLifetimeDeposits, null, handleOnSuccess);
    }
  }, [isAuthenticated]);

  return (
    <div data-cy="cashier-drawer" className="w-[100vw] max-w-[100vw] sm:max-w-[620px] sm:w-[85vw] ">
      <CloseButton onClick={closeDrawer} position="left" />
      <CashierHeader />
      <div className="relative">
        {loading && <CashierSkeletonLoader />}
        {availableBonusesFetched && (
          <CashierIframe setLoading={setLoading} closeCashier={closeDrawer} />
        )}
      </div>
    </div>
  );
}

export default CashierDrawer;
