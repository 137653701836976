import { Action } from 'redux';

import { PublicBonusesModel } from '@/models/public-bonuses-object.model';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';

export enum AvailableBonusesActionType {
  Get = 'GET_AVAILABLE_BONUSES',
  Success = 'GET_AVAILABLE_BONUSES_SUCCESS',
  Error = 'GET_AVAILABLE_BONUSES_ERROR',
  Reset = 'RESET_AVAILABLE_BONUSES',
  GetContent = 'GET_AVAILABLE_BONUS_CONTENT',
  GetContentSuccess = 'GET_AVAILABLE_BONUS_CONTENT_SUCCESS',
  GetContentError = 'GET_AVAILABLE_BONUS_CONTENT_ERROR',
}

export type GetAvailableBonusesAction = Action<AvailableBonusesActionType.Get> & {
  token?: string;
};

export type GetAvailableBonusesSuccessAction = Action<AvailableBonusesActionType.Success> & {
  availableBonuses: PublicBonusesModel[];
};

export type GetAvailableBonusesErrorAction = Action<AvailableBonusesActionType.Error> & {
  error: boolean;
};

export type GetAvailableBonusContentAction = Action<AvailableBonusesActionType.GetContent> & {
  bonusId: number;
};

export type ResetAvailableBonuses = Action<AvailableBonusesActionType.Reset>;

export type GetAvailableBonusContentErrorAction =
  Action<AvailableBonusesActionType.GetContentError> & {
    bonusId: number;
  };

export type GetAvailableBonusContentSuccessAction =
  Action<AvailableBonusesActionType.GetContentSuccess> & {
    bonusId: number;
    content: WpBonusContentModel;
  };

export type AvailableBonusesAction =
  | GetAvailableBonusesAction
  | GetAvailableBonusesSuccessAction
  | GetAvailableBonusesErrorAction
  | GetAvailableBonusContentAction
  | GetAvailableBonusContentSuccessAction
  | ResetAvailableBonuses
  | GetAvailableBonusContentErrorAction;

export const getAvailableBonuses = (token?: string): GetAvailableBonusesAction => ({
  type: AvailableBonusesActionType.Get,
  token,
});

export const resetAvailableBonuses = (): ResetAvailableBonuses => ({
  type: AvailableBonusesActionType.Reset,
});

export const getAvailableBonusesSuccess = (
  availableBonuses: PublicBonusesModel[],
): GetAvailableBonusesSuccessAction => ({
  type: AvailableBonusesActionType.Success,
  availableBonuses,
});

export const getAvailableBonusesError = (error: boolean): GetAvailableBonusesErrorAction => ({
  type: AvailableBonusesActionType.Error,
  error,
});

export const getAvailableBonusContent = (bonusId: number): GetAvailableBonusContentAction => ({
  type: AvailableBonusesActionType.GetContent,
  bonusId,
});

export const getAvailableBonusContentSuccess = (
  content: WpBonusContentModel,
  bonusId: number,
): GetAvailableBonusContentSuccessAction => ({
  type: AvailableBonusesActionType.GetContentSuccess,
  content,
  bonusId,
});

export const getAvailableBonusContentError = (
  bonusId: number,
): GetAvailableBonusContentErrorAction => ({
  type: AvailableBonusesActionType.GetContentError,
  bonusId,
});
