import { Action } from 'redux';

import { WpPromotion } from '@/models/wp-promotion.model';

export enum PromotionsActionsTypes {
  GetPromotions = 'GET_PROMOTIONS',
  GetPromotionsSuccess = 'GET_PROMOTIONS_SUCCESS',
  GetPromotionsError = 'GET_PROMOTIONS_ERROR',
}

export type GetPromotionsAction = Action<PromotionsActionsTypes.GetPromotions>;
export type GetPromotionsSuccessAction = Action<PromotionsActionsTypes.GetPromotionsSuccess> & {
  promotions: WpPromotion[];
};
export type GetPromotionsErrorAction = Action<PromotionsActionsTypes.GetPromotionsError>;

export type PromotionsActions =
  | GetPromotionsAction
  | GetPromotionsSuccessAction
  | GetPromotionsErrorAction;

export const getPromotions = (): GetPromotionsAction => ({
  type: PromotionsActionsTypes.GetPromotions,
});

export const getPromotionsSuccess = (promotions: WpPromotion[]): GetPromotionsSuccessAction => ({
  type: PromotionsActionsTypes.GetPromotionsSuccess,
  promotions,
});

export const getPromotionsError = (): GetPromotionsErrorAction => ({
  type: PromotionsActionsTypes.GetPromotionsError,
});
