import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@/components/button/Button';
import FadeIn from '@/components/transition/FadeIn';

const StyledGamePromotion = styled.div`
  padding: 4rem 1rem;
  width: 100%;

  .content {
    max-width: 900px;
    margin: 0 auto;
    display: block;
    align-items: center;
    text-align: center;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      display: grid;
      grid-gap: 5rem;
      grid-template-columns: 192px 1fr;
      text-align: left;
    }

    h2 {
      margin-bottom: 1rem;
    }

    a {
      margin: 0 !important;
    }

    button {
      margin-top: 2rem;
    }
  }

  .image-wrapper {
    position: relative;
    background: rgba(0, 0, 0, 0.2);
    display: block;
    border-radius: 4px;
    max-width: 180px;
    box-shadow: ${props => props.theme.boxShadow};
    margin: 0 auto 2rem;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      margin: 0;
      max-width: 100%;
    }

    &:before {
      content: '';
      background: red;
      color: red;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: rotate(10deg);
      border-radius: 4px;
      opacity: 0.2;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 122.75%;
    }

    img {
      display: block;
      position: absolute;
      border-radius: 4px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

interface GamePromotionProps {
  header: string;
  text: string | JSX.Element;
  buttonText: string;
  buttonLink: string;
  imageUrl: string;
  imageAlt: string;
}

function GamePromotion({
  header,
  text,
  buttonText,
  buttonLink,
  imageUrl,
  imageAlt,
}: GamePromotionProps): JSX.Element {
  return (
    <StyledGamePromotion className="header" data-cy="GAME_PROMOTIONS">
      <div className="content">
        <div className="image-wrapper">
          <FadeIn>{onload => <img src={imageUrl} alt={imageAlt} onLoad={onload} />}</FadeIn>
        </div>
        <div className="text">
          <h2>{header}</h2>
          <div>{text}</div>
          <Link to={buttonLink}>
            <Button className="button">{buttonText}</Button>
          </Link>
        </div>
      </div>
    </StyledGamePromotion>
  );
}

export default GamePromotion;
