import React from 'react';

import 'react-loading-skeleton/dist/skeleton.css';
import SkeletonLoader from '@/components/skeleton-loader/SkeletonLoader';

const SkeletonTab = () => (
  <div className="rounded-t-xl p-[15px] w-[50%] flex justify-center items-center ">
    <SkeletonLoader height="19px" width="100px" />
  </div>
);
const CashierSkeletonLoader = () => (
  <div className="z-90 absolute w-full top-0 flex-col ">
    <div className="bg-white w-full flex px-[32px] space-x-1">
      <SkeletonTab />
      <SkeletonTab />
    </div>
    <div className="flex flex-col p-[32px] space-y-6">
      <div className="flex flex-col space-y-1">
        <SkeletonLoader width="70%" height="36px" />
        <SkeletonLoader width="45%" height="17px" />
      </div>
      <div className="flex flex-col space-y-2">
        {Array(7)
          .fill(null)
          .map((_, index) => (
            <SkeletonLoader key={`${index}_skeleton_loader`} height="78px" />
          ))}
      </div>
    </div>
  </div>
);

export default CashierSkeletonLoader;
