import { LifetimeDeposits } from '@/models/gig/lifetime-deposits.model';
import {
  DepositActions,
  DepositActionTypes,
  GetDepositCountSuccessAction,
  IsSingleGameAction,
  SetToggleDepositAddedAction,
} from '@/store/actions/depositActions';

export interface DepositStateModel {
  count: number;
  loading: boolean;
  error: boolean;
  depositAdded: boolean;
  isSingleGame: boolean;
  deposits: LifetimeDeposits | null;
}

const initialState: DepositStateModel = {
  count: 0,
  loading: false,
  error: false,
  depositAdded: false,
  isSingleGame: false,
  deposits: null,
};

const getToggleDepositAdded = (
  state: DepositStateModel,
  action: SetToggleDepositAddedAction,
): DepositStateModel => ({
  ...state,
  depositAdded: action.depositAdded,
});

const getIsSingleGame = (
  state: DepositStateModel,
  action: IsSingleGameAction,
): DepositStateModel => ({
  ...state,
  isSingleGame: action.isSingleGame,
});

const getDepositCountStart = (state: DepositStateModel): DepositStateModel => ({
  ...state,
  loading: true,
  error: false,
});

const getDepositCountSuccess = (
  state: DepositStateModel,
  action: GetDepositCountSuccessAction,
): DepositStateModel => ({
  ...state,
  loading: false,
  count: action.depositCount,
});

const getDepositCountError = (state: DepositStateModel) => ({
  ...state,
  loading: false,
  error: true,
});

export const depositReducer = (state = initialState, action: DepositActions): DepositStateModel => {
  switch (action.type) {
    case DepositActionTypes.Get:
      return getDepositCountStart(state);
    case DepositActionTypes.GetSuccess:
      return getDepositCountSuccess(state, action);
    case DepositActionTypes.GetError:
      return getDepositCountError(state);
    case DepositActionTypes.ToggleDepositAdded:
      return getToggleDepositAdded(state, action);
    case DepositActionTypes.IsSingleGame:
      return getIsSingleGame(state, action);
    case DepositActionTypes.IncrementDepositCount:
      return {
        ...state,
        count: state.count + 1,
      };
    case DepositActionTypes.SetLifetimeDeposits:
      return {
        ...state,
        deposits: action.deposits,
      };
    default:
      return state;
  }
};
