import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

function useSearchParams(): void {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const modal = params.get('modal');

  useEffect(() => {
    if (modal === 'register') {
      navigate('/inregistrare');
    }
  }, [modal]);
}

export default useSearchParams;
