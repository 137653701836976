import * as React from 'react';
import { useState } from 'react';

import { ErrorCode } from '@lucky7ventures/lucky-components';
import axios from 'axios';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikState } from 'formik';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

import Button from '@/components/button/Button';
import Input from '@/components/form/Input';
import ValidationMessage from '@/components/form/ValidationMessage';
import { selectAuthToken } from '@/store/selectors/authSelectors';

const StyledChangePasswordBox = styled.div`
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-top: -2px;
  overflow: hidden;
  border-radius: 0 0 8px 8px;

  form {
    padding: 2rem 1rem;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 2rem;
    }
  }
`;

interface FormValues {
  oldPassword: string;
  newPassword: string;
}

function ChangePasswordBox({
  setShowChangePassword,
}: {
  setShowChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);
  const token = useSelector(selectAuthToken);

  const initialValues: FormValues = {
    oldPassword: '',
    newPassword: '',
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required('Parola este obligatorie')
      .min(6, 'Parola trebuie să conțină minim 6 caractere'),
    newPassword: Yup.string()
      .required('Parola este obligatorie')
      .min(6, 'Parola trebuie să conțină minim 6 caractere')
      .notOneOf(
        [Yup.ref('oldPassword'), null],
        'Parola curentă nu poate fi identică cu noua parolă',
      ),
  });

  function getErrorMessage(error: ErrorCode): string {
    switch (error.ErrorCodeID) {
      case 48:
        return 'Ai introdus o parolă invalidă';
      default:
        return error.Message;
    }
  }

  async function submitHandler(
    values: FormValues,
    resetForm: (nextState?: Partial<FormikState<FormValues>> | undefined) => void,
  ) {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const { data } = await axios.post('/api/auth/change/password', {
        token,
        payload: {
          OldPassword: values.oldPassword,
          NewPassword: values.newPassword,
        },
      });

      if (data.success) {
        setSuccess(true);
        resetForm();
        return;
      }
      if (data.error && data.error.length) {
        setError(getErrorMessage(data.error[0]));
        return;
      }
    } catch (error) {
      // TODO: How should we handle catch errors? Getting error when trying to access error object because "unknown"
      console.log(error);
      // setError(`${error.response.status} - ${error.response.statusText}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <StyledChangePasswordBox>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => submitHandler(values, resetForm)}
        validateOnMount
      >
        {({ isValid }) => (
          <Form>
            <Field name="oldPassword">
              {({ field }: FieldProps) => (
                <>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="password"
                    placeholder="Introdu parola curentă"
                    label="Parola curentă"
                    className="no-margin-top"
                  />
                  <ErrorMessage name="oldPassword">
                    {message => <ValidationMessage type="old-password" message={message} />}
                  </ErrorMessage>
                </>
              )}
            </Field>
            <Field name="newPassword">
              {({ field }: FieldProps) => (
                <>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="password"
                    placeholder="Introdu parolă nouă"
                    label="Parolă nouă"
                  />
                  <ErrorMessage name="newPassword">
                    {message => <ValidationMessage type="new-password" message={message} />}
                  </ErrorMessage>
                </>
              )}
            </Field>
            <div className="button-wrapper">
              {error && <p className="error">{error}</p>}
              {success && <p className="success">Parolă schimbată!</p>}
              <div className="button-wrapper">
                <Button color="white" onClick={() => setShowChangePassword(false)}>
                  Anulează
                </Button>
                <Button disabled={!isValid} type="submit">
                  {loading ? 'Se încarcă...' : 'Schimbă parola'}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </StyledChangePasswordBox>
  );
}

export default ChangePasswordBox;
