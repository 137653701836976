import { AuthActions, AuthActionTypes, LoginSuccessAction } from '@/store/actions/authActions';

export interface AuthStateModel {
  token: string | null;
  isAuthenticated: boolean;
}

const initialState: AuthStateModel = {
  token: null,
  isAuthenticated: false,
};

const loginSuccess = (state: AuthStateModel, action: LoginSuccessAction): AuthStateModel => ({
  ...state,
  token: action.token,
  isAuthenticated: true,
});

const logout = (): AuthStateModel => initialState;
const resetAuth = (): AuthStateModel => initialState;

export const authReducer = (state = initialState, action: AuthActions): AuthStateModel => {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess:
      return loginSuccess(state, action);
    case AuthActionTypes.Logout:
      return logout();
    case AuthActionTypes.ResetAuth:
      return resetAuth();
    default:
      return state;
  }
};
