import React from 'react';

import styled, { css } from 'styled-components/macro';

export const StyledButton = styled.button<{ size: string; color: string }>`
  display: inline-flex;
  padding: 1rem 2rem;
  border: none;
  border-radius: ${props => props.theme.borderRadius};
  color: ${props => props.theme.white};
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.2s ease;
  justify-content: center;
  white-space: nowrap;

  &:hover:not(:disabled) {
    background: ${props => props.theme.green};
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  ${props =>
    props.size === 'icon' &&
    css`
      padding: 11px;
      font-size: 25px;
    `}

  ${props =>
    props.size === 'small' &&
    css`
      padding: 14px 24px;
    `}

  ${props =>
    props.size === 'xsmall' &&
    css`
      padding: 8px 24px;
      font-size: 16px;
    `}

	${props =>
    props.size === 'big' &&
    css`
      padding: 1.4em 3em;
      font-size: 18px;
    `}

	${props =>
    props.color === 'ghost' &&
    css`
      background: none;
      box-shadow: inset 0 0 0 2px ${props => props.theme.white};

      &:hover:not(:disabled) {
        background: white;
        color: ${props => props.theme.red};
      }
    `}

	${props =>
    props.color === 'ghostBlack' &&
    css`
      background: none;
      box-shadow: inset 0 0 0 2px ${props => props.theme.black};
      color: ${props => props.theme.black};

      &:hover:not(:disabled) {
        background: ${props => props.theme.blackHover};
        color: ${props => props.theme.white};
      }
    `}

	${props =>
    props.color === 'white' &&
    css`
      background: ${props => props.theme.white};
      color: ${props => props.theme.blackHover};

      &:hover:not(:disabled) {
        background: white;
        color: ${props => props.theme.black};
      }
    `}


    ${props =>
    props.color === 'black' &&
    css`
      background: ${props => props.theme.black};
      box-shadow: ${props => props.theme.boxShadow};

      &:hover:not(:disabled) {
        background: ${props => props.theme.blackHover};
      }
    `};

  ${props =>
    props.color === 'transparent' &&
    css`
      background: transparent;
      color: ${props => props.theme.white};

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    `}

  ${props =>
    props.color === 'gray' &&
    css`
      border: none;
      background: ${props => props.theme.extraLightGray};
      color: ${props => props.theme.lightGray};

      &:hover:not(:disabled) {
        background: ${props => props.theme.blackHover};
        color: ${props => props.theme.white};
      }
    `}
`;

export type TButtonSize = 'xsmall' | 'small' | 'regular' | 'big' | 'icon';
export type TButtonColor = 'white' | 'black' | 'ghostBlack' | 'ghost' | 'transparent' | 'gray';

interface ButtonProps {
  children: React.ReactNode;
  size?: TButtonSize;
  type?: 'button' | 'submit';
  disabled?: boolean;
  color?: TButtonColor;
  onClick?: (e: React.SyntheticEvent) => void;
  className?: string;
  id?: string;
}

function Button({
  children,
  size = 'regular',
  type = 'button',
  disabled = false,
  color = 'black',
  onClick,
  className,
  id,
  ...props
}: ButtonProps): JSX.Element {
  return (
    <StyledButton
      size={size}
      type={type}
      disabled={disabled}
      color={color}
      onClick={onClick}
      className={className}
      id={id}
      {...props}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
