import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectUserIsBonusRestricted } from '@/store/selectors/userSelectors';

function usePromotionsRestriction() {
  const [promotionsRestricted, setPromotionsRestricted] = useState(false);
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const isBonusRestricted = useSelector(selectUserIsBonusRestricted);

  useEffect(() => {
    if (!isAuthenticated) {
      setPromotionsRestricted(false);
      return;
    }

    setPromotionsRestricted(isBonusRestricted);
  }, [isAuthenticated, isBonusRestricted]);

  return { promotionsRestricted };
}

export default usePromotionsRestriction;
