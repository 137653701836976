import { SagaIterator } from 'redux-saga';
import { all, put, takeEvery, AllEffect, ForkEffect, call } from 'redux-saga/effects';

import ApiService from '@/shared/api-service';
import {
  ConsentsActionsTypes,
  getConsentsError,
  getConsentsSuccess,
} from '@/store/actions/consentsActions';

export function* getConsentsSaga(): SagaIterator {
  try {
    const response = yield call(ApiService.getConsents);
    yield put(getConsentsSuccess(response.data));
  } catch (error) {
    yield put(getConsentsError('Error'));
  }
}

export function* watchConsents(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(ConsentsActionsTypes.Get, getConsentsSaga)]);
}
