import React from 'react';

import BonusIntroCard from '@/components/bonuses/BonusIntroCard';
import { TButtonColor, TButtonSize } from '@/components/button/Button';
import { ReactComponent as Diamond } from '@/images/diamond-small.svg';
import { ReactComponent as Heart } from '@/images/heart-small.svg';
import { ReactComponent as Spades } from '@/images/spades-small.svg';

export interface TBonusIntro {
  imageUrl: string;
  imageAlt: string;
  title: string;
  bodyText: string;
  ctaSize?: TButtonSize;
  ctaColor?: TButtonColor;
  cardSign: React.ElementType;
}

const BONUS_INTRO_CARDS: TBonusIntro[] = [
  {
    imageUrl:
      'https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=358,height=126,format=auto,dpr=2/assets/shining-crown.png',
    imageAlt: 'Shining Crown',
    title: '400 ROTIRI GRATUITE și 100% BONUS la prima depunere & verificarea contului',
    bodyText:
      '200 Rotiri Gratuite la Shining Crown, oferite consecutiv, câte 40 pe zi!\n+\n200 Rotiri Gratuite la Burning Hot, oferite instant după verificarea contului și prima depunere!\n100% Bonus: faci o primă depunere iar noi îți egalăm suma până la 1.000 Lei!',
    ctaSize: 'big',
    cardSign: Spades,
  },
  {
    imageUrl:
      'https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,height=126,format=auto,dpr=2/assets/Hot-to-Burn-Logo.png',
    imageAlt: 'Hot to Burn',
    title: '100 ROTIRI GRATUITE și 80% BONUS la a doua depunere',
    bodyText:
      '100 Rotiri Gratuite la Hot to Burn, oferite instant pentru a doua depunere!\n\n80% Bonus: faci o a doua depunere în cont iar noi îți oferim 80% bonus din suma maximă de 800 Lei!',
    ctaColor: 'ghostBlack',
    cardSign: Heart,
  },
  {
    imageUrl:
      'https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,height=126,format=auto,dpr=2/assets/20_Super_Hot_game_name.png',
    imageAlt: '20 Super Hot',
    title: '100 ROTIRI GRATUITE și 60% BONUS la a treia depunere',
    bodyText:
      '100 Rotiri Gratuite la 20 Super Hot, oferite instant.\n\nBonus 60%: La a treia depunere, noi îți oferim 60% Bonus până la 600 Lei!',
    ctaColor: 'ghostBlack',
    cardSign: Diamond,
  },
];

function BonusIntro(): JSX.Element {
  return (
    <section data-cy="BONUS_INTRO" id="bonus-intro" className="bg-teal-50 pb-8 sm:px-8 sm:pb-16">
      <div className="p-4 sm:p-0 sm:pt-16">
        <div className="my-0 mx-auto flex max-w-[1400px] flex-wrap justify-center gap-6">
          {BONUS_INTRO_CARDS.map((card, index) => (
            <BonusIntroCard {...card} index={index + 1} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default BonusIntro;
