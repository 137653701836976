import React from 'react';

import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import Button, { StyledButton } from '@/components/button/Button';
import CurrencyFormat from '@/components/formats/CurrencyFormat';
import PlayIcon from '@/components/icons/PlayIcon';
import FadeIn from '@/components/transition/FadeIn';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { GameCardVM } from '@/models/game-card-vm.model';
import { openLoginModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { isEmpty } from '@/utils/helpers';
import { gameThumbImg } from '@/utils/image-utils';

const StyledGameCard = styled.div<{ isMobile: boolean }>`
  position: relative;
  max-width: 300px;

  .image-wrapper {
    position: relative;
    display: block;
    cursor: pointer;
    box-shadow: ${props => props.theme.boxShadow};
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    &:after {
      content: '';
      display: block;
      padding-bottom: 122.75%;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      border-radius: 6px;
    }

    .overlay {
      border-radius: 6px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: ${props => props.theme.overlayBlack};
      color: ${props => props.theme.white};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    .tag {
      position: absolute;
      top: 8px;
      padding: 6px 12px;
      min-width: 48px;
      text-align: center;
      font-size: 10px;
      font-weight: 700;
      background: ${props => props.theme.black};
      color: ${props => props.theme.white};
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      &.hot {
        right: 12px;

        &.hot-live {
          top: 38px;
        }
      }

      &.new {
        left: 12px;
      }

      &.live {
        right: 12px;

        span {
          border-radius: 5px;
          height: 5px;
          width: 5px;
          background: ${props => props.theme.mint};
          display: inline-block;
          margin-right: 5px;
          opacity: 1;
          animation: fade 3s infinite;
        }
      }

      &.jackpot {
        left: 8px;
        right: 8px;
        bottom: 8px;
        top: auto;
        font-size: 11px;
        text-transform: none;
        letter-spacing: 0;
      }
    }
  }

  .name,
  .vendor {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
  }

  .name {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .vendor {
    font-size: 0.9rem;
    margin-top: 8px;

    small {
      font-weight: normal;
    }
  }

  .diamond {
    position: absolute;
    top: -24px;
    color: white;
    width: 48px;
    height: 48px;
    font-size: 24px;

    &.left {
      left: 10px;

      .text {
        right: -40px;
      }
    }

    &.right {
      right: 10px;

      .text {
        left: -40px;
      }
    }

    .text {
      position: absolute;
      top: -16px;
      font-size: 10px;
      color: ${props => props.theme.darkGreen};
      font-weight: 700;

      text-transform: uppercase;
    }
  }

  ${StyledButton} {
    background: rgba(0, 0, 0, 0.5);

    ${props =>
      props.isMobile &&
      css`
        width: 100%;
        height: 100%;
      `}
    &:hover {
      color: ${props => props.theme.black};
    }
  }

  ${props =>
    !props.isMobile &&
    css`
      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    `} @keyframes fade {
    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  }
`;

function GameCard({
  game,
  position,
  category,
}: {
  game: GameCardVM;
  position: number;
  category: string;
}): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const openFrom = location.pathname;
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const navigate = useNavigate();

  const navigateToGame = () => {
    // Disable link to game on games that don't allow demo play
    if (!game.isDemoAvailable && !isAuthenticated) {
      return;
    }
    navigate(`/joc/${game.slug}/${game.gameId}`);
  };

  return (
    <StyledGameCard
      className="game-card"
      isMobile={isMobile}
      onClick={() => {
        triggerGTag(GTagEvents.open_game, {
          openFrom,
          position,
          categoryName: category,
          gameName: game.name,
        });
        navigateToGame();
      }}
    >
      <div className="image-wrapper">
        <FadeIn>
          {(onload: () => void) => {
            return <img src={gameThumbImg(game)} alt={game.name} onLoad={onload} />;
          }}
        </FadeIn>
        {game.isNew && <span className="tag new">Nou</span>}
        {game.isHot && <span className={`tag hot${game.isLive ? ' hot-live' : ''}`}>Cald</span>}
        {game.isLive && (
          <span className="tag live">
            <span />
            Live
          </span>
        )}
        {!isEmpty(game.jackpot) && (
          <div className="tag jackpot">
            <CurrencyFormat currency="RON" amount={game.jackpot?.amount} />
          </div>
        )}
        <div className="overlay">
          {!game.isDemoAvailable && !isAuthenticated ? (
            <Button size="small" color="ghost" onClick={() => dispatch(openLoginModal())}>
              Intră în cont
            </Button>
          ) : (
            <PlayIcon />
          )}
        </div>
      </div>
      <h1 className="name">{game.name}</h1>
      <h2 className="vendor">
        <small>De</small> {game.shortDesc}
      </h2>
    </StyledGameCard>
  );
}

export default GameCard;
