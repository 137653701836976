import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocation } from 'react-use';

import { LigaLevelsEnum, LigaLevelStatus } from '@/models/liga.model';
import { selectUserLigaOptIn } from '@/store/selectors/userSelectors';
import { useLigaStore } from '@/store/useLigaStore';
import StorageManager from '@/utils/storage-manager-utils';

function useLigaToast(): void {
  const location = useLocation();
  const { data } = useLigaStore();
  const navigate = useNavigate();
  const hasLigaOptIn = useSelector(selectUserLigaOptIn);

  const clickHandler = () => {
    StorageManager.saveLigaNotification(new Date());
    navigate('/liga-magica');
  };

  useEffect(() => {
    if (!data || !hasLigaOptIn || location.pathname?.includes('liga-magica')) {
      return;
    }

    // check if the Liga Toast has been shown in the past 12Hours
    const lastNotificationShown = StorageManager.getLigaNotification();
    if (lastNotificationShown) {
      const currentTime = new Date();
      // Subtract 1 hour (in milliseconds) from the current time
      const oneHourAgo = new Date(currentTime.getTime() - 60 * 60 * 1000);
      // if the last notification shown is less than 1 hour ago, the toast will not display
      if (lastNotificationShown > oneHourAgo) {
        return;
      }
    }

    // check if there are some prizes to be claimed
    const allLigaPrizeWheelStatuses = Object.keys(data.prizeWheels).reduce((acc: string[], i) => {
      const statuses = data.prizeWheels[i as LigaLevelsEnum].map(i => i.status);
      return [...acc, ...statuses];
    }, []);

    const hasEligiblePrize = allLigaPrizeWheelStatuses.includes(LigaLevelStatus.Eligible);

    // if there is no eligible prize, the toast will not display
    if (!hasEligiblePrize) {
      return;
    }

    toast(
      <div>
        <div className="font-bold mb-2">LigaMagică</div>
        <div className="flex items-center">
          <div>Felicitări! Ai o Roata de premii deblocată pe care o poți învârti.</div>
          <button
            type="button"
            className="bg-black rounded-lg py-2 px-4 text-white ml-4"
            onClick={clickHandler}
          >
            Revendică
          </button>
        </div>
      </div>,
      {
        toastId: 'ligaToast',
        position: 'bottom-center',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        onClose: () => {
          StorageManager.saveLigaNotification(new Date());
        },
      },
    );
  }, [data, hasLigaOptIn, location.pathname]);
}

export default useLigaToast;
