import { PublicUserConsentModel } from '@lucky7ventures/lucky-components';
import { Action } from 'redux';

export enum ConsentsActionsTypes {
  Get = 'GET_CONSENTS',
  Start = 'GET_CONSENTS_START',
  Success = 'GET_CONSENTS_SUCCESS',
  Error = 'GET_CONSENTS_ERROR',
}

export type GetConsentsAction = Action<ConsentsActionsTypes.Get>;
export type GetConsentsSuccessAction = Action<ConsentsActionsTypes.Success> & {
  consents: PublicUserConsentModel[];
};
export type GetConsentsErrorAction = Action<ConsentsActionsTypes.Error> & {
  error: string;
};

export type ConsentsActions = GetConsentsAction | GetConsentsSuccessAction | GetConsentsErrorAction;

export const getConsents = (): GetConsentsAction => ({
  type: ConsentsActionsTypes.Get,
});

export const getConsentsSuccess = (
  consents: PublicUserConsentModel[],
): GetConsentsSuccessAction => ({
  type: ConsentsActionsTypes.Success,
  consents,
});

export const getConsentsError = (error: string): GetConsentsErrorAction => ({
  type: ConsentsActionsTypes.Error,
  error,
});
