import React from 'react';

import LazyLoad from 'react-lazyload';
import styled from 'styled-components/macro';

import TotalJackpots from './TotalJackpots';

const StyledSplash = styled.section`
  .wrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 3rem 0 1rem;
    display: grid;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 6rem 0;
      grid-template-columns: repeat(4, minmax(10px, 1fr));
      grid-gap: 2rem;
    }

    h1 {
      margin-bottom: 0;
    }
  }

  .column {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 2rem;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      margin-bottom: 0;
    }

    img {
      max-width: 500px;
      width: 100%;
      height: auto;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        max-width: 700px;
      }
    }

    p {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
  }
`;

function Splash(): JSX.Element {
  return (
    <StyledSplash data-cy="SPLASH">
      <div className="wrapper">
        <div className="column">
          <LazyLoad offset={400}>
            <img
              width="326px"
              height="326px"
              src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/hat.png"
              alt="Hat"
            />
          </LazyLoad>
          <p>
            Total Jackpoturi <br /> <TotalJackpots />
          </p>
        </div>
        <div className="column">
          <LazyLoad offset={400}>
            <img
              width="326px"
              height="326px"
              src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/star.png"
              alt="Star"
            />
          </LazyLoad>
          <p>
            Peste 2176 <br /> de jocuri
          </p>
        </div>
        <div className="column">
          <LazyLoad offset={400}>
            <img
              width="326px"
              height="326px"
              src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/chip.png"
              alt="Chip"
            />
          </LazyLoad>
          <p>
            Depozite sigure și <br /> plăți super-rapide!
          </p>
        </div>
        <div className="column">
          <LazyLoad offset={400}>
            <img
              width="326px"
              height="326px"
              src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/diamonds.png"
              alt="Diamonds"
            />
          </LazyLoad>
          <p>
            Bonusuri și campanii <br /> promoționale exclusive
          </p>
        </div>
      </div>
    </StyledSplash>
  );
}

export default Splash;
