import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import PromotionCard from './PromotionCard';
import PageHeader from '@/components/header/PageHeader';
import usePromotionsRestriction from '@/hooks/usePromotionsRestriction';
import { getPromotions } from '@/store/actions/promotionsActions';
import {
  selectFocusedPromotion,
  selectPromotions,
  selectPromotionsLoading,
  selectVisiblePromotions,
} from '@/store/selectors/promotionSelectors';

// prettier-ignore
const Promotions = (): JSX.Element => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const promotions = useSelector(selectPromotions);
  const visiblePromotions = useSelector(selectVisiblePromotions);
  const focusedPromotion = useSelector(selectFocusedPromotion);
  const promotionsLoading = useSelector(selectPromotionsLoading);
  const dispatch = useDispatch();
  const { promotionsRestricted } = usePromotionsRestriction();

  if (promotionsRestricted) {
    navigate('/');
  }

  useEffect(() => {
    if (promotions.length === 0) {
      dispatch(getPromotions());
    }
  }, []);

  return (
    <>
      <Helmet>
        <body className="header-background overflow-x-hidden" />
      </Helmet>
      <div className="[&>section]:!p-4 [&_.content]:!justify-start [&_.content]:!px-0 [&_.content]:!max-w-[1300px] [&_.content_h1]:!mt-2 relative hidden md:flex">
        <PageHeader>
          <h1 className="self-start text-2xl font-bold text-black">Promoții</h1>
        </PageHeader>
      </div>
      <div className="relative mx-4 flex flex-col items-center py-28 md:pt-44">
        {promotions.length > 0 && (
          <div className="absolute -top-[100px] left-1/2 z-[10] hidden w-full max-w-[1300px] -translate-x-1/2 md:block">
            <div className="[&>div]:!shadow-none [&>div]:sm:rounded-tr-none w-[60%]  rounded-lg border-4 border-solid border-white shadow-[rgba(100,100,111,0.4)_0px_7px_29px_0px]">
              {focusedPromotion && <PromotionCard {...focusedPromotion} />}
            </div>
          </div>
        )}
        <h1 className="block text-2xl font-bold text-black md:hidden">Promoții</h1>
        {promotions.length > 0 && (
            <div className="z-[2] grid w-full max-w-[1300px] justify-center gap-5 md:grid-cols-2 sm:gap-7 md:pt-36">
              {visiblePromotions
                .filter((promotion, index) => (width > 768 ? index > 0 : true))
                .map((promotion, index) => (
                  <PromotionCard {...promotion} key={index}/>
                ))
              }
          </div>
        )}
        {!promotionsLoading && promotions.length === 0 &&
          <div>
            <img
              src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/chip.png"
              alt="Chip"
              className="w-full max-w-[180px] h-auto block my-0 mx-auto"
            />
            <span>There are currently no promotions.</span>
          </div>
        }
        <div className="absolute -right-[5%] top-0 z-[2] hidden h-[575px] w-1/2 -translate-y-[50%] bg-promo-stars bg-contain bg-right bg-no-repeat sm:block" />
        <div className="absolute -left-[15%] top-[5%] hidden h-[575px] w-1/2 bg-promo-chip bg-contain bg-left bg-no-repeat sm:block" />
        <div className="absolute -right-[10%] top-[450px] hidden h-[575px] w-1/3 bg-promo-hat bg-contain bg-no-repeat sm:block" />
        <div className="absolute -bottom-36 -left-[10%] hidden h-[575px] w-1/2 bg-promo-coins bg-contain bg-no-repeat sm:block" />
      </div>
    </>
  );
};

export default Promotions;
