import * as React from 'react';

const LigaPrizeWheelIcon = ({ className }: { className: string }): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.14 22" className={className}>
      <g>
        <path
          fill="currentColor"
          d="m12.85,18c2.64-1.53,4.28-4.38,4.28-7.42s-1.64-5.89-4.29-7.42c-.61-.35-1.25-.61-1.91-.8l.43-1.14c.1-.28.07-.59-.1-.83-.17-.24-.45-.39-.74-.39h-3.9c-.3,0-.57.15-.74.39-.17.24-.21.55-.1.83l.43,1.14c-.66.19-1.31.45-1.92.81C1.64,4.69,0,7.53,0,10.58s1.64,5.89,4.28,7.41c.43.25.9.45,1.37.62v1.58h-1.89v1.8h9.62v-1.8h-1.96v-1.55c.49-.17.97-.37,1.43-.64Zm-3.24-7.42c0,.58-.47,1.05-1.05,1.05s-1.05-.47-1.05-1.05.47-1.05,1.05-1.05,1.05.47,1.05,1.05Zm-1.62,2.79c.18.04.38.06.57.06s.39-.02.57-.06l2,3.47c-.28.11-.57.21-.86.28-1.42.38-2.94.26-4.28-.29l2-3.46Zm4.7,2.56l-2.01-3.48c.25-.28.44-.61.56-.98h4.01c-.24,1.75-1.17,3.36-2.57,4.45Zm2.57-6.26h-4.01c-.12-.36-.31-.69-.56-.98l2.01-3.48c1.4,1.09,2.33,2.7,2.57,4.45ZM9.22,1.8l-.65,1.73-.65-1.73h1.3Zm-1.49,4.62c.13.35.47.59.84.59s.71-.23.84-.59l.89-2.37c.28.07.56.17.84.28l-2,3.46c-.18-.04-.38-.06-.57-.06s-.39.02-.57.06l-2-3.46c.27-.11.55-.21.84-.28l.89,2.37Zm-3.29-1.19l2.01,3.47c-.25.28-.44.61-.56.98H1.86c.24-1.76,1.17-3.37,2.58-4.45Zm-2.58,6.25h4.02c.12.36.31.69.56.98l-2.01,3.47c-1.41-1.09-2.34-2.69-2.58-4.45Zm5.59,8.71v-1.12c.37.05.74.07,1.12.07.35,0,.7-.02,1.04-.07v1.12h-2.17Z"
        />
      </g>
    </svg>
  );
};

export default LigaPrizeWheelIcon;
