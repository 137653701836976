import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/button/Button';
import { openLigaTOSModal, openLoginModal } from '@/store/actions/modalActions';

const LigaCTABox = ({ loading }: { loading: boolean }): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const btnHandler = () => {
    dispatch(openLoginModal());
  };

  const tosModalHandler = () => {
    dispatch(openLigaTOSModal());
  };

  return (
    <div className="rounded-lg shadow-lg p-6 text-center bg-white max-w-sm">
      <div className={`${loading ? 'animate-pulse' : ''}`}>
        <div>
          <span className="font-bold">Pariază cash la Sloturi</span>
        </div>
        <div className="text-brand-red font-bold">1 Leu = 1 Punct</div>
        <div className="flex justify-around items-center mt-4">
          <Button
            size="small"
            color="black"
            onClick={() => navigate('/inregistrare')}
            className="mr-4"
          >
            Înregistrează-te
          </Button>
          <Button size="small" color="ghostBlack" onClick={btnHandler}>
            Intră în cont
          </Button>
        </div>
        <div className="text-center mt-4">
          <button type="button" className="underline" onClick={tosModalHandler}>
            Termeni & Condiții
          </button>
        </div>
      </div>
    </div>
  );
};

export default LigaCTABox;
