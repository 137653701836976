import { useEffect, useState } from 'react';

import axios from 'axios';
import { useSelector } from 'react-redux';

import { DateRangeModel } from '@/models/date-range.model';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { doNothing } from '@/utils/helpers';

interface Return<T> {
  loading: boolean;
  history: T[];
  setDateRange: React.Dispatch<DateRangeModel>;
}

function useUpdateHistory<T>(endpoint: string): Return<T> {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [dateRange, setDateRange] = useState<DateRangeModel | null>(null);
  const token = useSelector(selectAuthToken);

  useEffect(() => {
    if (!dateRange || !token) {
      return;
    }

    setLoading(true);
    const { from, to } = dateRange;
    axios
      .post(endpoint, { token, from, to })
      .then(results => {
        if (results.data.data) {
          setHistory(results.data.data);
        }
      })
      .catch(doNothing)
      .finally(() => {
        setLoading(false);
      });
  }, [token, dateRange]);

  return { loading, history, setDateRange };
}

export default useUpdateHistory;
