import { UserWalletPublic } from '@lucky7ventures/lucky-components';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeEvery,
  call,
  StrictEffect,
} from 'redux-saga/effects';

import { ApiResponseModel } from '@/models/api-response.model';
import { isAxiosResponse } from '@/shared/type-guards';
import {
  GetWalletAction,
  getWalletError,
  getWalletSuccess,
  WalletActionsTypes,
} from '@/store/actions/walletActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';

type WalletAxiosResponse = AxiosResponse<ApiResponseModel<UserWalletPublic>>;

export function* getWalletSaga(
  action: GetWalletAction,
): Generator<StrictEffect, void, WalletAxiosResponse | string | null> {
  const token = action.token ? action.token : yield select(selectAuthToken);

  if (!token) {
    yield put(getWalletError());
    return;
  }

  try {
    const response = yield call(axios.post, '/api/user/wallet', { token });

    if (isAxiosResponse(response) && response.data.success && response.data.data) {
      yield put(getWalletSuccess(response.data.data));
    } else {
      yield put(getWalletError());
    }
  } catch (error) {
    yield put(getWalletError());
  }
}

export function* watchWallet(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(WalletActionsTypes.Get, getWalletSaga)]);
}
