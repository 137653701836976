import React from 'react';

import { BoltIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

const RegistrationUsp = () => {
  return (
    <div className="flex items-center justify-between py-3 px-4 rounded-t-lg sm:py-4 bg-brand-gold">
      <span className={classNames('text-gray-800 text-sm max-w-[95%]', {})}>
        Fă-ți cont și bucură-te de <span className="font-bold">depuneri instant</span> și{' '}
        <span className="font-bold">retrageri rapide</span>
      </span>
      <BoltIcon className="w-5 h-auto text-brand-red" />
    </div>
  );
};

export default RegistrationUsp;
