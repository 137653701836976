import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import { BFF_GAMES_CATEGORY } from '@/constants/constants';
import GameList, { StyledGameList } from '@/pages/games/GameList';
import { selectGamesByCategory } from '@/store/selectors/gamesSelectors';

const StyledHomeGames = styled.div`
  padding-bottom: 6rem;

  ${StyledGameList} {
    padding: 0;
    margin-top: 2rem;
    margin-bottom: 4rem;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 0 2rem;
    }

    .header {
      display: none;
    }

    .games {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(15, minmax(140px, 1fr));
      grid-template-rows: minmax(150px, 1fr);
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      padding: 0 1rem;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
        grid-template-rows: auto;
        gap: 2rem;
      }

      &:after {
        content: '';
        max-width: 2rem;

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          display: none;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .button-wrapper {
    text-align: center;
  }
`;

function HomeGames(): JSX.Element | null {
  const games = useSelector(selectGamesByCategory(BFF_GAMES_CATEGORY.RECOMMENDED));

  if (!games || games.length === 0) {
    return null;
  }

  return (
    <StyledHomeGames data-cy="HOME_GAMES">
      <GameList games={games} header="Home intro games" maxLength={12} />
      <div className="button-wrapper">
        <Link to="/jocuri" className="all-games-button">
          <Button size="big">Vezi toate jocurile</Button>
        </Link>
      </div>
    </StyledHomeGames>
  );
}

export default HomeGames;
