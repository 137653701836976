import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import KycUpload from './KycUpload';
import AlertIcon from '@/components/icons/AlertIcon';
import QuestionIcon from '@/components/icons/QuestionIcon';
import ThumbUpIcon from '@/components/icons/ThumbUpIcon';
import TimeIcon from '@/components/icons/TimeIcon';
import { getKyc } from '@/store/actions/kycActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import {
  selectKycLoading,
  selectPaymentMethod,
  selectPhotoId,
  selectProofOfAddress,
} from '@/store/selectors/kycSelectors';
import { isEmpty } from '@/utils/helpers';

const StyledVerify = styled.div`
  width: 100%;

  .content {
    display: grid;
    padding-bottom: 64px;

    .intro {
      grid-column: 1/-1;
      margin-top: 4rem;

      p {
        margin-bottom: 0;
      }
    }

    .kyc {
      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 64px 0 24px;

        h2 {
          color: ${props => props.theme.green};
        }

        .status {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          white-space: nowrap;

          svg {
            margin-right: 8px;
            font-size: 18px;
          }

          &.approved {
            color: ${props => props.theme.green};
          }

          &.rejected {
            color: ${props => props.theme.red};
          }

          &.processing {
            color: ${props => props.theme.yellow};
          }
        }
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

function Verify() {
  const photoId = useSelector(selectPhotoId);
  const proofOfAddress = useSelector(selectProofOfAddress);
  const paymentMethod = useSelector(selectPaymentMethod);
  const kycLoading = useSelector(selectKycLoading);
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getKyc());
    }
  }, [token, dispatch]);

  function getStatus(statusId: number) {
    switch (statusId) {
      case 1:
        return (
          <div className="status approved">
            <ThumbUpIcon /> Toate bune
          </div>
        );
      case 2:
      case 5:
        return (
          <div className="status requested">
            <QuestionIcon /> Solicitat
          </div>
        );
      case 3:
        return (
          <div className="status rejected">
            <AlertIcon /> Respins
          </div>
        );
      case 4:
        return (
          <div className="status processing">
            <TimeIcon /> În așteptare
          </div>
        );
      default:
        return <div className="status">Nesolicitat</div>;
    }
  }

  function getDocumentStatus(loading: boolean, statusId?: number) {
    if (loading) {
      return <div className="status">Se încarcă...</div>;
    }
    if (isEmpty(statusId)) {
      return <div className="status">Nesolicitat</div>;
    }
    if (statusId) {
      return getStatus(statusId);
    }
  }

  return (
    <StyledVerify>
      <div className="content">
        <div className="intro">
          <h1>Încărcați documentele</h1>
          <p>
            Urmărește instrucțiunile de mai jos pentru a-ți verifica contul, în cazul în care ai
            întrebări, poți contacta Serviciul Clienți.
          </p>
        </div>
        <div className="kyc">
          <header>
            <h2>Poza actului de identitate</h2>
            {getDocumentStatus(kycLoading, photoId?.Status)}
          </header>
          <p>
            Te rugăm să încarci o poză color cu actul de identitate (buletinul). Avem nevoie doar de
            fața documentului. Asigură-te că toate detaliile sunt vizibile.
          </p>
          {photoId && !isEmpty(photoId) && [2, 3, 5].indexOf(photoId.Status) > -1 && (
            <KycUpload kycId={photoId.KycId} type="photoId" />
          )}
        </div>
        <div className="kyc">
          <header>
            <h2>Dovada adresei</h2>
            {getDocumentStatus(kycLoading, proofOfAddress?.Status)}
          </header>
          <p>
            Pentru validarea adresei, încarcă o copie recentă a unei facturi sau ultimul extras de
            cont. <strong>NU NECESITĂM</strong> aceste documente în cazul în care ai trimis deja o
            copie după actul de identitate (buletin).
          </p>
          <p>Adresa din document trebuie să corespundă cu adresa folosită la înregistrare.</p>
          {proofOfAddress &&
            !isEmpty(proofOfAddress) &&
            [2, 3, 5].indexOf(proofOfAddress.Status) > -1 && (
              <KycUpload kycId={proofOfAddress.KycId} type="proofOfAddress" />
            )}
        </div>
        <div className="kyc">
          <header>
            <h2>Dovada plății</h2>
            {getDocumentStatus(kycLoading, paymentMethod?.Status)}
          </header>
          <p>
            Pentru a verifica metoda de plată, încarcă o copie validă a ultimei metode de plată
            utilizate. Ține cont că, pentru cardurile de credit/debit, solicităm o poză cu fața
            cardului unde cifrele din mijloc să fie acoperite, iar vizibile să rămână numele, data
            expirării, primele 6 cifre si ultimele 4 cifre din seria cardului.
          </p>
          <p>
            <em>
              Sfat: Dacă ai utilizat mai multe metode de plată, încărcă documentele pentru toate
              acestea. Astfel, te asiguri că nu va mai fi nevoie să îți solicităm mai multe
              documente și, prin urmare, putem să accelerăm procesul.
            </em>
          </p>
          {paymentMethod &&
            !isEmpty(paymentMethod) &&
            [2, 3, 5].indexOf(paymentMethod.Status) > -1 && (
              <KycUpload kycId={paymentMethod.KycId} type="paymentMethod" />
            )}
        </div>
      </div>
    </StyledVerify>
  );
}

export default Verify;
