import { BffSetAuthenticationMethodPathParam } from '@lucky7ventures/bff-types';

export const BFF_API_URLS = {
  ADDRESS_LOOKUP: '/api/v1/address/lookup',
  ADDRESS_RETRIEVAL: '/api/v1/address/retrieve',

  LOGIN_WITH_OTP: '/api/v1/auth/login-otp',

  GET_ALL_GAMES: '/api/v1/games/fetch',
  GET_GAMES_JACKPOTS: '/api/v1/games/jackpots',
  GET_LAST_PLAYED: '/api/v1/games/last-played',

  GET_AUTHENTICATION_METHOD: '/api/v1/user/get-auth-method',
  SET_AUTHENTICATION_METHOD: (authMethod: BffSetAuthenticationMethodPathParam) =>
    `/api/v1/user/set-auth-method/${authMethod}`,

  VALIDATE_EMAIL_PHONE: 'api/v1/validate/email-phone',
  REGISTER: 'api/v1/register/mjp',
};
