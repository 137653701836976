import React, { useEffect } from 'react';

import CurrencyFormat from '@/components/formats/CurrencyFormat';
import useApiV2Request from '@/hooks/useApiV2Request';
import ApiService from '@/shared/api-service';

function TotalJackpots(): JSX.Element {
  const { loading, request, data } = useApiV2Request<{ totalJackpots: number }>();

  useEffect(() => {
    request(ApiService.getJackpots, null);
  }, []);

  if (loading || !data) {
    return <span>Se încarcă...</span>;
  }

  return (
    <span>
      <CurrencyFormat amount={data.totalJackpots} currency="RON" />
    </span>
  );
}

export default TotalJackpots;
