import { BffErrorCode } from '@lucky7ventures/bff-types';
import axios, { AxiosError } from 'axios';

import { ErrorCodes } from '@/models/enums/error-codes.enum';
import { logException } from '@/utils/sentry-utils';

export interface ApiV2Error {
  errorId: number;
  error: string;
}

const isApiV2Error = (data: unknown): data is ApiV2Error => {
  const r = data as ApiV2Error;
  return !!(r.errorId && r.error);
};

export const apiV2ErrorHandler = (error: AxiosError): ApiV2Error => {
  if (axios.isCancel(error)) {
    return {
      errorId: 2003,
      error: ErrorCodes.CANCELLED,
    };
  }

  if (error.response) {
    if (isApiV2Error(error.response.data)) {
      return error.response.data;
    }
    // check if error is a CloudFlare error from the rate limiter
    if (error.response.status === 429) {
      return {
        errorId: 2001,
        error: ErrorCodes.TOO_MANY_REQUESTS,
      };
    }
    // check if error is a CloudFlare error from the forbidden country
    if (error.response.status === 403) {
      return {
        errorId: 2002,
        error: ErrorCodes.FORBIDDEN,
      };
    }
  }

  return {
    errorId: 2000,
    error: 'UNKNOWN_ERROR',
  };
};

export enum FrontendErrorCode {
  CancelledRequest = -1,
  TooManyRequests = -2,
  UnknownError = -3,
  UnexpectedResponseFormat = -4,
  Forbidden = -5,
}

export type BffApiErrorCode = BffErrorCode | FrontendErrorCode;

export type BffApiError = {
  code: BffApiErrorCode;
};

export interface BffErrorResponse {
  data: null;
  error: BffApiError;
}

const isBffErrorResponse = (data: unknown): data is BffErrorResponse => {
  const r = data as BffErrorResponse;
  return !!(r && r.error && r.error.code);
};

export const bffApiErrorHandler = (error: AxiosError): BffApiError => {
  if (axios.isCancel(error)) {
    return {
      code: FrontendErrorCode.CancelledRequest,
    };
  }

  if (error.response) {
    if (isBffErrorResponse(error.response.data) && error.response.data.error) {
      return error.response.data.error;
    }

    // check if error is a CloudFlare error from the rate limiter
    if (error.response.status === 429) {
      return {
        code: FrontendErrorCode.TooManyRequests,
      };
    }

    // error coming from Cloudflare WAF rules
    if (error.response.status === 403) {
      return {
        code: FrontendErrorCode.Forbidden,
      };
    }
  }

  // let's log all the unknown errors to Sentry to maybe get some context
  logException(new Error('BFF Unknown Error'), { error });

  return {
    code: FrontendErrorCode.UnknownError,
  };
};
