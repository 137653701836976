import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import LigaCTABox from './LigaCTABox';
import LigaCard from './LigaCard';
import LigaHero from './LigaHero';
import LigaOverviewExplainer from './LigaOverviewExplainer';
import LigaRecap from './LigaRecap';
import Button from '@/components/button/Button';
import { LIGA_WHEEL_BASE } from '@/constants/constants';
import {
  LigaLevelsEnum,
  LigaLevelStatus,
  LigaPrizeWheel,
  LigaPrizeWheels,
  LigaWheelLevelsEnum,
} from '@/models/liga.model';
import { openLigaOptInModal, openLoginModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated, selectAuthToken } from '@/store/selectors/authSelectors';
import {
  selectUserIsBonusRestricted,
  selectUserIsDepositor,
  selectUserIsLigaVerified,
  selectUserLigaOptIn,
} from '@/store/selectors/userSelectors';
import { useLigaStore } from '@/store/useLigaStore';
import { loggedOutLigaLevels } from '@/utils/liga-utils';

function LigaPage(): JSX.Element | null {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const { data, loading: ligaLoading, fetch } = useLigaStore();
  const token = useSelector(selectAuthToken);
  const hasOptin = useSelector(selectUserLigaOptIn);
  const isVerified = useSelector(selectUserIsLigaVerified);
  const isDepositor = useSelector(selectUserIsDepositor);
  const [selectedWheel, setSelectedWheel] = useState<string | null>(null);
  const ligasRef = useRef<HTMLDivElement>(null);
  const previousLigasRef = useRef<HTMLDivElement>(null);
  const [ligaLevels, setLigaLevels] = useState<LigaPrizeWheels>(loggedOutLigaLevels());
  const isBonusRestricted = useSelector(selectUserIsBonusRestricted);
  const navigate = useNavigate();

  useEffect(() => {
    // if user is bonus restricted we want to navigate him to homepage and present a notification
    if (isBonusRestricted) {
      navigate('/');
      toast(<div>Nu ești eligibil pentru această promoție.</div>, {
        toastId: 'ligaIneligibleToast',
        position: 'bottom-center',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }, [isBonusRestricted]);

  useEffect(() => {
    if (isAuthenticated) {
      fetch();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (data) {
      setLigaLevels(data.prizeWheels);
    }
  }, [data]);

  const closeWheel = () => {
    setSelectedWheel(null);
  };

  const scrollToLigas = () => {
    if (previousLigasRef && previousLigasRef.current) {
      window.scroll({ top: previousLigasRef.current.offsetTop, left: 0, behavior: 'smooth' });
      return;
    }

    if (ligasRef && ligasRef.current) {
      window.scroll({ top: ligasRef.current.offsetTop, left: 0, behavior: 'smooth' });
    }
  };
  const openWheel = (ligaWheel: LigaPrizeWheel) => {
    if (!isAuthenticated) {
      dispatch(openLoginModal());
      return;
    }

    if (!hasOptin) {
      dispatch(openLigaOptInModal());
      return;
    }

    if (ligaWheel.status === LigaLevelStatus.Claimed) {
      toast(
        <p>
          Ai folosit deja această Roată cu premii. Joacă din nou în LigaMagică săptămâna viitoare
          pentru a debloca această Roată din nou.
        </p>,
        {
          toastId: 'prizeWheelClaimed',
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
        },
      );
      return;
    }

    if (ligaWheel.status === LigaLevelStatus.Ineligible) {
      const liga7Message =
        'Nu ești încă eligibil să învârți această Roată cu premii. Efectuează prima depunere și verifică-ți contul pentru a o debloca.';
      const otherLigasMessage =
        'Nu ești eligibil să învârți această Roată cu premii. Adună mai multe puncte pentru a o debloca.';
      toast(
        <p>
          {ligaWheel.wheel === LigaWheelLevelsEnum.Liga7Wheel ? liga7Message : otherLigasMessage}
        </p>,
        {
          toastId: 'prizeWheelIneligible',
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
        },
      );
      return;
    }

    if (!token) {
      return;
    }
    const wheelUrl = `${LIGA_WHEEL_BASE}/${ligaWheel.wheel.toLowerCase()}?token=${token}`;
    setSelectedWheel(wheelUrl);
  };

  if (selectedWheel) {
    return (
      <div className="bg-brand-red fixed top-0 bottom-0 left-0 right-0 z-[200]">
        <div className="relative w-full h-full">
          <div className="absolute left-1 top-1">
            <Button size="icon" color="transparent" onClick={closeWheel}>
              <ArrowLeftIcon className="w-6 h-6" />
            </Button>
          </div>
          <iframe src={selectedWheel} title="LigaMagică Roata" className="w-full h-full" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <LigaHero
        isAuthenticated={isAuthenticated}
        data={data}
        loading={ligaLoading}
        openWheel={openWheel}
        isVerified={isVerified}
        isDepositor={isDepositor}
        scrollToLigas={scrollToLigas}
      />
      <div className="max-w-7xl w-full px-4 md:px-8 mx-auto relative -mt-24 lg:mt-0">
        <div id="e2e_ligaCta" className="lg:hidden flex justify-center">
          {isAuthenticated && data && (
            <LigaRecap
              data={data}
              isVerified={isVerified}
              isDepositor={isDepositor}
              openWheel={openWheel}
              availablePrizesClicked={scrollToLigas}
            />
          )}
          {!data && <LigaCTABox loading={ligaLoading} />}
        </div>
        <LigaOverviewExplainer ligaOptIn={hasOptin} />
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16 my-16"
          ref={ligasRef}
        >
          {(Object.keys(ligaLevels) as LigaLevelsEnum[]).map(level => (
            <div
              key={`liga-level-${level}`}
              className="lg:last:col-start-2"
              ref={
                data?.currentLiga !== level &&
                ligaLevels[level].find(i => i.status === LigaLevelStatus.Eligible)
                  ? previousLigasRef
                  : null
              }
            >
              <LigaCard
                nextTarget={data?.nextPrize.target}
                ligaLevel={level as LigaLevelsEnum}
                prizeWheels={ligaLevels[level]}
                openWheel={openWheel}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LigaPage;
