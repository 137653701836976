import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import useClickedOutside from '@/hooks/useClickedOutside';
import { ReactComponent as IconLeft } from '@/images/icon-left.svg';

const StyledButtonDropdown = styled.div`
  position: relative;

  .button-dropdown-trigger {
    display: flex;
    align-items: center;
    background: ${props => props.theme.black};
    border: none;
    color: white;
    padding: 0.6rem 0.7rem;
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: bold;

    &:hover {
      background: ${props => props.theme.blackHover};
    }

    .icon {
      margin-left: 0.5rem;

      svg {
        display: block;
        width: 1rem;
        height: 1rem;
        transform: rotate(-90deg);
        fill: white;
      }
    }
  }

  .button-dropdown-list {
    position: absolute;
    background: white;
    padding: 4px;
    border-radius: 6px;
    width: 250px;
    display: flex;
    flex-direction: column;
    right: 0;
    transform-origin: top right;
    z-index: 1;
    margin-top: 4px;
    box-shadow: ${props => props.theme.boxShadow};
    border: 1px solid rgba(0, 0, 0, 0.1);

    &.is-hidden {
      display: none;
    }

    button {
      text-align: left;
      padding: 8px;
      border-radius: 6px;
      font-size: 0.9rem;
      margin-bottom: 4px;
      font-weight: bold;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: white;
        background: ${props => props.theme.red};
      }

      &.is-active {
        color: white;
        background: ${props => props.theme.red};
      }
    }
  }
`;

export interface ButtonDropdownItem {
  isActive: boolean;
  label: string;
  clickCallback: () => void;
}

function ButtonDropdown({
  items,
  toggleLabel,
}: {
  items: ButtonDropdownItem[];
  toggleLabel: string;
}): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);
  const isClickedOutside = useClickedOutside(wrapperRef);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const itemClicked = (callback: () => void) => {
    setIsVisible(false);
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (isClickedOutside) {
      setIsVisible(false);
    }
  }, [isVisible, isClickedOutside]);

  return (
    <StyledButtonDropdown ref={wrapperRef}>
      <button className="button-dropdown-trigger" type="button" onClick={() => toggleVisibility()}>
        <span>{toggleLabel}</span>
        <span className="icon">
          <IconLeft />
        </span>
      </button>
      <div className={['button-dropdown-list', isVisible ? 'is-visible' : 'is-hidden'].join(' ')}>
        {items.map((item, i) => (
          <button
            type="button"
            className={item.isActive ? 'is-active' : ''}
            // eslint-disable-next-line react/no-array-index-key
            key={`button-dropdown-item-${i}`}
            onClick={() => itemClicked(item.clickCallback)}
          >
            {item.label}
          </button>
        ))}
      </div>
    </StyledButtonDropdown>
  );
}

export default ButtonDropdown;
