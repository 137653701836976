import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import JackpotLevel from './JackpotLevel';
import useApiV2Request from '@/hooks/useApiV2Request';
import useOnScreen from '@/hooks/useOnScreen';
import ApiService from '@/shared/api-service';

const StyledJackpotBanner = styled.div`
  background: ${props => props.theme.offWhite};
  padding: 3em 0;

  .container {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    @media (max-width: ${props => props.theme.breakpoints.medium}) {
      flex-direction: column-reverse;
      .jackpot-image {
        margin-top: 1rem;
      }
    }
  }

  .jackpot-image {
      img {
        max-width: 100%;
        @media (max-width: 1200px) and (min-width: ${props => props.theme.breakpoints.medium}) {
          max-width: 300px;
        }
      }
    }
  }

  .jackpot-content {
    flex: 1;

    @media (max-width: ${props => props.theme.breakpoints.medium}) {
      align-self: stretch;
    }

    .jackpot-levels {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
      @media (max-width: ${props => props.theme.breakpoints.small}) {
        grid-template-columns: auto;
        row-gap: 1rem;
      }
    }

    .jackpot-logo {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .jackpot-error {
        color: ${props => props.theme.red};
        button {
          text-decoration: underline;
        }
      }
    }
  }
`;

function JackpotBanner() {
  const [jackpotLevels, setJackpotLevels] = useState({
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
  });
  const [preloadedJackpotLevels, setPreloadedJackpotLevels] = useState(null);
  const [hasBeenVisible, setHasBeenVisible] = useState(false);
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const { request, error } = useApiV2Request();

  const onSuccess = data => {
    /* weird bit of logic here; if the component was in the viewport before the request finished,
        automatically set the jackpot levels;
        And if the component wasn't yet visible, save the jackpot levels temporarily in the preloadedJackpotLevels
        -> to be used as soon as the component comes into view
       */
    const prepareData = data => {
      const { level1, level2, level3, level4 } = data;
      return {
        level1: Number(level1),
        level2: Number(level2),
        level3: Number(level3),
        level4: Number(level4),
      };
    };

    const preparedData = prepareData(data.data);
    if (hasBeenVisible) {
      setJackpotLevels(preparedData);
    } else {
      setPreloadedJackpotLevels(preparedData);
    }
  };

  const fetchJackpotFeed = () => {
    request(ApiService.getEGTJackpots, null, onSuccess);
  };

  useEffect(() => {
    // fetch initial jackpot feed
    fetchJackpotFeed();
  }, []);

  useEffect(() => {
    if (isVisible) {
      setHasBeenVisible(true);
    }
  }, [isVisible]);

  /* this effect takes care of the case where the request has been completed before the
    view became visible; it moves the preloadedJackpotLevels to the jackpotLevels
   */
  useEffect(() => {
    if (isVisible && preloadedJackpotLevels) {
      setJackpotLevels(preloadedJackpotLevels);
    }
  }, [preloadedJackpotLevels, isVisible]);

  return (
    <StyledJackpotBanner data-cy="JACKPOT_BANNER">
      <div className="container">
        <div className="jackpot-image">
          <img
            src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=475,height=452,format=auto/assets/jackpot-machine.png"
            alt="Jackpots EGT"
          />
        </div>
        <div className="jackpot-content">
          <div className="text-2xl text-gray-800 mb-2 font-bold">
            Câștigă magic cu cele 4 jackpoturi Amusnet!
          </div>
          <div className="text-xl mb-8 text-gray-800">
            Jackpot câştigat, 26 Mai, 5 Dazzling Hot: 670.948 RON
          </div>
          <div className="jackpot-levels" ref={ref}>
            <div>
              <JackpotLevel icon="spades" title="Jackpot Pică" value={jackpotLevels.level4} />
            </div>
            <div>
              <JackpotLevel icon="hearts" title="Jackpot Inimă" value={jackpotLevels.level3} />
            </div>
            <div>
              <JackpotLevel icon="diamonds" title="Jackpot Romb" value={jackpotLevels.level2} />
            </div>
            <div>
              <JackpotLevel icon="clubs" title="Jackpot Treflă" value={jackpotLevels.level1} />
            </div>
          </div>
          <div className="jackpot-logo">
            <div>
              {error && (
                <div className="jackpot-error">
                  Valoarea jackpot-ului nu este momentan disponibil.{' '}
                  <button type="button" onClick={() => fetchJackpotFeed()}>
                    Te rugăm să încerci din nou.
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledJackpotBanner>
  );
}

export default JackpotBanner;
