import React, { Dispatch, SetStateAction } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import HelpIcon from '@/components/icons/HelpIcon';
import LoginIcon from '@/components/icons/LoginIcon';
import LogoLandscape from '@/components/icons/LogoLandscape';
import ResetIcon from '@/components/icons/ResetIcon';
import { DrawerEnum } from '@/models/enums/drawer.enum';
import { LoginSectionEnum } from '@/models/enums/login-section.enum';
import { openDrawer } from '@/store/actions/drawerActions';

const StyledOptionButton = styled.button`
  width: 100%;
  margin-top: 16px;
  display: flex;
  grid-template-columns: 3rem 1fr;
  padding: 1rem;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius};
  border: 2px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  justify-content: center;

  .icon {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    margin-right: 0.5rem;
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }
`;

function FailedLogins({
  closeModal,
  setLoginSection,
}: {
  closeModal: () => void;
  setLoginSection: Dispatch<SetStateAction<LoginSectionEnum>>;
}): JSX.Element {
  const dispatch = useDispatch();
  return (
    <div className="login">
      <LogoLandscape className="logo" />
      <p className="info">
        Oops, parola sau adresa de e-mail introdusă este greșită. Te rugăm să selectezi una dintre
        opțiunile de mai jos:
      </p>
      <div className="buttons">
        <StyledOptionButton
          type="button"
          className="options"
          onClick={() => setLoginSection(LoginSectionEnum.ForgotPassword)}
        >
          <div className="icon">
            <ResetIcon />
          </div>
          Resetare parola
        </StyledOptionButton>
        <StyledOptionButton
          type="button"
          className="options"
          onClick={() => {
            closeModal();
            setTimeout(() => {
              dispatch(openDrawer(DrawerEnum.Help));
            }, 150);
          }}
        >
          <div className="icon">
            <HelpIcon />
          </div>
          Asistență clienți
        </StyledOptionButton>
        <StyledOptionButton
          type="button"
          className="options"
          onClick={() => setLoginSection(LoginSectionEnum.Login)}
        >
          <div className="icon">
            <LoginIcon />
          </div>
          Încearcă o nouă logare
        </StyledOptionButton>
      </div>
    </div>
  );
}

export default FailedLogins;
