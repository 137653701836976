import React from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { ReactComponent as Logo } from '@/images/logo.svg';
import { selectGamesByProvider } from '@/store/selectors/gamesSelectors';

const StyledGameRules = styled.div`
  header {
    padding: 1rem;
    background: ${props => props.theme.red};

    svg {
      width: 100px;
      height: auto;
      color: ${props => props.theme.white};
    }
  }

  .content {
    width: 100%;
    padding: 4rem 1rem;
    margin: 0 auto;
    max-width: 800px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);

    th {
      padding: 1rem;
      background: ${props => props.theme.black};
      font-weight: 700;
      color: ${props => props.theme.white};
      text-align: left;
    }

    td {
      padding: 1rem;
    }

    tbody tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-collapse: collapse;

      &:last-child {
        border-radius: 0 0 1rem 1rem;
        border-bottom: 0;
      }
    }

    tr:nth-child(odd) {
      background: ${props => props.theme.offWhite};
    }

    a {
      text-decoration: underline;
    }
  }
`;

function GameRules(): JSX.Element {
  const novomaticGames = useSelector(selectGamesByProvider('novomatic'));

  return (
    <StyledGameRules>
      <Helmet>
        <body className="game-rules" />
      </Helmet>
      <header>
        <Logo />
      </header>
      <section className="content">
        <h1>Regulamente de joc</h1>
        <table>
          <thead>
            <tr>
              <th>Joc</th>
              <th>Ultima actualizare</th>
              <th>Download PDF</th>
            </tr>
          </thead>
          <tbody>
            {novomaticGames ? (
              novomaticGames.games.map(game => (
                <tr key={game.gameId}>
                  <td>{game.name}</td>
                  <td>2021-02-01</td>
                  <td>
                    <a href={`https://static.magicjackpot.ro/regulamente-de-joc/${game.slug}.pdf`}>
                      Download
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Se încarcă...</td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </StyledGameRules>
  );
}

export default GameRules;
