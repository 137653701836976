import React, { useState } from 'react';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import Terms from '@/components/formats/Terms';
import { WP_URL } from '@/constants/constants';
import { doNothing } from '@/utils/helpers';

function BonusTermsAndConditions() {
  const { termsId } = useParams();
  const [terms, setTerms] = useState<string | null>(null);

  useEffectOnce(() => {
    axios
      .get(`${WP_URL}/bonuses/${termsId}`)
      .then(response => {
        if (response?.data) {
          setTerms(response.data.bonus_terms);
        }
      })
      .catch(() => doNothing());
  });

  return (
    <div>
      <Helmet>
        <body className="header-background" />
      </Helmet>
      {terms && <Terms terms={terms} />}
    </div>
  );
}

export default BonusTermsAndConditions;
