import React, { useEffect, useRef, useState } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';

import LigaPrizeWheelBtn from './LigaPrizeWheelBtn';
import {
  LigaLevelsEnum,
  LigaLevelStatus,
  LigaPrizeWheel,
  LigaWheelLevelsEnum,
} from '@/models/liga.model';
import { openLoginModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import {
  isLigaWinterPeriod,
  ligaBadgeChipsUrl,
  ligaCardBackgroundUrl,
  ligaPrizes,
  ligaPrizesTitle,
} from '@/utils/liga-utils';

const isLigaWinter = isLigaWinterPeriod();

const LigaCard = ({
  ligaLevel,
  prizeWheels,
  openWheel,
  nextTarget,
}: {
  ligaLevel: LigaLevelsEnum;
  prizeWheels: { status: LigaLevelStatus; wheel: LigaWheelLevelsEnum }[];
  openWheel: (prizeWheel: LigaPrizeWheel) => void;
  nextTarget: LigaWheelLevelsEnum | 'None' | undefined;
}): JSX.Element => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const [flip, setFlip] = useState(false);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const observerCallback = (entries: any) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef]);

  const wheelBtnHandler = (prizeWheel: LigaPrizeWheel) => {
    if (!isAuthenticated) {
      dispatch(openLoginModal());
    }

    openWheel(prizeWheel);
  };

  const outerCardClickHandler = () => {
    // this is a weird fix because of iOS bug where the events on the backside of the card are not working
    // so it was not possible to flip the card back, that's why I've attached the event on the outer card container
    if (flip) {
      setFlip(false);
    }
  };

  return (
    <div
      ref={containerRef}
      className={`transition-all duration-1000  w-full h-[220px] ${
        isVisible ? 'opacity-100 blur-none' : 'opacity-0 blur-sm'
      }`}
      style={{
        perspective: '1000px',
      }}
      onClick={outerCardClickHandler}
    >
      <div
        className={`flip-card-effect relative w-full shadow-lg h-full ${
          flip ? 'rotate-y-180' : 'rotate-y-0'
        }`}
      >
        <div
          className="w-full h-full bg-cover rounded-lg  bg-center absolute backface-hidden"
          style={{ backgroundImage: `url(${ligaCardBackgroundUrl(ligaLevel)})` }}
        >
          {/* Front content of the card */}
          <div
            className={` p-8 relative text-white w-full h-full transition-opacity delay-100 ${
              flip ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'
            }`}
          >
            <div className="absolute-full bg-black/20 z-0 rounded-lg"></div>
            <div className={`absolute left-0 right-0 -top-12 flex justify-center items-start z-0`}>
              <img
                alt=""
                className={`${!isLigaWinter ? 'w-48' : 'w-36'}`}
                src={ligaBadgeChipsUrl(ligaLevel)}
              />
            </div>
            <div className={`flex justify-center mt-12`}>
              {prizeWheels.map(prizeWheel => (
                <div key={`${ligaLevel}-${prizeWheel.wheel}`} className="mx-4">
                  <LigaPrizeWheelBtn
                    nextTarget={prizeWheel.wheel === nextTarget}
                    isAuthenticated={isAuthenticated}
                    status={prizeWheel.status}
                    onClick={() => wheelBtnHandler(prizeWheel)}
                  />
                </div>
              ))}
            </div>
            <div className="absolute left-0 right-0 text-center bottom-4">
              <button
                type="button"
                className="text-lg underline"
                onClick={() => setFlip(prev => !prev)}
              >
                Premii disponibile
              </button>
            </div>
          </div>
        </div>
        <div
          className={`rotate-y-180 w-full h-full absolute top-0 left-0 backface-hidden bg-cover rounded-lg bg-center`}
          style={{ backgroundImage: `url(${ligaCardBackgroundUrl(ligaLevel)})` }}
        >
          {/* Back content of the card */}
          <div className={` p-8 px-4 relative text-white w-full h-full`}>
            <div className="absolute-full bg-black/20 z-0 rounded-lg"></div>
            <div className="z-10 absolute left-2 top-2 cursor-pointer">
              <ArrowLeftIcon className="w-8 h-8" />
            </div>
            <div className={`absolute left-0 right-0 -top-12 flex justify-center items-start z-0`}>
              <img alt="" className="w-48" src={ligaBadgeChipsUrl(ligaLevel)} />
            </div>

            <div className="z-1 grid grid-cols-2 text-center gap-2 mt-8 relative">
              <div className="font-bold col-span-2 text-lg">{ligaPrizesTitle(ligaLevel)}</div>
              {ligaPrizes(ligaLevel).map((prize, i) => (
                <div key={`liga-prize-${ligaLevel}-${i}`}>{prize}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LigaCard;
