import * as React from 'react';
import { useEffect, useState } from 'react';

import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import GameCard from './GameCard';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { GameCardVM } from '@/models/game-card-vm.model';

export const StyledGameList = styled.section`
  width: 100%;
  max-width: 1400px;
  margin: 3rem auto 4rem;
  padding: 0 1rem;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    margin: 4rem auto 6rem;
    padding: 0 2rem;
  }

  .header {
    width: 100%;
    display: inline-flex;
    align-items: flex-end;
    margin-bottom: 2rem;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      margin-bottom: 3rem;
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 2rem;
      }
    }

    span,
    a {
      color: ${props => props.theme.green};
      font-weight: 600;
      margin-left: 16px;
      line-height: 1.3;
      font-size: 0.8rem;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 1rem;
      }
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .games {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    position: relative;
    align-content: start;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
      grid-column-gap: 2rem;
      grid-row-gap: 4rem;
    }
  }

  .no-games-found {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    line-height: 1.5;
    text-align: center;
    margin-top: 4rem;
    max-width: 432px;
    width: 100%;
    padding: 0 1rem;
  }
`;

function GameList({
  games,
  header,
  slug,
  maxLength,
}: {
  games: GameCardVM[];
  header: string;
  slug?: string;
  maxLength?: number;
}): JSX.Element | null {
  const [visibleGames, setVisibleGames] = useState<GameCardVM[]>([]);

  useEffect(() => {
    if (games) {
      if (!maxLength || (maxLength && games.length < maxLength)) {
        setVisibleGames(games);
        return;
      }
      setVisibleGames([...games].splice(0, maxLength));
    }
  }, [games, maxLength]);

  if (!games || games.length === 0 || !visibleGames || visibleGames.length === 0) {
    return null;
  }

  return (
    <StyledGameList>
      <div className="header">
        <div className="flex items-center">
          <h1>{header}</h1>
          <div className="mt-1">
            {maxLength && games.length > maxLength && slug ? (
              <Link
                to={slug}
                id={header}
                onClick={() =>
                  triggerGTag(GTagEvents.open_category, {
                    openFrom: 'Game lobby',
                    categoryName: header,
                  })
                }
              >
                Vezi toate {games.length} jocuri
              </Link>
            ) : (
              <span>{games.length} jocuri</span>
            )}
          </div>
        </div>
      </div>
      <div className="games">
        {visibleGames.map((game: GameCardVM, index: number) => (
          <LazyLoad key={game.gameId} offset={400}>
            <GameCard game={game} position={index + 1} category={header} />
          </LazyLoad>
        ))}
      </div>
    </StyledGameList>
  );
}

export default GameList;
