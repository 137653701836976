import { isMobile } from 'react-device-detect';

import { NetworkClientMetrics } from './network-client-metrics';
import { ClientMetricsConfig, ClientMetricsConfigDto, NetworkRequest } from './network-types';

type PerformanceResourceTimingWithStatus = PerformanceResourceTiming & {
  responseStatus: number;
};

const clientMetricsUrl = process.env.REACT_APP_CLIENT_METRICS_URL;

const networkClientMetrics = new NetworkClientMetrics();

const networkObserver = new PerformanceObserver(performanceHandler);

async function getClientMetricsConfig(): Promise<ClientMetricsConfig | null> {
  try {
    const configResponse = await fetch(`${clientMetricsUrl}/config`);
    const clientMetricsConfig: ClientMetricsConfigDto = await configResponse.json();
    const networkConfig = clientMetricsConfig.metrics.find(
      item => item.name === 'client_http_duration',
    );

    return {
      metricId: networkConfig!.id,
      bucketsMs: networkConfig!.buckets_ms,
      whitelistHosts: networkConfig!.whitelist_hosts,
      pushInterval: clientMetricsConfig.push_interval_ms,
    };
  } catch {
    // don't do anything if metric config fails to load
    return null;
  }
}

function startNetworkTracking(config: ClientMetricsConfig) {
  networkClientMetrics.configure(config);
  networkObserver.observe({ entryTypes: ['resource'] });
  window.setInterval(sendClientNetworkMetrics, config.pushInterval);
}

function recordNetworkResponse(networkRequest: NetworkRequest) {
  networkClientMetrics.recordNetworkResponse(networkRequest);
}

function getClientMetricsMetaHeaders() {
  const platform = isMobile ? 'mobile' : 'desktop';
  const app = 'magicjackpot.ro';
  const country = 'romania';
  const version = process.env.REACT_APP_SENTRY_RELEASE || 'development';

  return `${platform}/${app}/${country}/${version}`;
}

// get the metrics from the lib, send and clear the lib
function sendClientNetworkMetrics() {
  const metrics = NetworkClientMetrics.getMetricsPayload(
    networkClientMetrics.getClientResponseMetrics(),
  );
  if (metrics) {
    // fire and forget
    fetch(`${clientMetricsUrl}/instrument`, {
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        'client-metrics-meta': getClientMetricsMetaHeaders(),
      },
      body: metrics,
    });
    networkClientMetrics.clearMetrics();
  }
}

function performanceHandler(entryList: PerformanceObserverEntryList) {
  const entries = getPerformanceEntries(entryList);
  if (entries.length) {
    const clientResponses = prepareClientResponses(entries);
    clientResponses.forEach(recordNetworkResponse);
  }
}

// filters out events that are api calls - we don't need assets
function getPerformanceEntries(list: PerformanceObserverEntryList) {
  const initiatorTypeFilters = ['xmlhttprequest', 'fetch'];

  const entries = list.getEntries() as PerformanceResourceTimingWithStatus[];

  return entries.filter(entry => {
    return initiatorTypeFilters.includes(entry.initiatorType) && entry.responseStatus;
  });
}

// maps performance entries to our custom lib model
function prepareClientResponses(entries: PerformanceResourceTimingWithStatus[]): NetworkRequest[] {
  return entries.map(entry => {
    const url = new URL(entry.name);
    // method is not exposed to the browser, so we settled with "unknown" with our backend devs
    return {
      duration: Math.ceil(entry.duration),
      host: url.host,
      method: 'unknown',
      path: url.pathname,
      status: entry.responseStatus,
    };
  });
}

async function initializeNetworkAnalytics() {
  const metricsConfig = await getClientMetricsConfig();
  if (metricsConfig) {
    startNetworkTracking(metricsConfig);
  }
}

export { initializeNetworkAnalytics };
