import { BFF_GAMES_CATEGORY } from '@/constants/constants';
import { GamesCategorySlug } from '@/features/router/routes-constants';
import { GameCardVM } from '@/models/game-card-vm.model';

export const mapGamesCategorySlugToName = (
  category: GamesCategorySlug | undefined,
): BFF_GAMES_CATEGORY => {
  switch (category) {
    case 'jocuri-de-iarna':
      return BFF_GAMES_CATEGORY.WINTER_GAMES;
    case 'recomandate':
      return BFF_GAMES_CATEGORY.RECOMMENDED;
    case 'jocuri-noi':
      return BFF_GAMES_CATEGORY.NEW_GAMES;
    case 'cazinou-live':
      return BFF_GAMES_CATEGORY.LIVE_CASINO;
    case 'sloturi':
      return BFF_GAMES_CATEGORY.SLOTS;
    case 'jackpoturi':
      return BFF_GAMES_CATEGORY.JACKPOTS;
    case 'jocuri-de-masa':
      return BFF_GAMES_CATEGORY.TABLE_GAMES;
    case 'toate-jocurile':
      return BFF_GAMES_CATEGORY.ALL_GAMES;
    default:
      return BFF_GAMES_CATEGORY.NONE;
  }
};

export const mapCategorySlugToTitle = (category: GamesCategorySlug | undefined): string => {
  switch (category) {
    case 'jocuri-de-iarna':
      return 'Jocuri de iarnă';
    case 'recomandate':
      return 'Recomandate';
    case 'jocuri-noi':
      return 'Jocuri noi';
    case 'cazinou-live':
      return 'Cazinou live';
    case 'sloturi':
      return 'Sloturi';
    case 'jackpoturi':
      return 'Jackpoturi';
    case 'jocuri-de-masa':
      return 'Jocuri de masă';
    case 'toate-jocurile':
      return 'Toate jocurile';
    default:
      return '';
  }
};

export function searchGames(search: string, games: GameCardVM[]): GameCardVM[] {
  const lowerCaseSearchString = search.toLowerCase();
  return Object.values(games).filter(
    item =>
      item.name.toLowerCase().includes(lowerCaseSearchString) ||
      item.shortDesc.toLowerCase().includes(lowerCaseSearchString),
  );
}
