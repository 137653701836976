import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CashierDrawer from './CashierDrawer';
import Drawer, { DrawerHandle } from '../drawer/Drawer';
import { useCashierStore, useCashierTransition } from '@/hooks/useCashier';
import { ModalType } from '@/models/enums/modal-type.enum';
import { openModal } from '@/store/actions/modalActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectHasWelcomeBonuses } from '@/store/selectors/availableBonusesSelectors';
import { selectDepositCount } from '@/store/selectors/depositSelectors';
import { selectIsFtdExitIntentShown } from '@/store/selectors/modalSelectors';

const DELAY = 250;

function CashierDrawerContainer(): JSX.Element | null {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const [isOpen, setIsOpen] = useState(false);
  const { showCashier, hideCashier, cashierIsShown } = useCashierStore();
  const dispatch = useDispatch();
  const depositCount = useSelector(selectDepositCount);
  const drawerRef = useRef<DrawerHandle>(null);
  const cashierTransition = useCashierTransition();
  const hasWelcomeBonuses = useSelector(selectHasWelcomeBonuses);
  const isFtdExitIntentShown = useSelector(selectIsFtdExitIntentShown);

  const scrollDrawerToTop = () => {
    if (drawerRef.current && drawerRef.current.scrollToTop) {
      drawerRef.current.scrollToTop();
    }
  };

  useEffect(() => {
    if (cashierTransition > 0) {
      scrollDrawerToTop();
    }
  }, [cashierTransition]);

  const closeDrawerHandler = () => {
    if (depositCount === 0 && cashierIsShown && hasWelcomeBonuses && !isFtdExitIntentShown) {
      dispatch(openModal(ModalType.ExitIntent));
    } else {
      setIsOpen(false);
      document.body.classList.remove('drawer-open');
      setTimeout(() => {
        hideCashier();
      }, DELAY);
    }
  };

  useEffect(() => {
    if (cashierIsShown) {
      document.body.classList.add('drawer-open');
      setIsOpen(true);
    } else {
      closeDrawerHandler();
    }
  }, [showCashier, cashierIsShown]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Drawer isVisible={isOpen} closeDrawer={closeDrawerHandler} ref={drawerRef}>
      <CashierDrawer closeDrawer={closeDrawerHandler} />
    </Drawer>
  );
}

export default CashierDrawerContainer;
