import { FrontEndUserBonusObject } from '@/models/gig/front-end-user-bonus.model';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';
import {
  ActiveBonusesAction,
  ActiveBonusesActionType,
  GetActiveBonusContentSuccessAction,
  GetActiveBonusesErrorAction,
  GetActiveBonusesSuccessAction,
} from '@/store/actions/activeBonusesActions';

export interface ActiveBonusesStateModel {
  activeBonuses: FrontEndUserBonusObject[];
  error: boolean;
  loading: boolean;
  content: Record<number, WpBonusContentModel>;
}

const initialState: ActiveBonusesStateModel = {
  activeBonuses: [],
  loading: false,
  error: false,
  content: {},
};

const getActiveBonuses = (state: ActiveBonusesStateModel) => ({
  ...state,
  loading: true,
  error: false,
});

const getActiveBonusesSuccess = (
  state: ActiveBonusesStateModel,
  action: GetActiveBonusesSuccessAction,
) => ({
  ...state,
  activeBonuses: action.activeBonuses,
  loading: false,
});

const getActiveBonusesError = (
  state: ActiveBonusesStateModel,
  action: GetActiveBonusesErrorAction,
): ActiveBonusesStateModel => ({
  ...state,
  error: action.error,
  loading: false,
});

const getActiveBonusContentSuccess = (
  state: ActiveBonusesStateModel,
  action: GetActiveBonusContentSuccessAction,
): ActiveBonusesStateModel => ({
  ...state,
  content: {
    ...state.content,
    [action.bonusId]: action.content,
  },
});

const resetActiveBonuses = (): ActiveBonusesStateModel => initialState;

export const activeBonusesReducer = (
  state = initialState,
  action: ActiveBonusesAction,
): ActiveBonusesStateModel => {
  switch (action.type) {
    case ActiveBonusesActionType.Get:
      return getActiveBonuses(state);
    case ActiveBonusesActionType.Success:
      return getActiveBonusesSuccess(state, action);
    case ActiveBonusesActionType.Error:
      return getActiveBonusesError(state, action);
    case ActiveBonusesActionType.Reset:
      return resetActiveBonuses();
    case ActiveBonusesActionType.GetContentSuccess:
      return getActiveBonusContentSuccess(state, action);
    default:
      return state;
  }
};
