import React from 'react';

import styled from 'styled-components';

import ParsedString from '@/components/formats/ParsedString';
import Loader from '@/components/loader/Loader';

const StyledTerms = styled.div`
  max-width: 800px;
  margin: 86px auto;
  width: 100%;
  padding: 4rem 2rem;
  min-height: 100vh;

  li {
    line-height: 1.5;
  }

  h1 {
    margin-bottom: 0;
  }

  p {
    margin-top: 1rem;

    :first-of-type {
      margin-top: 2rem;
      margin-bottom: 1.5rem;

      a {
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  ol {
    margin-top: 1rem;
    counter-reset: item;
    list-style-type: none;
    padding-left: 1.5rem;

    h2 {
      font-size: 1.4rem;
      margin-top: 4rem;
      margin-bottom: 2rem;
      scroll-margin-top: 120px;
    }

    h3 {
      font-size: 1.2rem;
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    > li {
      counter-increment: item;
      margin-top: 1rem;
      position: relative;
    }

    > li::before {
      top: 4px;
      content: counters(item, '.') '. ';
      font-weight: 700;
      font-size: 1.2rem;
      position: absolute;
      line-height: 1em;
      left: -2rem;
    }

    ol {
      li::before {
        line-height: 1.7;
        top: 0;
        left: -2.5rem;
        font-size: 0.9rem;
      }

      ol li::before {
        left: -3rem;
      }
    }
  }

  ol.letters {
    list-style-type: none;
    margin-top: 1rem;
    padding-left: 0;

    > li {
      &::before {
        display: none;
      }
    }
  }

  table {
    td {
      padding: 1rem 0;
    }

    td:first-child {
      font-weight: bold;
      padding-right: 1rem;
    }
  }
`;

function Terms({ terms }: { terms: string }): JSX.Element {
  return <StyledTerms>{terms ? <ParsedString string={terms} /> : <Loader />}</StyledTerms>;
}

export default Terms;
