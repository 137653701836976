import { create } from 'zustand';

import StorageManager from '@/utils/storage-manager-utils';

export interface TCookies {
  essential: boolean;
  performance: boolean;
}

interface CookieConsentState extends TCookies {
  acceptCookies: (performance: boolean) => void;
}

export const useCookieConsentStore = create<CookieConsentState>(set => {
  // Get the current value from local Storage
  const currentConsent = StorageManager.getCookiesConsent();

  return {
    essential: currentConsent.essential,
    performance: currentConsent.performance,
    acceptCookies: (performance: boolean) => {
      set(state => ({ ...state, essential: true, performance }));
      // Save the result to the Local Storage
      StorageManager.saveCookiesConsent({ essential: true, performance });
    },
  };
});
