import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import CashierHeader from '@/features/cashier/CashierHeader';
import CashierIframe from '@/features/cashier/CashierIframe';
import { selectAvailableBonusesFetched } from '@/store/selectors/availableBonusesSelectors';

const StyledCashier = styled.div`
  width: 100%;
  max-width: 620px;
  margin: 64px auto 0;
`;

function Cashier() {
  const availableBonusesFetched = useSelector(selectAvailableBonusesFetched);

  return (
    <StyledCashier>
      <CashierHeader />
      {availableBonusesFetched && <CashierIframe />}
    </StyledCashier>
  );
}

export default Cashier;
