import {
  BffGamesJackpotsResponse,
  BffGamesResponse,
  BffGamesLastPlayedResponse,
  BffResponse,
} from '@lucky7ventures/bff-types';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  ForkEffect,
  put,
  PutEffect,
  takeEvery,
  CallEffect,
  StrictEffect,
} from 'redux-saga/effects';

import BffApiService from '@/shared/bff-api-service';
import {
  GamesActionsTypes,
  getGamesError,
  getGamesJackpotsSuccess,
  getGamesSuccess,
  getLastPlayedGamesSuccess,
} from '@/store/actions/gamesActions';
import { checkDevice, doNothing } from '@/utils/helpers';

export function* getGamesSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<BffResponse<BffGamesResponse>>
> {
  try {
    const response = yield call(BffApiService.getAllGames, {
      payload: {
        device: checkDevice(),
      },
    });

    if (response.data.data) {
      yield put(getGamesSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getGamesError());
  }
}

export function* getGamesJackpotsSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<BffResponse<BffGamesJackpotsResponse>>
> {
  try {
    const response = yield call(BffApiService.getGamesJackpots, {
      payload: {
        lang: 'ro',
        currencyCode: 'RON',
        device: checkDevice(),
      },
    });

    if (response.data.data) {
      yield put(getGamesJackpotsSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getGamesError());
  }
}

export function* getLastPlayedGamesSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<BffResponse<BffGamesLastPlayedResponse>>
> {
  try {
    const lastPlayedResponse = yield call(BffApiService.getGamesLastPlayed);
    if (lastPlayedResponse.data.data) {
      yield put(getLastPlayedGamesSuccess(lastPlayedResponse.data.data));
    }
  } catch {
    doNothing();
  }
}

export function* watchGames(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(GamesActionsTypes.Get, getGamesSaga),
    takeEvery(GamesActionsTypes.GetLastPlayedGames, getLastPlayedGamesSaga),
    takeEvery(GamesActionsTypes.GetGamesJackpots, getGamesJackpotsSaga),
  ]);
}
