import React from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { NavLink, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import PageHeader from '@/components/header/PageHeader';
import LogOutIcon from '@/components/icons/LogOutIcon';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';

const StyledSettings = styled.div`
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem 4rem;
    min-height: calc(100vh - 220px);

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 0 2rem;
    }
  }

  nav {
    position: sticky;
    top: 64px;
    height: 52px;
    background-color: ${props => props.theme.white};
    z-index: 2;
    display: flex;
    justify-content: center;
    overflow-y: hidden;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      top: 72px;
    }

    ul {
      width: 100%;
      max-width: calc(1400px - 64px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow-x: scroll;
      box-shadow: inset 0px -4px 0px 0px ${props => props.theme.offWhiteHover};
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
      }

      li {
        display: inline-flex;
        flex: 1;

        a {
          width: 100%;
          padding: 16px 16px 22px;
          display: flex;
          font-weight: 600;
          text-align: center;
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.9rem;
          justify-content: center;
          align-items: center;
          height: 52px;

          @media (min-width: ${props => props.theme.breakpoints.small}) {
            padding: 16px 16px 20px;
            font-size: 1rem;
          }

          svg {
            width: 20px;
            height: 20px;
            margin-right: 0.5rem;
          }

          &.active {
            color: ${props => props.theme.red};

            &:after {
              content: '';
              height: 4px;
              background: ${props => props.theme.red};
              width: 100%;
              transition: transform 0.2s ease;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
`;

function Settings(): JSX.Element {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);

  return (
    <StyledSettings>
      <Helmet>
        <body className="settings" />
      </Helmet>
      <PageHeader>
        <h1>Setările mele</h1>
      </PageHeader>
      <nav>
        <ul>
          <li>
            <NavLink end to="">
              Detaliile mele
            </NavLink>
          </li>
          <li>
            <NavLink end to="verificare">
              Verificare
            </NavLink>
          </li>
          <li>
            <NavLink end to="joc-responsabil">
              Joc Responsabil
            </NavLink>
          </li>
          {isAuthenticated && (
            <>
              <li>
                <NavLink to="istoric">Istoric</NavLink>
              </li>
              <li>
                <NavLink id="e2e_settingsNavBonus" to="bonus">
                  Bonus
                </NavLink>
              </li>
              <li>
                <NavLink to="/deconectare">
                  <LogOutIcon /> Delogare
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
      <div className="container">
        <Outlet />
      </div>
    </StyledSettings>
  );
}

export default Settings;
