import React from 'react';

import styled, { css } from 'styled-components/macro';

import { ThemeColor } from '../../styles/theme';
import CloseIcon from '@/components/icons/CloseIcon';

const StyledCloseButton = styled.button<{ position: string; color: ThemeColor }>`
  position: absolute;
  top: 0;
  font-size: 1.5rem;
  padding: 8px;
  display: inline-flex;
  z-index: 2;
  color: ${props => props.theme[props.color]};

  ${props =>
    props.position === 'right' &&
    css`
      right: 0;
    `};

  ${props =>
    props.position === 'left' &&
    css`
      left: 0;
    `};
`;

function CloseButton({
  position,
  onClick,
  color = 'white',
}: {
  position: 'right' | 'left';
  onClick: () => void;
  color?: ThemeColor;
}): JSX.Element {
  return (
    <StyledCloseButton position={position} onClick={onClick} color={color} data-cy="close-button">
      <CloseIcon />
    </StyledCloseButton>
  );
}

export default CloseButton;
