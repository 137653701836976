import React from 'react';

import { Route, Routes as ReactRoutes } from 'react-router-dom';

import { gameCategoriesSlugs } from './routes-constants';
import PrivateRoute from '@/features/router/PrivateRoute';
import Game from '@/pages/games/Game';
import GameProviders from '@/pages/games/GameProviders';
import GameRules from '@/pages/games/GameRules';
import Games from '@/pages/games/Games';
import GamesByCategory from '@/pages/games/GamesByCategory';
import GamesByProvider from '@/pages/games/GamesByProvider';
import GamesLastPlayed from '@/pages/games/GamesLastPlayed';
import GamesLobby from '@/pages/games/GamesLobby';
import NoGames from '@/pages/games/NoGames';
import Help from '@/pages/help/Help';
import Home from '@/pages/home/Home';
import Impersonate from '@/pages/impersonate/Impersonate';
import AntiMoneyLaundering from '@/pages/legal/AntiMoneyLaundering';
import BonusTermsAndConditions from '@/pages/legal/BonusTermsAndConditions';
import CookiePolicy from '@/pages/legal/CookiePolicy';
import PrivacyPolicy from '@/pages/legal/PrivacyPolicy';
import TermsAndConditions from '@/pages/legal/TermsAndConditions';
import LigaPage from '@/pages/liga/LigaPage';
import Logout from '@/pages/logout/Logout';
import Cashier from '@/pages/payments/Cashier';
import PaymentMethods from '@/pages/payments/PaymentMethods';
import GrandPrize from '@/pages/promotions/GrandPrize';
import Promotions from '@/pages/promotions/Promotions';
import Registration from '@/pages/registration/Registration';
import ResetPassword from '@/pages/reset-password/ResetPassword';
import Settings from '@/pages/settings/Settings';
import ActiveBonuses from '@/pages/settings/active-bonuses/ActiveBonuses';
import Details from '@/pages/settings/details/Details';
import GamingHistory from '@/pages/settings/history/GamingHistory';
import History from '@/pages/settings/history/History';
import PaymentHistory from '@/pages/settings/history/PaymentHistory';
import ResponsibleGaming from '@/pages/settings/responsible-gaming/ResponsibleGaming';
import ResponsibleGamingContent from '@/pages/settings/responsible-gaming/ResponsibleGamingContent';
import Verify from '@/pages/settings/verify/Verify';

function AppRoutes() {
  return (
    <ReactRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/inregistrare" element={<Registration />} />
      <Route path="/jocuri" element={<Games />}>
        <Route index path="" element={<GamesLobby />} />
        <Route path="ultimul-jucat" element={<GamesLastPlayed />} />
        {gameCategoriesSlugs.map(slug => (
          <Route key={slug} path={slug} element={<GamesByCategory />} />
        ))}
        <Route path={`:provider`} element={<GamesByProvider />} />
        <Route element={<NoGames text="Niciun joc găsit" />} />
      </Route>
      <Route path="/ajutor" element={<Help />} />
      <Route path="/metode-de-plata" element={<PaymentMethods />} />
      <Route path="/joc-responsabil" element={<ResponsibleGaming />} />
      <Route path="/politica-impotriva-spalarii-banilor" element={<AntiMoneyLaundering />} />
      <Route path="/declaratie-de-confidentialitate" element={<PrivacyPolicy />} />
      <Route path="/politica-de-utilizare-a-cookie-urilor" element={<CookiePolicy />} />
      <Route path="/termeni-si-conditii" element={<TermsAndConditions />} />
      <Route path="/regulamente-de-joc" element={<GameRules />} />
      <Route path="/impersonate/:token" element={<Impersonate />} />
      <Route path="/reseteaza-parola/:recoveryCode" element={<ResetPassword />} />
      <Route element={<PrivateRoute />}>
        <Route path="/congratulations-wheel-of-magic" element={<GrandPrize />} />
        <Route path="/casierie" element={<Cashier />} />
        <Route path="/setarile-mele" element={<Settings />}>
          <Route index path="*" element={<Details />} />
          <Route index path="joc-responsabil" element={<ResponsibleGamingContent />} />
          <Route index path="verificare" element={<Verify />} />
          <Route path="istoric" element={<History />}>
            <Route index path="/setarile-mele/istoric" element={<GamingHistory />} />
            <Route index path="/setarile-mele/istoric/plati" element={<PaymentHistory />} />
          </Route>
          <Route index path="bonus" element={<ActiveBonuses />} />
        </Route>
        <Route path="/deconectare" element={<Logout />} />
      </Route>
      <Route path="/joc/:gameSlug/:gameId" element={<Game />} />
      <Route path="/termeni-si-conditii/:termsId" element={<BonusTermsAndConditions />} />
      <Route path="/promotii" element={<Promotions />} />
      <Route path="/liga-magica" element={<LigaPage />} />
      <Route path="/furnizorii" element={<GameProviders />} />
    </ReactRoutes>
  );
}

export default AppRoutes;
