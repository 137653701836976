import { TCookies } from '@/store/cookieConsentStore';

enum StorageKeys {
  CookiesConsent = 'MGJ_COOKIES_CONSENT',
  LigaNotification = 'MGJ_LIGA_NOTIFICATION',
}

const StorageManager = {
  getCookiesConsent: (): TCookies => {
    const current = localStorage.getItem(StorageKeys.CookiesConsent);
    if (!current) {
      return { essential: false, performance: false };
    }
    const toJson = JSON.parse(current);
    return { essential: toJson.essential, performance: toJson.performance };
  },
  saveCookiesConsent: ({ essential, performance }: TCookies) => {
    const toSave = JSON.stringify({ essential, performance });
    localStorage.setItem(StorageKeys.CookiesConsent, toSave);
  },
  getLigaNotification: (): Date | null => {
    const current = localStorage.getItem(StorageKeys.LigaNotification);
    if (!current) {
      return null;
    }
    const data = JSON.parse(current);
    if (!data.date) {
      return null;
    }
    return new Date(data.date);
  },
  saveLigaNotification: (date: Date) => {
    const toSave = JSON.stringify({ date });
    localStorage.setItem(StorageKeys.LigaNotification, toSave);
  },
};

export default StorageManager;
