import React, { useState } from 'react';

import { useEffectOnce } from 'react-use';
import styled from 'styled-components/macro';

const StyledClock = styled.div`
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  padding-top: 1rem;

  .border {
    height: 2px;
    background: rgba(255, 255, 255, 0.4);
    width: 40px;
    display: block;
    margin-bottom: 1rem;
  }

  span {
    line-height: 1.4;
  }
`;

function Clock(): JSX.Element {
  const [date, setDate] = useState(new Date());

  useEffectOnce(() => {
    const updateTime = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(updateTime);
    };
  });

  return (
    <StyledClock>
      <span className="border" />
      <span>{date.toLocaleDateString('ro-RO')}</span>
      <span>{date.toLocaleTimeString('ro-RO')}</span>
    </StyledClock>
  );
}

export default Clock;
