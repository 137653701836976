import * as React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import CurrencyFormat from '@/components/formats/CurrencyFormat';
import FadeIn from '@/components/transition/FadeIn';
import { GameCardVM } from '@/models/game-card-vm.model';
import { isEmpty } from '@/utils/helpers';
import { gameThumbImg } from '@/utils/image-utils';

const StyledSearchGameCard = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  background: ${props => props.theme.white};
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: ${props => props.theme.boxShadow};
  background: rgb(255, 255, 255);

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    margin-bottom: 2rem;
  }

  .image-wrapper {
    position: relative;
    background: rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: block;
    cursor: pointer;
    border-radius: 0.5rem 0 0 0.5rem;

    &:after {
      content: '';
      display: block;
      padding-bottom: 122.75%;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  .text {
    padding: 16px;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .name,
    .vendor {
      font-weight: 600;
      line-height: 1.4;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;

      small {
        font-weight: normal;
      }
    }

    .name {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    .vendor {
      font-size: 14px;
    }

    .tags {
      margin-top: 1rem;
      display: flex;

      .tag {
        padding: 6px 12px;
        min-width: 48px;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        background: ${props => props.theme.black};
        color: ${props => props.theme.white};
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        &.live {
          span {
            border-radius: 5px;
            height: 5px;
            width: 5px;
            background: ${props => props.theme.mint};
            display: inline-block;
            margin-right: 5px;
            opacity: 1;
            animation: fade 3s infinite;
          }
        }
      }
    }
  }

  @keyframes fade {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

function SearchGameCard({
  game,
  closeDrawer,
}: {
  game: GameCardVM;
  closeDrawer: () => void;
}): JSX.Element {
  return (
    <StyledSearchGameCard
      as={Link}
      to={`/joc/${game.slug}/${game.gameId}`}
      onClick={() => closeDrawer()}
      id="e2e_searchGameCard"
    >
      <div className="image-wrapper">
        <FadeIn overflow>
          {(onload: () => void) => <img src={gameThumbImg(game)} alt={game.name} onLoad={onload} />}
        </FadeIn>
      </div>
      <div className="text">
        <div className="game-info">
          <h1 className="name avenir">{game.name}</h1>
          <h2 className="vendor avenir">
            <small>De</small> {game.shortDesc}
          </h2>
          <div className="tags">
            {game.isNew && <span className="tag new">Noi</span>}
            {game.isHot && <span className={`tag hot${game.isLive ? ' hot-live' : ''}`}>Cald</span>}
            {game.isLive && (
              <span className="tag live">
                <span />
                Live
              </span>
            )}
            {!isEmpty(game.jackpot) && (
              <div className="tag jackpot">
                <CurrencyFormat currency="RON" amount={game.jackpot?.amount} />
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledSearchGameCard>
  );
}

export default SearchGameCard;
