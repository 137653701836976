import React, { Suspense, useCallback, useState } from 'react';

import { BffErrorCode } from '@lucky7ventures/bff-types';
import classNames from 'classnames';

import RegistrationFooter from './RegistrationFooter';
import RegistrationUsp from './RegistrationUsp';
import Step from '../../components/stepper/Step';
import Stepper from '../../components/stepper/Stepper';
import GiftIcon from '@/components/icons/GiftIcon';
import AddressInfoForm from '@/pages/registration/AddressInfoForm';
import BasicInfoForm from '@/pages/registration/BasicInfoForm';
import ConsentsForm from '@/pages/registration/ConsentsForm';
import HeroBgImage from '@/pages/registration/HeroBgImage';
import RegistrationConfetti from '@/pages/registration/RegistrationConfetti';
import { useAuthenticationInfoData } from '@/store/registrationStore';

const RegistrationCustomerSupport = React.lazy(() => import('./RegistrationCustomerSupport'));
const Registration2FA = React.lazy(() => import('./Registration2FA'));
const RegistrationSuccess = React.lazy(() => import('./RegistrationSuccess'));

const Registration = () => {
  const [activeStep, setActiveStep] = useState(1);
  // showError is for special error handling when we want to get out of the stepper but still show the error
  const [showError, setShowError] = useState<BffErrorCode | undefined>(undefined);
  const { isTwoFactorAuthentication, token } = useAuthenticationInfoData();

  const stepBack = useCallback(() => {
    setActiveStep(prev => prev - 1);
  }, [setActiveStep]);

  const stepForward = useCallback(() => {
    setActiveStep(prev => prev + 1);
  }, [setActiveStep]);

  return (
    <div
      data-cy="registration-module"
      className="w-full min-h-[950px] sm:min-h-screen flex flex-col justify-start sm:justify-center items-center px-3 relative pt-8 pb-8 2xl:pb-0"
    >
      {(isTwoFactorAuthentication || token) && <RegistrationConfetti />}
      <HeroBgImage />
      <span className="relative text-center w-full text-white font-bold text-4xl mt-10 mb-0 sm:mt-16 sm:mb-4 2xl:mt-0">
        <p className="m-0 leading-tight">Obține 2400 lei și</p>
        <p className="leading-tight">600 Rotiri Gratuite</p>
      </span>
      <div
        className={classNames(
          'bg-white/[85%] rounded-lg backdrop-blur-md shadow-md w-full sm:mb-16 relative z-20',
          {
            'max-w-xl': !isTwoFactorAuthentication && !token,
            'max-w-md': isTwoFactorAuthentication || token,
          },
        )}
      >
        {!showError && !isTwoFactorAuthentication && !token && (
          <>
            <RegistrationUsp />
            <div className="p-5 pb-0 sm:p-8 sm:pb-3">
              <Stepper activeStep={activeStep}>
                <Step>
                  <BasicInfoForm stepForward={stepForward} />
                </Step>
                <Step>
                  <AddressInfoForm stepBack={stepBack} stepForward={stepForward} />
                </Step>
                <Step stepIcon={GiftIcon}>
                  <ConsentsForm stepBack={stepBack} setShowError={setShowError} />
                </Step>
              </Stepper>
            </div>
            <RegistrationFooter />
          </>
        )}
        <Suspense fallback={<div />}>
          {showError && !isTwoFactorAuthentication && (
            <RegistrationCustomerSupport error={showError} />
          )}
          {isTwoFactorAuthentication && <Registration2FA />}
          {token && <RegistrationSuccess />}
        </Suspense>
      </div>
    </div>
  );
};

export default Registration;
