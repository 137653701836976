import { KycDocumentResponseDto } from '@lucky7ventures/lucky-components';

import { GetKycActionSuccess, KycActions, KycActionsTypes } from '@/store/actions/kycActions';
import { updateObject } from '@/utils/helpers';

export interface KycStateModel {
  kyc: KycDocumentResponseDto[];
  loading: boolean;
  error: boolean;
}

const initialState: KycStateModel = {
  kyc: [],
  loading: false,
  error: false,
};

const getKyc = (state: KycStateModel): KycStateModel =>
  updateObject(state, {
    loading: true,
    error: false,
  });

const getKycSuccess = (state: KycStateModel, action: GetKycActionSuccess): KycStateModel =>
  updateObject(state, {
    kyc: action.kyc,
    loading: false,
  });

const getKycError = (state: KycStateModel): KycStateModel =>
  updateObject(state, {
    loading: false,
    error: true,
  });

export const kycReducer = (state = initialState, action: KycActions): KycStateModel => {
  switch (action.type) {
    case KycActionsTypes.Get:
      return getKyc(state);
    case KycActionsTypes.GetSuccess:
      return getKycSuccess(state, action);
    case KycActionsTypes.GetError:
      return getKycError(state);
    case KycActionsTypes.ClearKycState:
      return initialState;
    default:
      return state;
  }
};
