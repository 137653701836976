import { createSelector } from 'reselect';

import { FrontEndUserBonusObject } from '@/models/gig/front-end-user-bonus.model';
import { RootState } from '@/models/root-state.model';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';

export const selectActiveBonuses = (state: RootState): FrontEndUserBonusObject[] =>
  state.activeBonuses.activeBonuses;

export const selectActiveBonusesContent = (state: RootState): Record<number, WpBonusContentModel> =>
  state.activeBonuses.content;

export const selectActiveBonusesWithContent = createSelector(
  selectActiveBonuses,
  selectActiveBonusesContent,
  (bonuses, content) => bonuses.map(bonus => ({ ...bonus, Content: content[bonus.BonusId] })),
);
