import React from 'react';

import LazyLoad from 'react-lazyload';
import styled from 'styled-components';

const NoGamesStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;

  div {
    line-height: 1.5;
    text-align: center;
    max-width: 432px;
    width: 100%;
    padding: 0 1rem;

    img {
      width: 100%;
      display: block;
      height: auto;
      max-width: 180px;
      margin: 0 auto;
    }
  }
`;

function NoGames({ text }: { text?: string }): JSX.Element {
  return (
    <NoGamesStyles>
      <div>
        <LazyLoad height={326} offset={400}>
          <img
            src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/chip.png"
            alt="Chip"
          />
        </LazyLoad>
        <p>
          {text ||
            'Se pare că încercați să accesați dintr-o țară din afara României. Încercați din nou sau contactați serviciul nostru de suport pentru ajutor.'}
        </p>
      </div>
    </NoGamesStyles>
  );
}

export default NoGames;
