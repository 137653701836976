import * as React from 'react';

import styled from 'styled-components/macro';

import ChangePassword from './ChangePassword';
import OffersAndPromotions from './OffersAndPromotions';
import UserDetails from './UserDetails';
import TwoFactorAuthentication from '@/pages/settings/details/TwoFactorAuthentication';

const StyledDetails = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 100%;
  padding-bottom: 64px;

  .intro {
    margin-top: 4rem;

    p {
      margin-bottom: 0;
    }
  }

  h2 {
    margin-top: 64px;
    font-size: 24px;
  }

  label {
    margin-top: 24px;
    display: block;
  }

  .button-wrapper {
    text-align: right;
    margin-top: 32px;
  }

  .error,
  .success {
    padding: 0;
    text-align: center;
    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 0;
  }

  .error {
    color: ${props => props.theme.red};
  }

  .success {
    color: ${props => props.theme.mint};
  }
`;

function Details(): JSX.Element {
  return (
    <StyledDetails>
      <div className="intro">
        <h1>Detaliile dvs.</h1>
        <p>
          Nu îți amintești detaliile pe care le-ai introdus în momentul în care ți-ai înregistrat
          contul? Iată-le. Unele dintre aceste detalii s-ar putea să necesite contactarea Serviciul
          Clienți pentru a le actualiza.
        </p>
      </div>
      <ChangePassword />
      <UserDetails />
      <OffersAndPromotions />
      <TwoFactorAuthentication />
    </StyledDetails>
  );
}

export default Details;
