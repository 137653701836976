import React from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import { HashLink } from 'react-router-hash-link';

import Button from '@/components/button/Button';
import ToggleSwitch from '@/components/form/ToggleSwitch';
import { TCookies, useCookieConsentStore } from '@/store/cookieConsentStore';

const initialValues: TCookies = {
  essential: true,
  performance: true,
};

const Cookies = ({ closeModal }: { closeModal: () => void }): JSX.Element | null => {
  const acceptCookies = useCookieConsentStore(state => state.acceptCookies);

  const handleSubmit = ({ performance }: TCookies) => {
    acceptCookies(performance);
    closeModal();
  };

  return (
    <div
      id="e2e_cookiesModal"
      className="prose flex w-full max-w-[400px] flex-col overflow-hidden rounded-lg bg-white py-6 px-6 text-left sm:px-6"
    >
      <div className="mb-4 text-lg font-bold">Acest website folosește cookie-uri</div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => (
          <Form className="[&_label]:border-0">
            <Field name="essential">
              {({ field }: FieldProps) => (
                <ToggleSwitch
                  disabled
                  id="essential"
                  label="Cookie-urile strict necesare"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            </Field>
            <div className="mb-8 text-sm">
              Aceste fişiere cookie sunt necesare pentru funcționarea site-ului web și nu pot fi
              dezactivate
            </div>
            <Field name="performance">
              {({ field }: FieldProps) => (
                <ToggleSwitch
                  id="performance"
                  label="Cookie-uri de performanță"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            </Field>
            <div className="text-sm">
              Aceste fişiere cookie îmbunătățesc performanțele site-ului nostru prin colectarea de
              date analitice. Toate informațiile acumulate ne ajută să știm câte persoane vizitează
              acest site.
            </div>
            <div className="mt-8 text-sm">
              Pentru a afla mai multe despre cookie-urile specifice pe care le folosim, vă rugăm să
              vizitați{' '}
              <HashLink
                to="/declaratie-de-confidentialitate/#cookies"
                onClick={() => closeModal()}
                className="text-sm underline"
              >
                Politica de Confidențialitate și Cookie
              </HashLink>
              .
            </div>
            <div className="mt-12">
              <Button type="submit" size="small" className="w-full">
                Acceptă
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Cookies;
