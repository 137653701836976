import { Action } from 'redux';

import { WpPrivacyPolicy } from '@/models/wp-privacy-policy.model';
import { WpTermsAndConditions } from '@/models/wp-terms-and-conditions.model';

export enum WordpressActionType {
  GetTermsAndConditions = 'GET_TERMS_AND_CONDITIONS',
  GetTermsAndConditionsSuccess = 'GET_TERMS_AND_CONDITIONS_SUCCESS',
  GetPrivacyPolicy = 'GET_PRIVACY_POLICY',
  GetPrivacyPolicySuccess = 'GET_PRIVACY_POLICY_SUCCESS',
}

export type GetTermsAndConditionsAction = Action<WordpressActionType.GetTermsAndConditions>;
export type GetTermsAndConditionsSuccessAction =
  Action<WordpressActionType.GetTermsAndConditionsSuccess> & {
    terms: WpTermsAndConditions;
  };
export type GetPrivacyPolicyAction = Action<WordpressActionType.GetPrivacyPolicy>;

export type GetPrivacyPolicySuccessAction = Action<WordpressActionType.GetPrivacyPolicySuccess> & {
  privacyPolicy: WpPrivacyPolicy;
};

export type WordpressActions =
  | GetTermsAndConditionsAction
  | GetTermsAndConditionsSuccessAction
  | GetPrivacyPolicyAction
  | GetPrivacyPolicySuccessAction;

export const getTermsAndConditions = (): GetTermsAndConditionsAction => ({
  type: WordpressActionType.GetTermsAndConditions,
});

export const getTermsAndConditionsSuccess = (
  terms: WpTermsAndConditions,
): GetTermsAndConditionsSuccessAction => ({
  type: WordpressActionType.GetTermsAndConditionsSuccess,
  terms,
});

export const getPrivacyPolicy = (): GetPrivacyPolicyAction => ({
  type: WordpressActionType.GetPrivacyPolicy,
});

export const getPrivacyPolicySuccess = (
  privacyPolicy: WpPrivacyPolicy,
): GetPrivacyPolicySuccessAction => ({
  type: WordpressActionType.GetPrivacyPolicySuccess,
  privacyPolicy,
});
