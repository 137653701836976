import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

export type BasicRegistrationInfo = {
  email: string;
  password: string;
  cnp: string;
  birthDate: {
    day: string;
    month: string;
    year: string;
  };
  phone: {
    number: string;
    country: string;
    prefix: string;
    wholeNumber: string;
  };
};

export type AddressRegistrationInfo = {
  fullName: string;
  address: string;
  houseNumber: string;
  city: string;
  postalCode: string;
};

export type Consents = {
  tcConsent: boolean;
  marketingConsent: boolean;
  privacyAndCookiePolicyConsent: boolean;
  pepHioConsent: boolean;
};

export type AuthenticationInfo = {
  isTwoFactorAuthentication?: boolean;
  userId?: number;
  token?: string;
};

type State = BasicRegistrationInfo & AddressRegistrationInfo & Consents & AuthenticationInfo;

type Actions = {
  updateField: <K extends keyof State>(field: K, value: State[K]) => void;
  updateFields: (fields: Partial<State>) => void;
  reset: () => void;
};

const initialState: State = {
  email: '',
  password: '',
  cnp: '',
  birthDate: { day: '', month: '', year: '' },
  phone: {
    number: '',
    country: 'ro',
    prefix: '+40',
    wholeNumber: '',
  },
  fullName: '',
  address: '',
  houseNumber: '',
  postalCode: '',
  city: '',
  tcConsent: true,
  marketingConsent: true,
  privacyAndCookiePolicyConsent: true,
  pepHioConsent: true,
  isTwoFactorAuthentication: undefined,
  token: undefined,
  userId: undefined,
};

export const useRegistrationStore = create<{ data: State } & Actions>(set => ({
  data: initialState,
  updateField: (field, value) => {
    set(state => ({ data: { ...state.data, [field]: value } }));
  },
  updateFields: (fields: Partial<State>) => {
    set(state => ({ data: { ...state.data, ...fields } }));
  },
  reset: () => set({ data: initialState }),
}));

export const useRegistrationData = () => {
  return useRegistrationStore(state => state.data, shallow);
};

export const useBasicInfoData = () => {
  return useRegistrationStore(
    state => ({
      email: state.data.email,
      password: state.data.password,
      birthDate: state.data.birthDate,
      phone: state.data.phone,
      cnp: state.data.cnp,
    }),
    shallow,
  );
};

export const useAddressInfoData = () => {
  return useRegistrationStore(
    state => ({
      fullName: state.data.fullName,
      address: state.data.address,
      houseNumber: state.data.houseNumber,
      postalCode: state.data.postalCode,
      city: state.data.city,
    }),
    shallow,
  );
};

export const useAuthenticationInfoData = () => {
  return useRegistrationStore(
    state => ({
      isTwoFactorAuthentication: state.data.isTwoFactorAuthentication,
      userId: state.data.userId,
      token: state.data.token,
    }),
    shallow,
  );
};

export const useRegistrationActions = () => {
  return useRegistrationStore(
    state => ({
      updateField: state.updateField,
      updateFields: state.updateFields,
      reset: state.reset,
    }),
    shallow,
  );
};
