import * as React from 'react';
import { useEffect } from 'react';

import {
  PaginatedTable,
  paginatedTableTransactionsMapper,
  WalletTransactionsModel,
} from '@lucky7ventures/lucky-components';

import DateFilter from '@/components/formats/DateFilter';
import useUpdateHistory from '@/hooks/useUpdateHistory';
import { DATE_FILTER_OPTIONS } from '@/utils/helpers';

function PaymentHistory(): JSX.Element {
  const transactionsEndpoint = '/api/user/wallet/transactions';
  const { loading, history, setDateRange } =
    useUpdateHistory<WalletTransactionsModel>(transactionsEndpoint);
  const headerCells = ['Tip', 'Sumă', 'Metodă', 'Dată'];

  const selectDateRange = (id: number) => {
    setDateRange(DATE_FILTER_OPTIONS[id].dateRange);
  };

  // This effect will trigger the initial request for the Last 30 days of history
  useEffect(() => {
    setDateRange(DATE_FILTER_OPTIONS[1].dateRange);
  }, []);

  const rowsMapper = paginatedTableTransactionsMapper('ro-RO', {
    transactionType: { deposit: 'Depozit', withdrawal: 'Retrageri' },
  });

  return (
    <div>
      <DateFilter items={DATE_FILTER_OPTIONS} selectDateRange={selectDateRange} />
      <PaginatedTable<WalletTransactionsModel>
        noDataMsg="Nici o tranzacție"
        dataRows={history}
        headerCells={headerCells}
        isLoading={loading}
        loadingMsg="Se încarcă..."
        rowsMapper={rowsMapper}
      />
    </div>
  );
}

export default PaymentHistory;
