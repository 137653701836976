import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import ButtonDropdown, { ButtonDropdownItem } from '@/components/button/ButtonDropdown';
import { DateFilterModel } from '@/utils/helpers';

const StyledDateFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  > Button {
    margin-left: 1rem;
  }
`;

function DateFilter({
  items,
  selectDateRange,
}: {
  items: Record<number, DateFilterModel>;
  selectDateRange: (id: number) => void;
}): JSX.Element {
  const [selected, setSelected] = useState(1);
  const [dropdownItems, setDropdownItems] = useState<ButtonDropdownItem[]>([]);

  const select = (id: number) => {
    setSelected(id);
    selectDateRange(id);
  };

  useEffect(() => {
    setDropdownItems(
      Object.keys(items)
        .map(key => +key)
        .map(key => ({
          ...items[key],
          clickCallback: () => select(key),
          isActive: key === selected,
        })),
    );
  }, [items, selected]);

  return (
    <StyledDateFilter>
      <ButtonDropdown items={dropdownItems} toggleLabel="Filtrare" />
    </StyledDateFilter>
  );
}

export default DateFilter;
