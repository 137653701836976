import { CommonActions, CommonActionType } from '@/store/actions/commonActions';

export interface CommonState {
  loadZendesk: boolean;
}

const initialState: CommonState = {
  loadZendesk: false,
};

export const commonReducer = (state = initialState, action: CommonActions): CommonState => {
  switch (action.type) {
    case CommonActionType.LoadZendesk:
      return { ...state, loadZendesk: true };
    default:
      return state;
  }
};
