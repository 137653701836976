import React from 'react';

import { useSelector } from 'react-redux';

import CurrencyFormat from '@/components/formats/CurrencyFormat';
import { selectUserCurrency } from '@/store/selectors/userSelectors';
import { selectWalletTotalBalance } from '@/store/selectors/walletSelectors';

const TotalBalance = (): JSX.Element => {
  const totalBalance = useSelector(selectWalletTotalBalance);
  const currency = useSelector(selectUserCurrency);

  if (totalBalance === null || !currency) {
    return <span>Se încarcă...</span>;
  }

  return <CurrencyFormat currency={currency} amount={totalBalance} />;
};

export default TotalBalance;
