import React from 'react';

import classNames from 'classnames';

const StepSeparator = ({ isCompleted }: { isCompleted: boolean }) => {
  return (
    <div
      className={classNames(
        `duration-400 h-[3px] bg-gray-400 bg-gradient-to-l from-gray-400 to-brand-red from-50% to-50%
        bg-[length:200%] bg-[right] transition-all ease-in-out flex-1`,
        {
          '!bg-[left]': isCompleted,
        },
      )}
    />
  );
};

export default StepSeparator;
