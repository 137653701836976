import React from 'react';

import styled from 'styled-components/macro';

import Accordion from '@/components/accordion/Accordion';

const FAQ = [
  {
    header: 'Contul meu',
    questions: [
      {
        question: 'De ce am contul blocat?',
        answer:
          'Pot exista diverse motive pentru care nu te poți autentifica. Dacă contul tău a fost blocat din cauza introducerii repetate a unei parole greșite, poți solicita o nouă parolă utilizând funcția de resetare a parolei. Dacă problema persistă, te rugăm verifică emailul sau contactează echipa de suport.',
      },
      {
        question: 'Contul meu este închis, cum îl pot redeschide?',
        answer:
          'Poți redeschide contul dacă a fost închis la solicitarea ta sau dacă ai solicitat auto-excludere, iar perioada a expirat. În acest caz, contactează echipa de suport. În orice caz, nu poți redeschide contul dacă ai solicitat auto-excludere permanentă sau dacă contul a fost inchis de noi din orice motiv.',
      },
      {
        question: 'Cum resetez parola?',
        answer:
          'Pur și simplu faceți clic pe „Autentificare” și apoi „Ați uitat parola?” care se află chiar deasupra câmpului de parolă din partea dreaptă și urmați instrucțiunile.',
      },
      {
        question: 'Nu am primit emailul de resetare a parolei.',
        answer:
          'Te rugăm să te asiguri că ai introdus adresa de email corectă folosită la crearea contul. Dacă tot nu funcționează, verifică folderul de spam din căsuța de email sau contactează-ne.',
      },
      {
        question: 'Pot avea mai multe conturi la MagicJackpot?',
        answer:
          'Permitem doar crearea unui singur cont per utilizator. Dacă întâmpini probleme la autentificare sau dacă vrei să verifici dacă ai cont, te rugăm contactează-ne.',
      },
    ],
  },
  {
    header: 'Verificarea contului',
    questions: [
      {
        question: 'Cum îmi verific contul?',
        answer:
          'Întra în cont, mergi la "Setări", apasă pe "Verificare" și urmărește instrucțiunile.',
      },
      {
        question: 'Cât durează verificarea contului?',
        answer:
          'Dorim să verificăm contul in 24 de ore. În perioadele aglomerate sau dacă avem nevoie de documente suplimentare, ar putea dura ceva mai mult. Dacă dorești să verifici stadiul contului, accesează „Setări” și apoi „Verificați”.',
      },
    ],
  },
  {
    header: 'Bonusuri și Promoții',
    questions: [
      {
        question: 'De ce nu am primit bonusul?',
        answer:
          'La MagicJackpot trebuie să îți activezi întotdeauna bonusurile înainte ca acestea să devină disponibile. Poți face acest lucru fie din „Acasă”, fie direct în casierie. Dacă aveți rotiri gratuite disponibile, pur și simplu deschideți jocul și vor fi chiar acolo.',
      },
      {
        question: 'Pot retrage fondurile din balanța bonus?',
        answer:
          'Pentru a putea retrage banii din balanța bonus, trebuie să îndeplinești toate cerințele de rulaj. Verifică cât a mai ramas de rulat accesând casieria.',
      },
      {
        question: 'Ce reprezintă cerința de rulare?',
        answer:
          'Dacă nu este specificat, cerința de rulare este de 35 x Suma Bonusului plus Depunerea și 35 x Câștigurile din rotirile gratuite.',
      },
      {
        question: 'Cât mai am de rulat din bonus?',
        answer:
          'Mergi la Casierie, unde poți urmări progresul de rulare a bonusului și află mai multe detalii despre bonusurile active.',
      },
      {
        question: 'Bonusul meu expiră?',
        answer:
          'Da, toate bonusurile noastre au o dată de expirare care este mentionată în termenii și condițiile fiecărei campanii.',
      },
      {
        question: 'Pot reactiva un bonus anulat?',
        answer: 'Din păcate nu. Dacă refuzi un bonus, acesta nu poate fi reactivat.',
      },
    ],
  },
  {
    header: 'Depuneri și Retrageri',
    questions: [
      {
        question: 'Se percep taxe la depunere?',
        answer:
          'Nu percepem alte taxe de depunere decât cele reglementate de legislația aplicabilă. Taxele pot varia în funcție de metoda de depunere pe care o utilizați, iar taxele sunt vizibile în casierie.',
      },
      {
        question: 'Cât durează ca depunerea mea să apară în contul de joc?',
        answer:
          'Depunerile sunt creditate instant. Dacă întâmpini probleme, încercă să reîncărci pagina sau să te deconectezi și să te re-autentifici. Dacă depunerea nu se regăsește în cont după 5 minute, contactează echipa de suport.',
      },
      {
        question: 'De ce sunt respinse tranzacțiile de depunere?',
        answer:
          'Dacă depunerea este respinsă, asigură-te că ai introdus detaliile corecte și că sunt fonduri suficiente. Dacă problema persistă, te rugăm să contactezi banca și echipa noastră de asistență pentru clienți.',
      },
      {
        question: 'Cât durează să îmi retrag banii?',
        answer:
          'La inițierea retragerii, după ce contul este verificat, echipa noastră va procesa retragerea în termen de 24 de ore. Odată ce retragerea dvs. a fost procesată, poate dura până la 5 zile bancare înainte de a primi banii în funcție de metoda de plată. Poți găsi mai multe informații în casierie.',
      },
      {
        question: 'Unde este retragerea mea?',
        answer:
          'Reține că banii pot ajunge în cont și în 5 zile lucrătoare, în funcție de metoda de plată aleasă. Dacă nu ai primit banii după timpul specificat în secțiunea Casierie, te rugăm să contactezi echipa noastră de asistență pentru clienți și vom face tot posibilul pentru ca retragerea să fie urmărită.',
      },
    ],
  },
  {
    header: 'Siguranță și Securitate',
    questions: [
      {
        question: 'Esti autorizat?',
        answer:
          'MagicJackpot.ro este deținut și operat de MJP Interactive Ltd, Număr de Înregistrare C 95830; Level 1 Burlington Complex, Dragonara Road, St. Julians, Malta, prin MJP Interactive Ltd. St. Julians Sucursala Bucuresti, Număr de Înregistrare J40/22589/2023, CUI: 49190978, cu sediul in București, sector 5, str, Serg. Ion Nuțu nr. 44, et 8. Drept de organizare în baza licenței L1203785W001253 cu valabilitate 01.01.2021-31.12.2030 și în baza autorizației de exploatare obținută prin decizia 2506/19.12.2023.',
      },
      {
        question: 'Datele mele personale sunt securizate la MagicJackpot?',
        answer:
          'Da, toate informațiile trimise către și de la MagicJackpot sunt criptate folosind SSL pentru a se asigura că datele și activitatea personală rămân integrale și private. Poți afla mai multe accesând Politica de Confidențialitate și cea de Cookie-uri.',
      },
      {
        question: 'Jocurile și rezultatele sunt corecte la MagicJackpot?',
        answer:
          'Toți furnizorii noștri de jocuri sunt autorizati și obligați să utilizeze generatoare de numere aleatorii pentru a genera rezultatul pentru fiecare rundă de joc din cazinou. MagicJackpot este supus verificărilor periodice de către centre de testare autorizate, conform legislației în vigoare pentru a oferi jucătorilor o experiență echitabilă și imparțială.',
      },
    ],
  },
  {
    header: 'Joc Responsabil',
    questions: [
      {
        question:
          'Unde pot găsi informații despre depunerile, retragerile și istoricul meu de joc?',
        answer:
          'Pentru a vă ajuta să obțineți o prezentare generală a activității dvs. recente și cât ați cheltuit și câștigat, stocăm aceste informații direct în casierie. Veți găsi această pagină deschizând casieria și făcând clic pe fila Casierie și obținând acces la toată activitatea dvs. din ultimele 3 luni.',
      },
      {
        question:
          'Ce fac dacă nu vreau să primesc nicio comunicare de marketing de la MagicJackpot prin e-mail, text sau apel telefonic?',
        answer:
          'La înregistrarea contului MagicJackpot, poți alege să renunți la primirea materialelor promoționale de la echipa noastră de marketing. Dacă te răzgândești și dorești să schimbi canalele de comunicare preferate, accesează „Contul meu” din pagina „Setări”.',
      },
      {
        question: 'Auto-evaluare',
        answer:
          'În secțiunea „Setări” a paginii Joc Responsabil, oferim informații despre analizarea activității de joc, recomandări și informații utile despre cum să joci responsabil.',
      },
      {
        question: 'Vreau să închid contul, cum procedez?',
        answer:
          'În secțiunea „Setări” a paginii Joc Responsabil, poți închide contul imediat. Totodată, poți să contactezi echipa de suport prin Chat sau Email.',
      },
    ],
  },
];

const StyledFaq = styled.div`
  h2 {
    margin: 4rem 0 1rem;
  }
`;

function Faq(): JSX.Element {
  return (
    <StyledFaq>
      {FAQ.map(section => (
        <section key={section.header}>
          <h2>{section.header}</h2>
          {section.questions.map(question => (
            <Accordion question={question.question} key={question.question}>
              <p>{question.answer}</p>
            </Accordion>
          ))}
        </section>
      ))}
    </StyledFaq>
  );
}

export default Faq;
