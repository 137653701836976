import * as React from 'react';
import { useEffect } from 'react';

import {
  PaginatedTable,
  GameTransactionModel,
  paginatedTableGamesMapper,
} from '@lucky7ventures/lucky-components';

import DateFilter from '@/components/formats/DateFilter';
import useUpdateHistory from '@/hooks/useUpdateHistory';
import { DATE_FILTER_OPTIONS } from '@/utils/helpers';

function GamingHistory(): JSX.Element {
  const gamesHistoryEndpoint = '/api/games/transactions';
  const { loading, history, setDateRange } =
    useUpdateHistory<GameTransactionModel>(gamesHistoryEndpoint);
  const headerCells = ['Joc', 'Pariuri', 'Câștiguri', 'Dată'];

  const selectDateRange = (id: number) => {
    setDateRange(DATE_FILTER_OPTIONS[id].dateRange);
  };

  // This effect will trigger the initial request for the Last 30 days of history
  useEffect(() => {
    setDateRange(DATE_FILTER_OPTIONS[1].dateRange);
  }, []);

  const rowsMapper = paginatedTableGamesMapper('ro-RO');

  return (
    <div>
      <DateFilter items={DATE_FILTER_OPTIONS} selectDateRange={selectDateRange} />
      <PaginatedTable<GameTransactionModel>
        noDataMsg="Nici o tranzacție"
        dataRows={history}
        headerCells={headerCells}
        isLoading={loading}
        loadingMsg="Se încarcă..."
        rowsMapper={rowsMapper}
      />
    </div>
  );
}

export default GamingHistory;
