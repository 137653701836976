import { UserWalletPublic } from '@lucky7ventures/lucky-components';

import {
  GetWalletSuccessAction,
  WalletActions,
  WalletActionsTypes,
} from '@/store/actions/walletActions';

export interface WalletStateModel {
  wallet: UserWalletPublic | null;
  loading: boolean;
  error: boolean;
}

const defaultState: WalletStateModel = {
  wallet: null,
  loading: false,
  error: false,
};

const getWallet = (state: WalletStateModel): WalletStateModel => ({
  ...state,
  loading: true,
  error: false,
});

const getWalletSuccess = (
  state: WalletStateModel,
  action: GetWalletSuccessAction,
): WalletStateModel => ({
  ...state,
  loading: false,
  wallet: action.wallet,
});

const getWalletError = (state: WalletStateModel): WalletStateModel => ({
  ...state,
  loading: false,
  error: true,
});

export const walletReducer = (state = defaultState, action: WalletActions): WalletStateModel => {
  switch (action.type) {
    case WalletActionsTypes.Get:
      return getWallet(state);
    case WalletActionsTypes.GetSuccess:
      return getWalletSuccess(state, action);
    case WalletActionsTypes.GetError:
      return getWalletError(state);
    case WalletActionsTypes.ClearWalletState:
      return defaultState;
    default:
      return state;
  }
};
