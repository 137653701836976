import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import ParsedString from '@/components/formats/ParsedString';
import { StyledLegal } from '@/pages/legal/LegalPage';
import { getPrivacyPolicy } from '@/store/actions/wordpressActions';
import { selectPrivacyPolicy } from '@/store/selectors/wordpressSelectors';

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const pp = useSelector(selectPrivacyPolicy);

  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  return (
    <StyledLegal>
      <Helmet>
        <body className="header-background" />
      </Helmet>
      <div className="content">
        {pp?.content ? (
          <ParsedString string={pp.content.toString()} />
        ) : (
          <div className="loading">Se încarcă...</div>
        )}
      </div>
    </StyledLegal>
  );
}

export default PrivacyPolicy;
