import React from 'react';

import { GiftIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-use';
import styled from 'styled-components/macro';

import CreditCardIcon from '@/components/icons/CreditCardIcon';
import GameIcon from '@/components/icons/GameIcon';
import HomeIcon from '@/components/icons/HomeIcon';
import MenuIcon from '@/components/icons/MenuIcon';
import { useCashierShow } from '@/hooks/useCashier';
import usePromotionsRestriction from '@/hooks/usePromotionsRestriction';
import { DrawerEnum } from '@/models/enums/drawer.enum';
import { openDrawer } from '@/store/actions/drawerActions';
import { selectAuthIsAuthenticated } from '@/store/selectors/authSelectors';

const StyledMobileNav = styled.nav<{ isAuthenticated: boolean }>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  z-index: 101;
  display: flex;
  background: ${props => props.theme.white};
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.2);

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    display: none;
  }

  a,
  button {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.white};
    margin: 0;
    padding: 0;
    width: 100%;
    height: 64px;
    position: relative;
    font-size: 11px;

    &.cashier {
      display: ${props => (props.isAuthenticated ? 'inline-flex' : 'none')};
    }

    svg {
      height: 20px;
      width: 20px;
      margin-bottom: 4px;

      &.close {
        width: 16px;
        height: 16px;
      }
    }

    &.active {
      color: ${props => props.theme.red};
    }

    .badge {
      position: absolute;
      top: 14px;
      right: 25%;
      height: 21px;
      width: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 21px;
      font-size: 10px;
      text-align: center;
      border: 3px solid ${props => props.theme.white};
      color: white;
      background: ${props => props.theme.red};
    }
  }
`;

function MobileNav(): JSX.Element {
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const dispatch = useDispatch();
  const showCashier = useCashierShow();
  const { pathname } = useLocation();
  const { promotionsRestricted } = usePromotionsRestriction();
  function scrollHandler(url: string) {
    if (pathname === url) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  return (
    <StyledMobileNav isAuthenticated={isAuthenticated} id="mobile-nav">
      <NavLink end to="/" onClick={() => scrollHandler(`/`)}>
        <HomeIcon />
        Acasă
      </NavLink>
      <NavLink to="/jocuri" onClick={() => scrollHandler(`/jocuri`)}>
        <GameIcon />
        Jocuri
      </NavLink>
      <button type="button" className="cashier" onClick={() => showCashier()}>
        <CreditCardIcon />
        Casierie
      </button>
      {!promotionsRestricted && (
        <NavLink end to="/promotii" className="promo">
          <GiftIcon />
          Promoții
        </NavLink>
      )}
      <button type="button" onClick={() => dispatch(openDrawer(DrawerEnum.Menu))}>
        <MenuIcon />
        Meniul
      </button>
    </StyledMobileNav>
  );
}

export default MobileNav;
